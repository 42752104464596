import React from 'react'

const CatServicesRealEstate  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={2002}>{props.strings.cat2002}</option>
            <option value={2003}>{props.strings.cat2003}</option>
            <option value={2004}>{props.strings.cat2004}</option>
            <option value={2005}>{props.strings.cat2005}</option>
            <option value={2006}>{props.strings.cat2006}</option>
            <option value={2007}>{props.strings.cat2007}</option>
            <option value={2008}>{props.strings.cat2008}</option>
            <option value={2009}>{props.strings.cat2009}</option>
            <option value={2010}>{props.strings.cat2010}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatServicesRealEstate 