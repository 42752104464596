import React from 'react'

const CatElectronicsNotebookParts  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10302}>{props.strings.cat10302}</option>
            <option value={10303}>{props.strings.cat10303}</option>
            <option value={10304}>{props.strings.cat10304}</option>
            <option value={10305}>{props.strings.cat10305}</option>
            <option value={10307}>{props.strings.cat10307}</option>
            <option value={10308}>{props.strings.cat10308}</option>
            <option value={10309}>{props.strings.cat10309}</option>
            <option value={10310}>{props.strings.cat10310}</option>
            <option value={10311}>{props.strings.cat10311}</option>
            <option value={10312}>{props.strings.cat10312}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsNotebookParts 