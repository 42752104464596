import React from 'react'

const Cafes = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={16001}>{props.strings.cat16001}</option>
        </>
    )
}

export default Cafes