import React from 'react'

const CarManufacturers = (props) => {
    return (
        <>
            <option value={0}>{props.strings.manufacturer}...</option>
            <option value={1000001}>BMW</option>
            <option value={1000100}>Mercedes-Benz</option>
            <option value={1000200}>TOYOTA</option>
            <option value={1000300}>LEXUS</option>
            <option value={1000400}>AUDI</option>
            <option value={1000500}>HYUNDAI</option>
            <option value={1000600}>VOLKSWAGEN</option>
            <option value={1000700}>CHEVROLET</option>
            <option value={1000800}>MAZDA</option>
            <option value={1000900}>INFINITI</option>
            <option value={1001000}>NISSAN</option>
            <option value={1001100}>KIA</option>
            <option value={1001200}>JEEP</option>
            <option value={1001300}>FORD</option>
            <option value={1001400}>MITSUBISHI</option>
            <option value={1001500}>CHRYSLER</option>
            <option value={1001600}>MAN</option>
            <option value={1001700}>VOLVO</option>
            <option value={1001800}>OPEL</option>
        </>
    )
}

export default CarManufacturers