import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Stores from './Stores'
import {
    requestStores,
    setIsFetching,
    setCurrentPage,
    setSearchText,
    setSuccess as setStoresSuccess
} from '../../redux/stores-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow
} from '../../redux/notify-reducer'

const StoresContainer = (props) => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

    const dispatch = useDispatch()

    const order = useSelector(state => state.stores.order)
    const location = useSelector(state => state.stores.location)
    const marker = useSelector(state => state.stores.marker)

    const search = useSelector(state => state.stores.search)

    const stores = useSelector(state => state.stores.stores)
    const count = useSelector(state => state.stores.count)
    const totalCount = useSelector(state => state.stores.totalCount)
    const currentPage = useSelector(state => state.stores.currentPage)
    const pagesCount = useSelector(state => state.stores.pagesCount)
    
    const storesStatus = useSelector(state => state.stores.status)
    const storesSuccess = useSelector(state => state.stores.success)

    const back = useSelector(state => state.stores.back)
    const isFetching = useSelector(state => state.stores.isFetching)

    useEffect(() => {
        if (isFetching) {
            dispatch(requestStores(currentPage, count, location, marker, search, order))
        }
    }, [count, currentPage, dispatch, isFetching, location, marker, order, search])
    
    const onSubmit = (values, { setSubmitting }) => {
        const text = values.search
        dispatch(setSearchText(text))
        dispatch(setCurrentPage(1))
        dispatch(setIsFetching(true))
        setSubmitting(false);
    }

    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setIsFetching(true))
    }
    
    useEffect(
        () => {
            if(storesSuccess){
                dispatch(setNotifySuccess(storesSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setStoresSuccess(null))
            }
        }, [dispatch, storesSuccess]
    )

    return (
        <Stores
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            finderStores={props.finderStores}

            stores={stores}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            storesStatus={storesStatus}
            back={back}
            isFetching={isFetching}

            onSubmit={onSubmit}
            onPageChanged={onPageChanged}
        />
    )
}

export default StoresContainer