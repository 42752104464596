import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    requestStore,
    setImages,
    setActiveIndex,
    setProfile,
    setStore,
    setIsPhotosCrope,
    setSuccess as setStoreSuccess,
    setError as setStoreError
} from '../../redux/adminStore-reducer'
import {
    requestRating,
    setProfileRating,
    setRating,
    setRatingCount,
    setSuccess as setRatingSuccess,
    setSuccess as setRatingError,
    setIsFetching as setRatingIsFetching
} from '../../redux/rating-reducer'
import { changeStatus, remove, setUpdated, setSuccess, setError } from '../../redux/adminStoreSet-reducer'
import {
    requestStoreProducts,
    setProducts,
    setViewStatus,
    setCurrentPage,
    setSuccess as setStoreProductsSuccess,
    setError as setStoreProductsError,
    setIsFetching as setStoreProductsIsFetching
} from '../../redux/adminStoreProducts-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setIsFetching } from '../../redux/adminStores-reducer'
import AdminStore from './AdminStore'

const AdminStoreContainer = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()

    const store = useSelector(state => state.adminStore.store)
    const images = useSelector(state => state.adminStore.images)
    const activeIndex = useSelector(state => state.adminStore.activeIndex)
    const ostProfile = useSelector(state => state.adminStore.profile)
    const isPhotosCrope = useSelector(state => state.adminStore.isPhotosCrope)
    const storeStatus = useSelector(state => state.adminStore.status)
    const storeSuccess = useSelector(state => state.adminStore.success)
    const storeError = useSelector(state => state.adminStore.error)
    const back = useSelector(state => state.adminStore.back)
    const storeIsFetching = useSelector(state => state.adminStore.isFetching)

    const rating = useSelector(state => state.rating.rating)
    const ratingCount = useSelector(state => state.rating.ratingCount)
    const ratingSuccess = useSelector(state => state.rating.success)
    const ratingError = useSelector(state => state.rating.error)
    const ratingIsFetching = useSelector(state => state.rating.isFetching)

    const products = useSelector(state => state.adminStoreProducts.products)
    const count = useSelector(state => state.adminStoreProducts.count)
    const totalCount = useSelector(state => state.adminStoreProducts.totalCount)
    const currentPage = useSelector(state => state.adminStoreProducts.currentPage)
    const pagesCount = useSelector(state => state.adminStoreProducts.pagesCount)
    const productsStatus = useSelector(state => state.adminStoreProducts.status)
    const productsSuccess = useSelector(state => state.adminStoreProducts.success)
    const productsError = useSelector(state => state.adminStoreProducts.error)
    const viewStatus = useSelector(state => state.adminStoreProducts.viewStatus)
    const productsIsFetching = useSelector(state => state.adminStoreProducts.isFetching)

    const updated = useSelector(state => state.adminStoreSet.updated)
    const storeSetSuccess = useSelector(state => state.adminStoreSet.success)
    const storeSetError = useSelector(state => state.adminStoreSet.error)
    const isFetching = useSelector(state => state.adminStoreSet.isFetching)

    const admin = 1

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        } else if (!params.storeId) {
            navigate('/admin/stores')
        } else if (!store) {
            navigate('/admin/stores')
        }
    }, [navigate, props.isAuth, props.isAdmin, params.storeId, store])
    
    useEffect(() => {
        if (params.storeId || updated) {
            dispatch(setStore([]))
            dispatch(setImages([]))
            dispatch(setActiveIndex(0))
            dispatch(setProfile([]))
            dispatch(requestStore(params.storeId, admin))
            if (updated) {
                dispatch(setIsFetching(true))
                dispatch(setUpdated(false))
            }
            window.scrollTo({top: 0, left: 0})
        }
    }, [dispatch, navigate, params.storeId, updated])

    useEffect(() => {
        if (params.storeId) {
            dispatch(setProfileRating(null))
            dispatch(setRating(null))
            dispatch(setRatingCount(null))
            dispatch(requestRating(params.storeId))
            dispatch(setRatingIsFetching(false))
        }
    }, [dispatch, params.storeId, ratingIsFetching])

    const onSubmitView = async (statusId) => {
        dispatch(changeStatus(admin, params.storeId, statusId))
    }

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(admin, params.storeId, trash))
        dispatch(setIsFetching(true))
        setTimeout(() => {
            navigate('/admin/stores')
        }, 1000)
    }

// products section
    useEffect(() => {
        if (params.storeId) {
            dispatch(setProducts([]))
            dispatch(requestStoreProducts(admin, count, currentPage, params.storeId, viewStatus))
        }
    }, [dispatch, navigate, count, currentPage, params.storeId, viewStatus])

    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setStoreProductsIsFetching(true))
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    const setStatus = (status) => {
        dispatch(setProducts([]))
        dispatch(setCurrentPage(1))
        dispatch(setViewStatus(status))
    }

    const crope = () => {
        if(isPhotosCrope) {
            dispatch(setIsPhotosCrope(false))
        } else {
            dispatch(setIsPhotosCrope(true))
        }
    }

    useEffect(
        () => {
            if(storeError){
                dispatch(setNotifyError(storeError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setStoreError(null))
            } else if(ratingError){
                dispatch(setNotifyError(ratingError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setRatingError(null))
            } else if(productsError){
                dispatch(setNotifyError(productsError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setStoreProductsError(null))
            } else if(storeSetError){
                dispatch(setNotifyError(storeSetError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(storeSuccess){
                dispatch(setNotifySuccess(storeSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setStoreSuccess(null))
            } else if(ratingSuccess){
                dispatch(setNotifySuccess(ratingSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setRatingSuccess(null))
            } else if(productsSuccess){
                dispatch(setNotifySuccess(productsSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setStoreProductsSuccess(null))
            } else if(storeSetSuccess){
                dispatch(setNotifySuccess(storeSetSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, storeError, ratingError, productsError, storeSetError, storeSuccess, ratingSuccess, productsSuccess, storeSetSuccess]
    )

    return (
        <AdminStore
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            store={store}
            images={images}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            ostProfile={ostProfile}
            isPhotosCrope={isPhotosCrope}
            crope={crope}
            storeStatus={storeStatus}
            back={back}
            storeIsFetching={storeIsFetching}

            rating={rating}
            ratingCount={ratingCount}

            isFetching={isFetching}
            
            products={products}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            productsStatus={productsStatus}
            productsIsFetching={productsIsFetching}
            
            onPageChanged={onPageChanged}
            setStatus={setStatus}
            
            onSubmitView={onSubmitView}
            onSubmitTrash={onSubmitTrash}
        />
    )
}

export default AdminStoreContainer