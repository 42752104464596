
const SET_STATUS = 'home/SET_STATUS'
const SET_SUCCESS = 'home/SET_SUCCESS'
const IS_FETCHING = 'home/IS_FETCHING'

let initialState = {
    status: null,
    success: null,
    back: false,
    isFetching: true
}

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export default homeReducer