import React from 'react'

const CatElectronicsForGaming  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10241}>{props.strings.cat10241}</option>
            <option value={10242}>{props.strings.cat10242}</option>
            <option value={10243}>{props.strings.cat10243}</option>
            <option value={10244}>{props.strings.cat10244}</option>
            <option value={10245}>{props.strings.cat10245}</option>
            <option value={100002}>{props.strings.cat100002}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsForGaming 