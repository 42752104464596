import React from 'react'

const CatManClothes  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={11005}>{props.strings.cat11005}</option>
            <option value={11007}>{props.strings.cat11007}</option>
            <option value={11010}>{props.strings.cat11010}</option>
            <option value={11013}>{props.strings.cat11013}</option>
            <option value={11017}>{props.strings.cat11017}</option>
            <option value={11020}>{props.strings.cat11020}</option>
            <option value={11023}>{props.strings.cat11023}</option>
            <option value={11035}>{props.strings.cat11035}</option>
            <option value={11037}>{props.strings.cat11037}</option>
            <option value={11040}>{props.strings.cat11040}</option>
            <option value={11045}>{props.strings.cat11045}</option>
            <option value={11047}>{props.strings.cat11047}</option>
            <option value={11050}>{props.strings.cat11050}</option>
            <option value={11053}>{props.strings.cat11053}</option>
            <option value={11057}>{props.strings.cat11057}</option>
            <option value={11060}>{props.strings.cat11060}</option>
            <option value={11063}>{props.strings.cat11063}</option>
            <option value={11067}>{props.strings.cat11067}</option>
            <option value={11070}>{props.strings.cat11070}</option>
            <option value={11073}>{props.strings.cat11073}</option>
            <option value={11075}>{props.strings.cat11075}</option>
            <option value={11077}>{props.strings.cat11077}</option>
            <option value={11080}>{props.strings.cat11080}</option>
            <option value={11083}>{props.strings.cat11083}</option>
            <option value={11087}>{props.strings.cat11087}</option>
            <option value={11090}>{props.strings.cat11090}</option>
            <option value={11093}>{props.strings.cat11093}</option>
            <option value={11095}>{props.strings.cat11095}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatManClothes 