import React from 'react'

const CatBuildingWood  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={7201}>{props.strings.cat7201}</option>
            <option value={7205}>{props.strings.cat7205}</option>
            <option value={7210}>{props.strings.cat7210}</option>
            <option value={7215}>{props.strings.cat7215}</option>
            <option value={7220}>{props.strings.cat7220}</option>
            <option value={7225}>{props.strings.cat7225}</option>
            <option value={7230}>{props.strings.cat7230}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBuildingWood 