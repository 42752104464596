import React from 'react'

const CatHomeOfficeFurniture  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={8051}>{props.strings.cat8051}</option>
            <option value={8052}>{props.strings.cat8052}</option>
            <option value={8053}>{props.strings.cat8053}</option>
            <option value={8054}>{props.strings.cat8054}</option>
            <option value={8055}>{props.strings.cat8055}</option>
            <option value={8056}>{props.strings.cat8056}</option>
            <option value={8057}>{props.strings.cat8057}</option>
            <option value={8058}>{props.strings.cat8058}</option>
            <option value={8059}>{props.strings.cat8059}</option>
            <option value={8060}>{props.strings.cat8060}</option>
            <option value={8061}>{props.strings.cat8061}</option>
            <option value={8062}>{props.strings.cat8062}</option>
            <option value={8063}>{props.strings.cat8063}</option>
            <option value={8064}>{props.strings.cat8064}</option>
            <option value={8065}>{props.strings.cat8065}</option>
            <option value={8066}>{props.strings.cat8066}</option>
            <option value={8067}>{props.strings.cat8067}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatHomeOfficeFurniture 