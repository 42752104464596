import React from 'react'
import dark from '../../../CSS/Dark.module.css'
import light from '../../../CSS/Light.module.css'
import BlockPanel from '../../common/BlockPanel/BlockPanel'
import SetPanel from '../../common/SetPanel/SetPanel'
import Success from '../../common/Success/Success'

const Set = (props) => {
    return (
        <>
            <BlockPanel
                theme={props.theme}
                iconLeft={<i className="fas fa-chevron-left"></i>}
                leftLink={"/mystore"}
                icon1={<i className="fas fa-store-alt"></i>}
                title={props.store.title}
            />
            <Success
                theme={props.theme}
                icon={<i className="far fa-play-circle"></i>}
                success={props.strings.media}
            />
            <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                <SetPanel
                    theme={props.theme}
                    to={"/mystore/settings/image"}
                    text={props.strings.images}
                    iconLeft={<i className="fas fa-image"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
            </div>
            <Success
                theme={props.theme}
                icon={<i className="far fa-edit"></i>}
                success={props.strings.main}
            />
            <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                <SetPanel
                    theme={props.theme}
                    to={"/mystore/settings/title"}
                    text={props.strings.title}
                    iconLeft={<i className="fas fa-store-alt"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
                <SetPanel
                    theme={props.theme}
                    to={"/mystore/settings/about"}
                    text={props.strings.aboutStore}
                    iconLeft={<i className="fas fa-file-alt"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
            </div>
            <Success
                theme={props.theme}
                icon={<i className="far fa-check-circle"></i>}
                success={props.strings.security}
            />
            <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                <SetPanel
                    theme={props.theme}
                    to={"/mystore/settings/trash"}
                    text={props.strings.trash}
                    iconLeft={<i className="fas fa-trash"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
            </div>
        </>
    )
}

export default Set