import { CommentsAPI } from '../api/api';

const SET_ACCESS = 'addComment/SET_SUCCESS'
const SET_SUCCESS = 'addComment/SET_SUCCESS'
const SET_ERROR = 'addComment/SET_ERROR'
const IS_FETCHING = 'addComment/IS_FETCHING'

let initialState = {
    access: false,
    success: null,
    error: null,
    isFetching: false
}

const addCommentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCESS:
            return { ...state, access: action.access }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setAccess = (access) => ({ type: SET_ACCESS, access })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const addComment = (id, text) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await CommentsAPI.addComment(id, text)
        dispatch(setAccess(data.data.access))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default addCommentReducer