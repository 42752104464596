import React from 'react'

const Bmw = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1000001}>BMW 1 series</option>
            <option value={1000002}>BMW 3 series</option>
            <option value={1000003}>BMW 320</option>
            <option value={1000004}>BMW 323</option>
            <option value={1000005}>BMW 325</option>
            <option value={1000006}>BMW 328</option>
            <option value={1000007}>BMW 330</option>
            <option value={1000008}>BMW 5 series</option>
            <option value={1000009}>BMW 520</option>
            <option value={1000010}>BMW 525</option>
            <option value={1000011}>BMW 528</option>
            <option value={1000012}>BMW 530</option>
            <option value={1000013}>BMW 535</option>
            <option value={1000014}>BMW 540</option>
            <option value={1000015}>BMW 545</option>
            <option value={1000016}>BMW 550</option>
            <option value={1000017}>BMW 6 series</option>
            <option value={1000018}>BMW 600</option>
            <option value={1000019}>BMW 640M</option>
            <option value={1000020}>BMW 645</option>
            <option value={1000021}>BMW 650</option>
            <option value={1000022}>BMW 7 series</option>
            <option value={1000023}>BMW 730</option>
            <option value={1000024}>BMW 745</option>
            <option value={1000025}>BMW 750</option>
            <option value={1000026}>BMW 760</option>
            <option value={1000027}>BMW 8 series</option>
            <option value={1000028}>BMW Alpina B7</option>
            <option value={1000029}>BMW E34</option>
            <option value={1000030}>BMW E39</option>
            <option value={1000031}>BMW E46</option>
            <option value={1000032}>BMW E60</option>
            <option value={1000033}>BMW F07</option>
            <option value={1000034}>BMW F10</option>
            <option value={1000035}>BMW F10 M5</option>
            <option value={1000036}>BMW F11</option>
            <option value={1000037}>BMW F30</option>
            <option value={1000038}>BMW GT</option>
            <option value={1000039}>BMW M</option>
            <option value={1000040}>BMW M Roadster</option>
            <option value={1000041}>BMW M3</option>
            <option value={1000042}>BMW M4</option>
            <option value={1000043}>BMW M5</option>
            <option value={1000044}>BMW M6</option>
            <option value={1000045}>BMW W212</option>
            <option value={1000046}>BMW X1</option>
            <option value={1000047}>BMW X3</option>
            <option value={1000048}>BMW X4</option>
            <option value={1000049}>BMW X5</option>
            <option value={1000050}>BMW X5 M</option>
            <option value={1000051}>BMW X6</option>
            <option value={1000052}>BMW X6 M</option>
            <option value={1000053}>BMW Z3</option>
            <option value={1000054}>BMW Z4</option>
            <option value={1000055}>BMW Z4 M Roadster</option>
            <option value={1000056}>BMW Z8</option>
        </>
    )
}

export default Bmw