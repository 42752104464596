import React from 'react'

const Ashgabat = (props) => {
    return (
        <>
            <option value={null}>{props.strings.marker}...</option>
            <option value={1}>{props.strings.loc1}</option>
        </>
    )
}

export default Ashgabat