import { SitebarAPI } from '../api/api'

const SET_THEME = 'sitebar/SET_THEME'
const SET_STRINGS = 'strings/SET_STRINGS'
const SET_SITEBAR_SLIDER ='sitebar/SET_SITEBAR_SLIDER'
const SET_IS_FETCHING = 'sitebar/SET_IS_FETCHING'

let initialState = {
    theme: 'light',
    strings: [],
    sitebarSlider: true,
    isFetching: true
}

const sitebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_THEME:
            return { ...state, theme: action.theme }
        case SET_STRINGS:
            return {...state, strings: action.strings}
        case SET_SITEBAR_SLIDER:
            return { ...state, sitebarSlider: action.sitebarSlider }
        case SET_IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setTheme = (theme) => ({ type: SET_THEME, theme })
export const setStrings = (strings) => ({ type: SET_STRINGS, strings })
export const setSitebarSlider = (sitebarSlider) => ({ type: SET_SITEBAR_SLIDER, sitebarSlider })
export const setIsFetching = (isFetching) => ({ type: SET_IS_FETCHING, isFetching })

export const requestStrings = () => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await SitebarAPI.strings()
        dispatch(setStrings(data.data.strings))
        dispatch(setIsFetching(false))
    }
}

export const requestTheme = () => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await SitebarAPI.theme()
        dispatch(setTheme(data.data.theme))
        dispatch(setIsFetching(false))
    }
}

export const themeData = (theme) => {
    return async (dispatch) => {
        await SitebarAPI.themeData(theme)
        dispatch(setIsFetching(true))
    }
}

export const languageData = (language) => {
    return async (dispatch) => {
        await SitebarAPI.languageData(language)
        dispatch(setIsFetching(true))
    }
}

export default sitebarReducer