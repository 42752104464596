import React from 'react'

const CatServicesComputer  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={2061}>{props.strings.cat2061}</option>
            <option value={2062}>{props.strings.cat2062}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatServicesComputer 