import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'
import RatingFull from '../additions/RatingFull/RatingFull'

const StoreFull = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockViewFull : dark.blockViewFull}>
            
            {props.status
                ? <div className={props.theme === 'light' ? light.status : dark.status}>
                    {props.strings['status' + props.status]}...
                </div>
                : null
            }
            
            <div className={props.theme === 'light' ? light.fullInfo : dark.fullInfo}>
                {props.title /* НАИМЕНОВАНИЕ */
                    ? <div className={props.theme === 'light' ? light.title : dark.title}>
                        <i className="fas fa-store-alt"></i> {props.title}
                    </div>
                    : null
                }

                {props.location /* ЛОКАЦИЯ */
                    ? <div className={props.theme === 'light' ? light.info : dark.info}>
                        <i className="fas fa-map-marker-alt"></i> {props.strings.location}: {props.strings['loc' + props.location]}. {props.strings['loc' + props.marker]}.
                    </div>
                    : null
                }

                {props.text /* ТЕКСТ */
                    ? <div className={props.theme === 'light' ? light.text : dark.text}>
                        {props.text}
                    </div>
                    : null
                }

                {props.phone /* ТЕЛЕФОН */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-phone-square"></i> {props.strings.phone}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.phone}
                        </div>
                        
                    </div>
                    : null
                }

                {props.pub /* ДАТА */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-calendar-check"></i> {props.strings.reg}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.pub}
                        </div>
                        
                    </div>
                    : null
                }
                
                <div className={props.theme === 'light' ? light.views : dark.views}>
                    <RatingFull strings={props.strings} theme={props.theme} rating={props.rating} ratingCount={props.ratingCount}/>
                </div>

            </div>
        </div>
    )
}

export default StoreFull