import React from 'react'

const CatFoodsBeverages  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6005}>{props.strings.cat6005}</option>
            <option value={6010}>{props.strings.cat6010}</option>
            <option value={6015}>{props.strings.cat6015}</option>
            <option value={6020}>{props.strings.cat6020}</option>
            <option value={6025}>{props.strings.cat6025}</option>
            <option value={6030}>{props.strings.cat6030}</option>
            <option value={6035}>{props.strings.cat6035}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsBeverages 