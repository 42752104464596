import React from 'react'

const Drive = (props) => {
    return (
        <>
            <option value={0}>{props.strings.drive}...</option>
            <option value={1}>RWD - {props.strings.drive1}</option>
            <option value={2}>FWD - {props.strings.drive2}</option>
            <option value={3}>4WD - {props.strings.drive3}</option>
            <option value={4}>AWD - {props.strings.drive4}</option>
        </>
    )
}

export default Drive