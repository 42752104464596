import React from 'react'

const CatElectronicsConnection  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10601}>{props.strings.cat10601}</option>
            <option value={10603}>{props.strings.cat10603}</option>
            <option value={10604}>{props.strings.cat10604}</option>
            <option value={10605}>{props.strings.cat10605}</option>
            <option value={10606}>{props.strings.cat10606}</option>
            <option value={10607}>{props.strings.cat10607}</option>
            <option value={10608}>{props.strings.cat10608}</option>
            <option value={10609}>{props.strings.cat10609}</option>
            <option value={10610}>{props.strings.cat10610}</option>
            <option value={100002}>{props.strings.cat100002}</option>
            <option value={100003}>{props.strings.cat100003}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsConnection 