import React from 'react'

const CatHomeOfficeCounters  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={8401}>{props.strings.cat8401}</option>
            <option value={8402}>{props.strings.cat8402}</option>
            <option value={8403}>{props.strings.cat8403}</option>
        </>
    )
}

export default CatHomeOfficeCounters 