import React from 'react'
import dark from './FormDark.module.css'
import light from './FormLight.module.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const AdminLoginForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.formPanel : dark.formPanel}>
            <Formik
                initialValues={props.initialValues}
                validate={props.validate}
                onSubmit={props.onSubmit}
                >
                {({ errors, isSubmitting }) => (
                    <Form className={props.theme === 'light' ? light.form : dark.form} >

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="login"
                            component="div"
                        />
                        <Field
                            type="text"
                            name="login"
                            autoComplete="login"
                            placeholder={props.strings.login}
                            pattern="[A-Z,a-z,0-9]{3,20}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.strings + ' (A-Z, a-z).'}
                        />


                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="salt"
                            component="div"
                        />
                        <Field
                            type="password"
                            name="salt"
                            autoComplete="current-password"
                            placeholder={props.strings.password}
                            pattern="[A-Z,a-z,0-9]{8,15}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.strings + ' (A-Z, a-z).'}
                        />

                        <button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {props.strings.entrance}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default AdminLoginForm