import React from 'react'

const CatHomeOfficeHouseholdGoods  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={8301}>{props.strings.cat8301}</option>
            <option value={8302}>{props.strings.cat8302}</option>
            <option value={8303}>{props.strings.cat8303}</option>
            <option value={8304}>{props.strings.cat8304}</option>
            <option value={8305}>{props.strings.cat8305}</option>
            <option value={8306}>{props.strings.cat8306}</option>
            <option value={8307}>{props.strings.cat8307}</option>
            <option value={8308}>{props.strings.cat8308}</option>
            <option value={8309}>{props.strings.cat8309}</option>
            <option value={8310}>{props.strings.cat8310}</option>
            <option value={8311}>{props.strings.cat8311}</option>
            <option value={8312}>{props.strings.cat8312}</option>
            <option value={8313}>{props.strings.cat8313}</option>
            <option value={8314}>{props.strings.cat8314}</option>
            <option value={8315}>{props.strings.cat8315}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatHomeOfficeHouseholdGoods 