import React from 'react'

const CatAnimalsPetsGoods  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={18101}>{props.strings.cat18101}</option>
            <option value={18103}>{props.strings.cat18103}</option>
            <option value={18105}>{props.strings.cat18105}</option>
            <option value={18107}>{props.strings.cat18107}</option>
            <option value={18110}>{props.strings.cat18110}</option>
            <option value={18113}>{props.strings.cat18113}</option>
            <option value={18115}>{props.strings.cat18115}</option>
            <option value={18117}>{props.strings.cat18117}</option>
            <option value={18120}>{props.strings.cat18120}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatAnimalsPetsGoods