import React from 'react'

const CatSportGoods  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={15002}>{props.strings.cat15002}</option>
            <option value={15003}>{props.strings.cat15003}</option>
            <option value={15004}>{props.strings.cat15004}</option>
            <option value={15005}>{props.strings.cat15005}</option>
            <option value={15006}>{props.strings.cat15006}</option>
            <option value={15007}>{props.strings.cat15007}</option>
            <option value={15008}>{props.strings.cat15008}</option>
            <option value={15009}>{props.strings.cat15009}</option>
            <option value={15010}>{props.strings.cat15010}</option>
            <option value={15011}>{props.strings.cat15011}</option>
            <option value={15012}>{props.strings.cat15012}</option>
            <option value={15013}>{props.strings.cat15013}</option>
            <option value={15014}>{props.strings.cat15014}</option>
            <option value={15015}>{props.strings.cat15015}</option>
            <option value={15016}>{props.strings.cat15016}</option>
            <option value={15017}>{props.strings.cat15017}</option>
            <option value={15018}>{props.strings.cat15018}</option>
            <option value={15019}>{props.strings.cat15019}</option>
            <option value={15020}>{props.strings.cat15020}</option>
            <option value={15021}>{props.strings.cat15021}</option>
            <option value={15022}>{props.strings.cat15022}</option>
            <option value={15023}>{props.strings.cat15023}</option>
            <option value={15024}>{props.strings.cat15024}</option>
            <option value={15025}>{props.strings.cat15025}</option>
            <option value={15026}>{props.strings.cat15026}</option>
            <option value={15027}>{props.strings.cat15027}</option>
            <option value={15028}>{props.strings.cat15028}</option>
            <option value={15029}>{props.strings.cat15029}</option>
            <option value={15030}>{props.strings.cat15030}</option>
            <option value={15031}>{props.strings.cat15031}</option>
            <option value={15032}>{props.strings.cat15032}</option>
            <option value={15033}>{props.strings.cat15033}</option>
            <option value={15034}>{props.strings.cat15034}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatSportGoods