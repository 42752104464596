import React from 'react'
import dark from './FormDark.module.css'
import light from './FormLight.module.css'
import { NavLink } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const CreateStoreForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.formPanel : dark.formPanel}>
            <Formik
                initialValues={{
                    title: '',
                    text: '',
                    checkUsing: ''
                }}
                validate={props.validate}
                onSubmit={props.onSubmit}
                >
                {({ isSubmitting }) => (
                    <Form className={props.theme === 'light' ? light.form : dark.form} >

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="title"
                            component="div"
                        />
                        <Field
                            type="text"
                            name="title"
                            autoComplete="title"
                            placeholder={props.strings.title}
                            pattern="[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я0-9\s._-]{3,50}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'}
                        />

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="text"
                            component="div"
                        />
                        <Field
                            type="text"
                            name="text"
                            as="textarea"
                            autoComplete="about"
                            placeholder={props.strings.aboutStore + "..."}
                        />

                        <Field
                            className={props.theme === 'light' ? light.check : dark.check}
                            type="checkbox"
                            name="checkUsing"
                        />
                        <NavLink to="/sections-and-informations/terms-of-use">
                            {props.strings.agree}! <i className="fas fa-chevron-right"></i>
                        </NavLink>
                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="checkUsing"
                            component="div"
                        />

                        <button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {props.strings.create}
                        </button>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default CreateStoreForm