import React from 'react'

const CatChildrenKids  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={11503}>{props.strings.cat11503}</option>
            <option value={11505}>{props.strings.cat11505}</option>
            <option value={11507}>{props.strings.cat11507}</option>
            <option value={11510}>{props.strings.cat11510}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatChildrenKids