import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import AdminPanelItem from '../Items/AdminPanelItem/AdminPanelItem'

const AdminPanelContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                <AdminPanelItem
                    theme={props.theme}
                    strings={props.strings}

                    title={props.strings.admins}
                    icon={<i className="fas fa-crown"></i>}
                    new={props.admins.new}
                    set={props.admins.set}
                    block={props.admins.block}
                    none={props.admins.none}
                    view={props.admins.view}
                    count={props.admins.count}
                />
                <AdminPanelItem
                    theme={props.theme}
                    strings={props.strings}
                    
                    title={props.strings.advertisings}
                    icon={<i className="fas fa-ticket"></i>}
                    new={props.advertisings.new}
                    set={props.advertisings.set}
                    block={props.advertisings.block}
                    none={props.advertisings.none}
                    view={props.advertisings.view}
                    count={props.advertisings.count}
                />
                <AdminPanelItem
                    theme={props.theme}
                    strings={props.strings}
                    
                    title={props.strings.users}
                    icon={<i className="fas fa-users"></i>}
                    new={props.profiles.new}
                    set={props.profiles.set}
                    block={props.profiles.block}
                    none={props.profiles.none}
                    view={props.profiles.view}
                    count={props.profiles.count}
                />
                <AdminPanelItem
                    theme={props.theme}
                    strings={props.strings}
                    
                    title={props.strings.stores}
                    icon={<i className="fas fa-store"></i>}
                    new={props.stores.new}
                    set={props.stores.set}
                    block={props.stores.block}
                    none={props.stores.none}
                    view={props.stores.view}
                    count={props.stores.count}
                />
                <AdminPanelItem
                    theme={props.theme}
                    strings={props.strings}
                    
                    title={props.strings.ratings}
                    icon={<i className="fas fa-star"></i>}
                    new={props.ratings.new}
                    set={props.ratings.set}
                    block={props.ratings.block}
                    none={props.ratings.none}
                    view={props.ratings.view}
                    count={props.ratings.count}
                />
                <AdminPanelItem
                    theme={props.theme}
                    strings={props.strings}
                    
                    title={props.strings.favorite}
                    icon={<i className="fas fa-star"></i>}
                    new={props.favorites.new}
                    set={props.favorites.set}
                    block={props.favorites.block}
                    none={props.favorites.none}
                    view={props.favorites.view}
                    count={props.favorites.count}
                />
                <AdminPanelItem
                    theme={props.theme}
                    strings={props.strings}
                    
                    title={props.strings.products}
                    icon={<i className="fas fa-bag-shopping"></i>}
                    new={props.products.new}
                    set={props.products.set}
                    block={props.products.block}
                    none={props.products.none}
                    view={props.products.view}
                    count={props.products.count}
                />
                <AdminPanelItem
                    theme={props.theme}
                    strings={props.strings}
                    
                    title={props.strings.comments}
                    icon={<i className="fas fa-comments"></i>}
                    new={props.comments.new}
                    set={props.comments.set}
                    block={props.comments.block}
                    none={props.comments.none}
                    view={props.comments.view}
                    count={props.comments.count}
                />
            </div>
        </div>
    )
}

export default AdminPanelContent