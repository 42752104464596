import React from 'react'

const Volkswagen = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1000600}>VOLKSWAGEN AMAROK</option>
            <option value={1000601}>VOLKSWAGEN BEETLE</option>
            <option value={1000602}>VOLKSWAGEN BORA</option>
            <option value={1000603}>VOLKSWAGEN CC</option>
            <option value={1000604}>VOLKSWAGEN CABRIOLET</option>
            <option value={1000605}>VOLKSWAGEN CADDY</option>
            <option value={1000606}>VOLKSWAGEN CORRADO</option>
            <option value={1000607}>VOLKSWAGEN CRAFTER</option>
            <option value={1000608}>VOLKSWAGEN DERBY</option>
            <option value={1000609}>VOLKSWAGEN EOS</option>
            <option value={1000610}>VOLKSWAGEN EUROVAN</option>
            <option value={1000611}>VOLKSWAGEN FOX</option>
            <option value={1000612}>VOLKSWAGEN GLI</option>
            <option value={1000613}>VOLKSWAGEN GTI</option>
            <option value={1000614}>VOLKSWAGEN GOLF</option>
            <option value={1000615}>VOLKSWAGEN GOLF III</option>
            <option value={1000616}>VOLKSWAGEN JETTA</option>
            <option value={1000617}>VOLKSWAGEN JETTA III</option>
            <option value={1000618}>VOLKSWAGEN JUKE</option>
            <option value={1000619}>VOLKSWAGEN MULTIVAN</option>
            <option value={1000620}>VOLKSWAGEN NEW BEETLE</option>
            <option value={1000621}>VOLKSWAGEN PASSAT</option>
            <option value={1000622}>VOLKSWAGEN PHAETON</option>
            <option value={1000623}>VOLKSWAGEN POLO</option>
            <option value={1000624}>VOLKSWAGEN QUANTUM</option>
            <option value={1000625}>VOLKSWAGEN R32</option>
            <option value={1000626}>VOLKSWAGEN RABBIT</option>
            <option value={1000627}>VOLKSWAGEN ROUTAN</option>
            <option value={1000628}>VOLKSWAGEN SCIROCCO</option>
            <option value={1000629}>VOLKSWAGEN SPACEFOX</option>
            <option value={1000630}>VOLKSWAGEN TIGUAN</option>
            <option value={1000631}>VOLKSWAGEN TOUAREG</option>
            <option value={1000632}>VOLKSWAGEN TOUAREG 2</option>
            <option value={1000633}>VOLKSWAGEN TRANSPORTER</option>
            <option value={1000634}>VOLKSWAGEN TYPE 2</option>
            <option value={1000635}>VOLKSWAGEN VANAGON</option>
            <option value={1000636}>VOLKSWAGEN RIO</option>
            <option value={1000637}>VOLKSWAGEN RIOLET</option>
        </>
    )
}

export default Volkswagen