import { addProductAPI } from '../api/api'

const SET_NEW_PRODUCT_ID = 'addProduct/SET_NEW_PRODUCT_ID'
const SET_SUCCESS = 'addProduct/SET_SUCCESS'
const SET_ERROR = 'addProduct/SET_ERROR'
const IS_FETCHING = 'addProduct/IS_FETCHING'

let initialState = {
    newProductId: null,
    success: null,
    error: null,
    back: true,
    isFetching: false
}

const addProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEW_PRODUCT_ID:
            return { ...state, newProductId: action.newProductId }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setNewProductId = (newProductId) => ({ type: SET_NEW_PRODUCT_ID, newProductId })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const addCar = (type, chapter, categorie, manufacturer, model, engine, transmission, drive, body, color, year, mileage, text, price) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await addProductAPI.addCar(type, chapter, categorie, manufacturer, model, engine, transmission, drive, body, color, year, mileage, text, price)
        dispatch(setNewProductId(data.data.id))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const addProduct = (type, chapter, categorie, title, text, price) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await addProductAPI.addProduct(type, chapter, categorie, title, text, price)
        dispatch(setNewProductId(data.data.id))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default addProductReducer