import React from 'react'

const CatFoodsVinegarsSeasoning  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6451}>{props.strings.cat6451}</option>
            <option value={6455}>{props.strings.cat6455}</option>
            <option value={6460}>{props.strings.cat6460}</option>
            <option value={6465}>{props.strings.cat6465}</option>
            <option value={6470}>{props.strings.cat6470}</option>
            <option value={6475}>{props.strings.cat6475}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsVinegarsSeasoning 