import React from 'react'
import dark from '../../../CSS/Dark.module.css'
import light from '../../../CSS/Light.module.css'
import BlockPanel from '../../common/BlockPanel/BlockPanel'
import SetPanel from '../../common/SetPanel/SetPanel'
import Success from '../../common/Success/Success'

const Set = (props) => {
    return (
        <>
            <BlockPanel
                theme={props.theme}
                iconLeft={<i className="fas fa-chevron-left"></i>}
                leftLink={"/admin/advertisings/" + props.advertising.id}
                icon1={<i className="fas fa-square-poll-horizontal"></i>}
                title={props.advertising.title}
            />
            <Success
                theme={props.theme}
                icon={<i className="far fa-play-circle"></i>}
                success={props.strings.media}
            />
            <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/image"}
                    text={props.strings.images}
                    iconLeft={<i className="fas fa-image"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
            </div>
            <Success
                theme={props.theme}
                icon={<i className="far fa-edit"></i>}
                success={props.strings.main}
            />
            <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/title"}
                    text={props.strings.title}
                    iconLeft={<i className="fas fa-square-poll-horizontal"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/subtitle"}
                    text={props.strings.subtitle}
                    iconLeft={<i className="fas fa-file-alt"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/about"}
                    text={props.strings.aboutAdvertising}
                    iconLeft={<i className="fas fa-file-alt"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/mail"}
                    text="E-mail"
                    iconLeft={<i className="fas fa-envelope"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/instagram"}
                    text="instagram"
                    iconLeft={<i className="fab fa-instagram"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/imo"}
                    text="imo"
                    iconLeft={<i className="fas fa-layer-group"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/phone-1"}
                    text={props.strings.phone + " 1"}
                    iconLeft={<i className="fas fa-phone-square"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/phone-2"}
                    text={props.strings.phone + " 2"}
                    iconLeft={<i className="fas fa-phone-square"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/link"}
                    text="link"
                    iconLeft={<i className="fas fa-link"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/href"}
                    text="href"
                    iconLeft={<i className="fas fa-link"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
            </div>
            <Success
                theme={props.theme}
                icon={<i className="far fa-check-circle"></i>}
                success={props.strings.security}
            />
            <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                <SetPanel
                    theme={props.theme}
                    to={"/admin/advertisings/" + props.advertising.id + "/settings/trash"}
                    text={props.strings.trash}
                    iconLeft={<i className="fas fa-trash"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />
            </div>
        </>
    )
}

export default Set