import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Products from './Products'
import {
    requestProducts,
    setIsFetching,
    setCurrentPage,
    setSearchText,
    setSuccess as setProductsSuccess
} from '../../redux/products-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow
} from '../../redux/notify-reducer'
const ProductsContainer = (props) => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

    const dispatch = useDispatch()

    const order = useSelector(state => state.products.order)
    const location = useSelector(state => state.products.location)

    const marker = useSelector(state => state.products.marker)
    const types = useSelector(state => state.products.types)
    const chapter = useSelector(state => state.products.chapter)
    const categorie = useSelector(state => state.products.categorie)

    const manufacturer = useSelector(state => state.products.manufacturer)
    const model = useSelector(state => state.products.model)

    const engine = useSelector(state => state.products.engine)
    const transmission = useSelector(state => state.products.transmission)
    const drive = useSelector(state => state.products.drive)
    const body = useSelector(state => state.products.body)
    const color = useSelector(state => state.products.color)
    const year = useSelector(state => state.products.year)

    const search = useSelector(state => state.products.search)

    const products = useSelector(state => state.products.products)
    const count = useSelector(state => state.products.count)
    const totalCount = useSelector(state => state.products.totalCount)
    const currentPage = useSelector(state => state.products.currentPage)
    const pagesCount = useSelector(state => state.products.pagesCount)

    const productsStatus = useSelector(state => state.products.status)
    const productsSuccess = useSelector(state => state.products.success)

    const back = useSelector(state => state.products.back)
    const isFetching = useSelector(state => state.products.isFetching)

    useEffect(() => {
        if (isFetching) {
            dispatch(requestProducts(order, location, marker, types, chapter, categorie, manufacturer, model, engine, transmission, drive, body, color, year, search, count, currentPage))
        }
    }, [order, location, marker, types, chapter, categorie, manufacturer, model, engine, transmission, drive, body, color, year, search, count, currentPage, isFetching, dispatch])

    const onSubmit = (values, { setSubmitting }) => {
        const text = values.search
        dispatch(setSearchText(text))
        dispatch(setCurrentPage(1))
        dispatch(setIsFetching(true))
        setSubmitting(false);
    }

    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setIsFetching(true))
    }

    useEffect(
        () => {
            if(productsSuccess){
                dispatch(setNotifySuccess(productsSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setProductsSuccess(null))
            }
        }, [dispatch, productsSuccess]
    )

    return (
        <Products
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            
            finderProducts={props.finderProducts}

            products={products}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            productsStatus={productsStatus}
            back={back}
            isFetching={isFetching}

            onSubmit={onSubmit}
            onPageChanged={onPageChanged}
        />
    )
}

export default ProductsContainer