import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'

const ProfileFull = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockViewFull : dark.blockViewFull}>
            
            {props.status
                ? <div className={props.theme === 'light' ? light.status : dark.status}>
                    {props.strings['status' + props.status]}...
                </div>
                : null
            }
            
            <div className={props.theme === 'light' ? light.fullInfo : dark.fullInfo}>

                {props.name /* НАИМЕНОВАНИЕ */
                    ? <div className={props.theme === 'light' ? light.title : dark.title}>
                        <i className="fas fa-user"></i> {props.name}
                    </div>
                    : null
                }

                {props.location /* ЛОКАЦИЯ И КАТЕГОРИЯ */
                    ? <div className={props.theme === 'light' ? light.info : dark.info}>
                        <i className="fas fa-map-marker-alt"></i> {props.strings.location}: {props.strings['loc' + props.location]}. {props.strings['loc' + props.marker]}.
                    </div>
                    : null
                }

                {props.text /* ТЕКСТ */
                    ? <div className={props.theme === 'light' ? light.text : dark.text}>
                        {props.text}
                    </div>
                    : null
                }

                {props.phone /* ТЕЛЕФОН */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-phone-square"></i> {props.strings.phone}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.phone}
                        </div>
                        
                    </div>
                    : null
                }

                {props.birth /* ДАТА РОЖДЕНИЯ */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-calendar-check"></i> {props.strings.birth}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.birth}
                        </div>
                        
                    </div>
                    : null
                }

                {props.reg /* ДАТА РУГИСТРАЦИИ */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-calendar-check"></i> {props.strings.reg}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.reg}
                        </div>
                        
                    </div>
                    : null
                }

            </div>
        </div>
    )
}

export default ProfileFull