import React from 'react'

const CatElectronicsPrintersScanners  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10261}>{props.strings.cat10261}</option>
            <option value={10262}>{props.strings.cat10262}</option>
            <option value={10263}>{props.strings.cat10263}</option>
            <option value={10264}>{props.strings.cat10264}</option>
            <option value={10265}>{props.strings.cat10265}</option>
            <option value={10266}>{props.strings.cat10266}</option>
            <option value={10267}>{props.strings.cat10267}</option>
            <option value={10268}>{props.strings.cat10268}</option>
            <option value={10269}>{props.strings.cat10269}</option>
            <option value={100002}>{props.strings.cat100002}</option>
            <option value={100003}>{props.strings.cat100003}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsPrintersScanners 