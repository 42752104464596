import { FavoriteAPI } from '../api/api'

const SET_SUCCESS = 'addFavorite/SET_SUCCESS'
const SET_ERROR = 'addFavorite/SET_ERROR'
const IS_FETCHING = 'addFavorite/IS_FETCHING'

let initialState = {
    success: null,
    error: null,
    isFetching: true
}

const addFavoriteReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const addFavorite = (id) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await FavoriteAPI.addFavorite(id)
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default addFavoriteReducer