import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    requestProduct,
    setImages,
    setActiveIndex,
    setProduct,
    setStore,
    setProfile,
    setIsPhotosCrope,
    setSuccess as setProductSuccess,
    setError as setProductError
} from '../../redux/adminProduct-reducer'
import { changeStatus, setUpdated, setSuccess, setError, remove } from '../../redux/adminProductSet-reducer'
import {
    requestComments,
    setComments,
    setCurrentPage,
    setSuccess as setCommentsSuccess,
    setError as setCommentsError,
    setIsFetching as setCommentsIsFetching
} from '../../redux/comments-reducer'
import {
    requestFavorite,
    setProfileFavorite,
    setFavoriteCount,
    setSuccess as setFavoriteSuccess,
    setError as setFavoriteError,
    setIsFetching as setFavoriteIsFetching
} from '../../redux/favorite-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import AdminProduct from './AdminProduct'
import { setIsFetching } from '../../redux/adminProducts-reducer'

const AdminProductContainer= (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const params = useParams()

    const product = useSelector(state => state.adminProduct.product)
    const images = useSelector(state => state.adminProduct.images)
    const activeIndex = useSelector(state => state.adminProduct.activeIndex)
    const isPhotosCrope = useSelector(state => state.adminProduct.isPhotosCrope)
    const store = useSelector(state => state.adminProduct.store)
    const ostProfile = useSelector(state => state.adminProduct.profile)
    const productStatus = useSelector(state => state.adminProduct.status)
    const productSuccess = useSelector(state => state.adminProduct.success)
    const productError = useSelector(state => state.adminProduct.error)
    const back = useSelector(state => state.adminProduct.back)
    const productIsFetching = useSelector(state => state.adminProduct.isFetching)
    
    const favoriteCount = useSelector(state => state.favorite.favoriteCount)
    const favoriteSuccess = useSelector(state => state.favorite.success)
    const favoriteError = useSelector(state => state.favorite.error)
    const favoriteIsFetching = useSelector(state => state.favorite.isFetching)

    const comments = useSelector(state => state.comments.comments)
    const count = useSelector(state => state.comments.count)
    const totalCount = useSelector(state => state.comments.totalCount)
    const currentPage = useSelector(state => state.comments.currentPage)
    const pagesCount = useSelector(state => state.comments.pagesCount)
    const commentsStatus = useSelector(state => state.comments.status)
    const commentsSuccess = useSelector(state => state.comments.success)
    const commentsError = useSelector(state => state.comments.error)
    const commentsIsFetching = useSelector(state => state.comments.isFetching)

    const updated = useSelector(state => state.adminProductSet.updated)
    const productSetSuccess = useSelector(state => state.adminProductSet.success)
    const productSetError = useSelector(state => state.adminProductSet.error)
    const isFetching = useSelector(state => state.adminProductSet.isFetching)

    const admin = 1
    
    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        } else if (!params.productId) {
            navigate('/admin/products')
        }
    }, [navigate, props.isAuth, props.isAdmin, params.productId])

    useEffect(() => {
        if (params.productId || updated) {
            dispatch(setProduct([]))
            dispatch(setImages([]))
            dispatch(setActiveIndex(0))
            dispatch(setStore([]))
            dispatch(setProfile([]))
            dispatch(setComments([]))
            dispatch(requestProduct(admin, params.productId))
            dispatch(requestComments(currentPage, count, params.productId))
            if (updated) {
                dispatch(setIsFetching(true))
                dispatch(setUpdated(false))
            }
            window.scrollTo({top: 0, left: 0})
        }
    }, [dispatch, count, currentPage, params.productId, updated])

    useEffect(() => {
        if (params.productId) {
            dispatch(setProfileFavorite(null))
            dispatch(setFavoriteCount(null))
            dispatch(requestFavorite(params.productId))
            dispatch(setFavoriteIsFetching(false))
        }
    }, [dispatch, params.productId, favoriteIsFetching])

    const onSubmitView = async (statusId) => {
        dispatch(changeStatus(admin, params.productId, statusId))
    }

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(admin, params.productId, trash))
        dispatch(setIsFetching(true))
        setTimeout(() => {
            navigate('/admin/products')
        }, 1000)
    }

    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setCommentsIsFetching(true))
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    const crope = () => {
        if(isPhotosCrope) {
            dispatch(setIsPhotosCrope(false))
        } else {
            dispatch(setIsPhotosCrope(true))
        }
    }

    useEffect(
        () => {
            if(productError){
                dispatch(setNotifyError(productError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setProductError(null))
            } else if(favoriteError){
                dispatch(setNotifyError(favoriteError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setFavoriteError(null))
            } else if(commentsError){
                dispatch(setNotifyError(commentsError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setCommentsError(null))
            } else if(productSetError){
                dispatch(setNotifyError(productSetError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(productSuccess){
                dispatch(setNotifySuccess(productSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setProductSuccess(null))
            } else if(favoriteSuccess){
                dispatch(setNotifySuccess(favoriteSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setFavoriteSuccess(null))
            } else if(commentsSuccess){
                dispatch(setNotifySuccess(commentsSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setCommentsSuccess(null))
            } else if(productSetSuccess){
                dispatch(setNotifySuccess(productSetSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [
            dispatch,
            productError,
            favoriteError,
            commentsError,
            productSetError,
            productSuccess,
            favoriteSuccess,
            commentsSuccess,
            productSetSuccess]
    )

    return (
        <AdminProduct
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            product={product}
            images={images}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            isPhotosCrope={isPhotosCrope}
            crope={crope}
            store={store}
            ostProfile={ostProfile}
            productStatus={productStatus}
            back={back}
            productIsFetching={productIsFetching}

            favoriteCount={favoriteCount}
            
            comments={comments}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            commentsStatus={commentsStatus}
            commentsIsFetching={commentsIsFetching}

            isFetching={isFetching}

            onPageChanged={onPageChanged}
            
            onSubmitView={onSubmitView}
            onSubmitTrash={onSubmitTrash}
        />
    )
}

export default AdminProductContainer