import React from 'react'

const CatElectronicsPhotoVideo  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10701}>{props.strings.cat10701}</option>
            <option value={10702}>{props.strings.cat10702}</option>
            <option value={10703}>{props.strings.cat10703}</option>
            <option value={10704}>{props.strings.cat10704}</option>
            <option value={10705}>{props.strings.cat10705}</option>
            <option value={10706}>{props.strings.cat10706}</option>
            <option value={100002}>{props.strings.cat100002}</option>
            <option value={100003}>{props.strings.cat100003}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsPhotoVideo 