import { StoresAPI } from '../api/api'

const SET_STORES = 'historyStores/SET_STORES'
const SET_COUNT = 'historyStores/SET_COUNT'
const SET_TOTAL_COUNT = 'historyStores/SET_TOTAL_COUNT'
const SET_CURRENT_PAGE = 'historyStores/SET_CURRENT_PAGE'
const SET_PAGES_COUNT = 'historyStores/SET_PAGES_COUNT'
const SET_STATUS = 'historyStores/SET_STATUS'
const SET_SUCCESS = 'historyStores/SET_SUCCESS'
const SET_ERROR = 'historyStores/SET_ERROR'
const IS_FETCHING = 'historyStores/IS_FETCHING'

let initialState = {
    stores: [],
    count: 30,
    totalCount: 0,
    currentPage: 1,
    pagesCount: 0,
    status: null,
    success: null,
    error: null,
    back: true,
    isFetching: true
}

const historyStoresReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STORES:
            return { ...state, stores: action.stores }
        case SET_COUNT:
            return { ...state, count: action.count }
        case SET_TOTAL_COUNT:
            return { ...state, totalCount: action.totalCount }
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.currentPage }
        case SET_PAGES_COUNT:
            return { ...state, pagesCount: action.pagesCount }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setStores = (stores) => ({ type: SET_STORES, stores })
export const setCount = (count) => ({ type: SET_COUNT, count })
export const setTotalCount = (totalCount) => ({ type: SET_TOTAL_COUNT, totalCount })
export const setCurrentPage = (currentPage) => ({ type: SET_CURRENT_PAGE, currentPage })
export const setPagesCount = (pagesCount) => ({ type: SET_PAGES_COUNT, pagesCount })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestStores = (currentPage, count) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await StoresAPI.historyStores(currentPage, count)
        dispatch(setStores(data.data.stores))
        dispatch(setTotalCount(data.data.totalCount))
        dispatch(setPagesCount(data.data.pagesCount))
        dispatch(setStatus(data.data.status))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default historyStoresReducer