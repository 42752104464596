import React from 'react'

const CatElectronicsRemoteControllers  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10081}>{props.strings.cat10081}</option>
            <option value={10082}>{props.strings.cat10082}</option>
            <option value={10083}>{props.strings.cat10083}</option>
            <option value={10084}>{props.strings.cat10084}</option>
            <option value={10085}>{props.strings.cat10085}</option>
            <option value={10086}>{props.strings.cat10086}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsRemoteControllers 