import React from 'react'

const CatFoodsFlourProducts  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6151}>{props.strings.cat6151}</option>
            <option value={6152}>{props.strings.cat6152}</option>
            <option value={6153}>{props.strings.cat6153}</option>
            <option value={6160}>{props.strings.cat6160}</option>
            <option value={6161}>{props.strings.cat6161}</option>
            <option value={6162}>{props.strings.cat6162}</option>
            <option value={6170}>{props.strings.cat6170}</option>
            <option value={6171}>{props.strings.cat6171}</option>
            <option value={6172}>{props.strings.cat6172}</option>
            <option value={6173}>{props.strings.cat6173}</option>
            <option value={6174}>{props.strings.cat6174}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsFlourProducts 