import React from 'react'

const CatHomeOfficeTablewares  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={8151}>{props.strings.cat8151}</option>
            <option value={8152}>{props.strings.cat8152}</option>
            <option value={8153}>{props.strings.cat8153}</option>
            <option value={8154}>{props.strings.cat8154}</option>
            <option value={8155}>{props.strings.cat8155}</option>
            <option value={8156}>{props.strings.cat8156}</option>
            <option value={8157}>{props.strings.cat8157}</option>
            <option value={8158}>{props.strings.cat8158}</option>
            <option value={8159}>{props.strings.cat8159}</option>
            <option value={8160}>{props.strings.cat8160}</option>
            <option value={8161}>{props.strings.cat8161}</option>
            <option value={8162}>{props.strings.cat8162}</option>
            <option value={8163}>{props.strings.cat8163}</option>
            <option value={8164}>{props.strings.cat8164}</option>
            <option value={8165}>{props.strings.cat8165}</option>
            <option value={8166}>{props.strings.cat8166}</option>
            <option value={8167}>{props.strings.cat8167}</option>
            <option value={8168}>{props.strings.cat8168}</option>
            <option value={8169}>{props.strings.cat8169}</option>
            <option value={8170}>{props.strings.cat8170}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatHomeOfficeTablewares 