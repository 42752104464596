import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import { Route, Routes } from 'react-router-dom'
import Menu from '../Items/Menu/Menu'
import Set from './Settings/Set'
import SetImage from './Settings/SetImage'
import SetTitle from './Settings/SetTitle'
import SetSubtitle from './Settings/SetSubtitle'
import SetAbout from './Settings/SetAbout'
import SetMail from './Settings/SetMail'
import SetInstagram from './Settings/SetInstagram'
import SetImo from './Settings/SetImo'
import SetPhone1 from './Settings/SetPhone1'
import SetPhone2 from './Settings/SetPhone2'
import SetLink from './Settings/SetLink'
import SetHref from './Settings/SetHref'
import SetTrash from './Settings/SetTrash'

const AdminAdvertisingSetContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                <Routes>
                    <Route
                        path='/'
                        element={
                            <Set
                                domain={props.domain}
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                            />
                        }
                    />

                    <Route
                        path='/image'
                        element={
                            <SetImage
                                domain={props.domain}
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                changeImage={props.onSubmitImage}
                                removeImage={props.onSubmitRemoveImage}
                            />
                        }
                    />

                    <Route
                        path='/title'
                        element={
                            <SetTitle
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                validate={props.validateTitle}
                                onSubmit={props.onSubmitTitle}
                            />
                        }
                    />

                    <Route
                        path='/subtitle'
                        element={
                            <SetSubtitle
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                validate={props.validateSubtitle}
                                onSubmit={props.onSubmitSubtitle}
                            />
                        }
                    />

                    <Route
                        path='/about'
                        element={
                            <SetAbout
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                validate={props.validateText}
                                onSubmit={props.onSubmitText}
                            />
                        }
                    />

                    <Route
                        path='/mail'
                        element={
                            <SetMail
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                onSubmit={props.onSubmitMail}
                            />
                        }
                    />

                    <Route
                        path='/instagram'
                        element={
                            <SetInstagram
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                onSubmit={props.onSubmitInstagram}
                            />
                        }
                    />

                    <Route
                        path='/imo'
                        element={
                            <SetImo
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                onSubmit={props.onSubmitImo}
                            />
                        }
                    />

                    <Route
                        path='/phone-1'
                        element={
                            <SetPhone1
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                validate={props.validatePhone1}
                                onSubmit={props.onSubmitPhone1}
                            />
                        }
                    />

                    <Route
                        path='/phone-2'
                        element={
                            <SetPhone2
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                validate={props.validatePhone2}
                                onSubmit={props.onSubmitPhone2}
                            />
                        }
                    />

                    <Route
                        path='/link'
                        element={
                            <SetLink
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                onSubmit={props.onSubmitLink}
                            />
                        }
                    />

                    <Route
                        path='/href'
                        element={
                            <SetHref
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                onSubmit={props.onSubmitHref}
                            />
                        }
                    />

                    <Route
                        path='/trash'
                        element={
                            <SetTrash
                                theme={props.theme}
                                strings={props.strings}
                                advertising={props.advertising}
                                navigate={props.navigate}
                                onSubmitTrash={props.onSubmitTrash}
                            />
                        }
                    />

                </Routes>
            </div>
        </div>
    )
}

export default AdminAdvertisingSetContent