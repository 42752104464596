import React from 'react'

const CatFoodsCanneds  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6305}>{props.strings.cat6305}</option>
            <option value={6310}>{props.strings.cat6310}</option>
            <option value={6315}>{props.strings.cat6315}</option>
            <option value={6320}>{props.strings.cat6320}</option>
            <option value={6325}>{props.strings.cat6325}</option>
            <option value={6330}>{props.strings.cat6330}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsCanneds 