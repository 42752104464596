import React from 'react'

const Body = (props) => {
    return (
        <>
            <option value={0}>{props.strings.body}...</option>
            <option value={1}>{props.strings.body1}</option>
            <option value={2}>{props.strings.body2}</option>
            <option value={3}>{props.strings.body3}</option>
            <option value={4}>{props.strings.body4}</option>
            <option value={5}>{props.strings.body5}</option>
            <option value={6}>{props.strings.body6}</option>
            <option value={7}>{props.strings.body7}</option>
            <option value={8}>{props.strings.body8}</option>
            <option value={9}>{props.strings.body9}</option>
            <option value={10}>{props.strings.body10}</option>
            <option value={11}>{props.strings.body11}</option>
            <option value={12}>{props.strings.body12}</option>
            <option value={13}>{props.strings.body13}</option>
            <option value={14}>{props.strings.body14}</option>
            <option value={15}>{props.strings.body15}</option>
        </>
    )
}

export default Body