import { ProfileStoreAPI } from '../api/api'

const SET_UPDATED = 'profileStoreSet/SET_UPDATED'
const SET_TRASHED = 'profileStoreSet/SET_TRASHED'
const SET_SUCCESS = 'profileStoreSet/SET_SUCCESS'
const SET_ERROR = 'profileStoreSet/SET_ERROR'
const IS_FETCHING = 'profileStoreSet/IS_FETCHING'

let initialState = {
    updated: false,
    trashed: false,
    success: null,
    error: null,
    back: true,
    isFetching: false
}

const profileStoreSetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPDATED:
            return { ...state, updated: action.updated }
        case SET_TRASHED:
            return { ...state, trashed: action.trashed }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setUpdated = (updated) => ({ type: SET_UPDATED, updated })
export const setTrashed = (trashed) => ({ type: SET_TRASHED, trashed })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const changeImage = (auth, imageId, image) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreAPI.changeImage(auth, imageId, image)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const imageDelete = (auth, removeId) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreAPI.imageDelete(auth, removeId)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeTitle = (auth, title) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreAPI.changeTitle(auth, title)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeText = (auth, text) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreAPI.changeText(auth, text)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const remove = (auth, trash) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreAPI.remove(auth, trash)
        dispatch(setTrashed(data.data.trashed))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default profileStoreSetReducer