import React from 'react'

const Ahal = (props) => {
    return (
        <>
            <option value={null}>{props.strings.marker}...</option>
            <option value={3}>{props.strings.loc3}</option>
            <option value={4}>{props.strings.loc4}</option>
            <option value={5}>{props.strings.loc5}</option>
            <option value={6}>{props.strings.loc6}</option>
            <option value={7}>{props.strings.loc7}</option>
            <option value={8}>{props.strings.loc8}</option>
            <option value={9}>{props.strings.loc9}</option>
            <option value={10}>{props.strings.loc10}</option>
            <option value={11}>{props.strings.loc11}</option>
        </>
    )
}

export default Ahal