import React from 'react'

const CatServicesTraining  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={2201}>{props.strings.cat2201}</option>
            <option value={2202}>{props.strings.cat2202}</option>
            <option value={2203}>{props.strings.cat2203}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatServicesTraining 