import React from 'react'

const Counter = (props) => {

    let count = 0

    if (props.count) {

        let len = props.count.length

        if (len > 3 && len < 5) {
            count = '+' + props.count[0] + (props.count[1] > 0 ? '.' + props.count[1] : '') + 'K'
        } else if (len > 4 && len < 6) {
            count = '+' + props.count[0] + props.count[1] + (props.count[2] > 0 ? '.' + props.count[2] : '') + 'K'
        } else if (len > 5 && len < 7) {
            count = '+' + props.count[0] + props.count[1] + props.count[2] + (props.count[3] > 0 ? '.' + props.count[3] : '') + 'K'
        } else if (len > 6 && len < 8) {
            count = '+' + props.count[0] + (props.count[1] > 0 ? '.' + props.count[1] : '') + 'M'
        } else if (len > 7 && len < 9) {
            count = '+' + props.count[0] + props.count[1] + (props.count[2] > 0 ? '.' + props.count[2] : '') + 'M'
        } else if (len > 8 && len < 10) {
            count = '+' + props.count[0] + props.count[1] + props.count[2] + (props.count[3] > 0 ? '.' + props.count[3] : '') + 'M'
        } else {
            count = props.count
        }
    }

  return (
    <>
        {count}
    </>
  )
}

export default Counter