import React from 'react'

const CatElectronicsMusic  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10041}>{props.strings.cat10041}</option>
            <option value={10042}>{props.strings.cat10042}</option>
            <option value={10043}>{props.strings.cat10043}</option>
            <option value={10044}>{props.strings.cat10044}</option>
            <option value={10045}>{props.strings.cat10045}</option>
            <option value={10046}>{props.strings.cat10046}</option>
            <option value={100003}>{props.strings.cat100003}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsMusic 