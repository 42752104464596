import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'
import { NavLink } from 'react-router-dom'
import Rating from '../additions/Rating/Rating'

const StoreItem = (props) => {
    return (
        <NavLink to={props.to + props.id} >
            <div className={props.theme === 'light' ? light.blockView : dark.blockView}>
                {props.image0
                    ? <img src={props.domain + '/media/store/' + props.image0} alt="store" />
                    : <img src={props.domain + "/media/store/noimgmini.png"} alt="store" />
                }
                <div className={props.theme === 'light' ? light.info : dark.info}>
                    <div className={props.theme === 'light' ? light.title : dark.title}>
                        <i className="fas fa-store-alt"></i> {props.title}
                    </div>
                    <div className={props.theme === 'light' ? light.small : dark.small}>
                        <small><i className="fas fa-map-marker-alt"></i> {props.strings['loc' + props.location]}. {props.strings['loc' + props.marker]}.</small>
                    </div>
                    
                    {props.status
                        ? <div className={props.theme === 'light' ? light.status : dark.status}>
                            {props.strings['status' + props.status]}...
                        </div>
                        : null
                    }

                    <div className={props.theme === 'light' ? light.preview : dark.preview}>
                        {props.text}
                    </div>
                    <div className={props.theme === 'light' ? light.phone : dark.phone}>
                        <i className="fas fa-phone-square"></i> {props.phone}
                        
                        <div className={props.theme === 'light' ? light.views : dark.views}>
                            <Rating theme={props.theme } rating={props.rating} ratingCount={props.ratingCount}/>
                        </div>
                    </div>
                </div>
            </div>
        </NavLink>
    )
}

export default StoreItem