import { RatingAPI } from '../api/api'

const SET_RATING = 'addRating/SET_RATING'
const SET_SUCCESS = 'addRating/SET_SUCCESS'
const SET_ERROR = 'addRating/SET_ERROR'
const IS_FETCHING = 'addRating/IS_FETCHING'

let initialState = {
    rating: null,
    success: null,
    error: null,
    isFetching: true
}

const addRatingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RATING:
            return { ...state, rating: action.rating }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setRating = (rating) => ({ type: SET_RATING, rating })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const addRating = (id, rating) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await RatingAPI.addRating(id, rating)
        dispatch(setRating(data.data.rating))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const removeRating = (id, removeRate) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await RatingAPI.removeRating(id, removeRate)
        dispatch(setRating(data.data.rating))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default addRatingReducer