import React from 'react'

const CatBeautyOralCavity  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12121}>{props.strings.cat12121}</option>
            <option value={12122}>{props.strings.cat12122}</option>
            <option value={12123}>{props.strings.cat12123}</option>
            <option value={12146}>{props.strings.cat12146}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyOralCavity