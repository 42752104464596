import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    setNewAdvertisingId,
    addAdvertising,
    setSuccess as setAddProductSuccess,
    setError as setAddProductError
} from '../../redux/addAdvertising-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import AddAdvertising from './AddAdvertising'

const AddAdvertisingContainer = (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const newAdvertisingId = useSelector(state => state.addAdvertising.newAdvertisingId)
    const addProductSuccess = useSelector(state => state.addAdvertising.success)
    const addProductError = useSelector(state => state.addAdvertising.error)
    const back = useSelector(state => state.addAdvertising.back)
    const isFetching = useSelector(state => state.addAdvertising.isFetching)

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        } else if (newAdvertisingId) {
            navigate('/admin/advertisings/' + newAdvertisingId + '/settings/image')
            dispatch(setNewAdvertisingId(null))
        }
    }, [dispatch, navigate, props.isAuth, props.isAdmin, newAdvertisingId])

    const initialValues = {
        title: '',
        subtitle: '',
        text: ''
    }
    const validate = (values) => {
        const errors = {}

        if (!values.title) {
            errors.title = props.strings.required
        } else if (values.title.length < 3) {
            errors.title = props.strings.min + ': 3/' + values.title.length
        } else if (values.title.length > 50) {
            errors.title = props.strings.max + ': 50/' + values.title.length
        } else if (!/^[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я0-9\s._-]{3,50}$/i.test(values.title)) {
            errors.title = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'
        }

        if (!values.subtitle) {
            errors.subtitle = props.strings.required
        } else if (values.subtitle.length < 3) {
            errors.subtitle = props.strings.min + ': 3/' + values.subtitle.length
        } else if (values.subtitle.length > 100) {
            errors.subtitle = props.strings.max + ': 100/' + values.subtitle.length
        } else if (!/^[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я0-9\s._-]{3,100}$/i.test(values.subtitle)) {
            errors.subtitle = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'
        }

        if (!values.text) {
            errors.text = props.strings.required
        } else if (values.text.length < 10) {
            errors.text = props.strings.min + ': 10/' + values.text.length
        } else if (values.text.length > 1500) {
            errors.text = props.strings.max + ': 1500/' + values.text.length
        }

        return errors
    }

    const onSubmit = (values, { setSubmitting }) => {
        const title = values.title
        const subtitle = values.subtitle
        const text = values.text
        dispatch(
            addAdvertising(
                title,
                subtitle,
                text
            )
        )
        setSubmitting(false)
    }

    useEffect(
        () => {
            if(addProductError){
                dispatch(setNotifyError(addProductError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setAddProductError(null))
            } else if(addProductSuccess){
                dispatch(setNotifySuccess(addProductSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setAddProductSuccess(null))
            }
        }, [dispatch, addProductSuccess, addProductError ]
    )

    return (
        <AddAdvertising
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            back={back}
            isFetching={isFetching}
            
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
        />
    )
}

export default AddAdvertisingContainer