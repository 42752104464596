import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import HomeContent from './HomeContent'
import Sitebar from '../Sitebars/Sitebar'
import AdvertisingsBanner from '../Advertisings/AdvertisingsBanner'

const Home = (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <AdvertisingsBanner
                domain={props.domain}
                theme={props.theme}
                strings={props.strings}
            />
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                <HomeContent
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}

                    topViewProducts={props.topViewProducts}
                    topViewProductsStatus={props.topViewProductsStatus}
                    topViewProductsIsFetching={props.topViewProductsIsFetching}
        
                    topRatingStores={props.topRatingStores}
                    topRatingStoresStatus={props.topRatingStoresStatus}
                    topRatingStoresIsFetching={props.topRatingStoresIsFetching}
        
                    newProducts={props.newProducts}
                    newProductsStatus={props.newProductsStatus}
                    newProductsIsFetching={props.newProductsIsFetching}

                    back={props.back}
                />
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    theme={props.theme}
                    strings={props.strings}
                    sitebarActivate={props.sitebarActivate}
                    
                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default Home