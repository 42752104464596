import React, { useEffect }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    requestTopViewProducts,
    setSuccess as setTopViewProductsSuccess
} from '../../redux/topViewProducts-reducer'
import {
    requestTopRatingStores,
    setSuccess as setTopRatingStoresSuccess
} from '../../redux/topRatingStores-reducer'
import {
    requestNewProducts,
    setSuccess as setNewProductsSuccess
} from '../../redux/newProductsReducer-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setWarning as setNotifyWarning,
    setWarningShow as setNotifyWarningShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import Home from './Home'

const HomeContainer = (props) => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

    const dispatch = useDispatch()

    const topViewProducts = useSelector(state => state.topViewProducts.topViewProducts)
    const topViewProductsStatus = useSelector(state => state.topViewProducts.status)
    const topViewProductsSuccess = useSelector(state => state.topViewProducts.success)
    const topViewProductsIsFetching = useSelector(state => state.topViewProducts.isFetching)

    const topRatingStores = useSelector(state => state.topRatingStores.topRatingStores)
    const topRatingStoresStatus = useSelector(state => state.topRatingStores.status)
    const topRatingStoresSuccess = useSelector(state => state.topRatingStores.success)
    const topRatingStoresIsFetching = useSelector(state => state.topRatingStores.isFetching)

    const newProducts = useSelector(state => state.newProducts.newProducts)
    const newProductsStatus = useSelector(state => state.newProducts.status)
    const newProductsSuccess = useSelector(state => state.newProducts.success)
    const newProductsIsFetching = useSelector(state => state.newProducts.isFetching)

    const HomeStatus = useSelector(state => state.home.status)
    const HomeSuccess = useSelector(state => state.home.success)
    const back = useSelector(state => state.home.back)
    const HomeIsFetching = useSelector(state => state.home.isFetching)

    useEffect(() => {
        if (topViewProductsIsFetching) {
            dispatch(requestTopViewProducts())
        }
    }, [dispatch, topViewProductsIsFetching])

    useEffect(() => {
        if (topRatingStoresIsFetching) {
            dispatch(requestTopRatingStores())
        }
    }, [dispatch, topRatingStoresIsFetching])

    useEffect(() => {
        if (newProductsIsFetching) {
            dispatch(requestNewProducts())
        }
    }, [dispatch, newProductsIsFetching])

    useEffect(
        () => {
            if(topViewProductsSuccess){
                dispatch(setNotifySuccess(topViewProductsSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setTopViewProductsSuccess(null))
            } else if(topRatingStoresSuccess){
                dispatch(setNotifySuccess(topRatingStoresSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setTopRatingStoresSuccess(null))
            } else if(newProductsSuccess){
                dispatch(setNotifySuccess(newProductsSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setNewProductsSuccess(null))
            }
        }, [dispatch, topViewProductsSuccess, topRatingStoresSuccess, newProductsSuccess]
    )

    return (
        <Home
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            
            topViewProducts={topViewProducts}
            topViewProductsStatus={topViewProductsStatus}
            topViewProductsIsFetching={topViewProductsIsFetching}

            topRatingStores={topRatingStores}
            topRatingStoresStatus={topRatingStoresStatus}
            topRatingStoresIsFetching={topRatingStoresIsFetching}

            newProducts={newProducts}
            newProductsStatus={newProductsStatus}
            newProductsIsFetching={newProductsIsFetching}

            back={back}
        />
    )
}

export default HomeContainer