import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import AddAdvertisingForm from '../common/Forms/AddAdvertisingForm'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'

const AddAdvertisingContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />

                <BlockPanel
                    theme={props.theme}
                    iconLeft={<i className="fas fa-chevron-left"></i>}
                    leftLink={"/admin/advertisings"}
                    icon1={<i className="fas fa-square-poll-horizontal"></i>}
                    title={props.strings.newAdvertising}
                />
                {props.isFetching
                    ? <PreloaderMini />
                    : <div className={props.theme === 'light' ? light.formContainer : dark.formContainer}>

                        <h2><i className="fas fa-pencil-alt"></i> {props.strings.addAdvertising}.</h2>

                        <AddAdvertisingForm
                            theme={props.theme}
                            strings={props.strings}
                            initialValues={props.initialValues}
                            validate={props.validate}
                            onSubmit={props.onSubmit}
                        />

                    </div>
                }
            </div>
        </div>
    )
}

export default AddAdvertisingContent