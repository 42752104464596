import { HomeAPI } from '../api/api';

const SET_TOP_VIEW_PRODUCTS = 'topViewProducts/SET_TOP_VIEW_PRODUCTS'
const SET_STATUS = 'topViewProducts/SET_STATUS'
const SET_SUCCESS = 'topViewProducts/SET_SUCCESS'
const IS_FETCHING = 'topViewProducts/IS_FETCHING'

let initialState = {
    topViewProducts: [],
    status: null,
    success: null,
    isFetching: true
}

const topViewProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOP_VIEW_PRODUCTS:
            return { ...state, topViewProducts: action.topViewProducts }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setTopViewProducts = (topViewProducts) => ({ type: SET_TOP_VIEW_PRODUCTS, topViewProducts })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestTopViewProducts = () => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await HomeAPI.topViewProducts()
        dispatch(setTopViewProducts(data.data.topViewProducts))
        dispatch(setSuccess(data.data.success))
        dispatch(setStatus(data.data.status))
        dispatch(setIsFetching(false))
    }
}

export default topViewProductsReducer