import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSuccessShow } from '../../../redux/notify-reducer'
import NotifySuccess from './NotifySuccess'

let NotifySuccessContainer = (props) => {
    const dispatch = useDispatch()
    const success = useSelector(state => state.notify.success)
    const successShow = useSelector(state => state.notify.successShow)

    useEffect(
        () => {
            if(successShow){
                setTimeout(() => {
                    dispatch(setSuccessShow(false))
                }, 1000)
            }
        }, [dispatch, successShow]
    )

    return (
        <>
            {successShow
                ?
                    <NotifySuccess
                        theme={props.theme}
                        strings={props.strings}
                        success={success}
                    />
                : null
            }
        </>
    )
}

export default NotifySuccessContainer