import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'
import SuccessAverage from '../common/Success/SuccessAverage'
import Paginator from '../common/Paginator/Paginator'
import AdvertisingItem from '../Items/Item/AdvertisingItem'
import ViewPanel from '../common/ViewPanel/ViewPanel'
import SetPanel from '../common/SetPanel/SetPanel'

const AdminAdvertisingsContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                <BlockPanel
                    theme={props.theme}
                    icon1={<i className="fas fa-square-poll-horizontal"></i>}
                    title={props.strings.advertisings}
                />

                <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                    <SetPanel
                        theme={props.theme}
                        to={"/admin/add/advertising"}
                        text={props.strings.addAdvertising}
                        iconLeft={<i className="fas fa-plus-circle"></i>}
                        iconRight={<i className="fas fa-chevron-right"></i>}
                    />
                </div>

                <ViewPanel
                    theme={props.theme}
                    strings={props.strings}
                    
                    setStatus={props.setStatus}
                />

                {props.isFetching
                    ? <PreloaderMini />
                    : !props.advertisings
                        ? <>
                            {props.status
                                ? <SuccessAverage
                                    theme={props.theme}
                                    icon={<i className="fas fa-square-poll-horizontal"></i>}
                                    success={props.strings['status' + props.status] + "!"}
                                />
                                : null
                            }
                        </>
                        : <>
                            <Paginator
                                theme={props.theme}
                                count={props.count}
                                totalCount={props.totalCount}
                                currentPage={props.currentPage}
                                pagesCount={props.pagesCount}
                                onPageChanged={props.onPageChanged}
                            />

                            {props.advertisings.map(
                                a => <AdvertisingItem
                                        key={a.id}
                                        domain={props.domain}
                                        theme={props.theme}
                                        strings={props.strings}
                                        to={'/admin/advertisings/'}
                                        id={a.id}
                                        image0={a.image0}
                                        title={a.title}
                                        subtitle={a.subtitle}
                                        phone1={a.phone1}
                                        phone2={a.phone2}
                                        pub={a.pub}
                                        status={a.status}
                                    />
                                )
                            }

                            <Paginator
                                theme={props.theme}
                                count={props.count}
                                totalCount={props.totalCount}
                                currentPage={props.currentPage}
                                pagesCount={props.pagesCount}
                                onPageChanged={props.onPageChanged}
                            />
                        </>
                }
            </div>
        </div>
    )
}

export default AdminAdvertisingsContent