import { ProfileStoreAPI } from '../api/api'

const SET_CREATED = 'createStore/SET_CREATED'
const SET_SUCCESS = 'createStore/SET_SUCCESS'
const SET_ERROR = 'createStore/SET_ERROR'
const IS_FETCHING = 'createStore/IS_FETCHING'

let initialState = {
    created: false,
    success: null,
    error: null,
    back: true,
    isFetching: true
}

const createStoreReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CREATED:
            return { ...state, created: action.created }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setCreated = (created) => ({ type: SET_CREATED, created })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const createStore = (title, text) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreAPI.createStore(title, text)
        dispatch(setCreated(data.data.created))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default createStoreReducer