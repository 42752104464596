import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'
import SuccessAverage from '../common/Success/SuccessAverage'
import Paginator from '../common/Paginator/Paginator'
import ProfileItem from '../Items/Item/ProfileItem'
import ViewPanel from '../common/ViewPanel/ViewPanel'

const AdminProfilesContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                <BlockPanel
                    theme={props.theme}
                    icon1={<i className="fas fa-users"></i>}
                    title={props.strings.users}
                />

                <ViewPanel
                    theme={props.theme}
                    strings={props.strings}
                    
                    setStatus={props.setStatus}
                />

                {props.isFetching
                    ? <PreloaderMini />
                    : !props.profiles
                        ? <>
                            {props.status
                                ? <SuccessAverage
                                    theme={props.theme}
                                    icon={<i className="fas fa-users"></i>}
                                    success={props.strings['status' + props.status] + "!"}
                                />
                                : null
                            }
                        </>
                        : <>
                            <Paginator
                                theme={props.theme}
                                count={props.count}
                                totalCount={props.totalCount}
                                currentPage={props.currentPage}
                                pagesCount={props.pagesCount}
                                onPageChanged={props.onPageChanged}
                            />

                            {props.profiles.map(
                                p => <ProfileItem
                                        key={p.id}
                                        domain={props.domain}
                                        theme={props.theme}
                                        strings={props.strings}
                                        to={'/admin/profiles/'}
                                        id={p.id}
                                        avatar0={p.avatar0}
                                        login={p.login}
                                        name={p.name}
                                        location={p.location}
                                        marker={p.marker}
                                        text={p.text}
                                        phone={p.phone}
                                        reg={p.reg}
                                        status={p.status}
                                    />
                                )
                            }

                            <Paginator
                                theme={props.theme}
                                count={props.count}
                                totalCount={props.totalCount}
                                currentPage={props.currentPage}
                                pagesCount={props.pagesCount}
                                onPageChanged={props.onPageChanged}
                            />
                        </>
                }
            </div>
        </div>
    )
}

export default AdminProfilesContent