import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import AdminProductSetContent from './AdminProductSetContent'
import Sitebar from '../Sitebars/Sitebar'
import Preloader from '../common/Preloader/Preloader'

const AdminProductSet = (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                {props.isFetching
                    ? <Preloader />
                    : null
                }
                <AdminProductSetContent
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}

                    product={props.product}

                    back={props.back}

                    onSubmitVideo={props.onSubmitVideo}
                    onSubmitRemoveVideo={props.onSubmitRemoveVideo}
                    onSubmitImage={props.onSubmitImage}
                    onSubmitRemoveImage={props.onSubmitRemoveImage}

                    validateTitle={props.validateTitle}
                    validateText={props.validateText}
                    validatePrice={props.validatePrice}

                    onSubmitTitle={props.onSubmitTitle}
                    onSubmitText={props.onSubmitText}
                    onSubmitPrice={props.onSubmitPrice}
                    onSubmitTrash={props.onSubmitTrash}
                    navigate={props.navigate}
                />
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    theme={props.theme}
                    strings={props.strings}
                    sitebarActivate={props.sitebarActivate}

                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default AdminProductSet