import React from 'react'

const Toyota = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1000200}>TOYOTA 4RUNNER</option>
            <option value={1000201}>TOYOTA ARISTO</option>
            <option value={1000202}>TOYOTA AURION</option>
            <option value={1000203}>TOYOTA AVALON</option>
            <option value={1000204}>TOYOTA AVENSIS</option>
            <option value={1000205}>TOYOTA CAMRY</option>
            <option value={1000206}>TOYOTA CAMRY HYBRYD</option>
            <option value={1000207}>TOYOTA CAMRY SOLARA</option>
            <option value={1000208}>TOYOTA CARINA</option>
            <option value={1000209}>TOYOTA CELICA</option>
            <option value={1000210}>TOYOTA CELSIOR</option>
            <option value={1000211}>TOYOTA CHASER</option>
            <option value={1000212}>TOYOTA COASTER</option>
            <option value={1000213}>TOYOTA COROLLA</option>
            <option value={1000214}>TOYOTA COROLLA VERSO</option>
            <option value={1000215}>TOYOTA CORONA</option>
            <option value={1000216}>TOYOTA CRESSIDA</option>
            <option value={1000217}>TOYOTA CRESTA</option>
            <option value={1000218}>TOYOTA CROWN</option>
            <option value={1000219}>TOYOTA DYNA</option>
            <option value={1000220}>TOYOTA ECHO</option>
            <option value={1000221}>TOYOTA FJ CRUISER</option>
            <option value={1000222}>TOYOTA FORTUNER</option>
            <option value={1000223}>TOYOTA HERRIER</option>
            <option value={1000224}>TOYOTA HIACE</option>
            <option value={1000225}>TOYOTA HIGHLANDER</option>
            <option value={1000226}>TOYOTA HIGHLANDER HYBRID</option>
            <option value={1000227}>TOYOTA HILUX</option>
            <option value={1000228}>TOYOTA INNOVA</option>
            <option value={1000229}>TOYOTA IPSUM</option>
            <option value={1000230}>TOYOTA LAND CRUISER</option>
            <option value={1000231}>TOYOTA LAND CRUISER PRADO</option>
            <option value={1000232}>TOYOTA MR2</option>
            <option value={1000233}>TOYOTA MARINO</option>
            <option value={1000234}>TOYOTA MARK II</option>
            <option value={1000235}>TOYOTA MATRIX</option>
            <option value={1000236}>TOYOTA PASEO</option>
            <option value={1000237}>TOYOTA PRADO</option>
            <option value={1000238}>TOYOTA PREVIA</option>
            <option value={1000239}>TOYOTA PRIUS</option>
            <option value={1000240}>TOYOTA PRIUS Plug-in</option>
            <option value={1000241}>TOYOTA PRIUS Plug-in HYBRYD</option>
            <option value={1000242}>TOYOTA PRIUS C</option>
            <option value={1000243}>TOYOTA PRIUS V</option>
            <option value={1000244}>TOYOTA RAW4</option>
            <option value={1000245}>TOYOTA RUSH</option>
            <option value={1000246}>TOYOTA SCION</option>
            <option value={1000247}>TOYOTA SEQUOIA</option>
            <option value={1000248}>TOYOTA SIENNA</option>
            <option value={1000249}>TOYOTA SOLARA</option>
            <option value={1000250}>TOYOTA SPRINTER</option>
            <option value={1000251}>TOYOTA SUPRA</option>
            <option value={1000252}>TOYOTA T100</option>
            <option value={1000253}>TOYOTA T100 XTRA</option>
            <option value={1000254}>TOYOTA TACOMA</option>
            <option value={1000255}>TOYOTA TACOMA XTRA</option>
            <option value={1000256}>TOYOTA TERCEL</option>
            <option value={1000257}>TOYOTA TOWN ACE</option>
            <option value={1000258}>TOYOTA TRUCK XTRACAB SR5</option>
            <option value={1000259}>TOYOTA TUNDRA</option>
            <option value={1000260}>TOYOTA TUNDRA MAX</option>
            <option value={1000261}>TOYOTA VENZA</option>
            <option value={1000262}>TOYOTA VISTA</option>
            <option value={1000263}>TOYOTA WINDOM</option>
            <option value={1000264}>TOYOTA XA</option>
            <option value={1000265}>TOYOTA XTRA</option>
            <option value={1000266}>TOYOTA YARIS</option>
        </>
    )
}

export default Toyota