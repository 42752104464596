import React from 'react'

const CatElectronicsUpsExtensions  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10361}>{props.strings.cat10361}</option>
            <option value={10362}>{props.strings.cat10362}</option>
            <option value={10363}>{props.strings.cat10363}</option>
            <option value={10364}>{props.strings.cat10364}</option>
            <option value={10365}>{props.strings.cat10365}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsUpsExtensions 