import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'
import { NavLink } from 'react-router-dom'
import Counter from '../additions/Counter/Counter'

const ProductItem = (props) => {
    return (
        <NavLink to={props.to + props.id} >
            <div className={props.theme === 'light' ? light.blockView : dark.blockView} >
                {props.image0
                    ? <img src={props.domain + "/media/img/" + props.image0} alt="product" />
                    : <img src={props.domain + "/media/img/noimgmini.png"} alt="product" />
                }
                <div className={props.theme === 'light' ? light.info : dark.info}>
                    <div className={props.theme === 'light' ? light.title : dark.title}>
                        {props.type === "1"
                            ? <i className="fas fa-car"></i> : props.type === "1000"
                            ? <i className="fas fa-screwdriver-wrench"></i> : props.type === "2000"
                            ? <i className="fas fa-people-carry-box"></i> : props.type === "3000"
                            ? <i className="fas fa-briefcase"></i> : props.type === "4000"
                            ? <i className="fas fa-building"></i> : props.type === "5000"
                            ? <i className="fas fa-business-time"></i> : props.type === "6000"
                            ? <i className="fas fa-plate-wheat"></i> : props.type === "7000"
                            ? <i className="fas fa-paint-roller"></i> : props.type === "8000"
                            ? <i className="fas fa-couch"></i> : props.type === "9000"
                            ? <i className="fas fa-stapler"></i> : props.type === "10000"
                            ? <i className="fas fa-plug"></i> : props.type === "11000"
                            ? <i className="fas fa-person"></i> : props.type === "11001"
                            ? <i className="fas fa-person-dress"></i> : props.type === "11002"
                            ? <i className="fas fa-child"></i> : props.type === "12000"
                            ? <i className="fas fa-hand-holding-heart"></i> : props.type === "13000"
                            ? <i className="fas fa-prescription-bottle-medical"></i> : props.type === "14000"
                            ? <i className="fas fa-binoculars"></i> : props.type === "15000"
                            ? <i className="fas fa-futbol"></i> : props.type === "16000"
                            ? <i className="fas fa-burger"></i> : props.type === "17000"
                            ? <i className="fas fa-wheat-awn"></i> : props.type === "18000"
                            ? <i className="fas fa-crow"></i> : <i className="fas fa-bag-shopping"></i>
                        } {props.title}
                    </div>
                    <div className={props.theme === 'light' ? light.small : dark.small}>
                        <small><i className="fas fa-map-marker-alt"></i> {props.strings['loc' + props.location]}. {props.strings['loc' + props.marker]}.</small>
                    </div>
                    <div className={props.theme === 'light' ? light.small : dark.small}>
                        <small><i className="fas fa-certificate"></i> {props.strings['cat' + props.type]} / {props.strings['cat' + props.chapter]} / {props.strings['cat' + props.categorie]}.</small>
                    </div>
                    
                    {props.status
                        ? <div className={props.theme === 'light' ? light.status : dark.status}>
                            {props.strings['status' + props.status]}...
                        </div>
                        : <div className={props.theme === 'light' ? light.preview : dark.preview}>
                            {props.text}
                        </div>
                    }

                    
                    <div className={props.theme === 'light' ? light.price : dark.price}>
                        {props.price
                            ? <i className="fas fa-wallet"></i>
                            : null
                        }
                        {props.price
                            ? " " + props.strings.price + ": " + props.price + " " + props.strings.valute
                            : null
                        }
                            <div className={props.theme === 'light' ? light.views : dark.views}>
                                <Counter count={props.views} /> <i className="fas fa-eye"></i> | <i className="fas fa-thumbs-up"></i> <Counter count={props.favoriteCount} />
                            </div>
                        </div>
                </div>
            </div>
        </NavLink>
    )
}

export default ProductItem