import React from 'react'

const Foods = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={6001}>{props.strings.cat6001}</option>
            <option value={6050}>{props.strings.cat6050}</option>
            <option value={6100}>{props.strings.cat6100}</option>
            <option value={6150}>{props.strings.cat6150}</option>
            <option value={6200}>{props.strings.cat6200}</option>
            <option value={6300}>{props.strings.cat6300}</option>
            <option value={6350}>{props.strings.cat6350}</option>
            <option value={6400}>{props.strings.cat6400}</option>
            <option value={6450}>{props.strings.cat6450}</option>
            <option value={6500}>{props.strings.cat6500}</option>
            <option value={6600}>{props.strings.cat6600}</option>
            <option value={6700}>{props.strings.cat6700}</option>
            <option value={6800}>{props.strings.cat6800}</option>
            <option value={6850}>{props.strings.cat6850}</option>
        </>
    )
}

export default Foods