import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import LoginForm from '../common/Forms/LoginForm'
import Menu from '../Items/Menu/Menu'
import BlockPanel from '../common/BlockPanel/BlockPanel'

const LoginContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>

                <Menu
                    theme={props.theme}
                    back={props.back}
                />

                <BlockPanel
                    theme={props.theme}
                    icon1={<i className="fas fa-user-check"></i>}
                    icon2={null}
                    title={props.strings.authorization + "!"}
                />

                <div className={props.theme === 'light' ? light.formContainer : dark.formContainer}>
                    <h2><i className="fas fa-sign-in-alt"></i> {props.strings.entrance}.</h2>
                    <LoginForm
                        theme={props.theme}
                        strings={props.strings}
                        
                        initialValues={props.initialValues}
                        validate={props.validate}
                        onSubmit={props.onSubmit}
                    />
                </div>
            </div>
        </div>
    )
}

export default LoginContent