import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    requestComment,
    setComment,
    setProduct,
    setSuccess as setCommentSuccess,
    setError as setCommentError
} from '../../redux/profileComment-reducer'
import {
    remove,
    setUpdated,
    setSuccess as setCommentSetSuccess,
    setError as setCommentSetError
} from '../../redux/profileCommentSet-reducer'
import { setIsFetching } from '../../redux/profileComments-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import ProfileComment from './ProfileComment'

const ProfileCommentContainer= (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const params = useParams()

    const comment = useSelector(state => state.profileComment.comment)
    const product = useSelector(state => state.profileComment.product)
    const status = useSelector(state => state.profileComment.status)
    const commentSuccess = useSelector(state => state.profileComment.success)
    const commentError = useSelector(state => state.profileComment.error)
    const back = useSelector(state => state.profileComment.back)
    const commentIsFetching = useSelector(state => state.profileComment.commentIsFetching)
    
    const updated = useSelector(state => state.profileCommentSet.updated)
    const commentSetSuccess = useSelector(state => state.profileCommentSet.success)
    const commentSetError = useSelector(state => state.profileCommentSet.error)
    const isFetching = useSelector(state => state.profileCommentSet.isFetching)

    const auth = 1

    useEffect(() => {
        if (!props.isAuth) {
            navigate('/')
        } else if (!params.commentId) {
            navigate('/profile/comments')
        }
    }, [navigate, props.isAuth, params.commentId])

    useEffect(() => {
        if (params.commentId || updated) {
            dispatch(setComment([]))
            dispatch(setProduct([]))
            dispatch(requestComment(auth, params.commentId))
            if (updated) {
                dispatch(setIsFetching(true))
                dispatch(setUpdated(false))
            }
            window.scrollTo({top: 0, left: 0})
        }
    }, [dispatch, params.commentId, updated])

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(auth, params.commentId, trash))
        setTimeout(() => {
            navigate('/profile/comments')
        }, 1000)
    }

    useEffect(
        () => {
            if(commentError){
                dispatch(setNotifyError(commentError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setCommentError(null))
            } else if(commentSetError){
                dispatch(setNotifyError(commentSetError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setCommentSetError(null))
            } else if(commentSuccess){
                dispatch(setNotifySuccess(commentSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setCommentSuccess(null))
            } else if(commentSetSuccess){
                dispatch(setNotifySuccess(commentSetSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setCommentSetSuccess(null))
            }
        }, [dispatch, commentError, commentSetError, commentSuccess, commentSetSuccess]
    )

    return (
        <ProfileComment
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            comment={comment}
            product={product}
            status={status}
            back={back}
            commentIsFetching={commentIsFetching}

            isFetching={isFetching}

            onSubmitTrash={onSubmitTrash}
        />
    )
}

export default ProfileCommentContainer