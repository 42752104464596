import React from 'react'
import dark from './NavbarToggleDark.module.css'
import light from './NavbarToggleLight.module.css'
import { NavLink } from 'react-router-dom'

const NavbarToggle = (props) => {

    return (
        <div className={props.navToggle
            ? props.theme === 'light' ? light.navbarToggle : dark.navbarToggle
            : props.theme === 'light' ? light.navbarToggleActive + " " + light.navbarToggle : dark.navbarToggleActive + " " + dark.navbarToggle
            }
        >
            <div className={props.theme === 'light' ? light.navbarItem : dark.navbarItem} >
                <NavLink
                    to="/"
                    onClick={props.navbarActivate}
                    className={props.theme === 'light'
                        ? ({isActive}) => isActive 
                            ? light.item + " " + light.active 
                            : light.item
                        : ({isActive}) => isActive 
                            ? dark.item + " " + dark.active 
                            : dark.item
                    }
                >
                    <i className="fas fa-home"></i> {props.strings.home}
                </NavLink>
            </div>

            <div className={props.theme === 'light' ? light.navbarItem : dark.navbarItem} >
                <NavLink
                    to="/products"
                    onClick={props.navbarActivate}
                    className={props.theme === 'light'
                        ? ({isActive}) => isActive 
                            ? light.item + " " + light.active 
                            : light.item
                        : ({isActive}) => isActive 
                            ? dark.item + " " + dark.active 
                            : dark.item
                    }
                >
                    <i className="fas fa-bag-shopping"></i> {props.strings.products}
                </NavLink>
            </div>

            <div className={props.theme === 'light' ? light.navbarItem : dark.navbarItem} >
                <NavLink
                    to="/stores"
                    onClick={props.navbarActivate}
                    className={props.theme === 'light'
                        ? ({isActive}) => isActive 
                            ? light.item + " " + light.active 
                            : light.item
                        : ({isActive}) => isActive 
                            ? dark.item + " " + dark.active 
                            : dark.item
                    }
                >
                    <i className="fas fa-store-alt"></i> {props.strings.stores}
                </NavLink>
            </div>

            { props.isAuth
                ? <div className={props.theme === 'light' ? light.navbarItem : dark.navbarItem} >
                    <NavLink
                        to="/profile"
                        onClick={props.navbarActivate}
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-user"></i> {props.strings.me}
                    </NavLink>
                </div>
                : null
            }

            { props.isAuth
                ? !props.profileStore
                    ? <div className={props.theme === 'light' ? light.navbarItem : dark.navbarItem} >
                        <NavLink
                            to="/create/store"
                            onClick={props.navbarActivate}
                            className={props.theme === 'light'
                                ? ({isActive}) => isActive 
                                    ? light.item + " " + light.active 
                                    : light.item
                                : ({isActive}) => isActive 
                                    ? dark.item + " " + dark.active 
                                    : dark.item
                            }
                        >
                            <i className="fas fa-store-alt"></i> + {props.strings.createStore}
                            </NavLink>
                    </div>
                    : <div className={props.theme === 'light' ? light.navbarItem : dark.navbarItem} >
                        <NavLink
                            to="/mystore"
                            onClick={props.navbarActivate}
                            className={props.theme === 'light'
                                ? ({isActive}) => isActive 
                                    ? light.item + " " + light.active 
                                    : light.item
                                : ({isActive}) => isActive 
                                    ? dark.item + " " + dark.active 
                                    : dark.item
                            }
                        >
                            <i className="fas fa-store-alt"></i> {props.profileStore.titleM}
                        </NavLink>
                    </div>
                : null
            }

            { props.isAuth
                ? <div className={props.theme === 'light' ? light.navbarItem : dark.navbarItem} >
                    <NavLink
                        to="/logout"
                        onClick={props.navbarActivate}
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-sign-out-alt"></i> {props.strings.exit}
                    </NavLink>
                </div>
                : <div className={props.theme === 'light' ? light.navbarItem : dark.navbarItem} >
                    <NavLink
                        to="/login"
                        onClick={props.navbarActivate}
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-sign-in-alt"></i> {props.strings.entrance}
                    </NavLink>
                </div>
            }
        </div>
    )
}

export default NavbarToggle