import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import StoreItem from '../Items/Item/StoreItem'
import ProductItem from '../Items/Item/ProductItem'
import Menu from '../Items/Menu/Menu'
import SuccessAverage from '../common/Success/SuccessAverage'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'

const HomeContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>

                <Menu 
                    theme={props.theme}
                    back={props.back}
                />

                <BlockPanel
                    theme={props.theme}
                    icon1={<i className="fas fa-star"></i>}
                    icon2={<i className="fas fa-bag-shopping"></i>}
                    title={props.strings.topViews}
                />

                {props.topViewProductsIsFetching
                    ? <PreloaderMini />
                    : !props.topViewProducts
                        ? props.topViewProductsStatus
                            ? <SuccessAverage
                                theme={props.theme}
                                icon={<i className="fas fa-bag-shopping"></i>}
                                success={props.strings['status' + props.topViewProductsStatus] + "!"}
                            />
                            : null
                        : props.topViewProducts.map(
                            p => <ProductItem
                                    key={p.id}
                                    domain={props.domain}
                                    theme={props.theme}
                                    strings={props.strings}
                                    to={'/products/'}
                                    id={p.id}
                                    image0={p.image0}
                                    title={p.title}
                                    type={p.type}
                                    chapter={p.chapter}
                                    categorie={p.categorie}
                                    location={p.location}
                                    marker={p.marker}
                                    text={p.text}
                                    price={p.price}
                                    views={p.views}
                                    favoriteCount={p.favoriteCount}
                                />
                            )
                }


                <BlockPanel
                    theme={props.theme}
                    icon1={<i className="fas fa-star"></i>}
                    icon2={<i className="fas fa-store-alt"></i>}
                    title={props.strings.topRatings}
                />

                {props.topViewProductsIsFetching
                    ? <PreloaderMini />
                    : !props.topRatingStores
                        ? props.topRatingStoresStatus
                            ? <SuccessAverage
                                theme={props.theme}
                                icon={<i className="fas fa-store-alt"></i>}
                                success={props.strings['status' + props.topRatingStoresStatus] + "!"}
                            />
                            : null
                        : props.topRatingStores.map(
                            s => <StoreItem
                                    key={s.id}
                                    domain={props.domain}
                                    theme={props.theme}
                                    strings={props.strings}
                                    to={'/stores/'}
                                    id={s.id}
                                    image0={s.image0}
                                    title={s.title}
                                    location={s.location}
                                    marker={s.marker}
                                    phone={s.phone}
                                    text={s.text}
                                    rating={s.rating}
                                    ratingCount={s.ratingCount}
                                />
                            )
                }

                <BlockPanel
                    theme={props.theme}
                    icon1={<i className="fas fa-bag-shopping"></i>}
                    title={props.strings.news}
                />

                {props.topViewProductsIsFetching
                    ? <PreloaderMini />
                    : !props.newProducts
                        ? props.newProductsStatus
                            ? <SuccessAverage
                                theme={props.theme}
                                icon={<i className="fas fa-bag-shopping"></i>}
                                success={props.strings['status' + props.newProductsStatus] + "!"}
                            />
                            : null
                        : props.newProducts.map(
                            p => <ProductItem
                                    key={p.id}
                                    domain={props.domain}
                                    theme={props.theme}
                                    strings={props.strings}
                                    to={'/products/'}
                                    id={p.id}
                                    image0={p.image0}
                                    title={p.title}
                                    type={p.type}
                                    chapter={p.chapter}
                                    categorie={p.categorie}
                                    location={p.location}
                                    marker={p.marker}
                                    text={p.text}
                                    price={p.price}
                                    views={p.views}
                                    favoriteCount={p.favoriteCount}
                                />
                            )
                }
            </div>
        </div>
    )
}

export default HomeContent