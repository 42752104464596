import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    setProduct,
    setImages,
    setStore,
    setProfile,
    requestProduct
} from '../../redux/adminProduct-reducer'
import { resizeFile, resizeFileMini } from '../../api/main'
import {
    changeVideo,
    videoDelete,
    changeImage,
    imageDelete,
    changeTitle,
    changeText,
    changePrice,
    setUpdated,
    setSuccess,
    setError,
    remove
} from '../../redux/adminProductSet-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setIsFetching } from '../../redux/adminProducts-reducer'
import AdminProductSet from './AdminProductSet'

const AdminProductSetContainer = (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()

    const product = useSelector(state => state.adminProduct.product)
    
    const updated = useSelector(state => state.adminProductSet.updated)
    const success = useSelector(state => state.adminProductSet.success)
    const error = useSelector(state => state.adminProductSet.error)
    const back = useSelector(state => state.adminProductSet.back)
    const isFetching = useSelector(state => state.adminProductSet.isFetching)

    const id = product.id
    const admin = 1

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        } else if (!params.productId) {
            navigate('/admin/products')
        } else if (!product) {
            navigate('/admin/products')
        }
    }, [navigate, props.isAuth, props.isAdmin, params.productId, product])

    useEffect(() => {
        if (params.productId || updated) {
            dispatch(setProduct([]))
            dispatch(setImages([]))
            dispatch(setStore([]))
            dispatch(setProfile([]))
            dispatch(requestProduct(admin, params.productId))
            if (updated) {
                dispatch(setIsFetching(true))
                dispatch(setUpdated(false))
            }
        }
    }, [dispatch, navigate, params.productId, updated])

    const onSubmitVideo = async (e) => {
        const video = e.target.files[0]
        dispatch(changeVideo(admin, id, video))
    }

    const onSubmitRemoveVideo = async (e) => {
        const removeVideo = true
        dispatch(videoDelete(admin, id, removeVideo))
    }

    const onSubmitImage = async (e) => {
        const file = e.target.files[0]
        const image0 = await resizeFileMini(file)
        const image = await resizeFile(file)
        const imageMiniId = 1
        const imageId = e.target.attributes.imageId.value
        if (product.image0 === null || imageId === 1) {
            dispatch(changeImage(admin, id, imageMiniId, image0))
            dispatch(changeImage(admin, id, imageId, image)) 
        } else {
            dispatch(changeImage(admin, id, imageId, image))
        }
    }

    const onSubmitRemoveImage = async (e) => {
            const removeId = e.target.attributes.imageId.value
            dispatch(imageDelete(admin, id, removeId))
    }

    const validateTitle= (values) => {
        const errors = {}
        if (!values.title) {
            errors.title = props.strings.required
        } else if (values.title.length < 3) {
            errors.title = props.strings.min + ': 3/' + values.title.length
        } else if (values.title.length > 50) {
            errors.title = props.strings.max + ': 50/' + values.title.length
        } else if (!/^[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я0-9\s._-]{3,50}$/i.test(values.title)) {
            errors.title = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'
        }
        return errors
    }

    const onSubmitTitle = (values, { setSubmitting }) => {
        const title = values.title
        dispatch(changeTitle(admin, id, title))
        setSubmitting(false)
    }
            
    const validateText= (values) => {
        const errors = {}
        if (!values.text) {
            errors.text = props.strings.required
        } else if (values.text.length < 10) {
            errors.text = props.strings.min + ': 10/' + values.text.length
        } else if (values.text.length > 1500) {
            errors.text = props.strings.max + ': 1500/' + values.text.length
        }
        return errors
    }

    const onSubmitText = (values, { setSubmitting }) => {
        const text = values.text
        dispatch(changeText(admin, id, text))
        setSubmitting(false)
    }

                
    const validatePrice= (values) => {
        const errors = {}
        if (!values.price) {
            errors.price = props.strings.required
        } else if (values.price.length < 1) {
            errors.price = props.strings.min + ': 1/' + values.price.length
        } else if (values.price.length > 9) {
            errors.price = props.strings.max + ': 9/' + values.price.length
        } else if (!/^[0-9]{1,9}$/i.test(values.price)) {
            errors.price = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9).'
        }
        return errors
    }
    
    const onSubmitPrice = (values, { setSubmitting }) => {
        const price = values.price
        dispatch(changePrice(admin, id, price))
        setSubmitting(false)
    }

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(admin, id, trash))
        dispatch(setIsFetching(true))
        setTimeout(() => {
            navigate('/admin/products')
        }, 1000)
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <AdminProductSet
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            product={product}

            back={back}
            isFetching={isFetching}

            onSubmitVideo={onSubmitVideo}
            onSubmitRemoveVideo={onSubmitRemoveVideo}
            onSubmitImage={onSubmitImage}
            onSubmitRemoveImage={onSubmitRemoveImage}

            validateTitle={validateTitle}
            validateText={validateText}
            validatePrice={validatePrice}

            onSubmitTitle={onSubmitTitle}
            onSubmitText={onSubmitText}
            onSubmitPrice={onSubmitPrice}
            onSubmitTrash={onSubmitTrash}
            navigate={navigate}
        />
    )
}

export default AdminProductSetContainer