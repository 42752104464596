import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resizeFile, resizeFileMini } from '../../api/main'
import {
    changeImage,
    imageDelete,
    changeTitle,
    changeText,
    setSuccess as setStoreSuccess,
    setError as setStoreError,
    remove
} from '../../redux/profileStoreSet-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import ProfileStoreSet from './ProfileStoreSet'

const ProfileStoreSetContainer = (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const storeSuccess = useSelector(state => state.profileStoreSet.success)
    const storeError = useSelector(state => state.profileStoreSet.error)
    const back = useSelector(state => state.profileStoreSet.back)
    const isFetching = useSelector(state => state.profileStoreSet.isFetching)

    const auth = 1

    useEffect(() => {
        if (!props.isAuth) {
            navigate('/login')
        } else if (props.profile.status === 2) {
            navigate('/profile')
        } else if (props.profile.status === 0) {
            navigate('/logout')
        } else if (props.profileStoreStatus === 5) {
            navigate('/create/store')
        }
    }, [navigate, props.isAuth, props.profile.status, props.profileStoreStatus])

    const onSubmitImage = async (e) => {
        const file = e.target.files[0]
        const image0 = await resizeFileMini(file)
        const image = await resizeFile(file)
        const imageMiniId = 1
        const imageId = e.target.attributes.imageid.value
        if (props.profileStore.image0 === null) {
            dispatch(changeImage(auth, imageMiniId, image0))
            dispatch(changeImage(auth, imageId, image))
        } else {
            dispatch(changeImage(auth, imageId, image))
        }
    }

    const onSubmitRemoveImage = async (e) => {
        const removeId = e.target.attributes.imageid.value
        dispatch(imageDelete(auth, removeId))
}
    
    const validateTitle= (values) => {
        const errors = {}
        if (!values.title) {
            errors.title = props.strings.required
        } else if (values.title.length < 3) {
            errors.title = props.strings.min + ': 3/' + values.title.length
        } else if (values.title.length > 50) {
            errors.title = props.strings.max + ': 50/' + values.title.length
        } else if (!/^[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я0-9\s._-]{3,50}$/i.test(values.title)) {
            errors.title = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'
        }
        return errors
    }

    const onSubmitTitle = (values, { setSubmitting }) => {
        const title = values.title
        dispatch(changeTitle(auth, title))
        setSubmitting(false)
    }
            
    const validateText= (values) => {
        const errors = {}
        if (!values.text) {
            errors.text = props.strings.required
        } else if (values.text.length < 10) {
            errors.text = props.strings.min + ': 10/' + values.text.length
        } else if (values.text.length > 1500) {
            errors.text = props.strings.max + ': 1500/' + values.text.length
        }
        return errors
    }

    const onSubmitText = (values, { setSubmitting }) => {
        const text = values.text
        dispatch(changeText(auth, text))
        setSubmitting(false)
    }

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(auth, trash))
        navigate('/profile')
    }

    useEffect(
        () => {
            if(storeError){
                dispatch(setNotifyError(storeError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setStoreError(null))
            } else if(storeSuccess){
                dispatch(setNotifySuccess(storeSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setStoreSuccess(null))
            }
        }, [dispatch, storeSuccess, storeError]
    )

    return (
        <ProfileStoreSet
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            store={props.profileStore}
            
            back={back}
            isFetching={isFetching}

            navigate={navigate}

            validateTitle={validateTitle}
            validateText={validateText}

            onSubmitImage={onSubmitImage}
            onSubmitRemoveImage={onSubmitRemoveImage}
            onSubmitTitle={onSubmitTitle}
            onSubmitText={onSubmitText}
            onSubmitTrash={onSubmitTrash}
        />
    )
}

export default ProfileStoreSetContainer