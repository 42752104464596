import { CommentsAPI } from '../api/api'

const SET_COMMENT = 'profileComment/SET_COMMENT'
const SET_PRODUCT = 'profileComment/SET_PRODUCT'
const SET_STATUS = 'profileComment/SET_STATUS'
const SET_SUCCESS = 'profileComment/SET_SUCCESS'
const SET_ERROR = 'profileComment/SET_ERROR'
const IS_FETCHING = 'profileComment/IS_FETCHING'

let initialState = {
    comment: [],
    profile: [],
    product: [],
    status: null,
    success: null,
    error: null,
    back: true,
    isFetching: true
}

const profileCommentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMMENT:
            return { ...state, comment: action.comment }
        case SET_PRODUCT:
            return { ...state, product: action.product }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setComment = (comment) => ({ type: SET_COMMENT, comment })
export const setProduct = (product) => ({ type: SET_PRODUCT, product })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestComment = (admin, id) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await CommentsAPI.profileComment(admin, id)
        dispatch(setComment(data.data.comment))
        dispatch(setProduct(data.data.product))
        dispatch(setStatus(data.data.status))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default profileCommentReducer