import React from 'react'
import dark from './FormDark.module.css'
import light from './FormLight.module.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const SetNameForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.formPanel : dark.formPanel}>
            <Formik
                initialValues={{ name: '' }}
                validate={props.validate}
                onSubmit={props.onSubmit}
                >
                {({ isSubmitting }) => (
                    <Form className={props.theme === 'light' ? light.form : dark.form} >

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="name"
                            component="div"
                        />
                        <Field
                            className={props.theme === 'light' ? light.editInput : dark.editInput}
                            type="text"
                            name="name"
                            autoComplete="username"
                            placeholder={props.strings.name}
                            pattern="[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я\s]{3,50}"
                            title={props.strings.pattern + ': ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'}
                        />
                        <button
                            className={props.theme === 'light' ? light.editButton : dark.editButton}
                            type="submit"
                            disabled={isSubmitting}
                        >
                            <i className="fas fa-check"></i>
                        </button>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default SetNameForm