import React from 'react'

const HomeOffice = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={8001}>{props.strings.cat8001}</option>
            <option value={8050}>{props.strings.cat8050}</option>
            <option value={8100}>{props.strings.cat8100}</option>
            <option value={8150}>{props.strings.cat8150}</option>
            <option value={8200}>{props.strings.cat8200}</option>
            <option value={8250}>{props.strings.cat8250}</option>
            <option value={8300}>{props.strings.cat8300}</option>
            <option value={8350}>{props.strings.cat8350}</option>
            <option value={8400}>{props.strings.cat8400}</option>
        </>
    )
}

export default HomeOffice