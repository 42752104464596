import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import AddAdvertisingContent from './AddAdvertisingContent'
import Sitebar from '../Sitebars/Sitebar'

const AddAdvertising = (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                <AddAdvertisingContent
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}
                    
                    initialValues={props.initialValues}
                    validate={props.validate}
                    onSubmit={props.onSubmit}

                    back={props.back}
                    isFetching={props.isFetching}
                />
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    theme={props.theme}
                    strings={props.strings}
                    sitebarActivate={props.sitebarActivate}
                    
                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default AddAdvertising