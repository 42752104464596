import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import PhoneVerify from './PhoneVerify'
import ProfileContent from './ProfileContent'
import Sitebar from '../Sitebars/Sitebar'

const Profile = (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                {props.profile.status === '2'
                    ?  <PhoneVerify
                        domain={props.domain}
                        theme={props.theme}
                        strings={props.strings}
                        phone={props.profile.phone}
                        back={props.back}
                    />
                    : <ProfileContent
                        domain={props.domain}
                        theme={props.theme}
                        strings={props.strings}

                        profile={props.profile}
                        images={props.images}
                        activeIndex={props.activeIndex}
                        setActiveIndex={props.setActiveIndex}
                        isPhotosCrope={props.isPhotosCrope}
                        crope={props.crope}
                        back={props.back}
                        isFetching={props.isFetching}
                    />
                }
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    theme={props.theme}
                    strings={props.strings}
                    sitebarActivate={props.sitebarActivate}

                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default Profile