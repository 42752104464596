import React from 'react'

const CatFoodsMilkProducts  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6101}>{props.strings.cat6101}</option>
            <option value={6102}>{props.strings.cat6102}</option>
            <option value={6103}>{props.strings.cat6103}</option>
            <option value={6110}>{props.strings.cat6110}</option>
            <option value={6112}>{props.strings.cat6112}</option>
            <option value={6113}>{props.strings.cat6113}</option>
            <option value={6120}>{props.strings.cat6120}</option>
            <option value={6121}>{props.strings.cat6121}</option>
            <option value={6122}>{props.strings.cat6122}</option>
            <option value={6123}>{props.strings.cat6123}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsMilkProducts 