import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    setNewProductId,
    addProduct,
    addCar,
    setSuccess as setAddProductSuccess,
    setError as setAddProductError
} from '../../redux/addProduct-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import AddProduct from './AddProduct'

const AddProductContainer = (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const newProductId = useSelector(state => state.addProduct.newProductId)
    const addProductSuccess = useSelector(state => state.addProduct.success)
    const addProductError = useSelector(state => state.addProduct.error)
    const back = useSelector(state => state.addProduct.back)
    const isFetching = useSelector(state => state.addProduct.isFetching)

    useEffect(() => {
        if (!props.isAuth) {
            navigate('/login')
        } else if (props.profile.status === 2) {
            navigate('/profile')
        } else if (props.profile.status === 0) {
            navigate('/logout')
        } else if (props.profileStoreStatus === 5) {
            navigate('/create/store')
        } else if (props.profileStore.status === 0) {
            navigate('/mystore')
        } else if (newProductId) {
            navigate('/mystore/products/' + newProductId + '/settings/image')
            dispatch(setNewProductId(null))
        }
    }, [ dispatch, navigate, props.isAuth, props.profile.status, props.profileStoreStatus, props.profileStore.status, newProductId, ] )

    const initialValues = {
        type: '',
        chapter: '',
        categorie: '',
        title: '',
        manufacturer: '',
        model: '',
        engine: '',
        transmission: '',
        drive: '',
        body: '',
        color: '',
        year: '',
        mileage: '',
        text: '',
        price: '',
        checkUsing: ''
    }

    const validate = (values) => {
        const errors = {}

        if (!values.type || values.type === '0') {
            errors.type = props.strings.required
        }

        if (!values.chapter || values.chapter === '0') {
            errors.chapter = props.strings.required
        }

        if (!values.categorie || values.categorie === '0') {
            errors.categorie = props.strings.required
        }

        if (values.type !== '1' && values.chapter !== '10') {
            if (!values.title) {
                errors.title = props.strings.required
            } else if (values.title.length < 3) {
                errors.title = props.strings.min + ': 3/' + values.title.length
            } else if (values.title.length > 50) {
                errors.title = props.strings.max + ': 50/' + values.title.length
            } else if (!/^[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я0-9\s._-]{3,50}$/i.test(values.title)) {
                errors.title = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'
            }
        }
        
        if (values.type === '1' && values.chapter === '10') {
            if (!values.manufacturer || values.manufacturer === '0') {
                errors.manufacturer = props.strings.required
            }
            if (!values.model || values.model === '0') {
                errors.model = props.strings.required
            }
            if (!values.engine || values.engine === '0') {
                errors.engine = props.strings.required
            }
            if (!values.transmission || values.transmission === '0') {
                errors.transmission = props.strings.required
            }
            if (!values.drive || values.drive === '0') {
                errors.drive = props.strings.required
            }
            if (!values.body || values.body === '0') {
                errors.body = props.strings.required
            }
            if (!values.color || values.color === '0') {
                errors.color = props.strings.required
            }
            if (!values.year || values.year === '0') {
                errors.year = props.strings.required
            }
            if (!values.mileage) {
                errors.mileage = props.strings.required
            } else if (values.mileage.length < 1) {
                errors.mileage = props.strings.min + ': 1/' + values.mileage.length
            } else if (values.mileage.length > 9) {
                errors.mileage = props.strings.max + ': 9/' + values.mileage.length
            } else if (!/^[0-9]{1,9}$/i.test(values.mileage)) {
                errors.mileage = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9).'
            }
        }

        if (!values.text) {
            errors.text = props.strings.required
        } else if (values.text.length < 10) {
            errors.text = props.strings.min + ': 10/' + values.text.length
        } else if (values.text.length > 1500) {
            errors.text = props.strings.max + ': 1500/' + values.text.length
        }

        if (!values.price) {
            errors.price = props.strings.required
        } else if (values.price.length < 1) {
            errors.price = props.strings.min + ': 1/' + values.price.length
        } else if (values.price.length > 9) {
            errors.price = props.strings.max + ': 9/' + values.price.length
        } else if (!/^[0-9]{1,9}$/i.test(values.price)) {
            errors.price = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9).'
        }

        if (!values.checkUsing) {
            errors.checkUsing = props.strings.error106
        }
        return errors;
    }

    const onSubmit = (values, { setSubmitting }) => {
        const type = values.type
        const chapter = values.chapter
        const categorie = values.categorie
        const title = values.title
        const manufacturer = values.manufacturer
        const model = values.model
        const engine = values.engine
        const transmission = values.transmission
        const drive = values.drive
        const body = values.body
        const color = values.color
        const year = values.year
        const mileage = values.mileage
        const text = values.text
        const price = values.price ? values.price : '0'
        if (values.type === '1' && values.chapter === '10') {
            dispatch(
                addCar(
                    type,
                    chapter,
                    categorie,
                    manufacturer,
                    model,
                    engine,
                    transmission,
                    drive,
                    body,
                    color,
                    year,
                    mileage,
                    text,
                    price
                )
            )
        } else {
            dispatch(
                addProduct(
                    type,
                    chapter,
                    categorie,
                    title,
                    text,
                    price
                )
            )
        }
        setSubmitting(false)
    }

    useEffect(
        () => {
            if(addProductError){
                dispatch(setNotifyError(addProductError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setAddProductError(null))
            } else if(addProductSuccess){
                dispatch(setNotifySuccess(addProductSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setAddProductSuccess(null))
            }
        }, [dispatch, addProductSuccess, addProductError ]
    )

    return (
        <AddProduct
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            back={back}
            isFetching={isFetching}
            
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
        />
    )
}

export default AddProductContainer