import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import Slider from '../Items/Slider/Slider'
import ProfileFull from '../Items/FullItem/ProfileFull'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import SetPanel from '../common/SetPanel/SetPanel'
import Success from '../common/Success/Success'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'

const ProfileContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>

                <Menu
                    theme={props.theme}
                    back={props.back}
                />

                {props.isFetching
                    ? <PreloaderMini />
                    : <>
                        <BlockPanel
                            theme={props.theme}
                            iconLeft={<i className="fas fa-store-alt"></i>}
                            leftLink={"/mystore"}
                            icon1={<i className="fas fa-user-circle"></i>}
                            title={props.profile.login}
                            iconRight={<i className="fas fa-ellipsis-v"></i>}
                            rightLink={"/profile/settings"}
                        />

                        <Slider
                                imageSource={props.domain + "/media/avatar/"}
                                imageAlt={"avatar"}
                                images={props.images}
                                activeIndex={props.activeIndex}
                                setActiveIndex={props.setActiveIndex}
                                crope={props.crope}
                                isPhotosCrope={props.isPhotosCrope}
                            />
                            
                        <ProfileFull
                            theme={props.theme}
                            strings={props.strings}
                            name={props.profile.name}
                            location={props.profile.location}
                            marker={props.profile.marker}
                            phone={props.profile.phone}
                            text={props.profile.text}
                            birth={props.profile.birth}
                            reg={props.profile.reg}
                            status={props.profile.status}
                        />

                        <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                            <SetPanel
                                theme={props.theme}
                                to={"/profile/comments"}
                                text={props.strings.myComments}
                                iconLeft={<i className="fas fa-comments"></i>}
                                iconRight={<i className="fas fa-chevron-right"></i>}
                            />
                        </div>

                        <BlockPanel
                            theme={props.theme}
                            icon1={<i className="fas fa-compass"></i>}
                            title={props.strings.navigation}
                        />
                        <Success
                            theme={props.theme}
                            icon={<i className="far fa-thumbs-up"></i>}
                            success={props.strings.favorite}
                        />
                        <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                            <SetPanel
                                theme={props.theme}
                                to={"/profile/favorite-stores"}
                                text={props.strings.stores}
                                iconLeft={<i className="fas fa-store-alt"></i>}
                                iconRight={<i className="fas fa-chevron-right"></i>}
                            />
                            <SetPanel
                                theme={props.theme}
                                to={"/profile/favorite-products"}
                                text={props.strings.products}
                                iconLeft={<i className="fas fa-bag-shopping"></i>}
                                iconRight={<i className="fas fa-chevron-right"></i>}
                            />
                        </div>

                        <Success
                            theme={props.theme}
                            icon={<i className="fas fa-clock-rotate-left"></i>}
                            success={props.strings.history}
                        />
                        <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                            <SetPanel
                                theme={props.theme}
                                to={"/profile/history-stores"}
                                text={props.strings.stores}
                                iconLeft={<i className="fas fa-store-alt"></i>}
                                iconRight={<i className="fas fa-chevron-right"></i>}
                            />
                            <SetPanel
                                theme={props.theme}
                                to={"/profile/history-products"}
                                text={props.strings.products}
                                iconLeft={<i className="fas fa-bag-shopping"></i>}
                                iconRight={<i className="fas fa-chevron-right"></i>}
                            />
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default ProfileContent