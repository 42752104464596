import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    login,
    setSuccess,
    setError
} from '../../redux/auth-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import Login from './Login'

const LoginContainer= (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const success = useSelector(state => state.auth.success)
    const error = useSelector(state => state.auth.error)
    const back = useSelector(state => state.auth.back)

    useEffect(() => {
        if (props.isAuth) {
            setTimeout(() => {
                navigate('/profile')
            }, 1000)
        }
    }, [props.isAuth, navigate])

    const initialValues={ code: '', phone: '', password: '' }

    const validate = (values) => {
        const errors = {}
        if (!values.phone) {
            errors.phone = props.strings.required
        } else if (values.phone.length < 8) {
            errors.phone = props.strings.min + ': 8/' + values.phone.length
        } else if (values.phone.length > 10) {
            errors.phone = props.strings.max + ': 10/' + values.phone.length
        } else if (!/^[0-9]{8,10}$/i.test(values.phone)) {
            errors.phone = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9).'
        }
    
        if (!values.password) {
            errors.password = props.strings.required
        } else if (values.password.length < 8) {
            errors.password = props.strings.min + ': 8/' + values.password.length
        } else if (values.password.length > 15) {
            errors.password = props.strings.max + ': 15/' + values.password.length
        } else if (!/^[A-Z,a-z,0-9]{8,15}$/i.test(values.password)) {
            errors.password = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.strings + ' (A-Z, a-z).'
        }
        return errors
    }

    const onSubmit = (values, { setSubmitting }) => {
        const code = values.code ? values.code : '+993'
        const phone = code + ' ' + values.phone
        const password = values.password
        dispatch(login(phone, password))
        setSubmitting(false);
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <Login
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            advertisingsSitebar={props.advertisingsSitebar}

            back={back}

            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
        />
    )
}

export default LoginContainer