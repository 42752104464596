import React from 'react'

const OrderStores = (props) => {
    return (
        <>
                <option value={'stores.id'}>{props.strings.news}</option>
                <option value={'rating  DESC, ratingCount'}>{props.strings.topRatings}</option>
        </>
    )
}

export default OrderStores