import React from 'react'

const CatBeautyHair  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12103}>{props.strings.cat12103}</option>
            <option value={12111}>{props.strings.cat12111}</option>
            <option value={12125}>{props.strings.cat12125}</option>
            <option value={12133}>{props.strings.cat12133}</option>
            <option value={12134}>{props.strings.cat12134}</option>
            <option value={12135}>{props.strings.cat12135}</option>
            <option value={12137}>{props.strings.cat12137}</option>
            <option value={12138}>{props.strings.cat12138}</option>
            <option value={12143}>{props.strings.cat12143}</option>
            <option value={12159}>{props.strings.cat12159}</option>
            <option value={12163}>{props.strings.cat12163}</option>
            <option value={12164}>{props.strings.cat12164}</option>
            <option value={12171}>{props.strings.cat12171}</option>
            <option value={12172}>{props.strings.cat12172}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyHair