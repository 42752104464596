import React from 'react'
import dark from '../../../CSS/Dark.module.css'
import light from '../../../CSS/Light.module.css'
import BlockPanel from '../../common/BlockPanel/BlockPanel'
import SetAdsMailForm from '../../common/Forms/SetAdsMailForm'

const SetMail = (props) => {
    return (
        <>
            <BlockPanel
                theme={props.theme}
                iconLeft={<i className="fas fa-chevron-left"></i>}
                leftLink={"/admin/advertisings/" + props.advertising.id + "/settings"}
                icon1={<i className="fas fa-envelope"></i>}
                title={props.advertising.mail}
            />
            <div className={props.theme === 'light' ? light.formContainer : dark.formContainer}>
                <SetAdsMailForm
                    theme={props.theme}
                    strings={props.strings}
                    onSubmit={props.onSubmit}
                />
            </div>
        </>
    )
}

export default SetMail