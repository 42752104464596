import React from 'react'
import { NavLink } from 'react-router-dom'
import dark from './SPDark.module.css'
import light from './SPLight.module.css'

const SetPanel = (props) => {
    return (
        <NavLink to={props.to} className={props.theme === 'light' ? light.setPanel : dark.setPanel}>

            <div className={props.theme === 'light' ? light.icon : dark.icon}>
                {props.iconLeft}
            </div>

            <div className={props.theme === 'light' ? light.text : dark.text}>
                {props.text}
            </div>

            <div className={props.theme === 'light' ? light.icon : dark.icon}>
                {props.iconRight}
            </div>

        </NavLink>
    )
}

export default SetPanel