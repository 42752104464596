import React from 'react'

const CatFoodsBerries  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6601}>{props.strings.cat6601}</option>
            <option value={6602}>{props.strings.cat6602}</option>
            <option value={6603}>{props.strings.cat6603}</option>
            <option value={6604}>{props.strings.cat6604}</option>
            <option value={6605}>{props.strings.cat6605}</option>
            <option value={6606}>{props.strings.cat6606}</option>
            <option value={6607}>{props.strings.cat6607}</option>
            <option value={6608}>{props.strings.cat6608}</option>
            <option value={6609}>{props.strings.cat6609}</option>
            <option value={6610}>{props.strings.cat6610}</option>
            <option value={6611}>{props.strings.cat6611}</option>
            <option value={6612}>{props.strings.cat6612}</option>
            <option value={6613}>{props.strings.cat6613}</option>
            <option value={6614}>{props.strings.cat6614}</option>
            <option value={6615}>{props.strings.cat6615}</option>
            <option value={6616}>{props.strings.cat6616}</option>
            <option value={6617}>{props.strings.cat6617}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsBerries 