import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import ProductContent from './ProductContent'
import Sitebar from '../Sitebars/Sitebar'

const Product = (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                <ProductContent
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}

                    isAuth={props.isAuth}
                    profile={props.profile}

                    product={props.product}
                    images={props.images}
                    activeIndex={props.activeIndex}
                    setActiveIndex={props.setActiveIndex}
                    isPhotosCrope={props.isPhotosCrope}
                    crope={props.crope}
                    store={props.store}
                    productStatus={props.productStatus}
                    back={props.back}
                    productIsFetching={props.productIsFetching}

                    profileFavorite={props.profileFavorite}
                    favoriteCount={props.favoriteCount}

                    comments={props.comments}
                    count={props.count}
                    totalCount={props.totalCount}
                    currentPage={props.currentPage}
                    pagesCount={props.pagesCount}
                    onPageChanged={props.onPageChanged}
                    commentsStatus={props.commentsStatus}
                    commentsIsFetching={props.commentsIsFetching}
                    
                    leftClick={props.leftClick}
                    onSubmitFavorite={props.onSubmitFavorite}
                    initialValues={props.initialValues}
                    validate={props.validate}
                    onSubmit={props.onSubmit}
                />
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}
                    sitebarActivate={props.sitebarActivate}

                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}

                    advertisingsSitebar={props.advertisingsSitebar}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default Product