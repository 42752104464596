import React from 'react'

const CatHomeOfficeChemistry  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={8351}>{props.strings.cat8351}</option>
            <option value={8352}>{props.strings.cat8352}</option>
            <option value={8353}>{props.strings.cat8353}</option>
            <option value={8354}>{props.strings.cat8354}</option>
            <option value={8355}>{props.strings.cat8355}</option>
            <option value={8356}>{props.strings.cat8356}</option>
            <option value={8357}>{props.strings.cat8357}</option>
            <option value={8358}>{props.strings.cat8358}</option>
            <option value={8359}>{props.strings.cat8359}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatHomeOfficeChemistry 