import React from 'react'

const CatBeautyChildren  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12101}>{props.strings.cat12101}</option>
            <option value={12103}>{props.strings.cat12103}</option>
            <option value={12107}>{props.strings.cat12107}</option>
            <option value={12108}>{props.strings.cat12108}</option>
            <option value={12112}>{props.strings.cat12112}</option>
            <option value={12121}>{props.strings.cat12121}</option>
            <option value={12122}>{props.strings.cat12122}</option>
            <option value={12125}>{props.strings.cat12125}</option>
            <option value={12132}>{props.strings.cat12132}</option>
            <option value={12135}>{props.strings.cat12135}</option>
            <option value={12142}>{props.strings.cat12142}</option>
            <option value={12143}>{props.strings.cat12143}</option>
            <option value={12144}>{props.strings.cat12144}</option>
            <option value={12151}>{props.strings.cat12151}</option>
            <option value={12152}>{props.strings.cat12152}</option>
            <option value={12154}>{props.strings.cat12154}</option>
            <option value={12163}>{props.strings.cat12163}</option>
            <option value={12171}>{props.strings.cat12171}</option>
            <option value={12172}>{props.strings.cat12172}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyChildren