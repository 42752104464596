import React from 'react'

const CatWomanBagsWallets  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={11303}>{props.strings.cat11303}</option>
            <option value={11305}>{props.strings.cat11305}</option>
            <option value={11307}>{props.strings.cat11307}</option>
            <option value={11310}>{props.strings.cat11310}</option>
            <option value={11311}>{props.strings.cat11311}</option>
            <option value={11313}>{props.strings.cat11313}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatWomanBagsWallets 