import React from 'react'

const Jeep = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1001200}>JEEP CHEROKEE</option>
            <option value={1001201}>JEEP COMANCHE</option>
            <option value={1001202}>JEEP COMMANDER</option>
            <option value={1001203}>JEEP COMPASS</option>
            <option value={1001204}>JEEP GRAND CHEROKEE</option>
            <option value={1001205}>JEEP LIBERTY</option>
            <option value={1001206}>JEEP PATRIOT</option>
            <option value={1001207}>JEEP WANGLER</option>
        </>
    )
}

export default Jeep