import Resizer from "react-image-file-resizer"

export const domain = "https://tmonline.com.tm"

export const token = "a651-b742-c833-d924-e015"

export const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1000,
            1000,
            "JPEG",
            90,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
    });

export const resizeFileMini = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            150,
            150,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "file",
            150,
            150
        );
    });