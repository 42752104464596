import React from 'react'

const Hyundai = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1000500}>HYUNDAI ACCENT</option>
            <option value={1000501}>HYUNDAI AZERA</option>
            <option value={1000502}>HYUNDAI COUNTY</option>
            <option value={1000503}>HYUNDAI CUPE</option>
            <option value={1000504}>HYUNDAI ELANTRA</option>
            <option value={1000505}>HYUNDAI ENTOURAGE</option>
            <option value={1000506}>HYUNDAI EQUUS</option>
            <option value={1000507}>HYUNDAI EXEL</option>
            <option value={1000508}>HYUNDAI GALLOPER</option>
            <option value={1000509}>HYUNDAI GENESIS</option>
            <option value={1000510}>HYUNDAI GENESIS COUPE</option>
            <option value={1000511}>HYUNDAI GETZ</option>
            <option value={1000512}>HYUNDAI GRANDEUR</option>
            <option value={1000513}>HYUNDAI H1</option>
            <option value={1000514}>HYUNDAI H100</option>
            <option value={1000515}>HYUNDAI HED5</option>
            <option value={1000516}>HYUNDAI I30</option>
            <option value={1000517}>HYUNDAI IX35</option>
            <option value={1000518}>HYUNDAI IX55</option>
            <option value={1000519}>HYUNDAI KONA</option>
            <option value={1000520}>HYUNDAI SANTA FE</option>
            <option value={1000521}>HYUNDAI SCOUPE</option>
            <option value={1000522}>HYUNDAI SONATA</option>
            <option value={1000523}>HYUNDAI TIBURON</option>
            <option value={1000524}>HYUNDAI TRAJET</option>
            <option value={1000525}>HYUNDAI TUCSON</option>
            <option value={1000526}>HYUNDAI VELOSTER</option>
            <option value={1000527}>HYUNDAI VERACRUZ</option>
            <option value={1000528}>HYUNDAI XG300</option>
            <option value={1000529}>HYUNDAI XG350</option>
        </>
    )
}

export default Hyundai