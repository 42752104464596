import React from 'react'

const CatBussinessProduction  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={5101}>{props.strings.cat5101}</option>
            <option value={5110}>{props.strings.cat5110}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBussinessProduction 