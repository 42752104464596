import React from 'react'
import dark from './Dark.module.css'
import light from './Light.module.css'

const SuccessMin = (props) => {
    return (
        <div className={props.theme === 'light' ? light.success + " " + light.min : dark.success + " " + dark.min}>
            <h3>
                {props.icon
                    ? props.icon
                    : null
                } {props.success
                    ? props.success
                    : null
                }
            </h3>
        </div>
    )
}

export default SuccessMin