import React  from 'react'
import { useSelector } from 'react-redux'
import TermsOfUse from './TermsOfUse'

const TermsOfUseContainer = (props) => {

    window.scrollTo({top: 0, left: 0})

    const back = useSelector(state => state.home.back)

    return (
        <TermsOfUse
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            back={back}
            
            advertisingsSitebar={props.advertisingsSitebar}
        />
    )
}

export default TermsOfUseContainer