import React from 'react'

const CatSportUniforms  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={15101}>{props.strings.cat15101}</option>
            <option value={15102}>{props.strings.cat15102}</option>
            <option value={15103}>{props.strings.cat15103}</option>
            <option value={15104}>{props.strings.cat15104}</option>
            <option value={15105}>{props.strings.cat15105}</option>
            <option value={15106}>{props.strings.cat15106}</option>
            <option value={15107}>{props.strings.cat15107}</option>
            <option value={15108}>{props.strings.cat15108}</option>
            <option value={15109}>{props.strings.cat15109}</option>
            <option value={15110}>{props.strings.cat15110}</option>
            <option value={15111}>{props.strings.cat15111}</option>
            <option value={15112}>{props.strings.cat15112}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatSportUniforms