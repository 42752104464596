import React from 'react'

const CatBeautyBody  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12100}>{props.strings.cat12100}</option>
            <option value={12103}>{props.strings.cat12103}</option>
            <option value={12108}>{props.strings.cat12108}</option>
            <option value={12112}>{props.strings.cat12112}</option>
            <option value={12117}>{props.strings.cat12117}</option>
            <option value={12132}>{props.strings.cat12132}</option>
            <option value={12135}>{props.strings.cat12135}</option>
            <option value={12137}>{props.strings.cat12137}</option>
            <option value={12138}>{props.strings.cat12138}</option>
            <option value={12142}>{props.strings.cat12142}</option>
            <option value={12143}>{props.strings.cat12143}</option>
            <option value={12144}>{props.strings.cat12144}</option>
            <option value={12147}>{props.strings.cat12147}</option>
            <option value={12153}>{props.strings.cat12153}</option>
            <option value={12160}>{props.strings.cat12160}</option>
            <option value={12163}>{props.strings.cat12163}</option>
            <option value={12172}>{props.strings.cat12172}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyBody