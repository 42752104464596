import React from 'react'

const CatElectronicsCar  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10051}>{props.strings.cat10051}</option>
            <option value={10052}>{props.strings.cat10052}</option>
            <option value={100002}>{props.strings.cat100002}</option>
            <option value={100003}>{props.strings.cat100003}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsCar 