import { AuthAPI } from '../api/api'

const SET_IS_AUTH = 'auth/SET_IS_AUTH'
const SET_IS_ADMIN = 'auth/SET_IS_ADMIN'
const SET_ACCESS = 'auth/SET_ACCESS'
const SET_SUCCESS = 'auth/SET_SUCCESS'
const SET_ERROR = 'auth/SET_ERROR'
const IS_FETCHING = 'auth/IS_FETCHING'

let initialState = {
    isAuth: false,
    isAdmin: false,
    access: false,
    success: null,
    error: null,
    back: false,
    isFetching: true
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_AUTH:
            return { ...state, isAuth: action.isAuth }
        case SET_IS_ADMIN:
            return { ...state, isAdmin: action.isAdmin }
        case SET_ACCESS:
            return { ...state, access: action.access }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setIsAuth = (isAuth) => ({ type: SET_IS_AUTH, isAuth })
export const setIsAdmin = (isAdmin) => ({ type: SET_IS_ADMIN, isAdmin })
export const setAccess = (access) => ({ type: SET_ACCESS, access })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestAuth = () => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AuthAPI.auth()
        dispatch(setIsAuth(data.data.isAuth))
        dispatch(setIsAdmin(data.data.isAdmin))
        dispatch(setIsFetching(false))
    }
}

export const signup = (name, login, location, marker, phone, password) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AuthAPI.signup(name, login, location, marker, phone, password)
        dispatch(setAccess(data.data.access))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const login = (phone, password) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AuthAPI.login(phone, password)
        dispatch(setAccess(data.data.access))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const logout = () => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        await AuthAPI.logout()
        dispatch(setIsAuth(false))
        dispatch(setIsAdmin(false))
        dispatch(setAccess(false))
        dispatch(setSuccess(null))
        dispatch(setError(null))
        dispatch(setIsFetching(false))
    }
}

export const adminLogin = (login, salt) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AuthAPI.adminLogin(login, salt)
        dispatch(setAccess(data.data.access))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const adminLogout = () => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        await AuthAPI.adminLogout()
        dispatch(setIsAdmin(false))
        dispatch(setAccess(false))
        dispatch(setSuccess(null))
        dispatch(setError(null))
        dispatch(setIsFetching(false))
    }
}

export default authReducer