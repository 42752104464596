import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dark from './FinderDark.module.css'
import light from './FinderLight.module.css'
import Locations from '../Locations/Locations'
import OrderStores from '../Orders/OrderStores'
import {
    setOrder,
    setLocation,
    setMarker,
    setStores,
    setCurrentPage,
    setIsFetching
} from '../../../redux/stores-reducer'
import Ahal from '../Locations/Ahal'
import Balkan from '../Locations/Balkan'
import Dashoguz from '../Locations/Dashoguz'
import Lebap from '../Locations/Lebap'
import Mary from '../Locations/Mary'
import Ashgabat from '../Locations/Ashgabat'

const FinderStores = (props) => {
    const dispatch = useDispatch()
    const location = useSelector(state => state.stores.location)
    
    const onCahngeOrder = (e) => {
        let order = e.target.value
        dispatch(setOrder(order))
        dispatch(setCurrentPage(1))
        dispatch(setStores([]))
        dispatch(setIsFetching(true))
    }
    const onCahngeLocation = (e) => {
        let location = e.target.value
        dispatch(setLocation(location))
        dispatch(setMarker(null))
        dispatch(setStores([]))
        dispatch(setIsFetching(true))
    }
    const onCahngeMarker = (e) => {
        let marker = e.target.value
        dispatch(setMarker(marker))
        dispatch(setCurrentPage(1))
        dispatch(setStores([]))
        dispatch(setIsFetching(true))
    }
    return (
        <div className={props.theme === 'light' ? light.filter : dark.filter}>
            <h3><i className="fas fa-search"></i> {props.strings.filter}</h3>

            <label>{props.strings.orderBy}.</label><br/>
            <select onChange={onCahngeOrder}>
                <OrderStores strings={props.strings}/>
            </select>

            <label>{props.strings.location}.</label><br/>
            <select onChange={onCahngeLocation}>
                <Locations strings={props.strings} />
            </select>

            {location === '1'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Ashgabat strings={props.strings} />
                    </select>
                </>
                : location === '2'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Ahal strings={props.strings} />
                    </select>
                </>
                : location === '12'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Balkan strings={props.strings} />
                    </select>
                </>
                : location === '24'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Dashoguz strings={props.strings} />
                    </select>
                </>
                : location === '35'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Lebap strings={props.strings} />
                    </select>
                </>
                : location === '52'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Mary strings={props.strings} />
                    </select>
                </>
                : null
            }
        </div>
    )
}

export default FinderStores