import React from 'react'

const Transmission = (props) => {
    return (
        <>
            <option value={0}>{props.strings.transmission}...</option>
            <option value={1}>{props.strings.transmission1}</option>
            <option value={2}>{props.strings.transmission2}</option>
            <option value={3}>{props.strings.transmission3}</option>
        </>
    )
}

export default Transmission