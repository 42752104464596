import React from 'react'
import dark from './FormDark.module.css'
import light from './FormLight.module.css'
import { NavLink } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Locations from '../Locations/Locations'
import Ashgabat from '../Locations/Ashgabat'
import Ahal from '../Locations/Ahal'
import Balkan from '../Locations/Balkan'
import Dashoguz from '../Locations/Dashoguz'
import Lebap from '../Locations/Lebap'
import Mary from '../Locations/Mary'

const SignupForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.formPanel : dark.formPanel}>
            <Formik
                initialValues={props.initialValues}
                validate={props.validate}
                onSubmit={props.onSubmit}
            >
                {({ isSubmitting, values }) => (
                    <Form className={props.theme === 'light' ? light.form : dark.form} >

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="name"
                            component="div"
                        />
                        <Field
                            type="text"
                            name="name"
                            autoComplete="username"
                            placeholder={props.strings.name}
                            pattern="[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я\s]{3,50}"
                            title={props.strings.pattern + ': ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'}
                        />

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="login"
                            component="div"
                        />
                        <Field
                            type="text"
                            name="login"
                            autoComplete="login"
                            placeholder={props.strings.login}
                            pattern="[A-Za-z0-9_]{3,50}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (_), ' + props.strings.strings + ' (A-Z, a-z).'}
                        />

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="location"
                            component="div"
                        />
                        <Field
                            name="location"
                            as="select"
                        >
                            <Locations strings={props.strings} />
                        </Field>

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="marker"
                            component="div"
                        />
                        {values.location === '1'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Ashgabat strings={props.strings} />
                            </Field>
                            : values.location === '2'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Ahal strings={props.strings} />
                            </Field>
                            : values.location === '12'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Balkan strings={props.strings} />
                            </Field>
                            : values.location === '24'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Dashoguz strings={props.strings} />
                            </Field>
                            : values.location === '35'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Lebap strings={props.strings} />
                            </Field>
                            : values.location === '52'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Mary strings={props.strings} />
                            </Field>
                            : null
                        }

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="phone"
                            component="div"
                        />
                        <Field
                            className={props.theme === 'light' ? light.codeRegion : dark.codeRegion}
                            name="code"
                            as="select"
                        >
                            <option defaultChecked value="+993">+993 Türkmenistan</option>
                            <option value="+90">+90 Türkiýe</option>
                            <option value="+7">+7 Россия</option>
                        </Field>
                        <Field
                            className={props.theme === 'light' ? light.phone : dark.phone}
                            type="tel"
                            name="phone"
                            autoComplete="new-phone"
                            placeholder={props.strings.phonePlace}
                            pattern="[0-9]{8,10}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9).'}
                        />

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="password"
                            component="div"
                        />
                        <Field
                            type="password"
                            name="password"
                            autoComplete="new-password"
                            placeholder={props.strings.password}
                            pattern="[A-Za-z0-9]{8,20}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.strings + ' (A-Z, a-z).'}
                        />
                        
                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="password2"
                            component="div"
                        />
                        <Field
                            type="password"
                            name="password2"
                            autoComplete="new-password"
                            placeholder={props.strings.passwordRepeat}
                            pattern="[A-Za-z0-9]{8,20}"
                            title={props.strings.passwordRepeatErr}
                        />

                        <Field
                            className={props.theme === 'light' ? light.check : dark.check}
                            type="checkbox"
                            name="checkUsing"
                        />

                        <NavLink to="/sections-and-informations/terms-of-use">
                            {props.strings.agree}! <i className="fas fa-chevron-right"></i>
                        </NavLink>
                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="checkUsing"
                            component="div"
                        />

                        <button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {props.strings.signup}
                        </button>

                        
                        <NavLink className={props.theme === 'light' ? light.login : dark.login} to="/login">
                            {props.strings.authorization} <i className="fas fa-chevron-right"></i>
                        </NavLink>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default SignupForm