import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import Paginator from '../common/Paginator/Paginator'
import ProductFull from '../Items/FullItem/ProductFull'
import CommentsItem from '../Items/CommentsItem/CommentsItem'
import Menu from '../Items/Menu/Menu'
import Slider from '../Items/Slider/Slider'
import SuccessMax from '../common/Success/SuccessMax'
import SuccessMin from '../common/Success/SuccessMin'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'

const ProfileStoreProductContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                {props.productIsFetching
                    ? <PreloaderMini />
                    : !props.product
                        ? <>
                            <Menu
                                theme={props.theme}
                                back={props.back}
                            />
                            {props.productStatus
                                ? <SuccessMax
                                    theme={props.theme}
                                    icon={<i className="fas fa-bag-shopping"></i>}
                                    success={props.strings['status' + props.productStatus] + "!"}
                                />
                                : null
                            }
                        </>
                        : <>
                            <Menu 
                                theme={props.theme}
                                back={props.back}
                            />
                            <BlockPanel
                                theme={props.theme}
                                iconLeft={<i className="fas fa-chevron-left"></i>}
                                leftLink={"/mystore"}
                                icon1={<i className="fas fa-bag-shopping"></i>}
                                title={props.strings.myProduct + ": " + props.product.title}
                                iconRight={<i className="fas fa-ellipsis-v"></i>}
                                rightLink={"/mystore/products/" + props.product.id + "/settings"}
                            />

                            <Slider
                                videoSource={props.domain + "/media/video/"}
                                videoPoster={props.domain + "/media/video/poster.png"}
                                imageSource={props.domain + "/media/img/"}
                                imageAlt={"product"}
                                images={props.images}
                                activeIndex={props.activeIndex}
                                setActiveIndex={props.setActiveIndex}
                                crope={props.crope}
                                isPhotosCrope={props.isPhotosCrope}
                            />
                            
                            <ProductFull
                                theme={props.theme}
                                strings={props.strings}
                                id={props.product.id}
                                title={props.product.title}
                                location={props.product.location}
                                marker={props.product.marker}
                                type={props.product.type}
                                chapter={props.product.chapter}
                                categorie={props.product.categorie}
                                engine={props.product.engine}
                                transmission={props.product.transmission}
                                drive={props.product.drive}
                                body={props.product.body}
                                color={props.product.color}
                                mileage={props.product.mileage}
                                year={props.product.year}
                                text={props.product.text}
                                price={props.product.price}
                                phone={props.product.phone}
                                pub={props.product.pub}
                                views={props.product.views}
                                status={props.product.status}
                                favoriteCount={props.favoriteCount}
            
                            />

                            <BlockPanel
                                theme={props.theme}
                                icon1={<i className="fas fa-comments"></i>}
                                title={props.strings.comments}
                            />

                            {props.commentsIsFetching
                                ? <PreloaderMini />
                                : <>

                                    {props.comments
                                        ? <Paginator
                                            theme={props.theme}
                                            count={props.count}
                                            totalCount={props.totalCount}
                                            currentPage={props.currentPage}
                                            pagesCount={props.pagesCount}
                                            onPageChanged={props.onPageChanged}
                                        />
                                        : null
                                    }

                                    {props.comments
                                        ? props.comments.map(
                                            c => <CommentsItem
                                                    domain={props.domain}
                                                    theme={props.theme}
                                                    strings={props.strings}
                                                    to={'/profile/comments/'}
                                                    key={c.id}
                                                    id={c.id}
                                                    profileId={c.profileId}
                                                    avatar={c.avatar0}
                                                    login={c.login}
                                                    text={c.text}
                                                    pub={c.pub}
                                                    status={c.status}
                                                    
                                                    profile={props.profile}
                                                />
                                            )
                                        : null
                                    }

                                    {props.comments
                                        ? <Paginator
                                            theme={props.theme}
                                            count={props.count}
                                            totalCount={props.totalCount}
                                            currentPage={props.currentPage}
                                            pagesCount={props.pagesCount}
                                            onPageChanged={props.onPageChanged}
                                        />
                                        : null
                                    }

                                    {!props.comments
                                        ? props.commentsStatus
                                            ? <SuccessMin
                                                theme={props.theme}
                                                icon={<i className="fas fa-comments"></i>}
                                                success={props.strings['status' + props.commentsStatus] + "!"}
                                            />
                                            : null
                                        : null
                                    }
                                </>
                            }
                        
                        </>
                }
            </div>
        </div>
    )
}

export default ProfileStoreProductContent