import React from 'react'

const CatBussinessSale  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={5010}>{props.strings.cat5010}</option>
            <option value={5020}>{props.strings.cat5020}</option>
            <option value={5030}>{props.strings.cat5030}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBussinessSale 