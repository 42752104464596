import React from 'react'
import dark from './FormDark.module.css'
import light from './FormLight.module.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const SetPasswordForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.formPanel : dark.formPanel}>
            <Formik
                initialValues={{
                    password: '',
                    password2: ''
                }}
                validate={props.validate}
                onSubmit={props.onSubmit}
                >
                {({ isSubmitting }) => (
                    <Form className={props.theme === 'light' ? light.form : dark.form} >
                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="password"
                            component="div"
                        />
                        <Field
                            type="password"
                            name="password"
                            autoComplete="new-password"
                            placeholder={props.strings.password}
                            pattern="[A-Za-z0-9]{8,20}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.strings + ' (A-Z, a-z).'}
                        />
                        
                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="password2"
                            component="div"
                        />
                        <Field
                            type="password"
                            name="password2"
                            autoComplete="new-password"
                            placeholder={props.strings.passwordRepeat}
                            pattern="[A-Za-z0-9]{8,20}"
                            title={props.strings.passwordRepeatErr}
                        />
                        <button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            <i className="fas fa-check"></i>
                        </button>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default SetPasswordForm