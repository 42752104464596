import React from 'react'

const CatFoodsSweets  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6051}>{props.strings.cat6051}</option>
            <option value={6052}>{props.strings.cat6052}</option>
            <option value={6053}>{props.strings.cat6053}</option>
            <option value={6054}>{props.strings.cat6054}</option>
            <option value={6055}>{props.strings.cat6055}</option>
            <option value={6056}>{props.strings.cat6056}</option>
            <option value={6057}>{props.strings.cat6057}</option>
            <option value={6058}>{props.strings.cat6058}</option>
            <option value={6059}>{props.strings.cat6059}</option>
            <option value={6060}>{props.strings.cat6060}</option>
            <option value={6061}>{props.strings.cat6061}</option>
            <option value={6062}>{props.strings.cat6062}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsSweets 