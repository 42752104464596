import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FavoriteStores from './FavoriteStores'
import {
    requestStores,
    setIsFetching,
    setCurrentPage,
    setSuccess as setStoresSuccess,
    setError as setStoresError
} from '../../redux/favoriteStores-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'

const FavoriteStoresContainer = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const stores = useSelector(state => state.favoriteStores.stores)
    const count = useSelector(state => state.favoriteStores.count)
    const totalCount = useSelector(state => state.favoriteStores.totalCount)
    const currentPage = useSelector(state => state.favoriteStores.currentPage)
    const pagesCount = useSelector(state => state.favoriteStores.pagesCount)
    
    const storesStatus = useSelector(state => state.favoriteStores.status)
    const storesSuccess = useSelector(state => state.favoriteStores.success)
    const storesError = useSelector(state => state.favoriteStores.error)

    const back = useSelector(state => state.favoriteStores.back)
    const isFetching = useSelector(state => state.favoriteStores.isFetching)

    useEffect(() => {
        if (!props.isAuth) {
            navigate('/login')
        } else if (props.profile.status === 0) {
            navigate('/logout')
        }
    }, [props.isAuth, props.profile.status, navigate])

    useEffect(() => {
        if (currentPage) {
            dispatch(requestStores(currentPage, count))
            window.scrollTo({top: 0, left: 0})
        }
    }, [dispatch, count, currentPage])
  
    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setIsFetching(true))
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
    
    useEffect(
        () => {
            if(storesError){
                dispatch(setNotifyError(storesError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setStoresError(null))
            } else if(storesSuccess){
                dispatch(setNotifySuccess(storesSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setStoresSuccess(null))
            }
        }, [dispatch, storesError, storesSuccess]
    )

    return (
        <FavoriteStores
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            advertisingsSitebar={props.advertisingsSitebar}

            stores={stores}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            storesStatus={storesStatus}
            back={back}
            isFetching={isFetching}

            onPageChanged={onPageChanged}
        />
    )
}

export default FavoriteStoresContainer