import React from 'react'

const Dashoguz = (props) => {
    return (
        <>
            <option value={null}>{props.strings.marker}...</option>
            <option value={25}>{props.strings.loc25}</option>
            <option value={26}>{props.strings.loc26}</option>
            <option value={27}>{props.strings.loc27}</option>
            <option value={28}>{props.strings.loc28}</option>
            <option value={29}>{props.strings.loc29}</option>
            <option value={30}>{props.strings.loc30}</option>
            <option value={31}>{props.strings.loc31}</option>
            <option value={32}>{props.strings.loc32}</option>
            <option value={33}>{props.strings.loc33}</option>
            <option value={34}>{props.strings.loc34}</option>
        </>
    )
}

export default Dashoguz