import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    requestAdvertising,
    setImages,
    setActiveIndex,
    setIsPhotosCrope,
    setSuccess,
    setError
} from '../../redux/advertising-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import Advertising from './Advertising'

const AdvertisingContainer = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()

    const advertising = useSelector(state => state.advertising.advertising)
    const images = useSelector(state => state.advertising.images)
    const activeIndex = useSelector(state => state.advertising.activeIndex)
    const isPhotosCrope = useSelector(state => state.advertising.isPhotosCrope)
    const status = useSelector(state => state.advertising.status)
    const success = useSelector(state => state.advertising.success)
    const error = useSelector(state => state.advertising.error)
    const back = useSelector(state => state.advertising.back)
    const isFetching = useSelector(state => state.advertising.isFetching)

    useEffect(() => {
        if (params.advertisingId) {
            dispatch(setImages([]))
            dispatch(setActiveIndex(0))
            dispatch(requestAdvertising(params.advertisingId))
            window.scrollTo({top: 0, left: 0})
        } else {
            navigate('/')
        }
    }, [params.advertisingId, dispatch, navigate])

    const crope = () => {
        if(isPhotosCrope) {
            dispatch(setIsPhotosCrope(false))
        } else {
            dispatch(setIsPhotosCrope(true))
        }
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <Advertising
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}
            
            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            
            advertising={advertising}
            images={images}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            isPhotosCrope={isPhotosCrope}
            crope={crope}
            status={status}
            back={back}
            isFetching={isFetching}
        />
    )
}

export default AdvertisingContainer