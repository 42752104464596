import React from 'react'

const CatElectronicsTvsMonitors  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10002}>{props.strings.cat10002}</option>
            <option value={10003}>{props.strings.cat10003}</option>
            <option value={10004}>{props.strings.cat10004}</option>
            <option value={10005}>{props.strings.cat10005}</option>
            <option value={100002}>{props.strings.cat100002}</option>
            <option value={100003}>{props.strings.cat100003}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsTvsMonitors 