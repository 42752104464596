import React from 'react'

const Color = (props) => {
    return (
        <>
            <option value={0}>{props.strings.color}...</option>
            <option value={1}>{props.strings.color1}</option>
            <option value={2}>{props.strings.color2}</option>
            <option value={3}>{props.strings.color3}</option>
            <option value={4}>{props.strings.color4}</option>
            <option value={5}>{props.strings.color5}</option>
            <option value={6}>{props.strings.color6}</option>
            <option value={7}>{props.strings.color7}</option>
            <option value={8}>{props.strings.color8}</option>
            <option value={9}>{props.strings.color9}</option>
            <option value={10}>{props.strings.color10}</option>
            <option value={11}>{props.strings.color11}</option>
            <option value={12}>{props.strings.color12}</option>
            <option value={13}>{props.strings.color13}</option>
            <option value={14}>{props.strings.color14}</option>
            <option value={15}>{props.strings.color15}</option>
            <option value={16}>{props.strings.color16}</option>
            <option value={17}>{props.strings.color17}</option>
            <option value={18}>{props.strings.color18}</option>
            <option value={19}>{props.strings.color19}</option>
            <option value={20}>{props.strings.color20}</option>
        </>
    )
}

export default Color