import React from 'react'

const CatFoodsFruits  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6501}>{props.strings.cat6501}</option>
            <option value={6502}>{props.strings.cat6502}</option>
            <option value={6503}>{props.strings.cat6503}</option>
            <option value={6504}>{props.strings.cat6504}</option>
            <option value={6505}>{props.strings.cat6505}</option>
            <option value={6506}>{props.strings.cat6506}</option>
            <option value={6507}>{props.strings.cat6507}</option>
            <option value={6508}>{props.strings.cat6508}</option>
            <option value={6509}>{props.strings.cat6509}</option>
            <option value={6510}>{props.strings.cat6510}</option>
            <option value={6511}>{props.strings.cat6511}</option>
            <option value={6512}>{props.strings.cat6512}</option>
            <option value={6513}>{props.strings.cat6513}</option>
            <option value={6514}>{props.strings.cat6514}</option>
            <option value={6515}>{props.strings.cat6515}</option>
            <option value={6516}>{props.strings.cat6516}</option>
            <option value={6517}>{props.strings.cat6517}</option>
            <option value={6518}>{props.strings.cat6518}</option>
            <option value={6519}>{props.strings.cat6519}</option>
            <option value={6520}>{props.strings.cat6520}</option>
            <option value={6521}>{props.strings.cat6521}</option>
            <option value={6522}>{props.strings.cat6522}</option>
            <option value={6523}>{props.strings.cat6523}</option>
            <option value={6524}>{props.strings.cat6524}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsFruits 