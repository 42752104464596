import React from 'react'

const CatChancelleryDocumentation  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={9101}>{props.strings.cat9101}</option>
            <option value={9102}>{props.strings.cat9102}</option>
            <option value={9103}>{props.strings.cat9103}</option>
            <option value={9104}>{props.strings.cat9104}</option>
            <option value={9105}>{props.strings.cat9105}</option>
            <option value={9106}>{props.strings.cat9106}</option>
            <option value={9107}>{props.strings.cat9107}</option>
            <option value={9108}>{props.strings.cat9108}</option>
            <option value={9109}>{props.strings.cat9109}</option>
            <option value={9110}>{props.strings.cat9110}</option>
            <option value={9111}>{props.strings.cat9111}</option>
            <option value={9112}>{props.strings.cat9112}</option>
            <option value={9113}>{props.strings.cat9113}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatChancelleryDocumentation 