import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FavoriteProducts from './FavoriteProducts'
import {
    requestProducts,
    setIsFetching,
    setCurrentPage,
    setSuccess as setProductsSuccess,
    setError as setProductssetError
} from '../../redux/favoriteProducts-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'

const FavoriteProductsContainer = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const products = useSelector(state => state.favoriteProducts.products)
    const count = useSelector(state => state.favoriteProducts.count)
    const totalCount = useSelector(state => state.favoriteProducts.totalCount)
    const currentPage = useSelector(state => state.favoriteProducts.currentPage)
    const pagesCount = useSelector(state => state.favoriteProducts.pagesCount)

    const productsStatus = useSelector(state => state.favoriteProducts.status)
    const productsSuccess = useSelector(state => state.favoriteProducts.success)
    const productsError = useSelector(state => state.favoriteProducts.error)

    const back = useSelector(state => state.favoriteProducts.back)
    const isFetching = useSelector(state => state.favoriteProducts.isFetching)

    useEffect(() => {
        if (!props.isAuth) {
            navigate('/login')
        } else if (props.profile.status === 0) {
            navigate('/logout')
        }
    }, [props.isAuth, props.profile.status, navigate])

    useEffect(() => {
        if (currentPage) {
            dispatch(requestProducts(currentPage, count))
            window.scrollTo({top: 0, left: 0})
        }
    }, [dispatch, count, currentPage])

    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setIsFetching(true))
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    useEffect(
        () => {
            if(productsError){
                dispatch(setNotifyError(productsError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setProductssetError(null))
            } else if(productsSuccess){
                dispatch(setNotifySuccess(productsSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setProductsSuccess(null))
            }
        }, [dispatch, productsError, productsSuccess]
    )

    return (
        <FavoriteProducts
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            
            advertisingsSitebar={props.advertisingsSitebar}

            products={products}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            productsStatus={productsStatus}
            back={back}
            isFetching={isFetching}

            onPageChanged={onPageChanged}
        />
    )
}

export default FavoriteProductsContainer