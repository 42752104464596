import React from 'react'

const Agriculture = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={17001}>{props.strings.cat17001}</option>
            <option value={17100}>{props.strings.cat17100}</option>
        </>
    )
}

export default Agriculture