import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Sitebar from '../Sitebars/Sitebar'
import AdminPanelContent from './AdminPanelContent'

const AdminPanel= (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                <AdminPanelContent
                    theme={props.theme}
                    strings={props.strings}

                    admins={props.admins}
                    advertisings={props.advertisings}
                    profiles={props.profiles}
                    stores={props.stores}
                    ratings={props.ratings}
                    favorites={props.favorites}
                    products={props.products}
                    comments={props.comments}

                    back={props.back}
                />
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    theme={props.theme}
                    strings={props.strings}

                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default AdminPanel