import { AdminAPI } from '../api/api'

const SET_ADMINS = 'adminPanel/SET_ADMINS'
const SET_ADVERTISINGS = 'adminPanel/SET_ADVERTISINGS'
const SET_PROFILES = 'adminPanel/SET_PROFILES'
const SET_STORES = 'adminPanel/SET_STORES'
const SET_RATINGS = 'adminPanel/SET_RATINGS'
const SET_FAVORITES = 'adminPanel/SET_FAVORITES'
const SET_PRODUCTS = 'adminPanel/SET_PRODUCTS'
const SET_COMMENTS = 'adminPanel/SET_COMMENTS'
const SET_SUCCESS = 'adminPanel/SET_SUCCESS'
const SET_ERROR = 'adminPanel/SET_ERROR'
const IS_FETCHING = 'adminPanel/IS_FETCHING'

let initialState = {
    admins: [],
    advertisings: [],
    profiles: [],
    stores: [],
    ratings: [],
    favorites: [],
    products: [],
    comments: [],
    success: null,
    error: null,
    back: false,
    isFetching: true
}

const adminPanelReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADMINS:
            return { ...state, admins: action.admins }
        case SET_ADVERTISINGS:
            return { ...state, advertisings: action.advertisings }
        case SET_PROFILES:
            return { ...state, profiles: action.profiles }
        case SET_STORES:
            return { ...state, stores: action.stores }
        case SET_RATINGS:
            return { ...state, ratings: action.ratings }
        case SET_FAVORITES:
            return { ...state, favorites: action.favorites }
        case SET_PRODUCTS:
            return { ...state, products: action.products }
        case SET_COMMENTS:
            return { ...state, comments: action.comments }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setAdmins = (admins) => ({ type: SET_ADMINS, admins })
export const setAdvertisings = (advertisings) => ({ type: SET_ADVERTISINGS, advertisings })
export const setProfiles = (profiles) => ({ type: SET_PROFILES, profiles })
export const setStores = (stores) => ({ type: SET_STORES, stores })
export const setRatings = (ratings) => ({ type: SET_RATINGS, ratings })
export const setFavoriotes = (favorites) => ({ type: SET_FAVORITES, favorites })
export const setProducts = (products) => ({ type: SET_PRODUCTS, products })
export const setComments = (comments) => ({ type: SET_COMMENTS, comments })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestPanelInformations = () => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAPI.panel()
        dispatch(setAdmins(data.data.admins))
        dispatch(setAdvertisings(data.data.advertisings))
        dispatch(setProfiles(data.data.profiles))
        dispatch(setStores(data.data.stores))
        dispatch(setRatings(data.data.ratings))
        dispatch(setFavoriotes(data.data.favorites))
        dispatch(setProducts(data.data.products))
        dispatch(setComments(data.data.comments))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default adminPanelReducer