import React from 'react'

const CatAgricultureHarvest  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={17003}>{props.strings.cat17003}</option>
            <option value={17005}>{props.strings.cat17005}</option>
            <option value={17007}>{props.strings.cat17007}</option>
            <option value={17010}>{props.strings.cat17010}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatAgricultureHarvest