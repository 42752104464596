import React from 'react'

const CatRecreationEntertainmentGoods  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={14101}>{props.strings.cat14101}</option>
            <option value={14103}>{props.strings.cat14103}</option>
            <option value={14105}>{props.strings.cat14105}</option>
            <option value={14107}>{props.strings.cat14107}</option>
            <option value={14110}>{props.strings.cat14110}</option>
            <option value={14113}>{props.strings.cat14113}</option>
            <option value={14115}>{props.strings.cat14115}</option>
            <option value={14117}>{props.strings.cat14117}</option>
            <option value={14120}>{props.strings.cat14120}</option>
            <option value={14123}>{props.strings.cat14123}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatRecreationEntertainmentGoods