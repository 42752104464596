import React from 'react'

const CatChancelleryWritingDrawing  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={9051}>{props.strings.cat9051}</option>
            <option value={9052}>{props.strings.cat9052}</option>
            <option value={9053}>{props.strings.cat9053}</option>
            <option value={9054}>{props.strings.cat9054}</option>
            <option value={9055}>{props.strings.cat9055}</option>
            <option value={9056}>{props.strings.cat9056}</option>
            <option value={9057}>{props.strings.cat9057}</option>
            <option value={9058}>{props.strings.cat9058}</option>
            <option value={9059}>{props.strings.cat9059}</option>
            <option value={9060}>{props.strings.cat9060}</option>
            <option value={9061}>{props.strings.cat9061}</option>
            <option value={9062}>{props.strings.cat9062}</option>
            <option value={9063}>{props.strings.cat9063}</option>
            <option value={9064}>{props.strings.cat9064}</option>
            <option value={9065}>{props.strings.cat9065}</option>
            <option value={9066}>{props.strings.cat9066}</option>
            <option value={9067}>{props.strings.cat9067}</option>
            <option value={9068}>{props.strings.cat9068}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatChancelleryWritingDrawing 