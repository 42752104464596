import React from 'react'
import dark from '../../../CSS/Dark.module.css'
import light from '../../../CSS/Light.module.css'
import BlockPanel from '../../common/BlockPanel/BlockPanel'
import SetPanel from '../../common/SetPanel/SetPanel'
import Success from '../../common/Success/Success'

const Set = (props) => {
    return (
        <>
            <BlockPanel
                theme={props.theme}
                iconLeft={<i className="fas fa-chevron-left"></i>}
                leftLink={"/profile"}
                icon1={<i className="fas fa-user-edit"></i>}
                title={props.profile.login}
            />
            
            <Success
                theme={props.theme}
                icon={<i className="far fa-user"></i>}
                success={props.strings.main}
            />

            <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >

                <SetPanel
                    theme={props.theme}
                    to={"/profile/settings/avatar"}
                    text={props.strings.avatar}
                    iconLeft={<i className="fas fa-image"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />

                <SetPanel
                    theme={props.theme}
                    to={"/profile/settings/login"}
                    text={props.strings.login}
                    iconLeft={<i className="fas fa-user-circle"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />

                <SetPanel
                    theme={props.theme}
                    to={"/profile/settings/location"}
                    text={props.strings.location}
                    iconLeft={<i className="fas fa-map-marker-alt"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />

            </div>

            <Success
                theme={props.theme}
                icon={<i className="far fa-address-card"></i>}
                success={props.strings.confidential}
            />

            <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >

                <SetPanel
                    theme={props.theme}
                    to={"/profile/settings/name"}
                    text={props.strings.name}
                    iconLeft={<i className="fas fa-user"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />

                <SetPanel
                    theme={props.theme}
                    to={"/profile/settings/birthday"}
                    text={props.strings.birth}
                    iconLeft={<i className="fas fa-birthday-cake"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />

                <SetPanel
                    theme={props.theme}
                    to={"/profile/settings/about"}
                    text={props.strings.aboutMe}
                    iconLeft={<i className="fas fa-file-alt"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />

            </div>

            <Success
                theme={props.theme}
                icon={<i className="far fa-check-circle"></i>}
                success={props.strings.security}
            />

            <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                
                <SetPanel
                    theme={props.theme}
                    to={"/profile/settings/password"}
                    text={props.strings.passwordUpd}
                    iconLeft={<i className="fas fa-key"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />

                <SetPanel
                    theme={props.theme}
                    to={"/profile/settings/trash"}
                    text={props.strings.trash}
                    iconLeft={<i className="fas fa-trash"></i>}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                />

            </div>
        </>
    )
}

export default Set