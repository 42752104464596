import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'

const AdvertisingFull = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockViewFull : dark.blockViewFull}>

            {props.status
                ? <div className={props.theme === 'light' ? light.status : dark.status}>
                    {props.strings['status' + props.status]}...
                </div>
                : null
            }
            
            <div className={props.theme === 'light' ? light.fullInfo : dark.fullInfo}>
                {props.title /* НАИМЕНОВАНИЕ */
                    ? <div className={props.theme === 'light' ? light.title : dark.title}>
                        <i className="fas fa-square-poll-horizontal"></i> {props.title}
                    </div>
                    : null
                }

                
                {props.subtitle/* НАИМЕНОВАНИЕ */
                    ? <div className={props.theme === 'light' ? light.subtitle : dark.subtitle}>
                        {props.subtitle}
                    </div>
                    : null
                }

                {props.text /* ТЕКСТ */
                    ? <div className={props.theme === 'light' ? light.text : dark.text}>
                        {props.text}
                    </div>
                    : null
                }

                {props.phone1 && props.phone2 /* ТЕЛЕФОН */
                    ? <>
                        <div className={props.theme === 'light' ? light.item : dark.item}>
                            <div className={props.theme === 'light' ? light.column : dark.column}>
                                <i className="fas fa-phone-square"></i> {props.strings.phone} 1:
                            </div>
                            <div className={props.theme === 'light' ? light.string : dark.string}>
                                <a href={"tel:" + props.phone1}>{props.phone1}</a>
                            </div>
                        </div>

                        <div className={props.theme === 'light' ? light.item : dark.item}>
                            <div className={props.theme === 'light' ? light.column : dark.column}>
                                <i className="fas fa-phone-square"></i> {props.strings.phone} 2:
                            </div>
                            <div className={props.theme === 'light' ? light.string : dark.string}>
                            <a href={"tel:" + props.phone2}>{props.phone2}</a>
                            </div>
                        </div>
                    </>
                    : props.phone1
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-phone-square"></i> {props.strings.phone}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            <a href={"tel:" + props.phone1}>{props.phone1}</a>
                        </div>
                    </div>
                    : null
                }

                {props.pub/* ДАТА */
                    ? <div className={props.theme === 'light' ? light.views : dark.views}>
                        {props.strings.pub}: <span>{props.pub} <i className="fas fa-calendar-check"></i></span>
                    </div>
                    : null
                }

            </div>
        </div>
    )
}

export default AdvertisingFull