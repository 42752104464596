import React from 'react'

const CatElectronicsSmartHouse  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10101}>{props.strings.cat10101}</option>
            <option value={10102}>{props.strings.cat10102}</option>
            <option value={10103}>{props.strings.cat10103}</option>
            <option value={10104}>{props.strings.cat10104}</option>
            <option value={10105}>{props.strings.cat10105}</option>
            <option value={10106}>{props.strings.cat10106}</option>
            <option value={10107}>{props.strings.cat10107}</option>
            <option value={10108}>{props.strings.cat10108}</option>
            <option value={10109}>{props.strings.cat10109}</option>
            <option value={100002}>{props.strings.cat100002}</option>
            <option value={100003}>{props.strings.cat100003}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsSmartHouse 