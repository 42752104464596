import { ProfileStoreProductAPI } from '../api/api'

const SET_UPDATED = 'profileStoreProductSet/SET_UPDATED'
const SET_TRASHED = 'profileStoreProductSet/SET_TRASHED'
const SET_STATUS = 'profileStoreProductSet/SET_STATUS'
const SET_SUCCESS = 'profileStoreProductSet/SET_SUCCESS'
const SET_ERROR = 'profileStoreProductSet/SET_ERROR'
const IS_FETCHING = 'profileStoreProductSet/IS_FETCHING'

let initialState = {
    updated: false,
    trashed: false,
    status: null,
    success: null,
    error: null,
    back: true,
    isFetching: false
}

const profileStoreProductSetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPDATED:
            return { ...state, updated: action.updated }
        case SET_TRASHED:
            return { ...state, trashed: action.trashed }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setUpdated = (updated) => ({ type: SET_UPDATED, updated })
export const setTrashed = (trashed) => ({ type: SET_TRASHED, trashed })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const changeVideo = (auth, id, video) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreProductAPI.changeVideo(auth, id, video)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}
export const videoDelete = (auth, id, removeVideo) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreProductAPI.videoDelete(auth, id, removeVideo)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeImage = (auth, id, imageId, image) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreProductAPI.changeImage(auth, id, imageId, image)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const imageDelete = (auth, id, removeId) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreProductAPI.imageDelete(auth, id, removeId)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeTitle = (auth, id, title) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreProductAPI.changeTitle(auth, id, title)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeText = (auth, id, text) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreProductAPI.changeText(auth, id, text)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changePrice = (auth, id, price) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreProductAPI.changePrice(auth, id, price)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const remove = (auth, id, trash) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileStoreProductAPI.remove(auth, id, trash)
        dispatch(setTrashed(data.data.trashed))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default profileStoreProductSetReducer