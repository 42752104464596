import React from 'react'

const CatServicesTransportation  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={2181}>{props.strings.cat2181}</option>
            <option value={2182}>{props.strings.cat2182}</option>
            <option value={2183}>{props.strings.cat2183}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatServicesTransportation 