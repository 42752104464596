import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import Slider from '../Items/Slider/Slider'
import ProfileFull from '../Items/FullItem/ProfileFull'
import StoreItem from '../Items/Item/StoreItem'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'
import ViewStatusBar from '../common/ViewStatusBar/ViewStatusBar'
import SuccessMin from '../common/Success/SuccessMin'
import SuccessMax from '../common/Success/SuccessMax'
import SetPanel from '../common/SetPanel/SetPanel'

const AdminProfileContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                {props.isFetching
                    ? <PreloaderMini />
                    : !props.ostProfile
                        ? <>
                            <Menu
                                theme={props.theme}
                                back={props.back}
                            />
                            {props.status
                                ? <SuccessMax
                                    theme={props.theme}
                                    icon={<i className="fas fa-user"></i>}
                                    success={props.strings['status' + props.status] + "!"}
                                />
                                : null
                            }
                        </>
                        : <>
                            <Menu
                                theme={props.theme}
                                back={props.back}
                                phone={props.ostProfile.phone}
                                sms={props.ostProfile.phone}
                            />

                            <BlockPanel
                                theme={props.theme}
                                iconLeft={<i className="fas fa-chevron-left"></i>}
                                leftLink={"/admin/profiles"}
                                icon1={<i className="fas fa-user-circle"></i>}
                                title={props.ostProfile.login}
                                iconRight={<i className="fas fa-ellipsis-v"></i>}
                                rightLink={"/admin/profiles/" + props.ostProfile.id + "/settings"}
                            />

                            <ViewStatusBar
                                theme={props.theme}
                                strings={props.strings}
            
                                onSubmitView={props.onSubmitView}
                                onSubmitTrash={props.onSubmitTrash}
                            />

                            <Slider
                                imageSource={props.domain + "/media/avatar/"}
                                imageAlt={"avatar"}
                                images={props.images}
                                activeIndex={props.activeIndex}
                                setActiveIndex={props.setActiveIndex}
                                crope={props.crope}
                                isPhotosCrope={props.isPhotosCrope}
                            />
                            
                            <ProfileFull
                                theme={props.theme}
                                strings={props.strings}
                                name={props.ostProfile.name}
                                location={props.ostProfile.location}
                                marker={props.ostProfile.marker}
                                phone={props.ostProfile.phone}
                                text={props.ostProfile.text}
                                birth={props.ostProfile.birth}
                                reg={props.ostProfile.reg}
                                status={props.ostProfile.status}
                            />

                            <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                                <SetPanel
                                    theme={props.theme}
                                    to={"/admin/profiles/comments/" + props.ostProfile.id}
                                    text={props.strings.comments}
                                    iconLeft={<i className="fas fa-comments"></i>}
                                    iconRight={<i className="fas fa-chevron-right"></i>}
                                />
                            </div>

                            <BlockPanel
                                theme={props.theme}
                                icon1={<i className="fas fa-store-alt"></i>}
                                title={props.strings.userStore}
                            />

                            {!props.store
                                ? props.status
                                    ? <SuccessMin
                                        theme={props.theme}
                                        icon={<i className="fas fa-store-alt"></i>}
                                        success={props.strings['status' + props.status] + "!"}
                                    />
                                    : null
                                : <StoreItem
                                    domain={props.domain}
                                    theme={props.theme}
                                    strings={props.strings}
                                    to={'/admin/stores/'}
                                    id={props.store.id}
                                    title={props.store.title}
                                    image0={props.store.image0}
                                    location={props.store.location}
                                    marker={props.store.marker}
                                    phone={props.store.phone}
                                    text={props.store.text}
                                    rating={props.store.rating}
                                    ratingCount={props.store.ratingCount}
                                    status={props.store.status}
                                />
                            }

                        </>
                }
            </div>
        </div>
    )
}

export default AdminProfileContent