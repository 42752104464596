import React from 'react'

const CatRealEstateSale  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={4002}>{props.strings.cat4002}</option>
            <option value={4010}>{props.strings.cat4010}</option>
            <option value={4020}>{props.strings.cat4020}</option>
            <option value={4030}>{props.strings.cat4030}</option>
        </>
    )
}

export default CatRealEstateSale 