import React from 'react'
import fullSize from './FullSize.module.css'
import normalSize from './NormalSize.module.css'

const ImageFrame = (props) => {
    
    return (
        <div
            className={props.isPhotosCrope
                ? fullSize.frameContainer
                : normalSize.frameContainer
            }
        >
            <img
                className={props.isPhotosCrope
                    ? fullSize.slideImage
                    : normalSize.slideImage
                }
                src={props.source}
                alt={props.alt}
            />
            
        </div>
    )
}

export default ImageFrame