import React from 'react'

const CatElectronicsStorageDrives  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10321}>{props.strings.cat10321}</option>
            <option value={10322}>{props.strings.cat10322}</option>
            <option value={10323}>{props.strings.cat10323}</option>
            <option value={10324}>{props.strings.cat10324}</option>
            <option value={10325}>{props.strings.cat10325}</option>
            <option value={10326}>{props.strings.cat10326}</option>
            <option value={10327}>{props.strings.cat10327}</option>
            <option value={10328}>{props.strings.cat10328}</option>
            <option value={10329}>{props.strings.cat10329}</option>
            <option value={10330}>{props.strings.cat10330}</option>
            <option value={10331}>{props.strings.cat10331}</option>
            <option value={100003}>{props.strings.cat100003}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsStorageDrives 