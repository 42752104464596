import React from 'react'

const Animals = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={18001}>{props.strings.cat18001}</option>
            <option value={18100}>{props.strings.cat18100}</option>
            <option value={18200}>{props.strings.cat18200}</option>
            <option value={18300}>{props.strings.cat18300}</option>
        </>
    )
}

export default Animals