import { AdminAdvertisingAPI } from '../api/api'

const SET_UPDATED = 'advertisingSet/SET_UPDATED'
const SET_TRASHED = 'advertisingSet/SET_TRASHED'
const SET_SUCCESS = 'advertisingSet/SET_SUCCESS'
const SET_ERROR = 'advertisingSet/SET_ERROR'
const IS_FETCHING = 'advertisingSet/IS_FETCHING'

let initialState = {
    updated: false,
    trashed: false,
    success: null,
    error: null,
    back: true,
    isFetching: false
}

const advertisingSetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPDATED:
            return { ...state, updated: action.updated }
        case SET_TRASHED:
            return { ...state, trashed: action.trashed }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setUpdated = (updated) => ({ type: SET_UPDATED, updated })
export const setTrashed = (trashed) => ({ type: SET_TRASHED, trashed })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const changeStatus = (id, status) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changeStatus(id, status)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeImage = (id, imageId, image) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changeImage(id, imageId, image)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const imageDelete = (id, removeId) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.imageDelete(id, removeId)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeTitle = (id, title) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changeTitle(id, title)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeSubtitle = (id, subtitle) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changeSubtitle(id, subtitle)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeText = (id, text) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changeText(id, text)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changePhone1 = (id, phone1) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changePhone1(id, phone1)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changePhone2 = (id, phone2) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changePhone2(id, phone2)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}
export const changeMail = (id, mail) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changeMail(id, mail)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeInstagram = (id, instagram) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changeInstagram(id, instagram)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeImo = (id, imo) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changeImo(id, imo)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeLink = (id, link) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changeLink(id, link)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeHref = (id, href) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.changeHref(id, href)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const remove = (id, trash) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.remove(id, trash)
        dispatch(setTrashed(data.data.trashed))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default advertisingSetReducer