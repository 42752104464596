import React from 'react'

const CatFoodsNuts  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6801}>{props.strings.cat6801}</option>
            <option value={6802}>{props.strings.cat6802}</option>
            <option value={6803}>{props.strings.cat6803}</option>
            <option value={6804}>{props.strings.cat6804}</option>
            <option value={6805}>{props.strings.cat6805}</option>
            <option value={6806}>{props.strings.cat6806}</option>
            <option value={6807}>{props.strings.cat6807}</option>
            <option value={6808}>{props.strings.cat6808}</option>
            <option value={6809}>{props.strings.cat6809}</option>
            <option value={6810}>{props.strings.cat6810}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsNuts 