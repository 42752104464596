import React from 'react'
import dark from './ContextDark.module.css'
import light from './ContextLight.module.css'

const Context = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockView : dark.blockView}>

            <div className={props.theme === 'light' ? light.info : dark.info}>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    {props.strings.usingTitle}
                </h2>
                <h3 className={props.theme === 'light' ? light.subtitle : dark.subtitle}>
                    {props.strings.usingSubtitle}
                </h3>
            </div>

            <div className={props.theme === 'light' ? light.info : dark.info}>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    {props.strings.usingUsers}
                </h2>
                <p>{props.strings.usingUsers1}</p>
                <p>{props.strings.usingUsers2}</p>
                <p>{props.strings.usingUsers3}</p>
                <p>{props.strings.usingUsers4}</p>
                <h3 className={props.theme === 'light' ? light.subtitle : dark.subtitle}>
                    {props.strings.usingUsersComments}
                </h3>
                <p className={props.theme === 'light' ? light.use : dark.use}>
                    {props.strings.usingUsersComments1}
                </p>
                <p className={props.theme === 'light' ? light.use : dark.use}>
                    {props.strings.usingUsersComments2}
                </p>
                <p>{props.strings.usingUsers5}</p>
                <p>{props.strings.usingUsers6}</p>
                <p>{props.strings.usingUsers7}</p>
            </div>

            <div className={props.theme === 'light' ? light.info : dark.info}>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    {props.strings.usingUsersStores}
                </h2>
                <p>{props.strings.usingUsersStores1}</p>
                <p>{props.strings.usingUsersStores2}</p>
            </div>

            <div className={props.theme === 'light' ? light.info : dark.info}>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    {props.strings.usingStores}
                </h2>
                <p>{props.strings.usingStores1}</p>
                <p>{props.strings.usingStores2}</p>
                <p>{props.strings.usingStores3}</p>
                <p>{props.strings.usingStores4}</p>
                <p>{props.strings.usingStores5}</p>
                <h3 className={props.theme === 'light' ? light.subtitle : dark.subtitle}>
                    {props.strings.usingStoresProducts}
                </h3>
                <p className={props.theme === 'light' ? light.use : dark.use}>
                    {props.strings.usingStoresProducts1}
                </p>
                <p className={props.theme === 'light' ? light.use : dark.use}>
                    {props.strings.usingStoresProducts2}
                </p>
                <p className={props.theme === 'light' ? light.use : dark.use}>
                    {props.strings.usingStoresProducts3}
                </p>
                <p className={props.theme === 'light' ? light.use : dark.use}>
                    {props.strings.usingStoresProducts4}
                </p>
                <p className={props.theme === 'light' ? light.use : dark.use}>
                    {props.strings.usingStoresProducts5}
                </p>
                <p>{props.strings.usingStores6}</p>
                <p>{props.strings.usingStores7}</p>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    &#169; tmOnline - {props.strings.copyright5}
                </h2>
            </div>

        </div>
    )
}

export default Context