import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import Paginator from '../common/Paginator/Paginator'
import ProductItem from '../Items/Item/ProductItem'
import StoreFull from '../Items/FullItem/StoreFull'
import Menu from '../Items/Menu/Menu'
import Slider from '../Items/Slider/Slider'
import SuccessMax from '../common/Success/SuccessMax'
import SuccessMin from '../common/Success/SuccessMin'
import RatingForm from '../Items/additions/RatingForm/RatingForm'
import Rating from '../Items/additions/RatingForm/Rating'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'

const StoreContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                {props.storeIsFetching
                    ? <PreloaderMini />
                    : !props.store
                        ? <>
                            <Menu
                                theme={props.theme}
                                back={props.back}
                            />
                            {props.storeStatus
                                ? <SuccessMax
                                    theme={props.theme}
                                    icon={<i className="fas fa-store-alt"></i>}
                                    success={props.strings['status' + props.storeStatus] + "!"}
                                />
                                : null
                            }
                        </>
                        : <>
                            <Menu
                                theme={props.theme}
                                back={props.back}
                                phone={props.store.phone}
                                sms={props.store.phone}
                            />

                            <BlockPanel
                                theme={props.theme}
                                iconLeft={<i className="fas fa-chevron-left"></i>}
                                leftClick={props.leftClick}
                                icon1={<i className="fas fa-store-alt"></i>}
                                title={props.store.title}
                            />

                            <Slider
                                imageSource={props.domain + "/media/store/"}
                                imageAlt={"store"}
                                images={props.images}
                                activeIndex={props.activeIndex}
                                setActiveIndex={props.setActiveIndex}
                                crope={props.crope}
                                isPhotosCrope={props.isPhotosCrope}
                            />
                            
                            {props.isAuth
                                ? !props.profileRating
                                    ? <RatingForm
                                        theme={props.theme}
                                        strings={props.strings}
                                        possibleRates={props.possibleRates}
                                        selectedRate={props.selectedRate}
                                        hoveredRate={props.hoveredRate}
                                        onSelectedRate={props.onSelectedRate}
                                        onHoveredRate={props.onHoveredRate}
                                    />
                                    : <Rating
                                        theme={props.theme}
                                        strings={props.strings}
                                        possibleRates={props.possibleRates}
                                        profileRating={props.profileRating}
                                        changeRate={props.changeRate}
                                    />
                                : null
                            }

                            <StoreFull
                                theme={props.theme}
                                strings={props.strings}
                                id={props.store.id}
                                title={props.store.title}
                                location={props.store.location}
                                marker={props.store.marker}
                                text={props.store.text}
                                phone={props.store.phone}
                                pub={props.store.pub}
                                rating={props.rating}
                                ratingCount={props.ratingCount}
                            />

                            <BlockPanel
                                theme={props.theme}
                                icon1={<i className="fas fa-bag-shopping"></i>}
                                title={props.strings.storeProducts}
                            />

                            {props.productIsFetching
                                ? <PreloaderMini />
                                : !props.products
                                    ? props.productsStatus
                                        ? <SuccessMin
                                            theme={props.theme}
                                            icon={<i className="fas fa-bag-shopping"></i>}
                                            success={props.strings['status' + props.productsStatus] + "!"}
                                        />
                                        : null
                                    : <>
                                        <Paginator
                                            theme={props.theme}
                                            count={props.count}
                                            totalCount={props.totalCount}
                                            currentPage={props.currentPage}
                                            pagesCount={props.pagesCount}
                                            onPageChanged={props.onPageChanged}
                                        />

                                        {props.products.map(
                                            p => <ProductItem
                                                    key={p.id}
                                                    domain={props.domain}
                                                    theme={props.theme}
                                                    strings={props.strings}
                                                    to={'/products/'}
                                                    id={p.id}
                                                    image0={p.image0}
                                                    title={p.title}
                                                    location={p.location}
                                                    marker={p.marker}
                                                    type={p.type}
                                                    chapter={p.chapter}
                                                    categorie={p.categorie}
                                                    text={p.text}
                                                    price={p.price}
                                                    views={p.views}
                                                    favoriteCount={p.favoriteCount}
                                                />
                                            )
                                        }

                                        <Paginator
                                            theme={props.theme}
                                            count={props.count}
                                            totalCount={props.totalCount}
                                            currentPage={props.currentPage}
                                            pagesCount={props.pagesCount}
                                            onPageChanged={props.onPageChanged}
                                        />
                                
                                    </>
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default StoreContent