import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'
import { domain } from '../../../../api/main';
import Counter from '../Counter/Counter';

const RatingFull = (props) => {
    const rating = parseFloat(props.rating)

    const ratingUp = (
        <img 
            className={props.theme === 'light' ? light.ratingStar : dark.ratingStar}
            src={domain + "/media/star/1.png"} alt='star'
        />
    )
    const ratingCentre = (
        <img 
            className={props.theme === 'light' ? light.ratingStar : dark.ratingStar}
            src={domain + "/media/star/05.png"} alt='star'
        />
    )
    const ratingDown = (
        <img 
            className={props.theme === 'light' ? light.ratingStar : dark.ratingStar}
            src={domain + "/media/star/0.png"} alt='star'
        />
    )
    return (
        <>
            {props.strings.rating}: <span>{rating ? rating : 0}</span> {rating === 5
                ? <>
                    {ratingUp}{ratingUp}{ratingUp}{ratingUp}{ratingUp}
                </>
                : rating > 4
                ? <>
                    {ratingUp}{ratingUp}{ratingUp}{ratingUp}{ratingCentre}
                </>: rating === 4
                ? <>
                    {ratingUp}{ratingUp}{ratingUp}{ratingUp}{ratingDown}
                </>
                : rating > 3
                ? <>
                    {ratingUp}{ratingUp}{ratingUp}{ratingCentre}{ratingDown}
                </>
                : rating === 3
                ? <>
                    {ratingUp}{ratingUp}{ratingUp}{ratingDown}{ratingDown}
                </>
                : rating > 2
                ? <>
                    {ratingUp}{ratingUp}{ratingCentre}{ratingDown}{ratingDown}
                </>
                : rating === 2
                ? <>
                    {ratingUp}{ratingUp}{ratingDown}{ratingDown}{ratingDown}
                </>
                : rating > 1
                ? <>
                    {ratingUp}{ratingCentre}{ratingDown}{ratingDown}{ratingDown}
                </>
                : rating === 1
                ? <>
                    {ratingUp}{ratingDown}{ratingDown}{ratingDown}{ratingDown}
                </>
                : <>
                    {ratingDown}{ratingDown}{ratingDown}{ratingDown}{ratingDown}
                </>
            } | <i className="fas fa-users"></i> <span><Counter count={props.ratingCount} /></span>
        </>
    )
}

export default RatingFull