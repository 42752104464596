import React from 'react'

const CatBeautyEyelashes  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12102}>{props.strings.cat12102}</option>
            <option value={12113}>{props.strings.cat12113}</option>
            <option value={12127}>{props.strings.cat12127}</option>
            <option value={12156}>{props.strings.cat12156}</option>
            <option value={12166}>{props.strings.cat12166}</option>
            <option value={12169}>{props.strings.cat12169}</option>
            <option value={12172}>{props.strings.cat12172}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyEyelashes