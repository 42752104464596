import React from 'react'

const CatElectronicsAntennas  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10061}>{props.strings.cat10061}</option>
            <option value={10062}>{props.strings.cat10062}</option>
            <option value={10063}>{props.strings.cat10063}</option>
            <option value={10064}>{props.strings.cat10064}</option>
            <option value={10065}>{props.strings.cat10065}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsAntennas 