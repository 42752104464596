import React from 'react'

const Infiniti = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1000900}>INFINITI EX</option>
            <option value={1000901}>INFINITI FX</option>
            <option value={1000902}>INFINITI FX35</option>
            <option value={1000903}>INFINITI G</option>
            <option value={1000904}>INFINITI G25</option>
            <option value={1000905}>INFINITI G35</option>
            <option value={1000906}>INFINITI G37</option>
            <option value={1000907}>INFINITI I</option>
            <option value={1000908}>INFINITI I30</option>
            <option value={1000909}>INFINITI I35</option>
            <option value={1000910}>INFINITI IPL G</option>
            <option value={1000911}>INFINITI J</option>
            <option value={1000912}>INFINITI JX</option>
            <option value={1000913}>INFINITI M</option>
            <option value={1000914}>INFINITI Q</option>
            <option value={1000915}>INFINITI QX</option>
            <option value={1000916}>INFINITI QX4</option>
            <option value={1000917}>INFINITI QX56</option>
        </>
    )
}

export default Infiniti