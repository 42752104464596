import React from 'react'

const CatHomeOfficeInterior  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={8002}>{props.strings.cat8002}</option>
            <option value={8003}>{props.strings.cat8003}</option>
            <option value={8004}>{props.strings.cat8004}</option>
            <option value={8005}>{props.strings.cat8005}</option>
            <option value={8006}>{props.strings.cat8006}</option>
            <option value={8007}>{props.strings.cat8007}</option>
            <option value={8008}>{props.strings.cat8008}</option>
            <option value={8009}>{props.strings.cat8009}</option>
            <option value={8010}>{props.strings.cat8010}</option>
            <option value={8011}>{props.strings.cat8011}</option>
            <option value={8012}>{props.strings.cat8012}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatHomeOfficeInterior 