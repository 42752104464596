import React from 'react'

const CatElectronicsLighting  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10161}>{props.strings.cat10161}</option>
            <option value={10162}>{props.strings.cat10162}</option>
            <option value={10163}>{props.strings.cat10163}</option>
            <option value={10164}>{props.strings.cat10164}</option>
            <option value={10165}>{props.strings.cat10165}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsLighting 