import React from 'react'
import dark from './VSDark.module.css'
import light from './VSLight.module.css'

const ViewStatusBar = (props) => {
    return (
        <div className={props.theme === 'light' ? light.viewStatusBar : dark.viewStatusBar}>
            <button
                className={
                    props.theme === 'light'
                        ? light.statusButton + ' ' + light.view
                        : dark.statusButton + ' ' + dark.view
                }
                onClick={() => {props.onSubmitView(1)}}
            >
                <i className="fas fa-eye"></i>
            </button>
            <button
                className={
                    props.theme === 'light'
                        ? light.statusButton + ' ' + light.block
                        : dark.statusButton + ' ' + dark.block
                }
                onClick={() => {props.onSubmitView(4)}}
            >
                <i className="fas fa-eye-slash"></i>
            </button>
            <button
                className={
                    props.theme === 'light'
                        ? light.statusButton + ' ' + light.delete
                        : dark.statusButton + ' ' + dark.delete
                }
                onClick={() => {props.onSubmitTrash()}}
            >
                <i className="fas fa-trash"></i>
            </button>
        </div>
    )
}

export default ViewStatusBar