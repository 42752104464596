import React from 'react'

const Children = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={11003}>{props.strings.cat11003}</option>
            <option value={11500}>{props.strings.cat11500}</option>
        </>
    )
}

export default Children