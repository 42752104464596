import React from 'react'

const CatCarParts = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={101}>{props.strings.cat101}</option>
            <option value={110}>{props.strings.cat110}</option>
            <option value={120}>{props.strings.cat120}</option>
            <option value={130}>{props.strings.cat130}</option>
            <option value={140}>{props.strings.cat140}</option>
            <option value={150}>{props.strings.cat150}</option>
            <option value={160}>{props.strings.cat160}</option>
            <option value={170}>{props.strings.cat170}</option>
            <option value={180}>{props.strings.cat180}</option>
            <option value={190}>{props.strings.cat190}</option>
            <option value={200}>{props.strings.cat200}</option>
            <option value={210}>{props.strings.cat210}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatCarParts