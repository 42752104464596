import React from 'react'

const Mazda = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1000800}>MAZDA 121</option>
            <option value={1000801}>MAZDA 2</option>
            <option value={1000802}>MAZDA 3</option>
            <option value={1000803}>MAZDA 323</option>
            <option value={1000804}>MAZDA 4</option>
            <option value={1000805}>MAZDA 5</option>
            <option value={1000806}>MAZDA 6</option>
            <option value={1000807}>MAZDA 6 5-DOOR</option>
            <option value={1000808}>MAZDA 6 SPORT</option>
            <option value={1000809}>MAZDA 626</option>
            <option value={1000810}>MAZDA 8</option>
            <option value={1000811}>MAZDA 929</option>
            <option value={1000812}>MAZDA ATENZA</option>
            <option value={1000813}>MAZDA AUTOZAM AZ-1</option>
            <option value={1000814}>MAZDA AXELA</option>
            <option value={1000815}>MAZDA AZ-OFFROAD</option>
            <option value={1000816}>MAZDA B-SERIES</option>
            <option value={1000817}>MAZDA B-SERIES PLUS</option>
            <option value={1000818}>MAZDA B360</option>
            <option value={1000819}>MAZDA B2000</option>
            <option value={1000820}>MAZDA B2500</option>
            <option value={1000821}>MAZDA B2600</option>
            <option value={1000822}>MAZDA BT-50</option>
            <option value={1000823}>MAZDA BIANTE</option>
            <option value={1000824}>MAZDA BONGO</option>
            <option value={1000825}>MAZDA CX-3</option>
            <option value={1000826}>MAZDA CX-30</option>
            <option value={1000827}>MAZDA CX-4</option>
            <option value={1000828}>MAZDA CX-5</option>
            <option value={1000829}>MAZDA CX-7</option>
            <option value={1000830}>MAZDA CX-9</option>
            <option value={1000831}>MAZDA CAPELLA</option>
            <option value={1000832}>MAZDA CAROL</option>
            <option value={1000833}>MAZDA COSMO</option>
            <option value={1000834}>MAZDA CRONOS</option>
            <option value={1000835}>MAZDA DEMIO</option>
            <option value={1000836}>MAZDA EUNOS</option>
            <option value={1000837}>MAZDA EUNOS COSMO</option>
            <option value={1000838}>MAZDA FAMILIA</option>
            <option value={1000839}>MAZDA FLAIR</option>
            <option value={1000840}>MAZDA FLAIR CROSSOVER</option>
            <option value={1000841}>MAZDA FLAIR WAGON</option>
            <option value={1000842}>MAZDA FURAI</option>
            <option value={1000843}>MAZDA GLC</option>
            <option value={1000844}>MAZDA HAZUMI</option>
            <option value={1000845}>MAZDA KABURA</option>
            <option value={1000846}>MAZDA KAI</option>
            <option value={1000847}>MAZDA KOERU</option>
            <option value={1000848}>MAZDA LANTIS</option>
            <option value={1000849}>MAZDA LAPUTA</option>
            <option value={1000850}>MAZDA LUCE</option>
            <option value={1000851}>MAZDA MILLENIA</option>
            <option value={1000852}>MAZDA MPV</option>
            <option value={1000853}>MAZDA MX-3</option>
            <option value={1000854}>MAZDA MX-30</option>
            <option value={1000855}>MAZDA MX-5</option>
            <option value={1000856}>MAZDA MIATA MX-5</option>
            <option value={1000857}>MAZDA MX-6</option>
            <option value={1000858}>MAZDA MX-SPORT</option>
            <option value={1000859}>MAZDA NAVAJO</option>
            <option value={1000860}>MAZDA PERSONA</option>
            <option value={1000861}>MAZDA PREMACY</option>
            <option value={1000862}>MAZDA PROTEGE</option>
            <option value={1000863}>MAZDA PROTEGE 5</option>
            <option value={1000864}>MAZDA R360</option>
            <option value={1000865}>MAZDA RX-5</option>
            <option value={1000866}>MAZDA RX-7</option>
            <option value={1000867}>MAZDA RX-8</option>
            <option value={1000868}>MAZDA RX-VISION</option>
            <option value={1000869}>MAZDA ROADPACER AP</option>
            <option value={1000870}>MAZDA ROTARY PICKUP</option>
            <option value={1000871}>MAZDA SCRUM</option>
            <option value={1000872}>MAZDA SENTIA</option>
            <option value={1000873}>MAZDA SPIANO</option>
            <option value={1000874}>MAZDA SPEED3</option>
            <option value={1000875}>MAZDA SPEED5</option>
            <option value={1000876}>MAZDA TAIKI</option>
            <option value={1000877}>MAZDA TAKERI</option>
            <option value={1000878}>MAZDA TRIBUTE</option>
            <option value={1000879}>MAZDA VERSIA</option>
            <option value={1000880}>MAZDA VISION COUPE</option>
            <option value={1000881}>MAZDA XEDOS6</option>
            <option value={1000882}>MAZDA XEDOS9</option>
        </>
    )
}

export default Mazda