import React from 'react'

const CatBeautyMan  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12100}>{props.strings.cat12100}</option>
            <option value={12103}>{props.strings.cat12103}</option>
            <option value={12105}>{props.strings.cat12105}</option>
            <option value={12111}>{props.strings.cat12111}</option>
            <option value={12112}>{props.strings.cat12112}</option>
            <option value={12117}>{props.strings.cat12117}</option>
            <option value={12132}>{props.strings.cat12132}</option>
            <option value={12135}>{props.strings.cat12135}</option>
            <option value={12144}>{props.strings.cat12144}</option>
            <option value={12145}>{props.strings.cat12145}</option>
            <option value={12148}>{props.strings.cat12148}</option>
            <option value={12151}>{props.strings.cat12151}</option>
            <option value={12159}>{props.strings.cat12159}</option>
            <option value={12163}>{props.strings.cat12163}</option>
            <option value={12164}>{props.strings.cat12164}</option>
            <option value={12167}>{props.strings.cat12167}</option>
            <option value={12171}>{props.strings.cat12171}</option>
            <option value={12172}>{props.strings.cat12172}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyMan