import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    requestAdvertising,
    setImages,
    setActiveIndex,
    setIsPhotosCrope,
    setSuccess,
    setError,
    setAdvertising
} from '../../redux/adminAdvertising-reducer'
import {
    setSuccess as setAdvertisingSetSuccess,
    setError as setAdvertisingSetError,
    setUpdated,
    changeStatus,
    remove
} from '../../redux/advertisingSet-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setIsFetching } from '../../redux/adminAdvertisings-reducer'
import AdminAdvertising from './AdminAdvertising'

const AdminAdvertisingContainer = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()

    const advertising = useSelector(state => state.adminAdvertising.advertising)
    const images = useSelector(state => state.adminAdvertising.images)
    const activeIndex = useSelector(state => state.adminAdvertising.activeIndex)
    const isPhotosCrope = useSelector(state => state.adminAdvertising.isPhotosCrope)
    const status = useSelector(state => state.adminAdvertising.status)
    const success = useSelector(state => state.adminAdvertising.success)
    const error = useSelector(state => state.adminAdvertising.error)
    const back = useSelector(state => state.adminAdvertising.back)
    const advertisingIsFetching = useSelector(state => state.adminAdvertising.isFetching)

    const updated = useSelector(state => state.advertisingSet.updated)
    const advertisingSetSuccess = useSelector(state => state.advertisingSet.success)
    const advertisingSetError = useSelector(state => state.advertisingSet.error)
    const isFetching = useSelector(state => state.advertisingSet.isFetching)

    const admin = 1

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        } else if (!params.advertisingId) {
            navigate('/admin/advertisings')
        }
    }, [navigate, props.isAuth, props.isAdmin, params.advertisingId])

    useEffect(() => {
        if (params.advertisingId || updated) {
            dispatch(setAdvertising([]))
            dispatch(setImages([]))
            dispatch(setActiveIndex(0))
            dispatch(requestAdvertising(admin, params.advertisingId))
            if (updated) {
                dispatch(setIsFetching(true))
                dispatch(setUpdated(false))
            }
            window.scrollTo({top: 0, left: 0})
        }
    }, [dispatch, params.advertisingId, updated])

    const onSubmitView = async (statusId) => {
        dispatch(changeStatus(params.advertisingId, statusId))
    }

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(params.advertisingId, trash))
        dispatch(setIsFetching(true))
        setTimeout(() => {
            navigate('/admin/advertisings')
        }, 1000)
    }

    const crope = () => {
        if(isPhotosCrope) {
            dispatch(setIsPhotosCrope(false))
        } else {
            dispatch(setIsPhotosCrope(true))
        }
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(advertisingSetError){
                dispatch(setNotifyError(advertisingSetError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setAdvertisingSetError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            } else if(advertisingSetSuccess){
                dispatch(setNotifySuccess(advertisingSetSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setAdvertisingSetSuccess(null))
            }
        }, [dispatch, error, advertisingSetError, success, advertisingSetSuccess]
    )

    return (
        <AdminAdvertising
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}
            
            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            
            advertising={advertising}
            images={images}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            isPhotosCrope={isPhotosCrope}
            crope={crope}
            status={status}
            back={back}
            advertisingIsFetching={advertisingIsFetching}

            isFetching={isFetching}

            onSubmitView={onSubmitView}
            onSubmitTrash={onSubmitTrash}
        />
    )
}

export default AdminAdvertisingContainer