import React from 'react'

const CatServicesBeauty  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={2141}>{props.strings.cat2141}</option>
            <option value={2142}>{props.strings.cat2142}</option>
            <option value={2143}>{props.strings.cat2143}</option>
            <option value={2144}>{props.strings.cat2144}</option>
            <option value={2145}>{props.strings.cat2145}</option>
            <option value={2146}>{props.strings.cat2146}</option>
            <option value={2147}>{props.strings.cat2147}</option>
            <option value={2148}>{props.strings.cat2148}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatServicesBeauty 