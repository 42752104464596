import React from 'react'

const CatRealEstateRent  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={4101}>{props.strings.cat4101}</option>
            <option value={4110}>{props.strings.cat4110}</option>
            <option value={4120}>{props.strings.cat4120}</option>
            <option value={4130}>{props.strings.cat4130}</option>
            <option value={4140}>{props.strings.cat4140}</option>
            <option value={4150}>{props.strings.cat4150}</option>
        </>
    )
}

export default CatRealEstateRent 