import React from 'react'

const CatElectronicsBeauty  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10981}>{props.strings.cat10981}</option>
            <option value={10982}>{props.strings.cat10982}</option>
            <option value={10983}>{props.strings.cat10983}</option>
            <option value={10984}>{props.strings.cat10984}</option>
            <option value={10985}>{props.strings.cat10985}</option>
            <option value={10986}>{props.strings.cat10986}</option>
            <option value={10987}>{props.strings.cat10987}</option>
            <option value={10988}>{props.strings.cat10988}</option>
            <option value={10989}>{props.strings.cat10989}</option>
            <option value={10990}>{props.strings.cat10990}</option>
            <option value={10991}>{props.strings.cat10991}</option>
            <option value={10992}>{props.strings.cat10992}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsBeauty 