import React from 'react'

const CatHomeOfficeInventory  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={8101}>{props.strings.cat8101}</option>
            <option value={8102}>{props.strings.cat8102}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatHomeOfficeInventory 