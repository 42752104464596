import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    setAdvertising,
    setImages,
    requestAdvertising
} from '../../redux/adminAdvertising-reducer'
import { resizeFileMini } from '../../api/main'
import {
    changeImage,
    imageDelete,
    changeTitle,
    changeSubtitle,
    changeText,
    changeMail,
    changeInstagram,
    changeImo,
    changePhone1,
    changePhone2,
    changeLink,
    changeHref,
    setUpdated,
    setSuccess,
    setError,
    remove
} from '../../redux/advertisingSet-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setIsFetching } from '../../redux/adminAdvertisings-reducer'
import AdminAdvertisingSet from './AdminAdvertisingSet'

const AdminAdvertisingSetContainer = (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()

    const advertising = useSelector(state => state.adminAdvertising.advertising)
    
    const updated = useSelector(state => state.advertisingSet.updated)
    const success = useSelector(state => state.advertisingSet.success)
    const error = useSelector(state => state.advertisingSet.error)
    const back = useSelector(state => state.advertisingSet.back)
    const isFetching = useSelector(state => state.advertisingSet.isFetching)

    const id = advertising.id
    const admin = 1

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        } else if (!params.advertisingId) {
            navigate('/admin/advertisings')
        } else if (!advertising) {
            navigate('/admin/advertisings')
        }
    }, [navigate, props.isAuth, props.isAdmin, params.advertisingId, advertising])

    useEffect(() => {
        if (params.advertisingId || updated) {
            dispatch(setAdvertising([]))
            dispatch(setImages([]))
            dispatch(requestAdvertising(admin, params.advertisingId))
            if (updated) {
                dispatch(setIsFetching(true))
                dispatch(setUpdated(false))
            }
        }
    }, [dispatch, params.advertisingId, updated])

    const onSubmitImage = async (e) => {
        const file = e.target.files[0]
        const image0 = await resizeFileMini(file)
        const image = file
        const imageMiniId = 1
        const imageId = e.target.attributes.imageId.value
        if (advertising.image0 === null || imageId === 1) {
            dispatch(changeImage(id, imageMiniId, image0))
            dispatch(changeImage(id, imageId, image))
        } else {
            dispatch(changeImage(id, imageId, image))
        }
    }

    const onSubmitRemoveImage = async (e) => {
            const removeId = e.target.attributes.imageId.value
            dispatch(imageDelete(id, removeId))
    }

    const validateTitle = (values) => {
        const errors = {}
        if (!values.title) {
            errors.title = props.strings.required
        } else if (values.title.length < 3) {
            errors.title = props.strings.min + ': 3/' + values.title.length
        } else if (values.title.length > 50) {
            errors.title = props.strings.max + ': 50/' + values.title.length
        } else if (!/^[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я0-9\s._-]{3,50}$/i.test(values.title)) {
            errors.title = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'
        }
        return errors
    }

    const onSubmitTitle = (values, { setSubmitting }) => {
        const title = values.title
        dispatch(changeTitle(id, title))
        setSubmitting(false)
    }

    const validateSubtitle = (values) => {
        const errors = {}
        if (!values.subtitle) {
            errors.subtitle = props.strings.required
        } else if (values.subtitle.length < 3) {
            errors.subtitle = props.strings.min + ': 3/' + values.subtitle.length
        } else if (values.subtitle.length > 100) {
            errors.subtitle = props.strings.max + ': 100/' + values.subtitle.length
        } else if (!/^[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я0-9\s._-]{3,100}$/i.test(values.subtitle)) {
            errors.subtitle = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'
        }
        return errors
    }
    
    const onSubmitSubtitle = (values, { setSubmitting }) => {
        const subtitle = values.subtitle
        dispatch(changeSubtitle(id, subtitle))
        setSubmitting(false)
    }

    const validateText = (values) => {
        const errors = {}
        if (!values.text) {
            errors.text = props.strings.required
        } else if (values.text.length < 10) {
            errors.text = props.strings.min + ': 10/' + values.text.length
        } else if (values.text.length > 1500) {
            errors.text = props.strings.max + ': 1500/' + values.text.length
        }
        return errors
    }
    
    const onSubmitText = (values, { setSubmitting }) => {
        const text = values.text
        dispatch(changeText(id, text))
        setSubmitting(false)
    }

    const onSubmitMail = (values, { setSubmitting }) => {
        const mail = values.mail
        dispatch(changeMail(id, mail))
        setSubmitting(false)
    }

    const onSubmitInstagram = (values, { setSubmitting }) => {
        const instagram = values.instagram
        dispatch(changeInstagram(id, instagram))
        setSubmitting(false)
    }

    const onSubmitImo = (values, { setSubmitting }) => {
        const imo = values.imo
        dispatch(changeImo(id, imo))
        setSubmitting(false)
    }

    const validatePhone1 = (values) => {
        const errors = {}
        if (!values.phone1) {
            errors.phone1 = props.strings.required
        } else if (values.phone1.length < 8) {
            errors.phone1 = props.strings.min + ': 8/' + values.phone1.length
        } else if (values.phone1.length > 10) {
            errors.phone1 = props.strings.max + ': 10/' + values.phone1.length
        } else if (!/^[0-9]{8,10}$/i.test(values.phone1)) {
            errors.phone1 = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9).'
        }
        return errors
    }
    
    const onSubmitPhone1 = (values, { setSubmitting }) => {
        const code1 = values.phone1 ? values.code1 ? values.code1 : '+993' : null
        const phone1 = code1 + ' ' + values.phone1
        dispatch(changePhone1(id, phone1))
        setSubmitting(false)
    }

    const validatePhone2 = (values) => {
        const errors = {}
        if (!values.phone2) {
            errors.phone2 = props.strings.required
        } else if (values.phone2.length < 8) {
            errors.phone2 = props.strings.min + ': 8/' + values.phone2.length
        } else if (values.phone2.length > 10) {
            errors.phone2 = props.strings.max + ': 10/' + values.phone2.length
        } else if (!/^[0-9]{8,10}$/i.test(values.phone2)) {
            errors.phone2 = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9).'
        }
        return errors
    }
    
    const onSubmitPhone2 = (values, { setSubmitting }) => {
        const code2 = values.phone2 ? values.code2 ? values.code2 : '+993' : null
        const phone2 = code2 + ' ' + values.phone2
        dispatch(changePhone2(id, phone2))
        setSubmitting(false)
    }

    const onSubmitLink = (values, { setSubmitting }) => {
        const link = values.link
        dispatch(changeLink(id, link))
        setSubmitting(false)
    }

    const onSubmitHref = (values, { setSubmitting }) => {
        const href = values.href
        dispatch(changeHref(id, href))
        setSubmitting(false)
    }

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(id, trash))
        dispatch(setIsFetching(true))
        setTimeout(() => {
            navigate('/admin/advertisings')
        }, 1000)
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <AdminAdvertisingSet
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            advertising={advertising}

            back={back}
            isFetching={isFetching}

            onSubmitImage={onSubmitImage}
            onSubmitRemoveImage={onSubmitRemoveImage}

            validateTitle={validateTitle}
            validateSubtitle={validateSubtitle}
            validateText={validateText}
            validatePhone1={validatePhone1}
            validatePhone2={validatePhone2}

            onSubmitTitle={onSubmitTitle}
            onSubmitSubtitle={onSubmitSubtitle}
            onSubmitText={onSubmitText}
            onSubmitMail={onSubmitMail}
            onSubmitInstagram={onSubmitInstagram}
            onSubmitImo={onSubmitImo}
            onSubmitPhone1={onSubmitPhone1}
            onSubmitPhone2={onSubmitPhone2}
            onSubmitLink={onSubmitLink}
            onSubmitHref={onSubmitHref}
            onSubmitTrash={onSubmitTrash}
            navigate={navigate}
        />
    )
}

export default AdminAdvertisingSetContainer