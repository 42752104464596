import React from 'react'
import dark from './FooterDark.module.css'
import light from './FooterLight.module.css'
import { NavLink } from 'react-router-dom'

const Footer = (props) => {
    return (
        <div className={props.theme === 'light' ? light.footer : dark.footer}>
            <div className={props.theme === 'light' ? light.bottom : dark.bottom}>
                <div className={props.theme === 'light' ? light.items : dark.items}>

                    <h3>{props.strings.informations}:</h3><br/>

                    <div className={props.theme === 'light' ? light.item : dark.item} >
                        <NavLink
                            to="/sections-and-informations/help"
                            className={props.theme === 'light'
                                ? ({isActive}) => isActive 
                                    ? light.link + " " + light.active 
                                    : light.link
                                : ({isActive}) => isActive 
                                    ? dark.link + " " + dark.active 
                                    : dark.link
                            }
                        >
                            <i className="fas fa-question-circle"></i> {props.strings.help}
                        </NavLink>
                    </div>

                    <div className={props.theme === 'light' ? light.item : dark.item} >
                        <NavLink
                            to="/sections-and-informations/terms-of-use"
                            className={props.theme === 'light'
                                ? ({isActive}) => isActive 
                                    ? light.link + " " + light.active 
                                    : light.link
                                : ({isActive}) => isActive 
                                    ? dark.link + " " + dark.active 
                                    : dark.link
                            }
                        >
                            <i className="fas fa-handshake"></i> {props.strings.using}
                        </NavLink>
                    </div>

                    <div className={props.theme === 'light' ? light.item : dark.item} >
                        <NavLink
                            to="/sections-and-informations/about-us"
                            className={props.theme === 'light'
                                ? ({isActive}) => isActive 
                                    ? light.link + " " + light.active 
                                    : light.link
                                : ({isActive}) => isActive 
                                    ? dark.link + " " + dark.active 
                                    : dark.link
                            }
                        >
                            <i className="fas fa-chalkboard-teacher"></i> {props.strings.copyright}
                        </NavLink>
                    </div>

                </div>

                <div className={props.theme === 'light' ? light.items : dark.items}>

                    <h3>{props.strings.contacts}:</h3><br/>

                    <div className={props.theme === 'light' ? light.item : dark.item} >
                        <a
                            className={props.theme === 'light' ? light.linkA : dark.linkA}
                            href="mailto:com.tmonline@gmail.com"
                        >
                            <i className="fas fa-envelope"></i> com.tmonline@gmail.com
                        </a>
                    </div>

                    <div className={props.theme === 'light' ? light.item : dark.item} >
                        <a
                            className={props.theme === 'light' ? light.linkA : dark.linkA}
                            href="tel:+99364298343"
                        >
                            <i className="fas fa-phone-square"></i> {props.strings.phone}: +993 64298343
                        </a>
                    </div>

                    <div className={props.theme === 'light' ? light.item : dark.item} >
                        <a
                            className={props.theme === 'light' ? light.linkA : dark.linkA}
                            href="https://bgroup.imo.im/_3hVQIkJs21ZmDyS9L"
                        >
                            <i className="fas fa-comment"></i> imo: +993 64298343
                        </a>
                    </div>

                </div>
                <div className={props.theme === 'light' ? light.copyright : dark.copyright}>
                    <h1>&#169; Copyright 2021.</h1>
                </div>
            </div>
        </div>
    )
}

export default Footer