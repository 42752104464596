import { AdminStoreAPI } from '../api/api'

const SET_UPDATED = 'adminStoreSet/SET_UPDATED'
const SET_TRASHED = 'adminStoreSet/SET_TRASHED'
const SET_SUCCESS = 'adminStoreSet/SET_SUCCESS'
const SET_ERROR = 'adminStoreSet/SET_ERROR'
const IS_FETCHING = 'adminStoreSet/IS_FETCHING'

let initialState = {
    updated: false,
    trashed: false,
    success: null,
    error: null,
    back: true,
    isFetching: false
}

const adminStoreSetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPDATED:
            return { ...state, updated: action.updated }
        case SET_TRASHED:
            return { ...state, trashed: action.trashed }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setUpdated = (updated) => ({ type: SET_UPDATED, updated })
export const setTrashed = (trashed) => ({ type: SET_TRASHED, trashed })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const changeStatus = (admin, id, status) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminStoreAPI.changeStatus(admin, id, status)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeImage = (admin, id, imageId, image) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminStoreAPI.changeImage(admin, id, imageId, image)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const imageDelete = (admin, id, removeId) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminStoreAPI.imageDelete(admin, id, removeId)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeTitle = (admin, id, title) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminStoreAPI.changeTitle(admin, id, title)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeText = (admin, id, text) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminStoreAPI.changeText(admin, id, text)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const remove = (admin, id, trash) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminStoreAPI.remove(admin, id, trash)
        dispatch(setTrashed(data.data.trashed))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default adminStoreSetReducer