import { HomeAPI } from '../api/api'

const SET_TOP_RATING_STORES = 'setTopRating/SET_TOP_RATING_STORES'
const SET_STATUS = 'setTopRating/SET_STATUS'
const SET_SUCCESS = 'setTopRating/SET_SUCCESS'
const IS_FETCHING = 'setTopRating/IS_FETCHING'

let initialState = {
    topRatingStores: [],
    status: null,
    success: null,
    isFetching: true
}

const topRatingStoresReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOP_RATING_STORES:
            return { ...state, topRatingStores: action.topRatingStores }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setTopRatingStores = (topRatingStores) => ({ type: SET_TOP_RATING_STORES, topRatingStores })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestTopRatingStores = () => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await HomeAPI.topRatingStores()
        dispatch(setTopRatingStores(data.data.topRatingStores))
        dispatch(setSuccess(data.data.success))
        dispatch(setStatus(data.data.status))
        dispatch(setIsFetching(false))
    }
}

export default topRatingStoresReducer