import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import Menu from '../Items/Menu/Menu'
import Context from './Context/Context'

const TermsOfUseContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>

                <Menu 
                    theme={props.theme}
                    back={props.back}
                />

                <BlockPanel
                    theme={props.theme}
                    iconLeft={<i className="fas fa-chevron-left"></i>}
                    leftLink={"/sections-and-informations/help"}
                    icon1={<i className="fas fa-handshake"></i>}
                    title={props.strings.using}
                    iconRight={<i className="fas fa-chevron-right"></i>}
                    rightLink={"/sections-and-informations/about-us"}
                />

                <Context
                    theme={props.theme}
                    strings={props.strings}
                />
                
            </div>
        </div>
    )
}

export default TermsOfUseContent