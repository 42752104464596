import React from 'react'

const CatBuildingConsumables  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={7951}>{props.strings.cat7951}</option>
            <option value={7953}>{props.strings.cat7953}</option>
            <option value={7957}>{props.strings.cat7957}</option>
            <option value={7960}>{props.strings.cat7960}</option>
            <option value={7963}>{props.strings.cat7963}</option>
            <option value={7965}>{props.strings.cat7965}</option>
            <option value={7967}>{props.strings.cat7967}</option>
            <option value={7970}>{props.strings.cat7970}</option>
            <option value={7973}>{props.strings.cat7973}</option>
            <option value={7975}>{props.strings.cat7975}</option>
            <option value={7977}>{props.strings.cat7977}</option>
            <option value={7980}>{props.strings.cat7980}</option>
            <option value={7983}>{props.strings.cat7983}</option>
            <option value={7985}>{props.strings.cat7985}</option>
            <option value={7987}>{props.strings.cat7987}</option>
            <option value={7990}>{props.strings.cat7990}</option>
            <option value={7993}>{props.strings.cat7993}</option>
            <option value={7995}>{props.strings.cat7995}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBuildingConsumables 