import React from 'react'
import dark from './SAPDark.module.css'
import light from './SAPLight.module.css'
import { NavLink } from 'react-router-dom'

const SitebarAdminPanel = (props) => {
    return (
        <div className={props.theme === 'light' ? light.adminPanel : dark.adminPanel}>
            <div className={props.theme === 'light' ? light.title : dark.title}>
                <i className="fas fa-shield-halved"></i> {props.strings.adminPanel}
            </div>
            {props.isAdmin
                ? <>
                    <NavLink
                        to="/admin/panel"
                        onClick={props.sitebarActivate}
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-solar-panel"></i> {props.strings.panel}
                    </NavLink>

                    <NavLink
                        to="/admin/administrators"
                        onClick={props.sitebarActivate}
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-crown"></i> {props.strings.admins}
                    </NavLink>

                    <NavLink
                        to="/admin/advertisings"
                        onClick={props.sitebarActivate}
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-square-poll-horizontal"></i> {props.strings.advertisings}
                    </NavLink>

                    <NavLink
                        to="/admin/profiles"
                        onClick={props.sitebarActivate}
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-users"></i> {props.strings.users}
                    </NavLink>

                    <NavLink
                        to="/admin/stores"
                        onClick={props.sitebarActivate}
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-store-alt"></i> {props.strings.stores}
                    </NavLink>

                    <NavLink
                        to="/admin/products"
                        onClick={props.sitebarActivate}
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-bag-shopping"></i> {props.strings.products}
                    </NavLink>

                    <NavLink
                        to="/admin/comments"
                        onClick={props.sitebarActivate}
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-comments"></i> {props.strings.comments}
                    </NavLink>
                    
                    <NavLink
                        to="/admin/logout"
                        onClick={props.sitebarActivate}
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-sign-out-alt"></i> {props.strings.exit}
                    </NavLink>
                </>
                : <NavLink
                    to="/admin/login"
                    onClick={props.sitebarActivate}
                    className={props.theme === 'light'
                        ? ({isActive}) => isActive 
                            ? light.item + " " + light.active 
                            : light.item
                        : ({isActive}) => isActive 
                            ? dark.item + " " + dark.active 
                            : dark.item
                    }
                >
                    <i className="fas fa-sign-in-alt"></i> {props.strings.entrance}
                </NavLink>
            }
        </div>
    )
}

export default SitebarAdminPanel