import React from 'react'
import { NavLink } from 'react-router-dom'
import dark from './BPDark.module.css'
import light from './BPLight.module.css'

const BlockPanel = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockPanel : dark.blockPanel}>

            <span
                className={
                props.theme === 'light'
                    ? light.item + " " + light.left
                    : dark.item + " " + dark.left
                }
            >
                {props.leftClick
                    ? <span
                        className={props.theme === 'light' ? light.icon : dark.icon}
                        onClick={props.leftClick}
                    >
                        {props.iconLeft}
                    </span>
                    : null
                }

                {props.leftLink
                    ? <NavLink
                        className={props.theme === 'light' ? light.icon : dark.icon}
                        to={props.leftLink}
                    >
                        {props.iconLeft}
                    </NavLink>
                    : null
                }

            </span>

            <h3>{props.icon1} {props.icon2} {props.title}</h3>

            <span
                className={
                props.theme === 'light'
                    ? light.item + " " + light.right
                    : dark.item + " " + dark.right
                }
            >
                {props.rightLink
                    ? <NavLink
                        className={props.theme === 'light' ? light.icon : dark.icon}
                        to={props.rightLink}
                    >
                        {props.iconRight}
                    </NavLink>
                    : null
                }

                {props.rightClick
                    ? <span
                        className={props.theme === 'light' ? light.icon : dark.icon}
                        onClick={props.rightClick}
                    >
                        {props.iconRight}
                    </span>
                    : null
                }
            </span>

        </div>
    )
}

export default BlockPanel