import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dark from './FinderDark.module.css'
import light from './FinderLight.module.css'
import {
    setCurrentPage,
    setOrder,
    setLocation,
    setMarker,
    setType,
    setChapter,
    setCategorie,
    setManufacturer,
    setModel,
    setEngine,
    setTransmission,
    setDrive,
    setBody,
    setColor,
    setYear,
    setProducts,
    setIsFetching
} from '../../../redux/products-reducer'
import OrderProducts from '../Orders/OrderProducts'
import Locations from '../Locations/Locations'
import Ashgabat from '../Locations/Ashgabat'
import Ahal from '../Locations/Ahal'
import Balkan from '../Locations/Balkan'
import Dashoguz from '../Locations/Dashoguz'
import Lebap from '../Locations/Lebap'
import Mary from '../Locations/Mary'
import CarManufacturers from '../FormSelects/ProductManufacturers/CarManufacturers'
import Type from '../Categories/Types/Type'
import Cars from '../Categories/Chapters/Cars'
import Tools from '../Categories/Chapters/Tools'
import Services from '../Categories/Chapters/Services'
import Jobs from '../Categories/Chapters/Jobs'
import RealEstate from '../Categories/Chapters/RealEstate'
import Bussiness from '../Categories/Chapters/Bussiness'
import Foods from '../Categories/Chapters/Foods'
import Building from '../Categories/Chapters/Building'
import HomeOffice from '../Categories/Chapters/HomeOffice'
import Chancellery from '../Categories/Chapters/Chancellery'
import Electronics from '../Categories/Chapters/Electronics'
import Man from '../Categories/Chapters/Man'
import Woman from '../Categories/Chapters/Woman'
import Children from '../Categories/Chapters/Children'
import Beauty from '../Categories/Chapters/Beauty'
import Pharamcy from '../Categories/Chapters/Pharamcy'
import Recreation from '../Categories/Chapters/Recreation'
import Sport from '../Categories/Chapters/Sport'
import Cafes from '../Categories/Chapters/Cafes'
import Agriculture from '../Categories/Chapters/Agriculture'
import Animals from '../Categories/Chapters/Animals'

import CatCars from '../Categories/Categories/CatCars'
import CatCarParts from '../Categories/Categories/CatCarParts'
import CatCarProducts from '../Categories/Categories/CatCarProducts'
import CatToolsHand from '../Categories/Categories/CatToolsHand'
import CatToolsElectric from '../Categories/Categories/CatToolsElectric'
import CatToolsHydrolic from '../Categories/Categories/CatToolsHydrolic'
import CatServicesRealEstate from '../Categories/Categories/CatServicesRealEstate'
import CatServicesCar from '../Categories/Categories/CatServicesCar'
import CatServicesEquipment from '../Categories/Categories/CatServicesEquipment'
import CatServicesComputer from '../Categories/Categories/CatServicesComputer'
import CatServicesPhones from '../Categories/Categories/CatServicesPhones'
import CatServicesStudios from '../Categories/Categories/CatServicesStudios'
import CatServicesClothing from '../Categories/Categories/CatServicesClothing'
import CatServicesBeauty from '../Categories/Categories/CatServicesBeauty'
import CatServicesDocuments from '../Categories/Categories/CatServicesDocuments'
import CatServicesTransportation from '../Categories/Categories/CatServicesTransportation'
import CatServicesTraining from '../Categories/Categories/CatServicesTraining'
import CatJobs from '../Categories/Categories/CatJobs'
import CatRealEstateSale from '../Categories/Categories/CatRealEstateSale'
import CatRealEstateRent from '../Categories/Categories/CatRealEstateRent'
import CatBussinessSale from '../Categories/Categories/CatBussinessSale'
import CatBussinessProduction from '../Categories/Categories/CatBussinessProduction'
import CatFoodsBeverages from '../Categories/Categories/CatFoodsBeverages'
import CatFoodsSweets from '../Categories/Categories/CatFoodsSweets'
import CatFoodsMilkProducts from '../Categories/Categories/CatFoodsMilkProducts'
import CatFoodsFlourProducts from '../Categories/Categories/CatFoodsFlourProducts'
import CatFoodsMeatProducts from '../Categories/Categories/CatFoodsMeatProducts'
import CatFoodsCanneds from '../Categories/Categories/CatFoodsCanneds'
import CatFoodsProteinsCarbohytrates from '../Categories/Categories/CatFoodsProteinsCarbohytrates'
import CatFoodsOils from '../Categories/Categories/CatFoodsOils'
import CatFoodsVinegarsSeasoning from '../Categories/Categories/CatFoodsVinegarsSeasoning'
import CatFoodsFruits from '../Categories/Categories/CatFoodsFruits'
import CatFoodsBerries from '../Categories/Categories/CatFoodsBerries'
import CatFoodsVegetables from '../Categories/Categories/CatFoodsVegetables'
import CatFoodsNuts from '../Categories/Categories/CatFoodsNuts'
import CatFoodsBaby from '../Categories/Categories/CatFoodsBaby'
import CatBuildingMaterials from '../Categories/Categories/CatBuildingMaterials'
import CatBuildingSlates from '../Categories/Categories/CatBuildingSlates'
import CatBuildingIron from '../Categories/Categories/CatBuildingIron'
import CatBuildingWood from '../Categories/Categories/CatBuildingWood'
import CatBuildingReadyOrder from '../Categories/Categories/CatBuildingReadyOrder'
import CatBuildingFinishing from '../Categories/Categories/CatBuildingFinishing'
import CatBuildingPipes from '../Categories/Categories/CatBuildingPipes'
import CatBuildingSanNode from '../Categories/Categories/CatBuildingSanNode'
import CatBuildingHeating from '../Categories/Categories/CatBuildingHeating'
import CatBuildingConsumables from '../Categories/Categories/CatBuildingConsumables'
import CatHomeOfficeInterior from '../Categories/Categories/CatHomeOfficeInterior'
import CatHomeOfficeFurniture from '../Categories/Categories/CatHomeOfficeFurniture'
import CatHomeOfficeInventory from '../Categories/Categories/CatHomeOfficeInventory'
import CatHomeOfficeTablewares from '../Categories/Categories/CatHomeOfficeTablewares'
import CatHomeOfficeKitchenGoods from '../Categories/Categories/CatHomeOfficeKitchenGoods'
import CatHomeOfficeKitchenAppliances
    from '../Categories/Categories/CatHomeOfficeKitchenAppliances'
import CatHomeOfficeHouseholdGoods from '../Categories/Categories/CatHomeOfficeHouseholdGoods'
import CatHomeOfficeChemistry from '../Categories/Categories/CatHomeOfficeChemistry'
import CatHomeOfficeCounters from '../Categories/Categories/CatHomeOfficeCounters'
import CatChancelleryPaperProducts from '../Categories/Categories/CatChancelleryPaperProducts'
import CatChancelleryWritingDrawing from '../Categories/Categories/CatChancelleryWritingDrawing'
import CatChancelleryDocumentation from '../Categories/Categories/CatChancelleryDocumentation'
import CatElectronicsTvsMonitors from '../Categories/Categories/CatElectronicsTvsMonitors'
import CatElectronicsPrefixes from '../Categories/Categories/CatElectronicsPrefixes'
import CatElectronicsMusic from '../Categories/Categories/CatElectronicsMusic'
import CatElectronicsCar from '../Categories/Categories/CatElectronicsCar'
import CatElectronicsAntennas from '../Categories/Categories/CatElectronicsAntennas'
import CatElectronicsRemoteControllers
    from '../Categories/Categories/CatElectronicsRemoteControllers'
import CatElectronicsSmartHouse from '../Categories/Categories/CatElectronicsSmartHouse'
import CatElectronicsElectricity from '../Categories/Categories/CatElectronicsElectricity'
import CatElectronicsLighting from '../Categories/Categories/CatElectronicsLighting'
import CatElectronicsCommercialEquipment
    from '../Categories/Categories/CatElectronicsCommercialEquipment'
import CatElectronicsComputerTechnology
    from '../Categories/Categories/CatElectronicsComputerTechnology'
import CatElectronicsForGaming from '../Categories/Categories/CatElectronicsForGaming'
import CatElectronicsPrintersScanners
    from '../Categories/Categories/CatElectronicsPrintersScanners'
import CatElectronicsNetwork from '../Categories/Categories/CatElectronicsNetwork'
import CatElectronicsPcParts from '../Categories/Categories/CatElectronicsPcParts'
import CatElectronicsNotebookParts from '../Categories/Categories/CatElectronicsNotebookParts'
import CatElectronicsStorageDrives from '../Categories/Categories/CatElectronicsStorageDrives'
import CatElectronicsSoftwares from '../Categories/Categories/CatElectronicsSoftwares'
import CatElectronicsUpsExtensions from '../Categories/Categories/CatElectronicsUpsExtensions'
import CatElectronicsOfficeEquipment from '../Categories/Categories/CatElectronicsOfficeEquipment'
import CatElectronicsConnection from '../Categories/Categories/CatElectronicsConnection'
import CatElectronicsPhoneParts from '../Categories/Categories/CatElectronicsPhoneParts'
import CatElectronicsPhotoVideo from '../Categories/Categories/CatElectronicsPhotoVideo'
import CatElectronicsBatteries from '../Categories/Categories/CatElectronicsBatteries'
import CatElectronicsAppliances from '../Categories/Categories/CatElectronicsAppliances'
import CatElectronicsBeauty from '../Categories/Categories/CatElectronicsBeauty'
import CatManClothes from '../Categories/Categories/CatManClothes'
import CatManBagsWallets from '../Categories/Categories/CatManBagsWallets'
import CatWomanClothes from '../Categories/Categories/CatWomanClothes'
import CatWomanBagsWallets from '../Categories/Categories/CatWomanBagsWallets'
import CatChildrenClothes from '../Categories/Categories/CatChildrenClothes'
import CatChildrenKids from '../Categories/Categories/CatChildrenKids'
import CatManJewelery from '../Categories/Categories/CatManJewelery'
import CatWomanJewelery from '../Categories/Categories/CatWomanJewelery'
import CatBeautyHair from '../Categories/Categories/CatBeautyHair'
import CatBeautyFace from '../Categories/Categories/CatBeautyFace'
import CatBeautyEyebrows from '../Categories/Categories/CatBeautyEyebrows'
import CatBeautyEyelashes from '../Categories/Categories/CatBeautyEyelashes'
import CatBeautyLips from '../Categories/Categories/CatBeautyLips'
import CatBeautyOralCavity from '../Categories/Categories/CatBeautyOralCavity'
import CatBeautyBody from '../Categories/Categories/CatBeautyBody'
import CatBeautyNails from '../Categories/Categories/CatBeautyNails'
import CatBeautyHygiene from '../Categories/Categories/CatBeautyHygiene'
import CatBeautyShowerBath from '../Categories/Categories/CatBeautyShowerBath'
import CatBeautyChildren from '../Categories/Categories/CatBeautyChildren'
import CatBeautyMan from '../Categories/Categories/CatBeautyMan'
import CatBeautyPerfumery from '../Categories/Categories/CatBeautyPerfumery'
import CatBeautyAccessories from '../Categories/Categories/CatBeautyAccessories'
import CatPharamcyMedications from '../Categories/Categories/CatPharamcyMedications'
import CatPharamcyMedicalDevices from '../Categories/Categories/CatPharamcyMedicalDevices'
import CatPharamcyOrthopedicProducts from '../Categories/Categories/CatPharamcyOrthopedicProducts'
import CatPharamcyMomAndBaby from '../Categories/Categories/CatPharamcyMomAndBaby'
import CatRecreationTourismGoods from '../Categories/Categories/CatRecreationTourismGoods'
import CatRecreationEntertainmentGoods from '../Categories/Categories/CatRecreationEntertainmentGoods'
import CatSportGoods from '../Categories/Categories/CatSportGoods'
import CatSportUniforms from '../Categories/Categories/CatSportUniforms'
import CatSportEquipments from '../Categories/Categories/CatSportEquipments'
import CatCafesMenu from '../Categories/Categories/CatCafesMenu'
import CatAgricultureHarvest from '../Categories/Categories/CatAgricultureHarvest'
import CatAgricultureTechnique from '../Categories/Categories/CatAgricultureTechnique'
import CatAnimalsPets from '../Categories/Categories/CatAnimalsPets'
import CatAnimalsPetsGoods from '../Categories/Categories/CatAnimalsPetsGoods'
import CatAnimalsVeterinary from '../Categories/Categories/CatAnimalsVeterinary'
import CatAnimalsEquipment from '../Categories/Categories/CatAnimalsEquipment'

import Engine from '../FormSelects/Transport/Engine'
import Transmission from '../FormSelects/Transport/Transmission'
import Drive from '../FormSelects/Transport/Drive'
import Body from '../FormSelects/Transport/Body'
import Color from '../FormSelects/Transport/Color'
import Year from '../FormSelects/Other/Year'

import Bmw from '../FormSelects/ProductModels/Cars/Bmw'
import Mercedes from '../FormSelects/ProductModels/Cars/Mercedes'
import Toyota from '../FormSelects/ProductModels/Cars/Toyota'
import Lexus from '../FormSelects/ProductModels/Cars/Lexus'
import Audi from '../FormSelects/ProductModels/Cars/Audi'
import Hyundai from '../FormSelects/ProductModels/Cars/Hyundai'
import Volkswagen from '../FormSelects/ProductModels/Cars/Volkswagen'
import Chevrolet from '../FormSelects/ProductModels/Cars/Chevrolet'
import Mazda from '../FormSelects/ProductModels/Cars/Mazda'
import Infiniti from '../FormSelects/ProductModels/Cars/Infiniti'
import Nissan from '../FormSelects/ProductModels/Cars/Nissan'
import Kia from '../FormSelects/ProductModels/Cars/Kia'
import Jeep from '../FormSelects/ProductModels/Cars/Jeep'

const FinderProducts = (props) => {
    const dispatch = useDispatch()
    const location = useSelector(state => state.products.location)
    const types = useSelector(state => state.products.types)
    const chapter = useSelector(state => state.products.chapter)
    const categorie = useSelector(state => state.products.categorie)
    const manufacturer = useSelector(state => state.products.manufacturer)
    const model = useSelector(state => state.products.model)

    const onCahngeOrder = (e) => {
        let order = e.target.value
        dispatch(setOrder(order))
        dispatch(setCurrentPage(1))
        dispatch(setProducts([]))
        dispatch(setIsFetching(true))
    }

    const onCahngeLocation = (e) => {
        let location = e.target.value
        dispatch(setLocation(location))
        dispatch(setMarker(null))
        dispatch(setCurrentPage(1))
        dispatch(setProducts([]))
        dispatch(setIsFetching(true))
    }

    const onCahngeMarker = (e) => {
        let marker = e.target.value
        dispatch(setMarker(marker))
        dispatch(setCurrentPage(1))
        dispatch(setProducts([]))
        dispatch(setIsFetching(true))
    }

    const onChangeType = (e) => {
        let types = e.target.value
        if (types === 0) {
            dispatch(setType(null))
            dispatch(setChapter(null))
            dispatch(setCategorie((null)))
            dispatch(setManufacturer(null))
            dispatch(setModel(null))
            dispatch(setEngine(null))
            dispatch(setTransmission(null))
            dispatch(setDrive(null))
            dispatch(setBody(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        } else {
            dispatch(setType(types))
            dispatch(setChapter(null))
            dispatch(setCategorie((null)))
            dispatch(setManufacturer(null))
            dispatch(setModel(null))
            dispatch(setEngine(null))
            dispatch(setTransmission(null))
            dispatch(setDrive(null))
            dispatch(setBody(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        }
    }

    const onCahngeChapter = (e) => {
        let chapter = e.target.value
        if (chapter === 0) {
            dispatch(setChapter(null))
            dispatch(setCategorie((null)))
            dispatch(setManufacturer(null))
            dispatch(setModel(null))
            dispatch(setEngine(null))
            dispatch(setTransmission(null))
            dispatch(setDrive(null))
            dispatch(setBody(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        } else {
            dispatch(setChapter(chapter))
            dispatch(setCategorie((null)))
            dispatch(setManufacturer(null))
            dispatch(setModel(null))
            dispatch(setEngine(null))
            dispatch(setTransmission(null))
            dispatch(setDrive(null))
            dispatch(setBody(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        }
    }

    const onCahngeCategorie = (e) => {
        let categorie = e.target.value
        if (categorie === 0) {
            dispatch(setCategorie(null))
            dispatch(setManufacturer(null))
            dispatch(setModel(null))
            dispatch(setEngine(null))
            dispatch(setTransmission(null))
            dispatch(setDrive(null))
            dispatch(setBody(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        } else {
            dispatch(setCategorie(categorie))
            dispatch(setManufacturer(null))
            dispatch(setModel(null))
            dispatch(setEngine(null))
            dispatch(setTransmission(null))
            dispatch(setDrive(null))
            dispatch(setBody(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        }
    }

    const onCahngeManufacturer = (e) => {
        let manufacturer = e.target.value
        if (manufacturer === 0) {
            dispatch(setManufacturer(null))
            dispatch(setModel(null))
            dispatch(setEngine(null))
            dispatch(setTransmission(null))
            dispatch(setDrive(null))
            dispatch(setBody(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        } else {
            dispatch(setManufacturer(manufacturer))
            dispatch(setModel(null))
            dispatch(setEngine(null))
            dispatch(setTransmission(null))
            dispatch(setDrive(null))
            dispatch(setBody(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        }
    }

    const onCahngeModel = (e) => {
        let model = e.target.value
        if (model === 0) {
            dispatch(setModel(null))
            dispatch(setEngine(null))
            dispatch(setTransmission(null))
            dispatch(setDrive(null))
            dispatch(setBody(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        } else {
            dispatch(setModel(model))
            dispatch(setEngine(null))
            dispatch(setTransmission(null))
            dispatch(setDrive(null))
            dispatch(setBody(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        }
    }

    const onCahngeEngine = (e) => {
        let engine = e.target.value
        if (engine === 0) {
            dispatch(setEngine(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        } else {
            dispatch(setEngine(engine))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        }
    }

    const onCahngeTransmission = (e) => {
        let transmission = e.target.value
        if (transmission === 0) {
            dispatch(setTransmission(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        } else {
            dispatch(setTransmission(transmission))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        }
    }

    const onCahngeDrive = (e) => {
        let drive = e.target.value
        if (drive === 0) {
            dispatch(setDrive(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        } else {
            dispatch(setDrive(drive))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        }
    }

    const onCahngeBody = (e) => {
        let body = e.target.value
        if (body === 0) {
            dispatch(setBody(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        } else {
            dispatch(setBody(body))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        }
    }

    const onCahngeColor = (e) => {
        let color = e.target.value
        if (color === 0) {
            dispatch(setColor(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        } else {
            dispatch(setColor(color))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        }
    }

    const onCahngeYear = (e) => {
        let year = e.target.value
        if (year === 0) {
            dispatch(setYear(null))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        } else {
            dispatch(setYear(year))
            dispatch(setCurrentPage(1))
            dispatch(setProducts([]))
            dispatch(setIsFetching(true))
        }
    }

    
    
    return (
        <div className={props.theme === 'light' ? light.filter : dark.filter}>
            <h3><i className="fas fa-search"></i> {props.strings.filter}</h3>

            <label>{props.strings.orderBy}.</label><br/>
            <select onChange={onCahngeOrder}>
                <OrderProducts strings={props.strings} />
            </select>

            <label>{props.strings.location}.</label><br />
            <select onChange={onCahngeLocation}>
                <Locations strings={props.strings} />
            </select>
            {location === '1'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Ashgabat strings={props.strings} />
                    </select>
                </>
                : location === '2'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Ahal strings={props.strings} />
                    </select>
                </>
                : location === '12'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Balkan strings={props.strings} />
                    </select>
                </>
                : location === '24'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Dashoguz strings={props.strings} />
                    </select>
                </>
                : location === '35'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Lebap strings={props.strings} />
                    </select>
                </>
                : location === '52'
                ? <>
                    <label>{props.strings.marker}.</label><br />
                    <select onChange={onCahngeMarker}>
                        <Mary strings={props.strings} />
                    </select>
                </>
                : null
            }

            <label>{props.strings.type}.</label><br />
            <select onChange={onChangeType}>
                <Type strings={props.strings} />
            </select>

            {types === '1'
                ? <>
                    <label>{props.strings.chapter}.</label><br />
                    <select onChange={onCahngeChapter}>
                        <Cars strings={props.strings} />
                    </select>
                    {chapter === '10'
                        ? <>
                            <label>{props.strings.categorie}.</label><br />
                            <select onChange={onCahngeCategorie}>
                                <CatCars strings={props.strings} />
                            </select>
                            {categorie !== null
                                ? <>
                                    <label>{props.strings.manufacturer}.</label><br />
                                    <select onChange={onCahngeManufacturer}>
                                        <CarManufacturers strings={props.strings} />
                                    </select>
                                    {manufacturer === '1000001'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Bmw strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1000100'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Mercedes strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1000200'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Toyota strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1000300'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Lexus strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1000400'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Audi strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1000500'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Hyundai strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1000600'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Volkswagen strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1000700'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Chevrolet strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1000800'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Mazda strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1000900'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Infiniti strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1001000'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Nissan strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1001100'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Kia strings={props.strings} />
                                            </select>
                                        </>
                                        : manufacturer === '1001200'
                                        ? <>
                                            <label>{props.strings.model}.</label><br />
                                            <select onChange={onCahngeModel}>
                                                <Jeep strings={props.strings} />
                                            </select>
                                        </>
                                        : null
                                    }
                                    
                                    {model !== null
                                        ? <>
                                            <label>{props.strings.engine}.</label><br />
                                            <select onChange={onCahngeEngine}>
                                                <Engine strings={props.strings} />
                                            </select>
                                            
                                            <label>{props.strings.transmission}.</label><br />
                                            <select onChange={onCahngeTransmission}>
                                                <Transmission strings={props.strings} />
                                            </select>

                                            <label>{props.strings.drive}.</label><br />
                                            <select onChange={onCahngeDrive}>
                                                <Drive strings={props.strings} />
                                            </select>

                                            <label>{props.strings.body}.</label><br />
                                            <select onChange={onCahngeBody}>
                                                <Body strings={props.strings} />
                                            </select>

                                            <label>{props.strings.color}.</label><br />
                                            <select onChange={onCahngeColor}>
                                                <Color strings={props.strings} />
                                            </select>

                                            <label>{props.strings.year}.</label><br />
                                            <select onChange={onCahngeYear}>
                                                <Year strings={props.strings} />
                                            </select>
                                        </>
                                        : null
                                    }
                                </>
                                : null
                            }
                        </>
                        : chapter === '100'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatCarParts strings={props.strings} />
                                </select>
                            </>
                        : chapter === '500'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatCarProducts strings={props.strings} />
                                </select>
                            </>
                        : null
                    }
                </>
                : types === '1000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Tools strings={props.strings} />
                        </select>
                        {chapter === '1001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatToolsHand strings={props.strings} />
                                </select>
                            </>
                            : chapter === '1300'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatToolsElectric strings={props.strings} />
                                </select>
                            </>
                            : chapter === '1600'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatToolsHydrolic strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '2000'
                    ? <>   
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Services strings={props.strings} />
                        </select>
                        {chapter === '2001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatServicesRealEstate strings={props.strings} />
                                </select>
                            </>
                            : chapter === '2020'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatServicesCar strings={props.strings} />
                                </select>
                            </>
                            : chapter === '2040'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatServicesEquipment strings={props.strings} />
                                </select>
                            </>
                            : chapter === '2060'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatServicesComputer strings={props.strings} />
                                </select>
                            </>
                            : chapter === '2080'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatServicesPhones strings={props.strings} />
                                </select>
                            </>
                            : chapter === '2100'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatServicesStudios strings={props.strings} />
                                </select>
                            </>
                            : chapter === '2120'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatServicesClothing strings={props.strings} />
                                </select>
                            </>
                            : chapter === '2140'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatServicesBeauty strings={props.strings} />
                                </select>
                            </>
                            : chapter === '2160'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatServicesDocuments strings={props.strings} />
                                </select>
                            </>
                            : chapter === '2180'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatServicesTransportation strings={props.strings} />
                                </select>
                            </>
                            : chapter === '2200'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatServicesTraining strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '3000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Jobs strings={props.strings} />
                        </select>
                        {chapter === '3001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatJobs strings={props.strings} />
                                </select>
                            </>
                            : chapter === '3002'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatJobs strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '4000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <RealEstate strings={props.strings} />
                        </select>
                        {chapter === '4001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatRealEstateSale strings={props.strings} />
                                </select>
                            </>
                            : chapter === '4100'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatRealEstateRent strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '5000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Bussiness strings={props.strings} />
                        </select>
                        {chapter === '5001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBussinessSale strings={props.strings} />
                                </select>
                            </>
                            : chapter === '5100'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBussinessProduction strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '6000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Foods strings={props.strings} />
                        </select>
                        {chapter === '6001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsBeverages strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6050'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsSweets strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6100'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsMilkProducts strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6150'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsFlourProducts strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6200'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsMeatProducts strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6300'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsCanneds strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6350'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsProteinsCarbohytrates strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6400'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsOils strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6450'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsVinegarsSeasoning strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6500'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsFruits strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6600'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsBerries strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6700'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsVegetables strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6800'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsNuts strings={props.strings} />
                                </select>
                            </>
                            : chapter === '6850'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatFoodsBaby strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '7000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Building strings={props.strings} />
                        </select>
                        {chapter === '7001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBuildingMaterials strings={props.strings} />
                                </select>
                            </>
                            : chapter === '7100'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBuildingSlates strings={props.strings} />
                                </select>
                            </>
                            : chapter === '7150'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBuildingIron strings={props.strings} />
                                </select>
                            </>
                            : chapter === '7200'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBuildingWood strings={props.strings} />
                                </select>
                            </>
                            : chapter === '7250'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBuildingReadyOrder strings={props.strings} />
                                </select>
                            </>
                            : chapter === '7300'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBuildingFinishing strings={props.strings} />
                                </select>
                            </>
                            : chapter === '7400'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBuildingPipes strings={props.strings} />
                                </select>
                            </>
                            : chapter === '7500'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBuildingSanNode strings={props.strings} />
                                </select>
                            </>
                            : chapter === '7600'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBuildingHeating strings={props.strings} />
                                </select>
                            </>
                            : chapter === '7950'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBuildingConsumables strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '8000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <HomeOffice strings={props.strings} />
                        </select>
                        {chapter === '8001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatHomeOfficeInterior strings={props.strings} />
                                </select>
                            </>
                            : chapter === '8050'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatHomeOfficeFurniture strings={props.strings} />
                                </select>
                            </>
                            : chapter === '8100'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatHomeOfficeInventory strings={props.strings} />
                                </select>
                            </>
                            : chapter === '8150'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatHomeOfficeTablewares strings={props.strings} />
                                </select>
                            </>
                            : chapter === '8200'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatHomeOfficeKitchenGoods strings={props.strings} />
                                </select>
                            </>
                            : chapter === '8250'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatHomeOfficeKitchenAppliances strings={props.strings} />
                                </select>
                            </>
                            : chapter === '8300'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatHomeOfficeHouseholdGoods strings={props.strings} />
                                </select>
                            </>
                            : chapter === '8350'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatHomeOfficeChemistry strings={props.strings} />
                                </select>
                            </>
                            : chapter === '8400'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatHomeOfficeCounters strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '9000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Chancellery strings={props.strings} />
                        </select>
                        {chapter === '9001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatChancelleryPaperProducts strings={props.strings} />
                                </select>
                            </>
                            : chapter === '9050'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatChancelleryWritingDrawing strings={props.strings} />
                                </select>
                            </>
                            : chapter === '9100'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatChancelleryDocumentation strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '10000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Electronics strings={props.strings} />
                        </select>
                        {chapter === '10001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsTvsMonitors strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10020'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsPrefixes strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10040'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsMusic strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10050'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsCar strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10060'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsAntennas strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10080'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsRemoteControllers strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10100'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsSmartHouse strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10120'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsElectricity strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10160'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsLighting strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10180'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsCommercialEquipment strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10200'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsComputerTechnology strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10240'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsForGaming strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10260'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsPrintersScanners strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10280'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsNetwork strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10300'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsPcParts strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10301'
                            ? <>
                                <label>{props.strings.categorieType}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsNotebookParts strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10320'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsStorageDrives strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10340'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsSoftwares strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10360'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsUpsExtensions strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10380'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsOfficeEquipment strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10600'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsConnection strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10620'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsPhoneParts strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10700'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsPhotoVideo strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10720'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsBatteries strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10900'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsAppliances strings={props.strings} />
                                </select>
                            </>
                            : chapter === '10980'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatElectronicsBeauty strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '11000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Man strings={props.strings} />
                        </select>
                        {chapter === '11003'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatManClothes strings={props.strings} />
                                </select>
                            </>
                            : chapter === '11300'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatManBagsWallets strings={props.strings} />
                                </select>
                            </>
                            : chapter === '11400'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatManJewelery strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '11001'
                    ? <>
                        <label>{props.strings.categorie}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Woman strings={props.strings} />
                        </select>
                        {chapter === '11003'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatWomanClothes strings={props.strings} />
                                </select>
                            </>
                            : chapter === '11300'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatWomanBagsWallets strings={props.strings} />
                                </select>
                            </>
                            : chapter === '11400'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatWomanJewelery strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '11002'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Children strings={props.strings} />
                        </select>
                        {chapter === '11003'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatChildrenClothes strings={props.strings} />
                                </select>
                            </>
                            : chapter === '11500'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatChildrenKids strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '12000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Beauty strings={props.strings} />
                        </select>
                        {chapter === '12001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyHair strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12002'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyFace strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12003'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyEyebrows strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12004'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyEyelashes strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12005'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyLips strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12006'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyOralCavity strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12008'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyBody strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12011'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyNails strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12012'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyHygiene strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12013'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyShowerBath strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12014'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyChildren strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12015'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyMan strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12017'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyPerfumery strings={props.strings} />
                                </select>
                            </>
                            : chapter === '12019'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatBeautyAccessories strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '13000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Pharamcy strings={props.strings} />
                        </select>
                        {chapter === '13001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatPharamcyMedications strings={props.strings} />
                                </select>
                            </>
                            : chapter === '13500'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatPharamcyMedicalDevices strings={props.strings} />
                                </select>
                            </>
                            : chapter === '13700'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatPharamcyOrthopedicProducts strings={props.strings} />
                                </select>
                            </>
                            : chapter === '13800'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatPharamcyMomAndBaby strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '14000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Recreation strings={props.strings} />
                        </select>
                        {chapter === '14001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatRecreationTourismGoods strings={props.strings} />
                                </select>
                            </>
                            : chapter === '14100'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatRecreationEntertainmentGoods strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '15000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Sport strings={props.strings} />
                        </select>
                        {chapter === '15001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatSportGoods strings={props.strings} />
                                </select>
                            </>
                            : chapter === '15100'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatSportUniforms strings={props.strings} />
                                </select>
                            </>
                            : chapter === '15200'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatSportEquipments strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '16000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Cafes strings={props.strings} />
                        </select>
                        {chapter === '16001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatCafesMenu strings={props.strings} />
                                </select>
                            </>
                            : null
                        }
                    </>
                : types === '17000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Agriculture strings={props.strings} />
                        </select>
                        {chapter === '17001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                <select onChange={onCahngeCategorie}>
                                    <CatAgricultureHarvest strings={props.strings} />
                                </select>
                            </>
                            : chapter === '17100'
                                ? <>
                                    <label>{props.strings.categorie}.</label><br />
                                    <select onChange={onCahngeCategorie}>
                                        <CatAgricultureTechnique strings={props.strings} />
                                    </select>
                                </>
                            : null
                        }
                    </>
                : types === '18000'
                    ? <>
                        <label>{props.strings.chapter}.</label><br />
                        <select onChange={onCahngeChapter}>
                            <Animals strings={props.strings} />
                        </select>
                        {chapter === '18001'
                            ? <>
                                <label>{props.strings.categorie}.</label><br />
                                    <select onChange={onCahngeCategorie}>
                                    <CatAnimalsPets strings={props.strings} />
                                </select>
                            </>
                            : chapter === '18100'
                                ? <>
                                    <label>{props.strings.categorie}.</label><br />
                                    <select onChange={onCahngeCategorie}>
                                        <CatAnimalsPetsGoods strings={props.strings} />
                                    </select>
                                </>
                            : chapter === '18200'
                                ? <>
                                    <label>{props.strings.categorie}.</label><br />
                                    <select onChange={onCahngeCategorie}>
                                        <CatAnimalsVeterinary strings={props.strings} />
                                    </select>
                                </>
                            : chapter === '18300'
                                ? <>
                                    <label>{props.strings.categorie}.</label><br />
                                    <select onChange={onCahngeCategorie}>
                                        <CatAnimalsEquipment strings={props.strings} />
                                    </select>
                                </>
                            : null
                        }
                    </>
                : null
            }
        </div>
    )
}

export default FinderProducts