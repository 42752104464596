import React from 'react'
import dark from '../../../CSS/Dark.module.css'
import light from '../../../CSS/Light.module.css'
import BlockPanel from '../../common/BlockPanel/BlockPanel'
import SetPriceForm from '../../common/Forms/SetPriceForm'

const SetPrice = (props) => {
    return (
        <>
            <BlockPanel
                theme={props.theme}
                iconLeft={<i className="fas fa-chevron-left"></i>}
                leftLink={"/mystore/products/" + props.product.id + "/settings"}
                icon1={<i className="fas fa-wallet"></i>}
                title={props.strings.price + ": " + (props.product.price ? props.product.price : 0) + " " + props.strings.valute}
            />
            <div className={props.theme === 'light' ? light.formContainer : dark.formContainer}>
                <SetPriceForm
                    theme={props.theme}
                    strings={props.strings}
                    validate={props.validate}
                    onSubmit={props.onSubmit}
                />
            </div>
        </>
    )
}

export default SetPrice