import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'
import Counter from '../additions/Counter/Counter'

const ProductFull = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockViewFull : dark.blockViewFull}>
        
            {props.status
                ? <div className={props.theme === 'light' ? light.status : dark.status}>
                    {props.strings['status' + props.status]}...
                </div>
                : null
            }
            
            <div className={props.theme === 'light' ? light.fullInfo : dark.fullInfo}>

                {props.title /* НАИМЕНОВАНИЕ */
                    ? <div className={props.theme === 'light' ? light.title : dark.title}>
                        {props.type === "1"
                            ? <i className="fas fa-car"></i> : props.type === "1000"
                            ? <i className="fas fa-screwdriver-wrench"></i> : props.type === "2000"
                            ? <i className="fas fa-people-carry-box"></i> : props.type === "3000"
                            ? <i className="fas fa-briefcase"></i> : props.type === "4000"
                            ? <i className="fas fa-building"></i> : props.type === "5000"
                            ? <i className="fas fa-business-time"></i> : props.type === "6000"
                            ? <i className="fas fa-plate-wheat"></i> : props.type === "7000"
                            ? <i className="fas fa-paint-roller"></i> : props.type === "8000"
                            ? <i className="fas fa-couch"></i> : props.type === "9000"
                            ? <i className="fas fa-stapler"></i> : props.type === "10000"
                            ? <i className="fas fa-plug"></i> : props.type === "11000"
                            ? <i className="fas fa-person"></i> : props.type === "11001"
                            ? <i className="fas fa-person-dress"></i> : props.type === "11002"
                            ? <i className="fas fa-child"></i> : props.type === "12000"
                            ? <i className="fas fa-hand-holding-heart"></i> : props.type === "13000"
                            ? <i className="fas fa-prescription-bottle-medical"></i> : props.type === "14000"
                            ? <i className="fas fa-binoculars"></i> : props.type === "15000"
                            ? <i className="fas fa-futbol"></i> : props.type === "16000"
                            ? <i className="fas fa-burger"></i> : props.type === "17000"
                            ? <i className="fas fa-wheat-awn"></i> : props.type === "18000"
                            ? <i className="fas fa-crow"></i> : <i className="fas fa-bag-shopping"></i>
                        } {props.title}
                    </div>
                : null
                }

                {props.location /* ЛОКАЦИЯ */
                    ? <div className={props.theme === 'light' ? light.info : dark.info}>
                        <i className="fas fa-map-marker-alt"></i> {props.strings.location}: {props.strings['loc' + props.location]}. {props.strings['loc' + props.marker]}.
                    </div>
                    : null
                }
                
                {props.categorie /* КАТЕГОРИЯ */
                    ? <div className={props.theme === 'light' ? light.info : dark.info}>
                        <i className="fas fa-certificate"></i> {props.strings.type}: {props.strings['cat' + props.type]} / {props.strings['cat' + props.chapter]} / {props.strings['cat' + props.categorie]}.
                    </div>
                    : null
                }

                {props.engine /* ДВИГАТЕЛЬ */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-gas-pump"></i> {props.strings.engine}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.engine}
                        </div>
                        
                    </div>
                    : null
                }

                {props.transmission /* ТРАНСМИССИЯ */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-exchange-alt"></i> {props.strings.transmission}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.strings['transmission' + props.transmission]}
                        </div>
                        
                    </div>
                    : null
                }

                {props.drive /* ДРАЙВ */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-random"></i> {props.strings.drive}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.strings['drive' + props.type]}
                        </div>
                        
                    </div>
                    : null
                }
                
                {props.mileage /* ПРОБЕГ */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-tachometer-alt"></i> {props.strings.mileage}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.mileage}
                        </div>
                        
                    </div>
                    : null
                }

                {props.body /* КУЗОВ */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-car"></i> {props.strings.body}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.strings['body' + props.body]}
                        </div>
                        
                    </div>
                    : null
                }

                {props.color /* ЦВЕТ */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-palette"></i> {props.strings.color}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.strings['color' + props.color]}
                        </div>
                        
                    </div>
                    : null
                }

                {props.year /* ГОД */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-calendar-check"></i> {props.strings.year}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.year}
                        </div>
                        
                    </div>
                    : null
                }

                {props.text /* ТЕКСТ */
                    ? <div className={props.theme === 'light' ? light.text : dark.text}>
                        {props.text}
                    </div>
                    : null
                }

                {props.price /* ЦЕНА */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-wallet"></i> {props.strings.price}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.price + " " + props.strings.valute}
                        </div>
                        
                    </div>
                    : null
                }

                {props.phone /* ТЕЛЕФОН */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-phone-square"></i> {props.strings.phone}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.phone}
                        </div>
                        
                    </div>
                    : null
                }

                {props.pub /* ДАТА */
                    ? <div className={props.theme === 'light' ? light.item : dark.item}>
                        <div className={props.theme === 'light' ? light.column : dark.column}>
                            <i className="fas fa-calendar-check"></i> {props.strings.pub}:
                        </div>
                        <div className={props.theme === 'light' ? light.string : dark.string}>
                            {props.pub}
                        </div>
                        
                    </div>
                    : null
                }
                
                {props.views/* ПРОСМОТРЕНО */
                    ? <div className={props.theme === 'light' ? light.views : dark.views}>
                        {props.strings.views}: <span><Counter count={props.views} /> <i className="fas fa-eye"></i> | <i className="fas fa-thumbs-up"></i>  <Counter count={props.favoriteCount} /></span>
                    </div>
                    : null
                }

            </div>
        </div>
    )
}

export default ProductFull