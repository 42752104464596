import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'

const ImageItem = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockView : dark.blockView} >
            <img src={props.src} alt={props.alt} />
            <div className={props.theme === 'light' ? light.selectBlock : dark.selectBlock}>
                <span>
                    {props.title}
                </span>
                <label
                    className={props.theme === 'light' ? light.updateInput : dark.updateInput}
                    htmlFor={"updateInput"+props.imageId}
                >
                    <i className="fas fa-folder-open"></i>
                    <input
                        className={props.theme === 'light' ? light.hidden : dark.hidden}
                        id={"updateInput"+props.imageId}
                        type="file"
                        name="image"
                        autoComplete="image"
                        accept="image/*"
                        onChange={props.changeImage}
                        imageid={props.imageId}
                    />
                </label>
                <button
                    className={props.theme === 'light' ? light.delete : dark.delete}
                    onClick={props.removeImage}
                    disabled={props.disabled}
                    imageid={props.imageId}
                >
                    <i className="fas fa-trash-alt"></i>
                </button>
            </div>
        </div>
    )
}

export default ImageItem