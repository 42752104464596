import React from 'react'

const CatBuildingReadyOrder  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={7251}>{props.strings.cat7251}</option>
            <option value={7255}>{props.strings.cat7255}</option>
            <option value={7260}>{props.strings.cat7260}</option>
            <option value={7265}>{props.strings.cat7265}</option>
            <option value={7270}>{props.strings.cat7270}</option>
            <option value={7275}>{props.strings.cat7275}</option>
            <option value={7280}>{props.strings.cat7280}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBuildingReadyOrder 