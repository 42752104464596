import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'

const FavoriteForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.favoriteForm : dark.favoriteForm}>
            <div
                className={
                    props.profileFavorite === true
                        ? props.theme === 'light' ? light.likeItem + ' ' + light.like : dark.likeItem + ' ' + dark.like
                        : props.theme === 'light' ? light.likeItem : dark.likeItem
                }
                onClick={props.onSubmitFavorite}
            >
                <span className={props.theme === 'light' ? light.count : dark.count}>
                    <i className="fas fa-thumbs-up"></i>
                </span> {props.favoriteCount
                    ? <span
                        className={props.theme === 'light' ? light.count : dark.count}>
                            +{props.favoriteCount}
                    </span>
                    : null
                }
            </div>
        </div>
    )
}

export default FavoriteForm