import React from 'react'

const Lexus = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1000300}>LEXUS CT</option>
            <option value={1000301}>LEXUS ES</option>
            <option value={1000302}>LEXUS ES300</option>
            <option value={1000303}>LEXUS ES330</option>
            <option value={1000304}>LEXUS ES350</option>
            <option value={1000305}>LEXUS GS</option>
            <option value={1000306}>LEXUS GX</option>
            <option value={1000307}>LEXUS HS</option>
            <option value={1000308}>LEXUS IS</option>
            <option value={1000309}>LEXUS IS-F</option>
            <option value={1000310}>LEXUS LFA</option>
            <option value={1000311}>LEXUS LS</option>
            <option value={1000312}>LEXUS LS HYBRID</option>
            <option value={1000313}>LEXUS LX</option>
            <option value={1000314}>LEXUS NX</option>
            <option value={1000315}>LEXUS RX</option>
            <option value={1000316}>LEXUS RX HYBRID</option>
            <option value={1000317}>LEXUS RX300</option>
            <option value={1000318}>LEXUS RX330</option>
            <option value={1000319}>LEXUS RX350</option>
            <option value={1000320}>LEXUS SC</option>
        </>
    )
}

export default Lexus