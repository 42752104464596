import React from 'react'

const Cars = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={10}>{props.strings.cat10}</option>
            <option value={100}>{props.strings.cat100}</option>
            <option value={500}>{props.strings.cat500}</option>
        </>
    )
}

export default Cars