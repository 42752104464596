import React from 'react'

const Pharamcy = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={13001}>{props.strings.cat13001}</option>
            <option value={13500}>{props.strings.cat13500}</option>
            <option value={13700}>{props.strings.cat13700}</option>
            <option value={13800}>{props.strings.cat13800}</option>
        </>
    )
}

export default Pharamcy