import { AdminStoreAPI } from '../api/api'

const SET_STORE = 'adminStore/SET_STORE'
const SET_IMAGES = 'adminStore/SET_IMAGES'
const SET_ACTIVE_INDEX = 'adminStore/SET_ACTIVE_INDEX'
const SET_PROFILE = 'adminStore/SET_PROFILE'
const SET_STATUS = 'adminStore/SET_STATUS'
const SET_SUCCESS = 'adminStore/SET_SUCCESS'
const SET_ERROR = 'adminStore/SET_ERROR'
const IS_PHOTOS_CROPE = 'adminStore/IS_PHOTOS_CROPE'
const IS_FETCHING = 'adminStore/IS_FETCHING'

let initialState = {
    store: [],
    images: [],
    activeIndex: 0,
    profile: [],
    status: null,
    success: null,
    error: null,
    back: true,
    isPhotosCrope: false,
    isFetching: true
}

const adminStoreReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STORE:
            return { ...state, store: action.store }
        case SET_IMAGES:
            return { ...state, images: action.images }
        case SET_ACTIVE_INDEX:
            return { ...state, activeIndex: action.activeIndex }
        case SET_PROFILE:
            return { ...state, profile: action.profile }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_PHOTOS_CROPE:
            return { ...state, isPhotosCrope: action.isPhotosCrope }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setStore = (store) => ({ type: SET_STORE, store })
export const setImages = (images) => ({ type: SET_IMAGES, images })
export const setActiveIndex = (activeIndex) => ({ type: SET_ACTIVE_INDEX, activeIndex })
export const setProfile = (profile) => ({ type: SET_PROFILE, profile })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsPhotosCrope = (isPhotosCrope) => ({ type: IS_PHOTOS_CROPE, isPhotosCrope })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestStore = (id, admin) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminStoreAPI.store(id, admin)
        dispatch(setStore(data.data.store))
        dispatch(setImages(data.data.images))
        dispatch(setProfile(data.data.profile))
        dispatch(setStatus(data.data.status))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default adminStoreReducer