import React from 'react'
import { NavLink } from 'react-router-dom'
import dark from './Dark.module.css'
import light from './Light.module.css'

const PasswordVerify = (props) => {
    return (
        <div className={props.theme === 'light' ? light.verify : dark.verify}>
            <div className={props.theme === 'light' ? light.blocks : dark.blocks}>
                {props.strings.passwordForgotText}
            </div>
                    
            <div className={props.theme === 'light' ? light.blocks : dark.blocks}>
                {props.strings.toNumber}:
            </div>

            <div className={props.theme === 'light' ? light.phone : dark.phone}>
                +993 64298343
            </div>

            <div className={props.theme === 'light' ? light.blocks : dark.blocks}>
                <a href={"sms:+993 64298343?body=PASS"}>
                    <i className="fas fa-envelope"></i> {props.strings.sendMessage} <i className="fas fa-chevron-right"></i>
                </a>
            </div>

            <div className={props.theme === 'light' ? light.blocks : dark.blocks}>
                <NavLink className={props.theme === 'light' ? light.login : dark.login} to={"/login"} >{props.strings.authorization} <i className="fas fa-chevron-right"></i></NavLink>
            </div>
        </div>
    )
}

export default PasswordVerify