import React from 'react'
import { useSelector } from 'react-redux'
import NotFound from './NotFound'

const NotFoundContainer = (props) => {
    
    window.scrollTo({top: 0, left: 0})

    const back = useSelector(state => state.notFound.back)
    const error = useSelector(state => state.notFound.error)
    const isFetching = useSelector(state => state.notFound.isFetching)

    return (
        <NotFound
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            
            back={back}
            error={error}
            isFetching={isFetching}
        />
    )
}

export default NotFoundContainer