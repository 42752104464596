import React from 'react'

const Electronics = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={10001}>{props.strings.cat10001}</option>
            <option value={10020}>{props.strings.cat10020}</option>
            <option value={10040}>{props.strings.cat10040}</option>
            <option value={10050}>{props.strings.cat10050}</option>
            <option value={10060}>{props.strings.cat10060}</option>
            <option value={10080}>{props.strings.cat10080}</option>
            <option value={10100}>{props.strings.cat10100}</option>
            <option value={10120}>{props.strings.cat10120}</option>
            <option value={10160}>{props.strings.cat10160}</option>
            <option value={10180}>{props.strings.cat10180}</option>
            <option value={10200}>{props.strings.cat10200}</option>
            <option value={10240}>{props.strings.cat10240}</option>
            <option value={10260}>{props.strings.cat10260}</option>
            <option value={10280}>{props.strings.cat10280}</option>
            <option value={10300}>{props.strings.cat10300}</option>
            <option value={10301}>{props.strings.cat10301}</option>
            <option value={10320}>{props.strings.cat10320}</option>
            <option value={10340}>{props.strings.cat10340}</option>
            <option value={10360}>{props.strings.cat10360}</option>
            <option value={10380}>{props.strings.cat10380}</option>
            <option value={10600}>{props.strings.cat10600}</option>
            <option value={10620}>{props.strings.cat10620}</option>
            <option value={10700}>{props.strings.cat10700}</option>
            <option value={10720}>{props.strings.cat10720}</option>
            <option value={10900}>{props.strings.cat10900}</option>
            <option value={10980}>{props.strings.cat10980}</option>
        </>
    )
}

export default Electronics