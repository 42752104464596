import React from 'react'

const CatPharamcyMedicalDevices  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={13501}>{props.strings.cat13501}</option>
            <option value={13510}>{props.strings.cat13510}</option>
            <option value={13520}>{props.strings.cat13520}</option>
            <option value={13530}>{props.strings.cat13530}</option>
            <option value={13540}>{props.strings.cat13540}</option>
            <option value={13550}>{props.strings.cat13550}</option>
            <option value={13560}>{props.strings.cat13560}</option>
            <option value={13570}>{props.strings.cat13570}</option>
            <option value={13580}>{props.strings.cat13580}</option>
            <option value={13590}>{props.strings.cat13590}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatPharamcyMedicalDevices