
const SET_SUCCESS = 'notify/SET_SUCCESS'
const SET_SUCCESS_SHOW = 'notify/SET_SUCCESS_SHOW'
const SET_WARNING = 'notify/SET_WARNING'
const SET_WARNING_SHOW = 'notify/SET_WARNING_SHOW'
const SET_ERROR = 'notify/SET_ERROR'
const SET_ERROR_SHOW = 'notify/SET_ERROR_SHOW'

let initialState = {
    success: null,
    successShow: false,
    warning: null,
    warningShow: false,
    error: null,
    errorShow: false
}

const notifyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_SUCCESS_SHOW:
            return { ...state, successShow: action.successShow }
        case SET_WARNING:
            return { ...state, warning: action.warning }
        case SET_WARNING_SHOW:
            return { ...state, warningShow: action.warningShow }
        case SET_ERROR:
            return { ...state, error: action.error }
        case SET_ERROR_SHOW:
            return { ...state, errorShow: action.errorShow }
        default:
            return state
    }
}

export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setSuccessShow = (successShow) => ({ type: SET_SUCCESS_SHOW, successShow })
export const setWarning = (warning) => ({ type: SET_WARNING, warning })
export const setWarningShow = (warningShow) => ({ type: SET_WARNING_SHOW, warningShow })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setErrorShow = (errorShow) => ({ type: SET_ERROR_SHOW, errorShow })

export default notifyReducer