import { AdminStoreAPI } from '../api/api'

const SET_STORES = 'adminStores/SET_STORES'
const SET_COUNT = 'adminStores/SET_COUNT'
const SET_TOTAL_COUNT = 'adminStores/SET_TOTAL_COUNT'
const SET_CURRENT_PAGE = 'adminStores/SET_CURRENT_PAGE'
const SET_PAGES_COUNT = 'adminStores/SET_PAGES_COUNT'
const SET_STATUS = 'adminStores/SET_STATUS'
const SET_SUCCESS = 'adminStores/SET_SUCCESS'
const SET_ERROR = 'adminStores/SET_ERROR'
const SET_VIEW_STATUS = 'adminStores/SET_VIEW_STATUS'
const IS_FETCHING = 'adminStores/IS_FETCHING'

let initialState = {
    stores: [],
    count: 30,
    totalCount: 0,
    currentPage: 1,
    pagesCount: 0,
    status: null,
    success: null,
    error: null,
    back: false,
    viewStatus: 1,
    isFetching: true
}

const adminStoresReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STORES:
            return { ...state, stores: action.stores }
        case SET_COUNT:
            return { ...state, count: action.count }
        case SET_TOTAL_COUNT:
            return { ...state, totalCount: action.totalCount }
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.currentPage }
        case SET_PAGES_COUNT:
            return { ...state, pagesCount: action.pagesCount }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case SET_VIEW_STATUS:
            return { ...state, viewStatus: action.viewStatus }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setStores = (stores) => ({ type: SET_STORES, stores })
export const setCount = (count) => ({ type: SET_COUNT, count })
export const setTotalCount = (totalCount) => ({ type: SET_TOTAL_COUNT, totalCount })
export const setCurrentPage = (currentPage) => ({ type: SET_CURRENT_PAGE, currentPage })
export const setPagesCount = (pagesCount) => ({ type: SET_PAGES_COUNT, pagesCount })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setViewStatus= (viewStatus) => ({ type: SET_VIEW_STATUS, viewStatus })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestStores = (admin, count, currentPage, viewStatus) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminStoreAPI.stores(admin, count, currentPage, viewStatus)
        dispatch(setStores(data.data.stores))
        dispatch(setTotalCount(data.data.totalCount))
        dispatch(setPagesCount(data.data.pagesCount))
        dispatch(setStatus(data.data.status))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default adminStoresReducer