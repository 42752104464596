import React from 'react'

const CatElectronicsSoftwares  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10341}>{props.strings.cat10341}</option>
            <option value={10342}>{props.strings.cat10342}</option>
            <option value={10343}>{props.strings.cat10343}</option>
            <option value={10344}>{props.strings.cat10344}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsSoftwares 