import { ProfileAPI } from '../api/api'

const SET_PROFILE = 'profile/SET_PROFILE'
const SET_IMAGES = 'profile/SET_IMAGES'
const SET_ACTIVE_INDEX = 'profile/SET_ACTIVE_INDEX'
const SET_SUCCESS = 'profile/SET_SUCCESS'
const SET_ERROR = 'profile/SET_ERROR'
const IS_PHOTOS_CROPE = 'profile/IS_PHOTOS_CROPE'
const IS_FETCHING = 'profile/IS_FETCHING'

let initialState = {
    profile: [],
    images: [],
    activeIndex: 0,
    success: null,
    error: null,
    back: false,
    isPhotosCrope: false,
    isFetching: true
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE:
            return { ...state, profile: action.profile }
        case SET_IMAGES:
            return { ...state, images: action.images }
        case SET_ACTIVE_INDEX:
            return { ...state, activeIndex: action.activeIndex }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_PHOTOS_CROPE:
            return { ...state, isPhotosCrope: action.isPhotosCrope }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setProfile = (profile) => ({ type: SET_PROFILE, profile })
export const setImages = (images) => ({ type: SET_IMAGES, images })
export const setActiveIndex = (activeIndex) => ({ type: SET_ACTIVE_INDEX, activeIndex })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsPhotosCrope = (isPhotosCrope) => ({ type: IS_PHOTOS_CROPE, isPhotosCrope })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestProfile = (auth) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProfileAPI.profile(auth)
        dispatch(setProfile(data.data.profile))
        dispatch(setImages(data.data.images))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const unsetProfile = () => {
    return async (dispatch) => {
        dispatch(setProfile([]))
        dispatch(setImages([]))
        dispatch(setSuccess(null))
        dispatch(setError(null))
    }
}

export default profileReducer