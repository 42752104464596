import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    requestStore,
    setImages,
    setActiveIndex,
    setIsPhotosCrope,
    setSuccess as setStoreSuccess,
    setError as setStoreError
} from '../../redux/store-reducer'
import {
    requestRating,
    setProfileRating,
    setRating,
    setRatingCount,
    setSuccess as setRatingSuccess,
    setSuccess as setRatingError,
    setIsFetching as setRatingIsFetching
} from '../../redux/rating-reducer'
import {
    requestProducts,
    setCurrentPage,
    setSuccess as setProductsSuccess,
    setIsFetching as setProductsIsFetching
} from '../../redux/storeProducts-reducer'
import {
    addRating,
    removeRating,
    setSuccess as setAddRatingSuccess,
    setError as setAddRatingError,
    setIsFetching as setAddRatingIsFetching
} from '../../redux/addRating-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import Store from './Store'

const StoreContainer = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()

    const store = useSelector(state => state.store.store)
    const images = useSelector(state => state.store.images)
    const activeIndex = useSelector(state => state.store.activeIndex)
    const isPhotosCrope = useSelector(state => state.store.isPhotosCrope)
    const storeStatus = useSelector(state => state.store.status)
    const storeSuccess = useSelector(state => state.store.success)
    const storeError = useSelector(state => state.store.error)
    const back = useSelector(state => state.store.back)
    const storeIsFetching = useSelector(state => state.store.isFetching)

    const profileRating = useSelector(state => state.rating.profileRating)
    const rating = useSelector(state => state.rating.rating)
    const ratingCount = useSelector(state => state.rating.ratingCount)
    const ratingSuccess = useSelector(state => state.rating.success)
    const ratingError = useSelector(state => state.rating.error)
    const ratingIsFetching = useSelector(state => state.rating.isFetching)

    const products = useSelector(state => state.storeProducts.products)
    const count = useSelector(state => state.storeProducts.count)
    const totalCount = useSelector(state => state.storeProducts.totalCount)
    const currentPage = useSelector(state => state.storeProducts.currentPage)
    const pagesCount = useSelector(state => state.storeProducts.pagesCount)
    const productsStatus = useSelector(state => state.storeProducts.status)
    const productsSuccess = useSelector(state => state.storeProducts.success)
    const productIsFetching = useSelector(state => state.storeProducts.isFetching)


    const addRatingSuccess = useSelector(state => state.addRating.success)
    const addRatingError = useSelector(state => state.addRating.error)

// Request section
    useEffect(() => {
        if (params.storeId) {
            dispatch(setImages([]))
            dispatch(setActiveIndex(0))
            dispatch(requestStore(params.storeId))

            window.scrollTo({top: 0, left: 0})
        } else {
            navigate('/stores')
        }
    }, [params.storeId, dispatch, navigate])
    
    useEffect(() => {
        if (params.storeId) {
            dispatch(setProfileRating(null))
            dispatch(setRating(null))
            dispatch(setRatingCount(null))
            dispatch(requestRating(params.storeId))
            dispatch(setAddRatingIsFetching(false))
            dispatch(setRatingIsFetching(false))
        }
    }, [dispatch, params.storeId, ratingIsFetching])

    useEffect(() => {
        if (params.storeId) {
            dispatch(requestProducts(params.storeId, currentPage, count))
        } else {
            navigate('/stores')
        }
    }, [params.storeId, currentPage, count, dispatch, navigate])

    const leftClick = () => {
        navigate(-1)
    }

    const id = params.storeId

// products section
    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setProductsIsFetching(true))
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

// Store section
    const crope = () => {
        if(isPhotosCrope) {
            dispatch(setIsPhotosCrope(false))
        } else {
            dispatch(setIsPhotosCrope(true))
        }
    }

// Rating section
    const possibleRates = [1, 2, 3, 4, 5]

    const [selectedRate, setSelectedRate] = useState(null)
    const [hoveredRate, setHoveredRate] = useState(null)
    const [removeRate, setRemoveRate] = useState(false)

    const onSelectedRate = (rate) => {
        setSelectedRate(rate)
    }

    const onHoveredRate = (rate) => {
        setHoveredRate(rate)
    }

    const changeRate = () => {
        setRemoveRate(true)
        dispatch(setRatingIsFetching(true))
    }

    useEffect(() => {
        if (selectedRate) {
            dispatch(addRating(id, selectedRate))
            dispatch(setRatingIsFetching(true))
        }
    }, [dispatch, id, selectedRate])

    useEffect(() => {
        if (removeRate) {
            dispatch(removeRating(id, removeRate))
            setSelectedRate(null)
            setRemoveRate(false)
        }
    }, [dispatch, id, removeRate])

    useEffect(
        () => {
            if(storeError){
                dispatch(setNotifyError(storeError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setStoreError(null))
            } else if(ratingError){
                dispatch(setNotifyError(ratingError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setRatingError(null))
            } else if(addRatingError){
                dispatch(setNotifyError(addRatingError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setAddRatingError(null))
            } else if(storeSuccess){
                dispatch(setNotifySuccess(storeSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setStoreSuccess(null))
            } else if(ratingSuccess){
                dispatch(setNotifySuccess(ratingSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setRatingSuccess(null))
            } else if(productsSuccess){
                dispatch(setNotifySuccess(productsSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setProductsSuccess(null))
            } else if(addRatingSuccess){
                dispatch(setNotifySuccess(addRatingSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setAddRatingSuccess(null))
            }
        }, [dispatch, storeError, ratingError, addRatingError, storeSuccess, ratingSuccess, productsSuccess, addRatingSuccess]
    )

    return (
        <Store
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            advertisingsSitebar={props.advertisingsSitebar}

            store={store}
            images={images}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            isPhotosCrope={isPhotosCrope}
            crope={crope}
            storeStatus={storeStatus}
            back={back}
            storeIsFetching={storeIsFetching}

            profileRating={profileRating}
            rating={rating}
            ratingCount={ratingCount}

            products={products}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            onPageChanged={onPageChanged}
            productsStatus={productsStatus}
            productIsFetching={productIsFetching}

            leftClick={leftClick}
            possibleRates={possibleRates}
            selectedRate={selectedRate}
            hoveredRate={hoveredRate}
            onSelectedRate={onSelectedRate}
            onHoveredRate={onHoveredRate}
            changeRate={changeRate}
        />
    )
}

export default StoreContainer