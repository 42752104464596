import axios from 'axios'
import { domain, token } from './main';

const instance = axios.create({
    withCredentials: true,
    /*headers: {
        'API-KEY': token
    },*/
    baseURL: domain + '/api/v1/index.php'
})

export const AuthAPI = {
    auth() {
        return instance.get(`?route=auth`)
            .then(response => {
                return response
                
            })
    },
    signup(name, login, location, marker, phone, password) {
        return instance.post(`?route=signup`, {name, login, location, marker, phone, password})
            .then(response => {
                return response
                
            })
    },
    login(phone, password) {
        return instance.post(`?route=login`, {phone, password})
            .then(response => {
                return response
                
            })
    },
    logout() {
        return instance.get(`?route=logout`)
    },
    adminLogin(login, salt) {
        return instance.post(`?route=admin_login`, {login, salt})
            .then(response => {
                return response
                
            })
    },
    adminLogout() {
        return instance.get(`?route=admin_logout`)
    }
}

export const ProfileAPI = {
    profile(auth) {
        return instance.get(`?route=profile&auth=${auth}`)
            .then(response => {
                return response
                
            })
    },
    // Profile Settings
    changeAvatar(auth, imageId, image) {
        const formData = new FormData()
        formData.append('image', image)
        return instance.post(`?route=profile_set&auth=${auth}&image_id=${imageId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                return response
                
            })
    },
    avatarDelete(auth, removeId) {
        return instance.post(`?route=profile_set&auth=${auth}`, {removeId})
            .then(response => {
                return response
                
            })
    },
    changeLogin(auth, login) {
        return instance.post(`?route=profile_set&auth=${auth}`, {login})
            .then(response => {
                return response
                
            })
    },
    changeLocation(auth, location, marker) {
        return instance.post(`?route=profile_set&auth=${auth}`, {location, marker})
            .then(response => {
                return response
                
            })
    },
    changeName(auth, name) {
        return instance.post(`?route=profile_set&auth=${auth}`, {name})
            .then(response => {
                return response
                
            })
    },
    changeBirth(auth, birth) {
        return instance.post(`?route=profile_set&auth=${auth}`, {birth})
            .then(response => {
                return response
                
            })
    },
    changeText(auth, text) {
        return instance.post(`?route=profile_set&auth=${auth}`, {text})
            .then(response => {
                return response
                
            })
    },
    changePassword(auth, password) {
        return instance.post(`?route=profile_set&auth=${auth}`, {password})
            .then(response => {
                return response
                
            })
    },
    remove(auth, trash) {
        return instance.post(`?route=profile_set&auth=${auth}`, {trash})
            .then(response => {
                return response
                
            })
    }
}

export const ProfileStoreAPI = {
    // ProfileStore
    store(auth) {
        return instance.get(`?route=store&auth=${auth}`)
            .then(response => {
                return response
                
            })
    },
    createStore(title, text) {
        return instance.post(`?route=create_store`, {title, text})
            .then(response => {
                return response
                
            })
    },
    // ProfileStore Settings
    changeImage(auth, imageId, image) {
        const formData = new FormData()
        formData.append('image', image)
        return instance.post(`?route=store_set&auth=${auth}&image_id=${imageId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                return response
                
            })
    },
    imageDelete(auth, removeId) {
        return instance.post(`?route=store_set&auth=${auth}`, {removeId})
            .then(response => {
                return response
                
            })
    },
    changeTitle(auth, title) {
        return instance.post(`?route=store_set&auth=${auth}`, {title})
            .then(response => {
                return response
                
            })
    },
    changeText(auth, text) {
        return instance.post(`?route=store_set&auth=${auth}`, {text})
            .then(response => {
                return response
                
            })
    },
    changePhone(auth, phone) {
        return instance.post(`?route=store_set&auth=${auth}`, {phone})
            .then(response => {
                return response
                
            })
    },
    remove(auth, trash) {
        return instance.post(`?route=store_set&auth=${auth}`, {trash})
            .then(response => {
                return response
                
            })
    }
}

export const addProductAPI = {
    // Add Product API
    addCar(type, chapter, categorie, manufacturer, model, engine, transmission, drive, body, color, year, mileage, text, price) {
        return instance.post(`?route=add_product`, {type, chapter, categorie, manufacturer, model, engine, transmission, drive, body, color, year, mileage, text, price})
            .then(response => {
                return response
                
            })
    },
    addProduct(type, chapter, categorie, title, text, price) {
        return instance.post(`?route=add_product`, {type, chapter, categorie, title, text, price})
            .then(response => {
                return response
                
            })
    }
}

export const ProfileStoreProductsAPI = {
    // Profile Store Products
    products(auth, currentPage, count, viewStatus) {
        return instance.get(`?route=products&auth=${auth}&page=${currentPage}&count=${count}&view=${viewStatus}`)
            .then(response => {
                return response    
            })
    }
}

export const ProfileStoreProductAPI = {
    product(auth, id) {
        return instance.get(`?route=product&auth=${auth}&id=${id}`)
            .then(response => {
                return response    
            })
    },
    // ProfileStoreProduct Settings
    changeVideo(auth, id, video) {
        const formData = new FormData()
        formData.append('video', video)
        return instance.post(`?route=product_set&auth=${auth}&id=${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                return response
                
            })
    },
    videoDelete(auth, id, removeVideo) {
        return instance.post(`?route=product_set&auth=${auth}&id=${id}`, {removeVideo})
            .then(response => {
                return response
                
            })
    },
    changeImage(auth, id, imageId, image) {
        const formData = new FormData()
        formData.append('image', image)
        return instance.post(`?route=product_set&auth=${auth}&id=${id}&image_id=${imageId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                return response
                
            })
    },
    imageDelete(auth, id, removeId) {
        return instance.post(`?route=product_set&auth=${auth}&id=${id}`, {removeId})
            .then(response => {
                return response
                
            })
    },
    changeTitle(auth, id, title) {
        return instance.post(`?route=product_set&auth=${auth}&id=${id}`, {title})
            .then(response => {
                return response
                
            })
    },
    changeText(auth, id, text) {
        return instance.post(`?route=product_set&auth=${auth}&id=${id}`, {text})
            .then(response => {
                return response
                
            })
    },
    changePrice(auth, id, price) {
        return instance.post(`?route=product_set&auth=${auth}&id=${id}`, {price})
            .then(response => {
                return response
                
            })
    },
    remove(auth, id, trash) {
        return instance.post(`?route=product_set&auth=${auth}&id=${id}`, {trash})
            .then(response => {
                return response
                
            })
    }
}

export const AdvertisingsAPI = {
    advertisings() {
        return instance.get(`?route=advertisings`)
            .then(response => {
                return response
                
            })
    }
}

export const AdvertisingAPI = {
    advertising(id) {
        return instance.get(`?route=advertising&id=${id}`)
            .then(response => {
                return response
                
            })
    }
}

export const HomeAPI = {
    topViewProducts() {
        return instance.get(`?route=top_view_products`)
            .then(response => {
                return response    
            })
    },
    topRatingStores() {
        return instance.get(`?route=top_rating_stores`)
            .then(response => {
                return response    
            })
    },
    newProducts() {
        return instance.get(`?route=new_products`)
            .then(response => {
                return response    
            })
    }
}

export const ProductsAPI = {
    products(order, location, marker, types, chapter, categorie, manufacturer, model, engine, transmission, drive, body, color, year, search, count, currentPage) {
        return instance.get(`?route=products&order=${order}&location=${location}&marker=${marker}&type=${types}&chapter=${chapter}&categorie=${categorie}&manufacturer=${manufacturer}&model=${model}&engine=${'%'+engine+'%'}&transmission=${transmission}&drive=${drive}&body=${body}&color=${color}&year=${year}&search=${'%'+search+'%'}&count=${count}&page=${currentPage}`)
            .then(response => {
                return response    
            })
    },
    favoriteProducts(currentPage, count) {
        return instance.get(`?route=favorite_products&page=${currentPage}&count=${count}`)
            .then(response => {
                return response    
            })
    },
    historyProducts(currentPage, count) {
        return instance.get(`?route=history_products&page=${currentPage}&count=${count}`)
            .then(response => {
                return response    
            })
    }
}


export const ProductAPI = {
    product(id) {
        return instance.get(`?route=product&id=${id}`)
            .then(response => {
                return response    
            })
    },  
    favorite(id) {
        return instance.get(`?route=favorite&id=${id}`)
            .then(response => {
                return response    
            })
    }
}

export const CommentsAPI = {
    comments(currentPage, count, productId) {
        return instance.get(`?route=comments&page=${currentPage}&count=${count}&product=${productId}`)
            .then(response => {
                return response    
            })
    },
    profileComments(auth, currentPage, count) {
        return instance.get(`?route=comments&auth=${auth}&page=${currentPage}&count=${count}`)
            .then(response => {
                return response    
            })
    },
    profileComment(auth, id) {
        return instance.get(`?route=comment&auth=${auth}&id=${id}`)
            .then(response => {
                return response    
            })
    },
    // Add Comment API
    addComment(id, text) {
        return instance.post(`?route=add_comment`, {id, text})
            .then(response => {
                return response
                
            })
    },
    // Comment settings API
    changeText(auth, id, text) {
        return instance.post(`?route=comment_set&auth=${auth}&id=${id}`, {text})
            .then(response => {
                return response
                
            })
    },
    remove(auth, id, trash) {
        return instance.post(`?route=comment_set&auth=${auth}&id=${id}`, {trash})
            .then(response => {
                return response
                
            })
    }
}

export const StoresAPI = {
    stores(currentPage, count, location, marker, search, order) {
        return instance.get(`?route=stores&page=${currentPage}&count=${count}&location=${location}&marker=${marker}&search=${'%'+search+'%'}&order=${order}`)
            .then(response => {
                return response
                
            })
    },
    favoriteStores(currentPage, count) {
        return instance.get(`?route=favorite_stores&page=${currentPage}&count=${count}`)
            .then(response => {
                return response    
            })
    },
    historyStores(currentPage, count) {
        return instance.get(`?route=history_stores&page=${currentPage}&count=${count}`)
            .then(response => {
                return response    
            })
    }
}
export const StoreAPI = {
    store(id) {
        return instance.get(`?route=store&id=${id}`)
            .then(response => {
                return response    
            })
    },
    products(store, currentPage, count) {
        return instance.get(`?route=products&store=${store}}&count=${count}&page=${currentPage}`)
            .then(response => {
                return response    
            })
    }
}

export const RatingAPI = {
    rating(id) {
        return instance.get(`?route=rating&id=${id}`)
            .then(response => {
                return response
                
            })
    },
    addRating(id, rating) {
        return instance.post(`?route=add_rating`, {id, rating})
            .then(response => {
                return response
                
            })
    },
    removeRating(id, removeRate) {
        return instance.post(`?route=add_rating`, {id, removeRate})
            .then(response => {
                return response
                
            })
    }
}

export const FavoriteAPI = {
    addFavorite(id) {
        return instance.post(`?route=add_favorite`, {id})
            .then(response => {
                return response
            })
    }
}

export const SitebarAPI = {
    strings() {
        return instance.get(`?route=strings`)
            .then(response => {
                return response
                
            })
    },
    theme() {
        return instance.get(`?route=theme`)
            .then(response => {
                return response
                
            })
    },
    themeData(theme) {
        return instance.post(`?route=theme_data`, {theme})
    },
    languageData(language) {
        return instance.post(`?route=language_data`, {language})
    }
}

export const AdminAPI = {
    // PANEL
    panel() {
        return instance.get(`?route=panel`)
            .then(response => {
                return response
                
            })
    },
    // ADMINS
    admins() {
        return instance.get(`?route=admins`)
            .then(response => {
                return response
                
            })
    }
}

export const AdminAdvertisingAPI = {
    // ADVERTISINGS
    addAdvertising(title, subtitle, text) {
        return instance.post(`?route=add_advertising`, {title, subtitle, text})
            .then(response => {
                return response
                
            })
    },
    advertisings(admin, count, currentPage, viewStatus) {
        return instance.get(`?route=advertisings&admin=${admin}&count=${count}&page=${currentPage}&view=${viewStatus}`)
            .then(response => {
                return response
                
            })
    },
    advertising(admin, id) {
        return instance.get(`?route=advertising&admin=${admin}&id=${id}`)
            .then(response => {
                return response
                
            })
    },
    changeStatus(id, status) {
        return instance.post(`?route=advertising_set&id=${id}`, {status})
            .then(response => {
                return response
                
            })
    },
    changeImage(id, imageId, image) {
        const formData = new FormData()
        formData.append('image', image)
        return instance.post(`?route=advertising_set&id=${id}&image_id=${imageId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                return response
                
            })
    },
    imageDelete(id, removeId) {
        return instance.post(`?route=advertising_set&id=${id}`, {removeId})
            .then(response => {
                return response
                
            })
    },
    changeTitle(id, title) {
        return instance.post(`?route=advertising_set&id=${id}`, {title})
            .then(response => {
                return response
                
            })
    },
    changeSubtitle(id, subtitle) {
        return instance.post(`?route=advertising_set&id=${id}`, {subtitle})
            .then(response => {
                return response
                
            })
    },
    changeText(id, text) {
        return instance.post(`?route=advertising_set&id=${id}`, {text})
            .then(response => {
                return response
                
            })
    },
    changePhone1(id, phone1) {
        return instance.post(`?route=advertising_set&id=${id}`, {phone1})
            .then(response => {
                return response
                
            })
    },
    changePhone2(id, phone2) {
        return instance.post(`?route=advertising_set&id=${id}`, {phone2})
            .then(response => {
                return response
                
            })
    },
    changeMail(id, mail) {
        return instance.post(`?route=advertising_set&id=${id}`, {mail})
            .then(response => {
                return response
                
            })
    },
    changeInstagram(id, instagram) {
        return instance.post(`?route=advertising_set&id=${id}`, {instagram})
            .then(response => {
                return response
                
            })
    },
    changeImo(id, imo) {
        return instance.post(`?route=advertising_set&id=${id}`, {imo})
            .then(response => {
                return response
                
            })
    },
    changeLink(id, link) {
        return instance.post(`?route=advertising_set&id=${id}`, {link})
            .then(response => {
                return response
                
            })
    },
    changeHref(id, href) {
        return instance.post(`?route=advertising_set&id=${id}`, {href})
            .then(response => {
                return response
                
            })
    },
    remove(id, trash) {
        return instance.post(`?route=advertising_set&id=${id}`, {trash})
            .then(response => {
                return response
                
            })
    }
}

export const AdminProfileAPI = {
    // PROFILES
    profiles(count, currentPage, viewStatus) {
        return instance.get(`?route=profiles&count=${count}&page=${currentPage}&view=${viewStatus}`)
            .then(response => {
                return response
                
            })
    },
    profile(id) {
        return instance.get(`?route=profile&id=${id}`)
            .then(response => {
                return response
                
            })
    },
    profileComments(profile, count, currentPage) {
        return instance.get(`?route=comments&profile=${profile}&count=${count}&page=${currentPage}`)
            .then(response => {
                return response
                
            })
    },
    changeStatus(admin, id, status) {
        return instance.post(`?route=profile_set&admin=${admin}&id=${id}`, {status})
            .then(response => {
                return response
                
            })
    },
    changeAvatar(admin, id, imageId, image) {
        const formData = new FormData()
        formData.append('image', image)
        return instance.post(`?route=profile_set&admin=${admin}&id=${id}&image_id=${imageId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                return response
                
            })
    },
    avatarDelete(admin, id, removeId) {
        return instance.post(`?route=profile_set&admin=${admin}&id=${id}`, {removeId})
            .then(response => {
                return response
                
            })
    },
    changeLogin(admin, id, login) {
        return instance.post(`?route=profile_set&admin=${admin}&id=${id}`, {login})
            .then(response => {
                return response
                
            })
    },
    changeLocation(admin, id, location, marker) {
        return instance.post(`?route=profile_set&admin=${admin}&id=${id}`, {location, marker})
            .then(response => {
                return response
                
            })
    },
    changeName(admin, id, name) {
        return instance.post(`?route=profile_set&admin=${admin}&id=${id}`, {name})
            .then(response => {
                return response
                
            })
    },
    changeBirth(admin, id, birth) {
        return instance.post(`?route=profile_set&admin=${admin}&id=${id}`, {birth})
            .then(response => {
                return response
                
            })
    },
    changeText(admin, id, text) {
        return instance.post(`?route=profile_set&admin=${admin}&id=${id}`, {text})
            .then(response => {
                return response
                
            })
    },
    changePassword(admin, id, password) {
        return instance.post(`?route=profile_set&admin=${admin}&id=${id}`, {password})
            .then(response => {
                return response
                
            })
    },
    remove(admin, id, trash) {
        return instance.post(`?route=profile_set&admin=${admin}&id=${id}`, {trash})
            .then(response => {
                return response
                
            })
    }
}


export const AdminStoreAPI = {
    // STORES
    stores(admin, count, currentPage, viewStatus) {
        return instance.get(`?route=stores&admin=${admin}&count=${count}&page=${currentPage}&view=${viewStatus}`)
            .then(response => {
                return response
                
            })
    },
    store(id, admin) {
        return instance.get(`?route=store&id=${id}&admin=${admin}`)
            .then(response => {
                return response
                
            })
    },
    storeProducts(admin, count, currentPage, store, viewStatus) {
        return instance.get(`?route=products&admin=${admin}&count=${count}&page=${currentPage}&store=${store}&view=${viewStatus}`)
            .then(response => {
                return response
                
            })
    },
    changeStatus(admin, id, status) {
        return instance.post(`?route=store_set&admin=${admin}&id=${id}`, {status})
            .then(response => {
                return response
                
            })
    },
    changeImage(admin, id, imageId, image) {
        const formData = new FormData()
        formData.append('image', image)
        return instance.post(`?route=store_set&admin=${admin}&id=${id}&image_id=${imageId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                return response
                
            })
    },
    imageDelete(admin, id, removeId) {
        return instance.post(`?route=store_set&admin=${admin}&id=${id}`, {removeId})
            .then(response => {
                return response
                
            })
    },
    changeTitle(admin, id, title) {
        return instance.post(`?route=store_set&admin=${admin}&id=${id}`, {title})
            .then(response => {
                return response
                
            })
    },
    changeText(admin, id, text) {
        return instance.post(`?route=store_set&admin=${admin}&id=${id}`, {text})
            .then(response => {
                return response
                
            })
    },
    remove(admin, id, trash) {
        return instance.post(`?route=store_set&admin=${admin}&id=${id}`, {trash})
            .then(response => {
                return response
                
            })
    }
}

export const AdminProductAPI = {
    // PRODUCTS
    products(admin, count, currentPage, viewStatus) {
        return instance.get(`?route=products&admin=${admin}&count=${count}&page=${currentPage}&view=${viewStatus}`)
            .then(response => {
                return response
            })
    },
    product(admin, id) {
        return instance.get(`?route=product&admin=${admin}&id=${id}`)
            .then(response => {
                return response
            })
    },
    changeStatus(admin, id, status) {
        return instance.post(`?route=product_set&admin=${admin}&id=${id}`, {status})
            .then(response => {
                return response
            })
    },
    changeVideo(admin, id, video) {
        const formData = new FormData()
        formData.append('video', video)
        return instance.post(`?route=product_set&admin=${admin}&id=${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                return response
            })
    },
    videoDelete(admin, id, removeVideo) {
        return instance.post(`?route=product_set&admin=${admin}&id=${id}`, {removeVideo})
            .then(response => {
                return response
            })
    },
    changeImage(admin, id, imageId, image) {
        const formData = new FormData()
        formData.append('image', image)
        return instance.post(`?route=product_set&admin=${admin}&id=${id}&image_id=${imageId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                return response
            })
    },
    imageDelete(admin, id, removeId) {
        return instance.post(`?route=product_set&admin=${admin}&id=${id}`, {removeId})
            .then(response => {
                return response
            })
    },
    changeTitle(admin, id, title) {
        return instance.post(`?route=product_set&admin=${admin}&id=${id}`, {title})
            .then(response => {
                return response
            })
    },
    changeText(admin, id, text) {
        return instance.post(`?route=product_set&admin=${admin}&id=${id}`, {text})
            .then(response => {
                return response
            })
    },
    changePrice(admin, id, price) {
        return instance.post(`?route=product_set&admin=${admin}&id=${id}`, {price})
            .then(response => {
                return response
            })
    },
    remove(admin, id, trash) {
        return instance.post(`?route=product_set&admin=${admin}&id=${id}`, {trash})
            .then(response => {
                return response
                
            })
    }
}

export const AdminCommentAPI = {
    // COMMENTS
    comments(admin, count, currentPage, viewStatus) {
        return instance.get(`?route=comments&admin=${admin}&count=${count}&page=${currentPage}&view=${viewStatus}`)
            .then(response => {
                return response
            })
    },
    comment(admin, id) {
        return instance.get(`?route=comment&admin=${admin}&id=${id}`)
            .then(response => {
                return response
            })
    },
    changeStatus(admin, id, status) {
        return instance.post(`?route=comment_set&admin=${admin}&id=${id}`, {status})
            .then(response => {
                return response
            })
    },
    changeText(admin, id, text) {
        return instance.post(`?route=comment_set&admin=${admin}&id=${id}`, {text}) 
            .then(response => {
                return response
            })
    },
    remove(admin, id, trash) {
        return instance.post(`?route=comment_set&admin=${admin}&id=${id}`, {trash})
            .then(response => {
                return response
                
            })
    }
}

