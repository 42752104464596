import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import AccountVerify from '../common/Verify/AccountVerify'

const PhoneVerify = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                <BlockPanel
                    theme={props.theme}
                    icon1={<i className="fas fa-mobile-alt"></i>}
                    title={props.strings.phoneVerifyTitle + "!"}
                />
                <AccountVerify
                    theme={props.theme}
                    strings={props.strings}
                    phone={props.phone}
                />
            </div>
        </div>
    )
}

export default PhoneVerify