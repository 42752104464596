import React from 'react'

const Building = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={7001}>{props.strings.cat7001}</option>
            <option value={7100}>{props.strings.cat7100}</option>
            <option value={7150}>{props.strings.cat7150}</option>
            <option value={7200}>{props.strings.cat7200}</option>
            <option value={7250}>{props.strings.cat7250}</option>
            <option value={7300}>{props.strings.cat7300}</option>
            <option value={7400}>{props.strings.cat7400}</option>
            <option value={7500}>{props.strings.cat7500}</option>
            <option value={7600}>{props.strings.cat7600}</option>
            <option value={7950}>{props.strings.cat7950}</option>
        </>
    )
}

export default Building