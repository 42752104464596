import React from 'react'

const Jobs = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={3001}>{props.strings.cat3001}</option>
            <option value={3002}>{props.strings.cat3002}</option>
        </>
    )
}

export default Jobs