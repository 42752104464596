import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'
import { NavLink } from 'react-router-dom'

const AdvertisingItem = (props) => {
    return (
        <NavLink to={props.to + props.id} >
            <div className={props.theme === 'light' ? light.blockView : dark.blockView} >
                {props.image0
                    ? <img src={props.domain + "/media/reclama/" + props.image0} alt="advertising" />
                    : <img src={props.domain + "/media/reclama/noimgmini.png"} alt="advertising" />
                }
                <div className={props.theme === 'light' ? light.info : dark.info}>

                    <div className={props.theme === 'light' ? light.title : dark.title}>
                        <i className="fas fa-square-poll-horizontal"></i> {props.title}
                    </div>
                    <div className={props.theme === 'light' ? light.small : dark.small} >
                        <i className="fas fa-square-poll-horizontal"></i> {props.subtitle}
                    </div>
                    
                    {props.status
                        ? <div className={props.theme === 'light' ? light.status : dark.status}>
                            {props.strings['status' + props.status]}...
                        </div>
                        : null
                    }

                    {props.phone1
                        ? <div className={props.theme === 'light' ? light.phone : dark.phone}>
                            <i className="fas fa-phone-square"></i> {props.phone1} | {props.phone2 ? props.phone2 : null}
                        </div>
                        : null
                    }
                    
                    <div className={props.theme === 'light' ? light.views : dark.views}>
                        <i className="fas fa-calendar-check"></i> {props.pub}
                    </div>
                </div>
            </div>
        </NavLink>
    )
}

export default AdvertisingItem