import React from 'react'

const CatBuildingSlates  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={7101}>{props.strings.cat7101}</option>
            <option value={7105}>{props.strings.cat7105}</option>
            <option value={7110}>{props.strings.cat7110}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBuildingSlates 