import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    requestAdvertisings,
    setAdvertisings,
    setCurrentPage,
    setViewStatus,
    setSuccess,
    setError,
    setIsFetching
} from '../../redux/adminAdvertisings-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setTrashed } from '../../redux/advertisingSet-reducer'
import AdminAdvertisings from './AdminAdvertisings'

const AdminAdvertisingsContainer= (props) => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const advertisings = useSelector(state => state.adminAdvertisings.advertisings)
    const count = useSelector(state => state.adminAdvertisings.count)
    const totalCount = useSelector(state => state.adminAdvertisings.totalCount)
    const currentPage = useSelector(state => state.adminAdvertisings.currentPage)
    const pagesCount = useSelector(state => state.adminAdvertisings.pagesCount)
    const status = useSelector(state => state.adminAdvertisings.status)
    const success = useSelector(state => state.adminAdvertisings.success)
    const error = useSelector(state => state.adminAdvertisings.error)
    const viewStatus = useSelector(state => state.adminAdvertisings.viewStatus)
    const isFetching = useSelector(state => state.adminAdvertisings.isFetching)
    const back = useSelector(state => state.adminAdvertisings.back)

    const trashed = useSelector(state => state.advertisingSet.trashed)

    const admin = 1

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        }
    }, [navigate, props.isAuth, props.isAdmin])

    useEffect(() => {
        if (isFetching) {
            dispatch(setAdvertisings([]))
            dispatch(requestAdvertisings(admin, count, currentPage, viewStatus))
        }
    }, [dispatch, count, currentPage, viewStatus, isFetching])

    useEffect(() => {
        if (trashed) {
            dispatch(setTrashed(false))
        }
    }, [dispatch, trashed])

    const setStatus = (status) => {
        dispatch(setAdvertisings([]))
        dispatch(setViewStatus(status))
        dispatch(setIsFetching(true))
    }

    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setIsFetching(true))
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <AdminAdvertisings
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            advertisings={advertisings}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            status={status}
            back={back}
            isFetching={isFetching}
            
            onPageChanged={onPageChanged}
            setStatus={setStatus}
        />
    )
}

export default AdminAdvertisingsContainer