import React from 'react'

const RealEstate = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={4001}>{props.strings.cat4001}</option>
            <option value={4100}>{props.strings.cat4100}</option>
        </>
    )
}

export default RealEstate