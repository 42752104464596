import React from 'react'

const CatPharamcyMomAndBaby  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={13801}>{props.strings.cat13801}</option>
            <option value={13810}>{props.strings.cat13810}</option>
            <option value={13820}>{props.strings.cat13820}</option>
            <option value={13830}>{props.strings.cat13830}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatPharamcyMomAndBaby