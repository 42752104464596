import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'

const RatingForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.ratingForm : dark.ratingForm}>
            <div className={props.theme === 'light' ? light.title : dark.title}>
                {props.strings.ratingUp}:
            </div>
            {props.possibleRates.map(rate => (
                    <div
                        key={rate}
                        className={props.theme === 'light'
                            ? light.rate + " "
                                + ((rate <= props.selectedRate) ? light.inRate + " " : "")
                                + ((rate <= props.hoveredRate) ? light.inHover + " " : "")
                            : dark.rate + " "
                                + ((rate <= props.selectedRate) ? dark.inRate + " " : "")
                                + ((rate <= props.hoveredRate) ? dark.inHover + " " : "")
                        }
                        onClick={() => props.onSelectedRate(rate)}
                        onMouseEnter={() => {props.onHoveredRate(rate); props.onSelectedRate(null)}}
                        onMouseLeave={() => props.onHoveredRate(null)}
                    >
                        <i className={"fas fa-star"}></i>
                    </div>
                    )
                )
            }
        </div>
    )
}

export default RatingForm