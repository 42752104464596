import { AdminProfileAPI } from '../api/api'

const SET_UPDATED = 'adminProfileSet/SET_UPDATED'
const SET_TRASHED = 'adminProfileSet/SET_TRASHED'
const SET_SUCCESS = 'adminProfileSet/SET_SUCCESS'
const SET_ERROR = 'adminProfileSet/SET_ERROR'
const IS_FETCHING = 'adminProfileSet/IS_FETCHING'

let initialState = {
    updated: false,
    trashed: false,
    success: null,
    error: null,
    back: true,
    isFetching: false
}

const adminProfileSetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPDATED:
            return { ...state, updated: action.updated }
        case SET_TRASHED:
            return { ...state, trashed: action.trashed }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setUpdated = (updated) => ({ type: SET_UPDATED, updated })
export const setTrashed = (trashed) => ({ type: SET_TRASHED, trashed })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const changeStatus = (admin, id, status) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminProfileAPI.changeStatus(admin, id, status)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeAvatar = (admin, id, imageId, image) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminProfileAPI.changeAvatar(admin, id, imageId, image)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const avatarDelete = (admin, id, removeId) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminProfileAPI.avatarDelete(admin, id, removeId)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeLogin = (admin, id, login) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminProfileAPI.changeLogin(admin, id, login)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeLocation = (admin, id, location, marker) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminProfileAPI.changeLocation(admin, id, location, marker)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeName = (admin, id, name) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminProfileAPI.changeName(admin, id, name)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeBirth = (admin, id, birth) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminProfileAPI.changeBirth(admin, id, birth)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeText = (admin, id, text) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminProfileAPI.changeText(admin, id, text)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changePassword = (admin, id, password) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminProfileAPI.changePassword(admin, id, password)
        dispatch(setUpdated(data.data.updated))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const remove = (auth, id, trash) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminProfileAPI.remove(auth, id, trash)
        dispatch(setTrashed(data.data.trashed))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default adminProfileSetReducer