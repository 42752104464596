import React from 'react'

const CatBuildingHeating  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={7601}>{props.strings.cat7601}</option>
            <option value={7605}>{props.strings.cat7605}</option>
            <option value={7610}>{props.strings.cat7610}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBuildingHeating 