import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    changeLogin,
    changeLocation,
    changeName,
    changeBirth,
    changeText,
    changePassword,
    changeAvatar,
    avatarDelete,
    setSuccess,
    setError,
    remove
} from '../../redux/profileSet-reducer'
import { resizeFile, resizeFileMini } from '../../api/main'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import ProfileSet from './ProfileSet'

const ProfileSetContainer = (props) => {

    window.scrollTo({top: 0, left: 0})
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const success = useSelector(state => state.profileSet.success)
    const error = useSelector(state => state.profileSet.error)
    const back = useSelector(state => state.profileSet.back)
    const isFetching = useSelector(state => state.profileSet.isFetching)

    const auth = 1

    useEffect(() => {
        if (!props.isAuth) {
            navigate('/login')
        } else if (props.profile.status === 2) {
            navigate('/profile')
        } else if (props.profile.status === 0) {
            navigate('/logout')
        }
    }, [navigate, props.isAuth, props.profile.status])

    const onSubmitAvatar = async (e) => {
        const file = e.target.files[0]
        const image0 = await resizeFileMini(file)
        const image = await resizeFile(file)
        const imageMiniId = 1
        const imageId = e.target.attributes.imageid.value
        if (props.profile.avatar0 === null) {
            dispatch(changeAvatar(auth, imageMiniId, image0))
            dispatch(changeAvatar(auth, imageId, image))
        } else {
            dispatch(changeAvatar(auth, imageId, image))
        }
    }

    const onSubmitRemoveAvatar = async (e) => {
        const removeId = e.target.attributes.imageid.value
        dispatch(avatarDelete(auth, removeId))
    }

    const validateLogin = (values) => {
        const errors = {}
        if (!values.login) {
            errors.login = props.strings.required
        } else if (values.login.length < 3) {
            errors.login = props.strings.min + ': 3/' + values.login.length
        } else if (values.login.length > 50) {
            errors.login = props.strings.max + ': 50/' + values.login.length
        } else if (!/^[A-Za-z0-9_]{3,50}$/i.test(values.login)) {
            errors.login = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (_), ' + props.strings.strings + ' (A-Z, a-z).'
        }
        return errors
    }

    const onSubmitLogin = (values, { setSubmitting }) => {
        const login = values.login
        dispatch(changeLogin(auth, login))
        setSubmitting(false)
    }

    const validateLocation = (values) => {
        const errors = {}
        if (!values.location) {
            errors.location = props.strings.required
        } else if (values.location === '0') {
            errors.location = props.strings.required
        }

        if (!values.marker) {
            if (!values.marker) {
                errors.marker = props.strings.required
            } else if (values.marker === '0') {
                errors.marker = props.strings.required
            }
        }
        return errors
    }

    const onSubmitLocation = (values, { setSubmitting }) => {
        const location = values.location
        const marker = values.marker
        dispatch(changeLocation(auth, location, marker))
        setSubmitting(false)
    }
    
    const validateName= (values) => {
        const errors = {}
        if (!values.name) {
            errors.name = props.strings.required
        } else if (values.name.length < 3) {
            errors.name = props.strings.min + ': 3/' + values.name.length
        } else if (values.name.length > 50) {
            errors.name = props.strings.max + ': 50/' + values.name.length
        } else if (!/^[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я\s]{3,50}$/i.test(values.name)) {
            errors.name = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'
        }
        return errors
    }

    const onSubmitName = (values, { setSubmitting }) => {
        const name = values.name
        dispatch(changeName(auth, name))
        setSubmitting(false)
    }
        
    const validateBirth= (values) => {
        const errors = {}
        if (!values.birth) {
            errors.birth = props.strings.required
        }
        return errors
    }

    const onSubmitBirth = (values, { setSubmitting }) => {
        const birth = values.birth
        dispatch(changeBirth(auth, birth))
        setSubmitting(false)
    }
            
    const validateText= (values) => {
        const errors = {}
        if (!values.text) {
            errors.text = props.strings.required
        } else if (values.text.length < 10) {
            errors.text = props.strings.min + ': 10/' + values.text.length
        } else if (values.text.length > 1500) {
            errors.text = props.strings.max + ': 1500/' + values.text.length
        }
        return errors
    }

    const onSubmitText = (values, { setSubmitting }) => {
        const text = values.text
        dispatch(changeText(auth, text))
        setSubmitting(false)
    }

    const validatePassword= (values) => {
        const errors = {}
        if (!values.password) {
            errors.password = props.strings.required
        } else if (values.password.length < 8) {
            errors.password = props.strings.min + ': 8/' + values.password.length
        } else if (values.password.length > 20) {
            errors.password = props.strings.max + ': 20/' + values.password.length
        } else if (!/^[A-Za-z0-9]{8,20}$/i.test(values.password)) {
            errors.password = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.strings + ' (A-Z, a-z).'
        }
    
        if (!values.password2) {
            errors.password2 = props.strings.required
        } else if (values.password2 !== values.password) {
            errors.password2 = props.strings.error + '. ' + props.strings.passwordRepeatErr
        }
        return errors
    }

    const onSubmitPassword = (values, { setSubmitting }) => {
        const password = values.password
        dispatch(changePassword(auth, password))
        setSubmitting(false)
    }

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(auth, trash))
        navigate('/login')
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, success, error]
    )

    return (
        <ProfileSet
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            back={back}
            isFetching={isFetching}

            validateLogin={validateLogin}
            validateLocation={validateLocation}
            validateName={validateName}
            validateBirth={validateBirth}
            validateText={validateText}
            validatePassword={validatePassword}

            onSubmitAvatar={onSubmitAvatar}
            onSubmitRemoveAvatar={onSubmitRemoveAvatar}
            onSubmitLogin={onSubmitLogin}
            onSubmitLocation={onSubmitLocation}
            onSubmitName={onSubmitName}
            onSubmitBirth={onSubmitBirth}
            onSubmitText={onSubmitText}
            onSubmitPassword={onSubmitPassword}
            onSubmitTrash={onSubmitTrash}
            navigate={navigate}
        />
    )
}

export default ProfileSetContainer