import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    requestAdmins,
    setCurrentPage,
    setSuccess,
    setError,
    setIsFetching
} from '../../redux/adminAdmins-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import AdminAdmins from './AdminAdmins'

const AdminAdminsContainer= (props) => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const admins = useSelector(state => state.adminAdmins.admins)

    const count = useSelector(state => state.adminAdmins.count)
    const totalCount = useSelector(state => state.adminAdmins.totalCount)
    const currentPage = useSelector(state => state.adminAdmins.currentPage)
    const pagesCount = useSelector(state => state.adminAdmins.pagesCount)

    const status = useSelector(state => state.adminAdmins.status)
    const success = useSelector(state => state.adminAdmins.success)
    const error = useSelector(state => state.adminAdmins.error)
    const isFetching = useSelector(state => state.adminAdmins.isFetching)
    const back = useSelector(state => state.adminAdmins.back)

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        }
    }, [navigate, props.isAuth, props.isAdmin])

    
    useEffect(() => {
        if (isFetching) {
            dispatch(requestAdmins())
        }
    }, [dispatch, isFetching])

    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setIsFetching(true))
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <AdminAdmins
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            admins={admins}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            status={status}
            back={back}
            isFetching={isFetching}

            onPageChanged={onPageChanged}
        />
    )
}

export default AdminAdminsContainer