import React from 'react'

const CatAnimalsEquipment  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={18301}>{props.strings.cat18301}</option>
            <option value={18303}>{props.strings.cat18303}</option>
            <option value={18305}>{props.strings.cat18305}</option>
            <option value={18307}>{props.strings.cat18307}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatAnimalsEquipment