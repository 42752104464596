import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import SuccessMax from '../common/Success/SuccessMax'

const AdminLogoutContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                <SuccessMax
                    theme={props.theme}
                    icon={<i className="far fa-smile"></i>}
                    success={props.strings.success102 + "!"}
                />
            </div>
        </div>
    )
}

export default AdminLogoutContent