import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import StoreItem from '../Items/Item/StoreItem'
import Paginator from '../common/Paginator/Paginator'
import Menu from '../Items/Menu/Menu'
import SuccessAverage from '../common/Success/SuccessAverage'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'

const FavoriteStoresContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                <BlockPanel
                    theme={props.theme}
                    iconLeft={<i className="fas fa-chevron-left"></i>}
                    leftLink={"/profile"}
                    icon1={<i className="fas fa-store-alt"></i>}
                    icon2={<i className="fas fa-thumbs-up"></i>}
                    title={props.strings.favorite}
                />
                {props.isFetching
                    ? <PreloaderMini />
                    : !props.stores
                        ? <SuccessAverage
                            theme={props.theme}
                            icon={<i className="fas fa-store-alt"></i>}
                            success={props.strings['status' + props.storesStatus] + "!"}
                        />
                        : <>
                            <Paginator
                                theme={props.theme}
                                count={props.count}
                                totalCount={props.totalCount}
                                currentPage={props.currentPage}
                                pagesCount={props.pagesCount}
                                onPageChanged={props.onPageChanged}
                            />

                            {props.stores.map(
                                s => <StoreItem
                                        key={s.id}
                                        domain={props.domain}
                                        theme={props.theme}
                                        strings={props.strings}
                                        to={'/stores/'}
                                        id={s.id}
                                        image0={s.image0}
                                        title={s.title}
                                        location={s.location}
                                        marker={s.marker}
                                        phone={s.phone}
                                        text={s.text}
                                        rating={s.rating}
                                        ratingCount={s.ratingCount}
                                    />
                                )
                            }

                            <Paginator
                                theme={props.theme}
                                count={props.count}
                                totalCount={props.totalCount}
                                currentPage={props.currentPage}
                                pagesCount={props.pagesCount}
                                onPageChanged={props.onPageChanged}
                            />
                        </>
                }
            </div>
        </div>
    )
}

export default FavoriteStoresContent