import React from 'react'

const CatPharamcyOrthopedicProducts  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={13701}>{props.strings.cat13701}</option>
            <option value={13710}>{props.strings.cat13710}</option>
            <option value={13720}>{props.strings.cat13720}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatPharamcyOrthopedicProducts