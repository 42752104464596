import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import Slider from '../Items/Slider/Slider'
import SuccessMax from '../common/Success/SuccessMax'
import AdvertisingFull from '../Items/FullItem/AdvertisingFull'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'

const AdvertisingContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                {props.isFetching
                    ? <PreloaderMini />
                    : !props.advertising
                        ? <>
                            <Menu
                                theme={props.theme}
                                back={props.back}
                            />

                            {props.status
                                ? <SuccessMax
                                    theme={props.theme}
                                    icon={<i className="fas fa-square-poll-horizontal"></i>}
                                    success={props.strings['status' + props.status] + "!"}
                                />
                                : null
                            }
                        </>
                        : <>
                            <Menu
                                theme={props.theme}
                                back={props.back}
                                mail={props.advertising.mail}
                                instagram={props.advertising.instagram}
                                imo={props.advertising.imo}
                                link={props.advertising.link}
                                href={props.advertising.href}
                            />

                            <Slider
                                imageSource={props.domain + "/media/reclama/"}
                                imageAlt={"advertising"}
                                images={props.images}
                                activeIndex={props.activeIndex}
                                setActiveIndex={props.setActiveIndex}
                                crope={props.crope}
                                isPhotosCrope={props.isPhotosCrope}
                            />
                            
                            <AdvertisingFull
                                theme={props.theme}
                                strings={props.strings}
                                id={props.advertising.id}
                                title={props.advertising.title}
                                subtitle={props.advertising.subtitle}
                                text={props.advertising.text}
                                phone1={props.advertising.phone1}
                                phone2={props.advertising.phone2}
                                pub={props.advertising.pub}
                            />
                        </>
                }

            </div>
        </div>
    )
}

export default AdvertisingContent