import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Preloader from '../common/Preloader/Preloader'
import Sitebar from '../Sitebars/Sitebar'
import AdminProductContent from './AdminProductContent'

const AdminProduct= (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                {props.isFetching
                    ? <Preloader />
                    : null
                }
                <AdminProductContent
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}

                    profile={props.profile}

                    product={props.product}
                    images={props.images}
                    activeIndex={props.activeIndex}
                    setActiveIndex={props.setActiveIndex}
                    isPhotosCrope={props.isPhotosCrope}
                    crope={props.crope}
                    store={props.store}
                    ostProfile={props.ostProfile}
                    productStatus={props.productStatus}
                    back={props.back}
                    productIsFetching={props.productIsFetching}
        
                    favoriteCount={props.favoriteCount}
            
                    isAdmin={props.isAdmin}
                    comments={props.comments}
                    count={props.count}
                    totalCount={props.totalCount}
                    currentPage={props.currentPage}
                    pagesCount={props.pagesCount}
                    commentsStatus={props.commentsStatus}
                    commentsIsFetching={props.commentsIsFetching}
        
                    onPageChanged={props.onPageChanged}
                    
                    onSubmitView={props.onSubmitView}
                    onSubmitTrash={props.onSubmitTrash}
                />
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    theme={props.theme}
                    strings={props.strings}
                    sitebarActivate={props.sitebarActivate}

                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default AdminProduct