import React from 'react'

const CatBeautyEyebrows  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12111}>{props.strings.cat12111}</option>
            <option value={12124}>{props.strings.cat12124}</option>
            <option value={12133}>{props.strings.cat12133}</option>
            <option value={12157}>{props.strings.cat12157}</option>
            <option value={12159}>{props.strings.cat12159}</option>
            <option value={12166}>{props.strings.cat12166}</option>
            <option value={12169}>{props.strings.cat12169}</option>
            <option value={12172}>{props.strings.cat12172}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyEyebrows