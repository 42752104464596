import React from 'react'

const CatFoodsMeatProducts  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6201}>{props.strings.cat6201}</option>
            <option value={6210}>{props.strings.cat6210}</option>
            <option value={6220}>{props.strings.cat6220}</option>
            <option value={6230}>{props.strings.cat6230}</option>
            <option value={6240}>{props.strings.cat6240}</option>
            <option value={6250}>{props.strings.cat6250}</option>
            <option value={6260}>{props.strings.cat6260}</option>
            <option value={6270}>{props.strings.cat6270}</option>
            <option value={6280}>{props.strings.cat6280}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsMeatProducts 