import React from 'react'

const CatRecreationTourismGoods  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={14003}>{props.strings.cat14003}</option>
            <option value={14005}>{props.strings.cat14005}</option>
            <option value={14007}>{props.strings.cat14007}</option>
            <option value={14010}>{props.strings.cat14010}</option>
            <option value={14013}>{props.strings.cat14013}</option>
            <option value={14015}>{props.strings.cat14015}</option>
            <option value={14017}>{props.strings.cat14017}</option>
            <option value={14020}>{props.strings.cat14020}</option>
            <option value={14023}>{props.strings.cat14023}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatRecreationTourismGoods