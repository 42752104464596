import React from 'react'

const CatAgricultureTechnique  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={17101}>{props.strings.cat17101}</option>
            <option value={17103}>{props.strings.cat17103}</option>
            <option value={17105}>{props.strings.cat17105}</option>
            <option value={17107}>{props.strings.cat17107}</option>
            <option value={17110}>{props.strings.cat17110}</option>
            <option value={17113}>{props.strings.cat17113}</option>
            <option value={17115}>{props.strings.cat17115}</option>
            <option value={17117}>{props.strings.cat17117}</option>
            <option value={17120}>{props.strings.cat17120}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatAgricultureTechnique