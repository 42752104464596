import React from 'react'

const CatHomeOfficeKitchenGoods  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={8201}>{props.strings.cat8201}</option>
            <option value={8202}>{props.strings.cat8202}</option>
            <option value={8203}>{props.strings.cat8203}</option>
            <option value={8204}>{props.strings.cat8204}</option>
            <option value={8205}>{props.strings.cat8205}</option>
            <option value={8206}>{props.strings.cat8206}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatHomeOfficeKitchenGoods 