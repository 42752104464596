import React from 'react'
import dark from './PaginatorDark.module.css'
import light from './PaginatorLight.module.css'

const Paginator = ({ theme, currentPage, pagesCount, onPageChanged }) => {
	const pageNumbers = []
	for (let i = 1; i <= pagesCount; i++) {
		pageNumbers.push(i)
	}
	return (
		<div className={theme === 'light' ? light.paginator : dark.paginator}>
			<div className={theme === 'light' ? light.items : dark.items}>

				{currentPage > 2
					? <div className={theme === 'light' ? light.item : dark.item} onClick={() => onPageChanged(1)}>
						<span>
							<i className="fas fa-fast-backward"></i>
						</span>
					</div>
					: null
				}

				{currentPage > 1
					? <div className={theme === 'light' ? light.item : dark.item} onClick={() => onPageChanged(currentPage - 1)}>
						<span>
							<i className="fas fa-step-backward"></i>
						</span>
					</div>
					: null
				}

				{currentPage > 2
					? <div className={theme === 'light' ? light.item : dark.item} onClick={() => onPageChanged(currentPage - 2)}>
						<span>
							{currentPage - 2}
						</span>
					</div>
					: null
				}

				{currentPage > 1
					? <div className={theme === 'light' ? light.item : dark.item} onClick={() => onPageChanged(currentPage - 1)}>
						<span>
							{currentPage - 1}
						</span>
					</div>
					: null
				}

				<div className={theme === 'light' ? light.item + " " + light.active : dark.item + " " + dark.active}>
					<span>
						{currentPage}
					</span>
				</div>

				{currentPage < pagesCount
					? <div className={theme === 'light' ? light.item : dark.item} onClick={() => onPageChanged(currentPage + 1)}>
						<span>
							{currentPage + 1}
						</span>
					</div>
					: null
				}

				{currentPage < (pagesCount - 1)
					? <div className={theme === 'light' ? light.item : dark.item} onClick={() => onPageChanged(currentPage + 2)}>
						<span>
							{currentPage + 2}
						</span>
					</div>
					: null
				}

				{currentPage < pagesCount
					? <div  className={theme === 'light' ? light.item : dark.item} onClick={() => onPageChanged(currentPage + 1)}>
						<span>
							<i className="fas fa-step-forward"></i>
						</span>
					</div>
					: null
				}

				{currentPage < (pagesCount - 1)
					? <div  className={theme === 'light' ? light.item : dark.item} onClick={() => onPageChanged(pagesCount)}>
						<span>
							<i className="fas fa-fast-forward"></i>
						</span>
					</div>
					: null
				}

			</div>
		</div>
	)
}

export default Paginator