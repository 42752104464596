import React from 'react'
import BlockPanel from '../../common/BlockPanel/BlockPanel'
import Info from '../../common/Info/Info'
import Success from '../../common/Success/Success'
import ImageItem from '../../Items/Item/ImageItem'

const SetImage = (props) => {
    return (
        <>
            <BlockPanel
                theme={props.theme}
                iconLeft={<i className="fas fa-chevron-left"></i>}
                leftLink={"/admin/stores/" + props.store.id + "/settings"}
                icon1={<i className="far fa-play-circle"></i>}
                title={props.strings.media}
            />
            
            <Info
                theme={props.theme}
                icon={<i className="fas fa-circle-info"></i>}
                info={props.strings.imageI}
                requestI={props.strings.requestI}
            />
            { props.store.image0 !== null || props.store.image1 !== null
                ? <Success
                    theme={props.theme}
                    icon={<i className="fas fa-expand"></i>}
                    success={props.strings.mainImage}
                />
                : null
            }

            { props.store.image0 !== null || props.store.image1 !== null
                ? props.store.image0 !== null
                    ? <ImageItem
                        domain={props.domain}
                        theme={props.theme}
                        title={props.strings.update+":"}
                        src={props.domain + "/media/store/" + props.store.image0}
                        alt={"store"}
                        changeImage={props.changeImage}
                        removeImage={props.removeImage}
                        disabled={false}
                        imageId={1}
                    />
                    : <ImageItem
                        domain={props.domain}
                        theme={props.theme}
                        title={props.strings.select+":"}
                        src={props.domain + "/media/store/noimgmini.png"}
                        alt={"store"}
                        changeImage={props.changeImage}
                        removeImage={props.removeImage}
                        disabled={true}
                        imageId={1}
                    />
                : null
            }

            { props.store.image1 !== null
                ? <>
                    <Success
                        theme={props.theme}
                        icon={<i className="fas fa-images"></i>}
                        success={props.strings.images}
                    />
                    <ImageItem
                        domain={props.domain}
                        theme={props.theme}
                        title={props.strings.update + ":"}
                        src={props.domain + "/media/store/" + props.store.image1}
                        alt={"store"}
                        changeImage={props.changeImage}
                        removeImage={props.removeImage}
                        disabled={false}
                        imageId={2}
                    />
                </>
                : <>
                    <Success
                        theme={props.theme}
                        icon={<i className="fas fa-image"></i>}
                        success={props.strings.newImage}
                    />
                    <ImageItem
                        domain={props.domain}
                        theme={props.theme}
                        title={props.strings.select + ":"}
                        src={props.domain + "/media/store/noimgmini.png"}
                        alt={"store"}
                        changeImage={props.changeImage}
                        removeImage={props.removeImage}
                        disabled={true}
                        imageId={2}
                    />
                </>
            }
        </>
    )
}

export default SetImage