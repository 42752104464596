import React from 'react'

const CatToolsHydrolic  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={1010}>{props.strings.cat1010}</option>
            <option value={1020}>{props.strings.cat1020}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatToolsHydrolic 