import React from 'react'
import dark from './TPDark.module.css'
import light from './TPLight.module.css'

const TrashPanel = (props) => {
    return (
        <div className={props.theme === 'light' ? light.trashPanel : dark.trashPanel}>
            <button
                className={
                    props.theme === 'light'
                        ? light.trashButton + ' ' + light.delete
                        : dark.trashButton + ' ' + dark.delete
                }
                onClick={props.onSubmitTrash}
            >
                <i className="fas fa-trash"></i>
            </button>
            <button
                className={
                    props.theme === 'light'
                        ? light.trashButton + ' ' + light.cancel
                        : dark.trashButton + ' ' + dark.cancel
                }
                onClick={() => {props.navigate(-1)}}
            >
                <i className="fas fa-xmark"></i>
            </button>
        </div>
    )
}

export default TrashPanel