import React from 'react'

const CatBeautyNails  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12120}>{props.strings.cat12120}</option>
            <option value={12134}>{props.strings.cat12134}</option>
            <option value={12138}>{props.strings.cat12138}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyNails