import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import SignupForm from '../common/Forms/SignupForm'

const SignupContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>

                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                
                <BlockPanel
                    theme={props.theme}
                    icon1={<i className="fas fa-user-plus"></i>}
                    title={props.strings.signup + "!"}
                />

                <div className={props.theme === 'light' ? light.formContainer : dark.formContainer}>
                    <h2><i className="fas fa-pencil-alt"></i> {props.strings.newAccount}.</h2>
                    <SignupForm
                        theme={props.theme}
                        strings={props.strings}
                        
                        initialValues={props.initialValues}
                        validate={props.validate}
                        onSubmit={props.onSubmit}
                    />
                </div>
            </div>
        </div>
    )
}

export default SignupContent