import React from 'react'

const CatHomeOfficeKitchenAppliances  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={8251}>{props.strings.cat8251}</option>
            <option value={8252}>{props.strings.cat8252}</option>
            <option value={8253}>{props.strings.cat8253}</option>
            <option value={8254}>{props.strings.cat8254}</option>
            <option value={8255}>{props.strings.cat8255}</option>
            <option value={8256}>{props.strings.cat8256}</option>
            <option value={8257}>{props.strings.cat8257}</option>
            <option value={8258}>{props.strings.cat8258}</option>
            <option value={8259}>{props.strings.cat8259}</option>
            <option value={8260}>{props.strings.cat8260}</option>
            <option value={8261}>{props.strings.cat8261}</option>
            <option value={8262}>{props.strings.cat8262}</option>
            <option value={8263}>{props.strings.cat8263}</option>
            <option value={8264}>{props.strings.cat8264}</option>
            <option value={8265}>{props.strings.cat8265}</option>
            <option value={8266}>{props.strings.cat8266}</option>
            <option value={8267}>{props.strings.cat8267}</option>
            <option value={8268}>{props.strings.cat8268}</option>
            <option value={8269}>{props.strings.cat8269}</option>
            <option value={8270}>{props.strings.cat8270}</option>
            <option value={8271}>{props.strings.cat8271}</option>
            <option value={8272}>{props.strings.cat8272}</option>
            <option value={8273}>{props.strings.cat8273}</option>
            <option value={8274}>{props.strings.cat8274}</option>
            <option value={8275}>{props.strings.cat8275}</option>
            <option value={8276}>{props.strings.cat8276}</option>
            <option value={8277}>{props.strings.cat8277}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatHomeOfficeKitchenAppliances 