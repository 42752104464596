import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    requestProduct,
    setImages,
    setActiveIndex,
    setProduct,
    setStore,
    setIsPhotosCrope,
    setSuccess as setProductSuccess,
    setError as setProductError
} from '../../redux/product-reducer'
import {
    requestComments,
    setComments,
    setCurrentPage,
    setSuccess as setCommentsSuccess,
    setError as setCommentsError,
    setIsFetching as setCommentsIsFetching
} from '../../redux/comments-reducer'
import { setIsFetching } from '../../redux/profileComments-reducer'
import {
    addComment,
    setSuccess as setAddCommentSuccess,
    setError as setAddCommentError,
    setAccess
} from '../../redux/addComment-reducer'
import {
    requestFavorite,
    setProfileFavorite,
    setFavoriteCount,
    setSuccess as setFavoriteSuccess,
    setError as setFavoriteError,
    setIsFetching as setFavoriteIsFetching
} from '../../redux/favorite-reducer'
import {
    addFavorite,
    setSuccess as setAddFavoriteSuccess,
    setError as setAddFavoriteError,
    setIsFetching as setAddFavoriteIsFetching
} from '../../redux/addFavorite-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import Product from './Product'

const ProductContainer = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const params = useParams()

    const product = useSelector(state => state.product.product)
    const images = useSelector(state => state.product.images)
    const activeIndex = useSelector(state => state.product.activeIndex)
    const isPhotosCrope = useSelector(state => state.product.isPhotosCrope)
    const store = useSelector(state => state.product.store)
    const productStatus = useSelector(state => state.product.status)
    const productSuccess = useSelector(state => state.product.success)
    const productError = useSelector(state => state.product.error)
    const back = useSelector(state => state.product.back)
    const productIsFetching = useSelector(state => state.product.isFetching)
    
    const profileFavorite = useSelector(state => state.favorite.profileFavorite)
    const favoriteCount = useSelector(state => state.favorite.favoriteCount)
    const favoriteSuccess = useSelector(state => state.favorite.success)
    const favoriteError = useSelector(state => state.favorite.error)
    const favoriteIsFetching = useSelector(state => state.favorite.isFetching)

    const comments = useSelector(state => state.comments.comments)
    const count = useSelector(state => state.comments.count)
    const totalCount = useSelector(state => state.comments.totalCount)
    const currentPage = useSelector(state => state.comments.currentPage)
    const pagesCount = useSelector(state => state.comments.pagesCount)
    const commentsStatus = useSelector(state => state.comments.status)
    const commentsSuccess = useSelector(state => state.comments.success)
    const commentsError = useSelector(state => state.comments.error)
    const commentsIsFetching = useSelector(state => state.comments.isFetching)

    const addCommentAccess = useSelector(state => state.addComment.access)
    const addCommentSuccess = useSelector(state => state.addComment.success)
    const addCommentError = useSelector(state => state.addComment.error)

    const addFavoriteSuccess = useSelector(state => state.addFavorite.success)
    const addFavoriteError = useSelector(state => state.addFavorite.error)

    useEffect(() => {
        if (params.productId) {
            dispatch(setImages([]))
            dispatch(setActiveIndex(0))
            dispatch(setProduct([]))
            dispatch(setStore([]))
            dispatch(setCurrentPage(1))
            dispatch(requestProduct(params.productId))
            
            window.scrollTo({top: 0, left: 0})
    
        } else {
            navigate('/products')
        }
    }, [dispatch, navigate, params.productId])

    useEffect(() => {
        if (params.productId) {
            dispatch(setProfileFavorite(null))
            dispatch(setFavoriteCount(null))
            dispatch(requestFavorite(params.productId))
            dispatch(setAddFavoriteIsFetching(false))
            dispatch(setFavoriteIsFetching(false))
        }
    }, [dispatch, params.productId, favoriteIsFetching])

    useEffect(() => {
        if (params.productId || addCommentAccess) {
            dispatch(setComments([]))
            dispatch(requestComments(currentPage, count, params.productId))
            dispatch(setAccess(false))
        }
    }, [dispatch, params.productId, addCommentAccess, count, currentPage])

    const leftClick = () => {
        navigate(-1)
    }

    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setCommentsIsFetching(true))
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    const crope = () => {
        if(isPhotosCrope) {
            dispatch(setIsPhotosCrope(false))
        } else {
            dispatch(setIsPhotosCrope(true))
        }
    }

    const onSubmitFavorite = () => {
        dispatch(addFavorite(product.id))
        dispatch(setFavoriteIsFetching(true))
    }

    const initialValues = {
        text: ''
    }

    const validate = (values) => {
        const errors = {}
        if (!values.text) {
            errors.text = props.strings.required
        } else if (values.text.length < 10) {
            errors.text = props.strings.min + ': 10/' + values.text.length
        } else if (values.text.length > 1500) {
            errors.text = props.strings.max + ': 1500/' + values.text.length
        }
        return errors;
    }

    const onSubmit = (values, { setSubmitting }) => {
        const id = params.productId
        const text = values.text
        dispatch(addComment(id, text))
        setSubmitting(false)
        dispatch(setIsFetching(true))
    }

    useEffect(
        () => {
            if(productError){
                dispatch(setNotifyError(productError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setProductError(null))
            } else if(favoriteError){
                dispatch(setNotifyError(favoriteError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setFavoriteError(null))
            } else if(commentsError){
                dispatch(setNotifyError(commentsError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setCommentsError(null))
            } else if(addCommentError){
                dispatch(setNotifyError(addCommentError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setAddCommentError(null))
            } else if(addFavoriteError){
                dispatch(setNotifyError(addFavoriteError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setAddFavoriteError(null))
            } else if(productSuccess){
                dispatch(setNotifySuccess(productSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setProductSuccess(null))
            } else if(favoriteSuccess){
                dispatch(setNotifySuccess(favoriteSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setFavoriteSuccess(null))
            } else if(commentsSuccess){
                dispatch(setNotifySuccess(commentsSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setCommentsSuccess(null))
            } else if(addCommentSuccess){
                dispatch(setNotifySuccess(addCommentSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setAddCommentSuccess(null))
            } else if(addFavoriteSuccess){
                dispatch(setNotifySuccess(addFavoriteSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setAddFavoriteSuccess(null))
            }
        }, [
            dispatch,
            productError,
            favoriteError,
            commentsError,
            addFavoriteError,
            addCommentError,
            productSuccess,
            favoriteSuccess,
            commentsSuccess,
            addCommentSuccess,
            addFavoriteSuccess
        ]
    )

    return (
        <Product
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            advertisingsSitebar={props.advertisingsSitebar}

            product={product}
            images={images}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            isPhotosCrope={isPhotosCrope}
            crope={crope}
            store={store}
            productStatus={productStatus}
            back={back}
            productIsFetching={productIsFetching}
            
            profileFavorite={profileFavorite}
            favoriteCount={favoriteCount}

            comments={comments}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            onPageChanged={onPageChanged}
            commentsStatus={commentsStatus}
            commentsIsFetching={commentsIsFetching}

            leftClick={leftClick}
            onSubmitFavorite={onSubmitFavorite}
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
        />
    )
}

export default ProductContainer