import React from 'react'

const CatBuildingSanNode  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={7501}>{props.strings.cat7501}</option>
            <option value={7505}>{props.strings.cat7505}</option>
            <option value={7510}>{props.strings.cat7510}</option>
            <option value={7515}>{props.strings.cat7515}</option>
            <option value={7520}>{props.strings.cat7520}</option>
            <option value={7525}>{props.strings.cat7525}</option>
            <option value={7540}>{props.strings.cat7540}</option>
            <option value={7545}>{props.strings.cat7545}</option>
            <option value={7550}>{props.strings.cat7550}</option>
            <option value={7555}>{props.strings.cat7555}</option>
            <option value={7560}>{props.strings.cat7560}</option>
            <option value={7565}>{props.strings.cat7565}</option>
            <option value={7570}>{props.strings.cat7570}</option>
            <option value={7575}>{props.strings.cat7575}</option>
            <option value={7580}>{props.strings.cat7580}</option>
            <option value={7585}>{props.strings.cat7585}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBuildingSanNode 