import React from 'react'

const Lebap = (props) => {
    return (
        <>
            <option value={null}>{props.strings.marker}...</option>
            <option value={36}>{props.strings.loc36}</option>
            <option value={37}>{props.strings.loc37}</option>
            <option value={38}>{props.strings.loc38}</option>
            <option value={39}>{props.strings.loc39}</option>
            <option value={40}>{props.strings.loc40}</option>
            <option value={41}>{props.strings.loc41}</option>
            <option value={42}>{props.strings.loc42}</option>
            <option value={43}>{props.strings.loc43}</option>
            <option value={44}>{props.strings.loc44}</option>
            <option value={45}>{props.strings.loc45}</option>
            <option value={46}>{props.strings.loc46}</option>
            <option value={47}>{props.strings.loc47}</option>
            <option value={48}>{props.strings.loc48}</option>
            <option value={49}>{props.strings.loc49}</option>
            <option value={50}>{props.strings.loc50}</option>
            <option value={51}>{props.strings.loc51}</option>
        </>
    )
}

export default Lebap