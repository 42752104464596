import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import CreateStoreForm from '../common/Forms/CreateStoreForm'

const CreateStoreContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                <BlockPanel
                    theme={props.theme}
                    iconLeft={<i className="fas fa-chevron-left"></i>}
                    leftLink={"/profile"}
                    icon1={<i className="fas fa-store-alt"></i>}
                    title={"+ " + props.strings.createStore + "!"}
                />
                <div className={props.theme === 'light' ? light.formContainer : dark.formContainer}>
                    <h2><i className="fas fa-pencil-alt"></i> {props.strings.newStore}.</h2>
                    <CreateStoreForm
                        theme={props.theme}
                        strings={props.strings}
                        
                        validate={props.validate}
                        onSubmit={props.onSubmit}
                    />
                </div>
            </div>
        </div>
    )
}

export default CreateStoreContent