import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'

const Rating = (props) => {
    return (
        <div className={props.theme === 'light' ? light.ratingForm : dark.ratingForm}>
            <div className={props.theme === 'light' ? light.title : dark.title}>
                {props.strings.rate}:
            </div>
            {props.possibleRates.map(rate => (
                    <div
                        key={rate}
                        className={props.theme === 'light'
                            ? light.rate + " "
                                + ((rate <= props.profileRating) ? light.inRate + " " : "")
                            : dark.rate + " "
                                + ((rate <= props.profileRating) ? dark.inRate + " " : "")
                        }
                    >
                        <i className={"fas fa-star"}></i>
                    </div>
                    )
                )
            }
            <div className={props.theme === 'light' ? light.change : dark.change}>
                <button
                    className={props.theme === 'light' ? light.changeButton : dark.changeButton}
                    onClick={() => {props.changeRate()}}
                >
                    {props.strings.change}
                </button>
            </div>
        </div>
    )
}

export default Rating