import React from 'react'
import BlockPanel from '../../common/BlockPanel/BlockPanel'
import Info from '../../common/Info/Info'
import Success from '../../common/Success/Success'
import ImageItem from '../../Items/Item/ImageItem'

const SetImage = (props) => {
    return (
        <>
            <BlockPanel
                theme={props.theme}
                iconLeft={<i className="fas fa-chevron-left"></i>}
                leftLink={"/mystore/products/" + props.product.id + "/settings"}
                icon1={<i className="far fa-play-circle"></i>}
                title={props.strings.media}
            />
            
            <Info
                theme={props.theme}
                icon={<i className="fas fa-circle-info"></i>}
                info={props.strings.imageI}
                requestI={props.strings.requestI}
            />
            
            { props.product.image0 !== null || props.product.image1 !== null || props.product.image2 !== null || props.product.image3 !== null || props.product.image4 !== null || props.product.image5 !== null
                ? <Success
                    theme={props.theme}
                    icon={<i className="fas fa-expand"></i>}
                    success={props.strings.mainImage}
                />
                : null
            }

            { props.product.image0 !== null || props.product.image1 !== null || props.product.image2 !== null || props.product.image3 !== null || props.product.image4 !== null || props.product.image5 !== null
                ? props.product.image0 !== null
                    ? <ImageItem
                        domain={props.domain}
                        theme={props.theme}
                        title={props.strings.update+":"}
                        src={props.domain + "/media/img/" + props.product.image0}
                        alt={"product"}
                        changeImage={props.changeImage}
                        removeImage={props.removeImage}
                        disabled={false}
                        imageId={1}
                    />
                    : <ImageItem
                        domain={props.domain}
                        theme={props.theme}
                        title={props.strings.select+":"}
                        src={props.domain + "/media/img/noimgmini.png"}
                        alt={"product"}
                        changeImage={props.changeImage}
                        removeImage={props.removeImage}
                        disabled={true}
                        imageId={1}
                    />
                : null
            }

            { props.product.image1 !== null || props.product.image2 !== null || props.product.image3 !== null || props.product.image4 !== null || props.product.image5 !== null
                ? <Success
                    theme={props.theme}
                    icon={<i className="fas fa-images"></i>}
                    success={props.strings.images}
                />
                : null
            }

            { props.product.image1 !== null
                ? <ImageItem
                    domain={props.domain}
                    theme={props.theme}
                    title={props.strings.update + ":"}
                    src={props.domain + "/media/img/" + props.product.image1}
                    alt={"product"}
                    changeImage={props.changeImage}
                    removeImage={props.removeImage}
                    disabled={false}
                    imageId={2}
                />
                : null
            }
            { props.product.image2 !== null
                ? <ImageItem
                    domain={props.domain}
                    theme={props.theme}
                    title={props.strings.update + ":"}
                    src={props.domain + "/media/img/" + props.product.image2}
                    alt={"product"}
                    changeImage={props.changeImage}
                    removeImage={props.removeImage}
                    disabled={false}
                    imageId={3}
                />
                : null
            }
            { props.product.image3 !== null
                ? <ImageItem
                    domain={props.domain}
                    theme={props.theme}
                    title={props.strings.update + ":"}
                    src={props.domain + "/media/img/" + props.product.image3}
                    alt={"product"}
                    changeImage={props.changeImage}
                    removeImage={props.removeImage}
                    disabled={false}
                    imageId={4}
                />
                : null
            }
            { props.product.image4 !== null
                ? <ImageItem
                    domain={props.domain}
                    theme={props.theme}
                    title={props.strings.update + ":"}
                    src={props.domain + "/media/img/" + props.product.image4}
                    alt={"product"}
                    changeImage={props.changeImage}
                    removeImage={props.removeImage}
                    disabled={false}
                    imageId={5}
                />
                : null
            }
            { props.product.image5 !== null
                ? <ImageItem
                    domain={props.domain}
                    theme={props.theme}
                    title={props.strings.update + ":"}
                    src={props.domain + "/media/img/" + props.product.image5}
                    alt={"product"}
                    changeImage={props.changeImage}
                    removeImage={props.removeImage}
                    disabled={false}
                    imageId={6}
                />
                : null
            }

            { props.product.image1 === null || props.product.image2 === null || props.product.image3 === null || props.product.image4 === null || props.product.image5 === null
                ? <Success
                    theme={props.theme}
                    icon={<i className="fas fa-image"></i>}
                    success={props.strings.newImage}
                />
                : null
            }

            { props.product.image1 === null
                ? <ImageItem
                    domain={props.domain}
                    theme={props.theme}
                    title={props.strings.select + ":"}
                    src={props.domain + "/media/img/noimgmini.png"}
                    alt={"product"}
                    changeImage={props.changeImage}
                    removeImage={props.removeImage}
                    disabled={true}
                    imageId={2}
                />
                : props.product.image2 === null
                ? <ImageItem
                    domain={props.domain}
                    theme={props.theme}
                    title={props.strings.select + ":"}
                    src={props.domain + "/media/img/noimgmini.png"}
                    alt={"product"}
                    changeImage={props.changeImage}
                    removeImage={props.removeImage}
                    disabled={true}
                    imageId={3}
                />
                : props.product.image3 === null
                ? <ImageItem
                    domain={props.domain}
                    theme={props.theme}
                    title={props.strings.select + ":"}
                    src={props.domain + "/media/img/noimgmini.png"}
                    alt={"product"}
                    changeImage={props.changeImage}
                    removeImage={props.removeImage}
                    disabled={true}
                    imageId={4}
                />
                : props.product.image4 === null
                ? <ImageItem
                    domain={props.domain}
                    theme={props.theme}
                    title={props.strings.select + ":"}
                    src={props.domain + "/media/img/noimgmini.png"}
                    alt={"product"}
                    changeImage={props.changeImage}
                    removeImage={props.removeImage}
                    disabled={true}
                    imageId={5}
                />
                : props.product.image5 === null
                ? <ImageItem
                    domain={props.domain}
                    theme={props.theme}
                    title={props.strings.select + ":"}
                    src={props.domain + "/media/img/noimgmini.png"}
                    alt={"product"}
                    changeImage={props.changeImage}
                    removeImage={props.removeImage}
                    disabled={true}
                    imageId={6}
                />
                : null
            }
        </>
    )
}

export default SetImage