import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setWarningShow } from '../../../redux/notify-reducer'
import NotifyWarning from './NotifyWarning'

let NotifyWarningContainer = (props) => {
    const dispatch = useDispatch()
    const warning = useSelector(state => state.notify.warning)
    const warningShow = useSelector(state => state.notify.warningShow)

    
    useEffect(
        () => {
            if(warningShow){
                setTimeout(() => {
                    dispatch(setWarningShow(false))
                }, 3000)
            }
        }, [dispatch, warningShow]
    )

    return (
        <>
            {warningShow
                ?
                    <NotifyWarning
                        theme={props.theme}
                        strings={props.strings}
                        warning={warning}
                    />
                : null
            }
        </>
    )
}

export default NotifyWarningContainer