import React from 'react'

const CatFoodsProteinsCarbohytrates  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6351}>{props.strings.cat6351}</option>
            <option value={6355}>{props.strings.cat6355}</option>
            <option value={6360}>{props.strings.cat6360}</option>
            <option value={6365}>{props.strings.cat6365}</option>
            <option value={6370}>{props.strings.cat6370}</option>
            <option value={6375}>{props.strings.cat6375}</option>
            <option value={6380}>{props.strings.cat6380}</option>
            <option value={6385}>{props.strings.cat6385}</option>
            <option value={6390}>{props.strings.cat6390}</option>
            <option value={6395}>{props.strings.cat6395}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsProteinsCarbohytrates 