import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'
import SuccessMax from '../common/Success/SuccessMax'
import SuccessMin from '../common/Success/SuccessMin'
import Slider from '../Items/Slider/Slider'
import ProductFull from '../Items/FullItem/ProductFull'
import StoreItem from '../Items/Item/StoreItem'
import Paginator from '../common/Paginator/Paginator'
import CommentsItem from '../Items/CommentsItem/CommentsItem'
import ProfileItem from '../Items/Item/ProfileItem'
import ViewStatusBar from '../common/ViewStatusBar/ViewStatusBar'

const AdminProductContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                {props.productIsFetching
                    ? <PreloaderMini />
                    : !props.product
                        ? <>
                            <Menu
                                theme={props.theme}
                                back={props.back}
                            />
                            {props.productStatus
                                ? <SuccessMax
                                    theme={props.theme}
                                    icon={<i className="fas fa-bag-shopping"></i>}
                                    success={props.strings['status' + props.productStatus] + "!"}
                                />
                                : null
                            }
                        </>
                        : <>
                            <Menu 
                                theme={props.theme}
                                back={props.back}
                                phone={props.store.phone}
                                sms={props.store.phone}
                            />

                            <BlockPanel
                                theme={props.theme}
                                iconLeft={<i className="fas fa-chevron-left"></i>}
                                leftLink={"/admin/products"}
                                icon1={<i className="fas fa-bag-shopping"></i>}
                                title={props.product.title}
                                iconRight={<i className="fas fa-ellipsis-v"></i>}
                                rightLink={"/admin/products/" + props.product.id + "/settings"}
                            />

                            <ViewStatusBar
                                theme={props.theme}
                                strings={props.strings}
            
                                onSubmitView={props.onSubmitView}
                                onSubmitTrash={props.onSubmitTrash}
                            />

                            <Slider
                                videoSource={props.domain + "/media/video/"}
                                videoPoster={props.domain + "/media/video/poster.png"}
                                imageSource={props.domain + "/media/img/"}
                                imageAlt={"product"}
                                images={props.images}
                                activeIndex={props.activeIndex}
                                setActiveIndex={props.setActiveIndex}
                                crope={props.crope}
                                isPhotosCrope={props.isPhotosCrope}
                            />
                            
                            <ProductFull
                                theme={props.theme}
                                strings={props.strings}
                                id={props.product.id}
                                title={props.product.title}
                                type={props.product.type}
                                chapter={props.product.chapter}
                                categorie={props.product.categorie}
                                location={props.product.location}
                                marker={props.product.marker}
                                engine={props.product.engine}
                                transmission={props.product.transmission}
                                drive={props.product.drive}
                                body={props.product.body}
                                color={props.product.color}
                                mileage={props.product.mileage}
                                year={props.product.year}
                                text={props.product.text}
                                price={props.product.price}
                                phone={props.product.phone}
                                pub={props.product.pub}
                                views={props.product.views}
                                status={props.product.status}
                                favoriteCount={props.favoriteCount}
                            />

                            <BlockPanel
                                theme={props.theme}
                                icon1={<i className="fas fa-store-alt"></i>}
                                title={props.strings.productStore}
                            />
                            <StoreItem
                                domain={props.domain}
                                theme={props.theme}
                                strings={props.strings}
                                to={'/admin/stores/'}
                                id={props.store.id}
                                title={props.store.title}
                                image0={props.store.image0}
                                location={props.store.location}
                                marker={props.store.marker}
                                phone={props.store.phone}
                                text={props.store.text}
                                rating={props.store.rating}
                                ratingCount={props.store.ratingCount}
                                status={props.store.status}
                            />

                            <BlockPanel
                                theme={props.theme}
                                icon1={<i className="fas fa-user-circle"></i>}
                                title={props.strings.storeUser}
                            />           
                            {!props.ostProfile
                                ? props.productStatus
                                    ? <SuccessMin
                                        theme={props.theme}
                                        icon={<i className="fas fa-user"></i>}
                                        success={props.strings['status' + props.productStatus] + "!"}
                                    />
                                    : null
                                : <ProfileItem
                                    domain={props.domain}
                                    theme={props.theme}
                                    strings={props.strings}
                                    to={'/admin/profiles/'}
                                    id={props.ostProfile.id}
                                    avatar0={props.ostProfile.avatar0}
                                    login={props.ostProfile.login}
                                    name={props.ostProfile.name}
                                    location={props.ostProfile.location}
                                    marker={props.ostProfile.marker}
                                    text={props.ostProfile.text}
                                    phone={props.ostProfile.phone}
                                    reg={props.ostProfile.reg}
                                    status={props.ostProfile.status}
                                />
                            }

                            <BlockPanel
                                theme={props.theme}
                                icon1={<i className="fas fa-comments"></i>}
                                title={props.strings.comments}
                            />

                            {props.commentsIsFetching
                                ? <PreloaderMini />
                                : <>

                                    {props.comments
                                        ? <Paginator
                                            theme={props.theme}
                                            count={props.count}
                                            totalCount={props.totalCount}
                                            currentPage={props.currentPage}
                                            pagesCount={props.pagesCount}
                                            onPageChanged={props.onPageChanged}
                                        />
                                        : null
                                    }

                                    {props.comments
                                        ? props.comments.map(
                                            c => <CommentsItem
                                                    isAdmin={props.isAdmin}
                                                    domain={props.domain}
                                                    theme={props.theme}
                                                    strings={props.strings}
                                                    to={'/profile/comments/'}
                                                    key={c.id}
                                                    id={c.id}
                                                    profileId={c.profileId}
                                                    avatar={c.avatar0}
                                                    login={c.login}
                                                    text={c.text}
                                                    pub={c.pub}
                                                    status={c.status}

                                                    profile={props.profile}
                                                />
                                            )
                                        : null
                                    }

                                    {props.comments
                                        ? <Paginator
                                            theme={props.theme}
                                            count={props.count}
                                            totalCount={props.totalCount}
                                            currentPage={props.currentPage}
                                            pagesCount={props.pagesCount}
                                            onPageChanged={props.onPageChanged}
                                        />
                                        : null
                                    }

                                    {!props.comments && !props.profileComments
                                        ? props.commentsStatus
                                            ? <SuccessMin
                                                theme={props.theme}
                                                icon={<i className="fas fa-comments"></i>}
                                                success={props.strings['status' + props.commentsStatus] + "!"}
                                            />
                                            : null
                                        : null
                                    }
                                </>
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default AdminProductContent