import React from 'react'

const CatBeautyHygiene  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12105}>{props.strings.cat12105}</option>
            <option value={12108}>{props.strings.cat12108}</option>
            <option value={12110}>{props.strings.cat12110}</option>
            <option value={12144}>{props.strings.cat12144}</option>
            <option value={12148}>{props.strings.cat12148}</option>
            <option value={12172}>{props.strings.cat12172}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyHygiene