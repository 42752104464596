import React from 'react'

const CatWomanJewelery  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={11403}>{props.strings.cat11403}</option>
            <option value={11405}>{props.strings.cat11405}</option>
            <option value={11407}>{props.strings.cat11407}</option>
            <option value={11410}>{props.strings.cat11410}</option>
            <option value={11413}>{props.strings.cat11413}</option>
            <option value={11415}>{props.strings.cat11415}</option>
            <option value={11417}>{props.strings.cat11417}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatWomanJewelery 