import React from 'react'
import dark from './ContextDark.module.css'
import light from './ContextLight.module.css'

const Context = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockView : dark.blockView}>

            <div className={props.theme === 'light' ? light.info : dark.info}>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    {props.strings.usingTitle}
                </h2>
                <h3 className={props.theme === 'light' ? light.subtitle : dark.subtitle}>
                    {props.strings.copyrightSubtitle}
                </h3>
                <h3 className={props.theme === 'light' ? light.subtitle : dark.subtitle}>
                    {props.strings.copyrightSubtitle1}
                </h3>
            </div>

            <div className={props.theme === 'light' ? light.info : dark.info}>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    {props.strings.copyright1}
                </h2>
                <p>{props.strings.copyright2}</p>
            </div>

            <div className={props.theme === 'light' ? light.info : dark.info}>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    {props.strings.copyright3}
                </h2>
                <p>{props.strings.copyright4}</p>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    &#169; tmOnline - {props.strings.copyright5}
                </h2>
            </div>

        </div>
    )
}

export default Context