import React from 'react'
import dark from './FormDark.module.css'
import light from './FormLight.module.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const AddAdvertisingForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.formPanel : dark.formPanel}>
            <Formik
                initialValues={props.initialValues}
                validate={props.validate}
                onSubmit={props.onSubmit}
            >
                {({ isSubmitting }) => (
                    <Form className={props.theme === 'light' ? light.form : dark.form} >
                        
                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="title"
                            component="div"
                        />
                        <Field
                            type="text"
                            name="title"
                            autoComplete="title"
                            placeholder={props.strings.title + "..."}
                            pattern="[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я\s._-]{3,50}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'}
                        />

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="subtitle"
                            component="div"
                        />
                        <Field
                            type="text"
                            name="subtitle"
                            autoComplete="subtitle"
                            placeholder={props.strings.subtitle + "..."}
                            pattern="[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я\s ._-]{3,100}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'}
                        />

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="text"
                            component="div"
                        />
                        <Field
                            type="text"
                            name="text"
                            as="textarea"
                            autoComplete="about"
                            placeholder={props.strings.aboutAdvertising + "..."}
                        />

                        <button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {props.strings.add}
                        </button>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default AddAdvertisingForm