import React from 'react'
import BlockPanel from '../../common/BlockPanel/BlockPanel'
import Info from '../../common/Info/Info'
import Success from '../../common/Success/Success'
import VideoItem from '../../Items/Item/VideoItem'

const SetVideo = (props) => {
    return (
        <>
            <BlockPanel
                theme={props.theme}
                iconLeft={<i className="fas fa-chevron-left"></i>}
                leftLink={"/mystore/products/" + props.product.id + "/settings"}
                icon1={<i className="far fa-play-circle"></i>}
                title={props.strings.media}
            />
            
            <Info
                theme={props.theme}
                icon={<i className="fas fa-circle-info"></i>}
                info={props.strings.videoI}
                requestI={props.strings.requestI}
            />

            { props.product.video !== null
                ? <Success
                    theme={props.theme}
                    icon={<i className="fas fa-film"></i>}
                    success={props.strings.video}
                />
                : <Success
                    theme={props.theme}
                    icon={<i className="fas fa-film"></i>}
                    success={props.strings.newVideo}
                />
            }

            { props.product.video !== null
                ? <>
                    <VideoItem
                        domain={props.domain}
                        theme={props.theme}
                        title={props.strings.update + ":"}
                        src={props.domain + "/media/video/" + props.product.video}
                        poster={props.domain + "/media/video/poster.png"}
                        changeVideo={props.changeVideo}
                        removeVideo={props.removeVideo}
                        disabled={false}
                    />
                </>
                : <>
                    <VideoItem
                        domain={props.domain}
                        theme={props.theme}
                        title={props.strings.add + ":"}
                        src={null}
                        poster={props.domain + "/media/video/poster.png"}
                        changeVideo={props.changeVideo}
                        removeVideo={props.removeVideo}
                        disabled={true}
                    />
                </>
            }
            
        </>
    )
}

export default SetVideo