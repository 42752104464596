import React from 'react'
import BlockPanel from '../../common/BlockPanel/BlockPanel'
import Info from '../../common/Info/Info'
import TrashPanel from '../../common/TrashPanel/TrashPanel'

const SetTrash = (props) => {
    return (
        <>
            <BlockPanel
                theme={props.theme}
                iconLeft={<i className="fas fa-chevron-left"></i>}
                leftLink={"/admin/stores/" + props.store.id + "/settings"}
                icon1={<i className="fas fa-trash"></i>}
                icon2={<i className="fas fa-store-alt"></i>}
                title={props.store.title}
            />
            <Info
                theme={props.theme}
                icon={<i className="fas fa-circle-info"></i>}
                info={props.strings.trashStoreI}
                requestI={props.strings.requestI}
            />
            <TrashPanel
                theme={props.theme}
                strings={props.strings}
                navigate={props.navigate}
                onSubmitTrash={props.onSubmitTrash}
            />
        </>
    )
}

export default SetTrash