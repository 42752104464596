import React from 'react'

const Chancellery = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={9001}>{props.strings.cat9001}</option>
            <option value={9050}>{props.strings.cat9050}</option>
            <option value={9100}>{props.strings.cat9100}</option>
        </>
    )
}

export default Chancellery