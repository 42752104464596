import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Preloader from '../common/Preloader/Preloader'
import Sitebar from '../Sitebars/Sitebar'
import AdminStoreContent from './AdminStoreContent'

const AdminStore = (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                {props.isFetching
                    ? <Preloader />
                    : null
                }
                <AdminStoreContent
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}
                    
                    store={props.store}
                    images={props.images}
                    activeIndex={props.activeIndex}
                    setActiveIndex={props.setActiveIndex}
                    ostProfile={props.ostProfile}
                    isPhotosCrope={props.isPhotosCrope}
                    crope={props.crope}
                    storeStatus={props.storeStatus}
                    back={props.back}
                    storeIsFetching={props.storeIsFetching}
                    
                    rating={props.rating}
                    ratingCount={props.ratingCount}
                    
                    products={props.products}
                    count={props.count}
                    totalCount={props.totalCount}
                    currentPage={props.currentPage}
                    pagesCount={props.pagesCount}
                    productsStatus={props.productsStatus}
                    productsIsFetching={props.productsIsFetching}

                    onPageChanged={props.onPageChanged}
                    setStatus={props.setStatus}

                    onSubmitView={props.onSubmitView}
                    onSubmitTrash={props.onSubmitTrash}
                />
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    theme={props.theme}
                    strings={props.strings}
                    sitebarActivate={props.sitebarActivate}

                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default AdminStore