import React from 'react'
import { useDispatch } from 'react-redux'
import normalSize from './NormalSize.module.css'
import fullSize from './FullSize.module.css'
import Player from '../Player/Player'
import Image from '../Image/Image'

const Slider = (props) => {
    
    const dispatch = useDispatch()

    const len = props.images.length - 1

    let x1 = null
    let y1 = null

    const swipeStart = (e) => {
        const firstTouch = e.touches[0]
        x1 = firstTouch.clientX
        y1 = firstTouch.clientY
    }

    const swipeMove = (e) => {
        if (!document.fullscreenElement) {
            if (!x1 || !y1) {
                return false
            }
    
            let x2 = e.touches[0].clientX
            let y2 = e.touches[0].clientY
    
            let xDiff = x2 - x1
            let yDiff = y2 - y1
    
            if (Math.abs(xDiff) > Math.abs(yDiff)) {
    
                if (xDiff > 0) {
                    if (props.activeIndex !== 0) {
                        dispatch(props.setActiveIndex(props.activeIndex < 1 ? len : props.activeIndex - 1))
                    }
                } else {
                    if (props.activeIndex !== len) {
                        dispatch(props.setActiveIndex(props.activeIndex === len ? 0 : props.activeIndex + 1))
                    }
                }
    
            } else {
    
                if (yDiff > 0) props.crope()
    
            }
    
            x1 = null
            y1 = null
        }
    }



    return (
        <div
            className={
                props.isPhotosCrope
                    ? fullSize.sliderContainer
                    : normalSize.sliderContainer
            }
            onTouchStart={swipeStart}
            onTouchMove={swipeMove}
        >
            <section>
                {props.images.map((slide, index) => (
                    <div
                        key={index}
                        className={
                            props.isPhotosCrope
                                ? props.activeIndex === index
                                    ? fullSize.slides + " " + fullSize.active
                                    : fullSize.inactive
                                : props.activeIndex === index
                                    ? normalSize.slides + " " + normalSize.active
                                    : normalSize.inactive
                        }
                    >
                        {slide.video
                            ? <Player
                                poster={props.videoPoster}
                                source={props.videoSource + slide.video}
                                isPhotosCrope={props.isPhotosCrope}
                            />
                            : <Image
                                source={props.imageSource + slide.image}
                                alt={props.imageAlt}
                                isPhotosCrope={props.isPhotosCrope}
                                crope={props.crope}
                            />
                        }
                    </div>
                ))}
            </section>
            <div
                className={props.isPhotosCrope
                    ? fullSize.crope
                    : normalSize.crope
                }
            >
                {props.isPhotosCrope
                    ? <span
                        key={1}
                        className={props.isPhotosCrope
                            ? fullSize.cropeBtn
                            : normalSize.cropeBtn
                        }
                        onClick={() => props.crope()}
                    ><i className="fas fa-search-minus"></i></span>
                    : <span
                        key={2}
                        className={props.isPhotosCrope
                            ? fullSize.cropeBtn
                            : normalSize.cropeBtn
                        }
                        onClick={() => props.crope()}
                    ><i className="fas fa-search-plus"></i></span>
                }
            </div>
            <div>
                {props.images.length > 1
                    ? <>
                        <div
                            className={
                                props.isPhotosCrope
                                    ? fullSize.arrows
                                    : normalSize.arrows
                            }
                        >

                        {props.activeIndex !== 0
                            ? <span
                                className={
                                    props.isPhotosCrope
                                        ? fullSize.prev
                                        : normalSize.prev
                                }
                                onClick={() => {
                                        dispatch(
                                            props.setActiveIndex(
                                                props.activeIndex < 1
                                                ? len
                                                : props.activeIndex - 1
                                            )
                                        )
                                    }
                                }
                            >
                                <i className="fas fa-chevron-circle-left"></i>
                            </span>
                            : null
                        }

                        {props.activeIndex !== len
                            ? <span
                                className={
                                    props.isPhotosCrope
                                        ? fullSize.next
                                        : normalSize.next
                                }
                                onClick={() => {
                                        dispatch(
                                            props.setActiveIndex(props.activeIndex === len
                                                ? 0
                                                : props.activeIndex + 1
                                            )
                                        )
                                    }
                                }
                            >
                                <i className="fas fa-chevron-circle-right"></i>
                            </span>
                            : null
                        }

                        </div>
                        
                        <div
                            className={
                                props.isPhotosCrope
                                    ? fullSize.allDots
                                    : normalSize.allDots
                            }
                        >
                            {props.isPhotosCrope
                                ? props.images.map((slide, index) => (
                                    slide.video
                                        ? <span
                                            key={index}
                                            className={
                                                props.activeIndex === index
                                                    ? fullSize.dot + " " + fullSize.activeDot
                                                    : fullSize.dot
                                            }
                                            onClick={() => {dispatch(props.setActiveIndex(index))}}
                                        ><i className='fas fa-film'></i></span>
                                        : <span
                                            key={index}
                                            className={
                                                props.activeIndex === index
                                                    ? fullSize.dot + " " + fullSize.activeDot
                                                    : fullSize.dot
                                            }
                                            onClick={() => {dispatch(props.setActiveIndex(index))}}
                                        ><i className='fas fa-circle'></i></span>
                                    
                                ))
                                : props.images.map((slide, index) => (
                                    slide.video
                                    ? <span
                                        key={index}
                                        className={
                                            props.activeIndex === index
                                                ? normalSize.dot + " " + normalSize.activeDot
                                                : normalSize.dot
                                        }
                                        onClick={() => {dispatch(props.setActiveIndex(index))}}
                                    ><i className='fas fa-film'></i></span>
                                    : <span
                                        key={index}
                                        className={
                                            props.activeIndex === index
                                                ? normalSize.dot + " " + normalSize.activeDot
                                                : normalSize.dot
                                        }
                                        onClick={() => {dispatch(props.setActiveIndex(index))}}
                                    ><i className='fas fa-circle'></i></span>
                                ))
                            }
                        </div>
                    </>
                    : null
                }
            </div>
        </div>
    )
}

export default Slider
