import React from 'react'

const Mary = (props) => {
    return (
        <>
            <option value={null}>{props.strings.marker}...</option>
            <option value={53}>{props.strings.loc53}</option>
            <option value={54}>{props.strings.loc54}</option>
            <option value={55}>{props.strings.loc55}</option>
            <option value={56}>{props.strings.loc56}</option>
            <option value={57}>{props.strings.loc57}</option>
            <option value={58}>{props.strings.loc58}</option>
            <option value={59}>{props.strings.loc59}</option>
            <option value={60}>{props.strings.loc60}</option>
            <option value={61}>{props.strings.loc61}</option>
            <option value={62}>{props.strings.loc62}</option>
            <option value={63}>{props.strings.loc63}</option>
            <option value={64}>{props.strings.loc64}</option>
        </>
    )
}

export default Mary