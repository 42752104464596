import React, {useState, useEffect} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import s from './Menu.module.css'

const Menu = (props) => {
    const navigate = useNavigate()

    const [scrollMenu, setScrollMenu] = useState(false)

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        return function () {
            document.removeEventListener('scroll', scrollHandler)
        }
    }, []);

    const scrollHandler = (e) => {
        if(e.target.documentElement.scrollTop > 50) {
            setScrollMenu(true)
        }else{
            setScrollMenu(false)
        }
    }
    return (
        <div className={scrollMenu ? s.menu + " " + s.active : s.menu}
        >

            {props.back === true
                ? <span
                    className={s.item + " " + s.back}
                    onClick={() => navigate(-1)}
                >
                    <i className="fas fa-chevron-circle-left"></i>
                </span>
                : null
            }

            <span
                className={s.item + " " + s.up}
                onClick={() => window.scrollTo({top: 0, left: 0, behavior: "smooth"})}
            >
                <i className="fas fa-chevron-circle-up"></i>
            </span>

            {props.phone
                ? <a
                    className={s.item + " " + s.phone}
                    href={"tel:"+props.phone}
                >
                    <i className="fas fa-phone-square"></i>
                </a>
                : null
            }

            {props.sms
                ? <a
                    className={s.item + " " + s.sms}
                    href={"sms:"+props.sms}
                >
                    <i className="fas fa-envelope"></i>
                </a>
                : null
            }

            {props.mail
                ? <a
                    className={s.item + " " + s.sms}
                    href={"mailto:"+props.mail}
                >
                    <i className="fas fa-envelope"></i>
                </a>
                : null
            }

            {props.instagram
                ? <a
                    className={s.item + " " + s.instagram}
                    href={props.instagram}
                    target='blank'
                >
                    <i className="fab fa-instagram"></i>
                </a>
                : null
            }
            
            {props.imo
                ? <a
                    className={s.item + " " + s.up}
                    href={props.imo}
                    target='blank'
                >
                    <span
                        className={s.imo}
                    >
                        imo
                    </span>
                </a>
                : null
            }

            {props.link
                ? <NavLink
                    className={s.item + " " + s.up}
                    to={props.link}
                >
                    <span
                        className={s.store}
                    >
                        <i className="fas fa-store-alt"></i>
                    </span>
                </NavLink>
                : null
            }
            
            {props.href
                ? <a
                    className={s.item + " " + s.up}
                    href={props.href}
                    target='blank'
                >
                    <i className="fas fa-square-up-right"></i>
                </a>
                : null
            }
        </div>
    )
}

export default Menu