import React from 'react'
import dark from './ContextDark.module.css'
import light from './ContextLight.module.css'

const Context = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockView : dark.blockView}>

            <div className={props.theme === 'light' ? light.info : dark.info}>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    {props.strings.usingTitle}
                </h2>
                <h3 className={props.theme === 'light' ? light.subtitle : dark.subtitle}>
                    {props.strings.copyrightSubtitle}
                </h3>
                <h3 className={props.theme === 'light' ? light.subtitle : dark.subtitle}>
                    {props.strings.copyrightSubtitle1}
                </h3>
            </div>

            <div className={props.theme === 'light' ? light.info : dark.info}>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    {props.strings.helpSubitle1}
                </h2>
                <p>{props.strings.help1}?</p>
                <p className={props.theme === 'light' ? light.use : dark.use}>{props.strings.help2}</p>
                <p>{props.strings.help3}?</p>
                <p className={props.theme === 'light' ? light.use : dark.use}>{props.strings.help4}</p>
                <p>{props.strings.help5}?</p>
                <p className={props.theme === 'light' ? light.use : dark.use}>{props.strings.help6}</p>
                <p>{props.strings.help7}?</p>
                <p className={props.theme === 'light' ? light.use : dark.use}>{props.strings.help8}</p>
                <p className={props.theme === 'light' ? light.use : dark.use}>
                    {props.strings.help9} <a className={props.theme === 'light' ? light.link : dark.link} href="mailto:com.tmonline@gmail.com">com.tmonline@gmail.com</a>
                </p>
                <h2 className={props.theme === 'light' ? light.title : dark.title}>
                    &#169; tmOnline - {props.strings.copyright5}
                </h2>
            </div>

        </div>
    )
}

export default Context