import React from 'react'

const Nissan = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1001000}>NISSAN 200SX</option>
            <option value={1001001}>NISSAN 240SX</option>
            <option value={1001002}>NISSAN 280ZX</option>
            <option value={1001003}>NISSAN 300ZX</option>
            <option value={1001004}>NISSAN 350Z</option>
            <option value={1001005}>NISSAN 350Z ROADSTER</option>
            <option value={1001006}>NISSAN 370Z</option>
            <option value={1001007}>NISSAN ALMERA</option>
            <option value={1001008}>NISSAN ALTIMA</option>
            <option value={1001009}>NISSAN ARMADA</option>
            <option value={1001010}>NISSAN BLUEBIRD</option>
            <option value={1001011}>NISSAN CANTER</option>
            <option value={1001012}>NISSAN CEFIRO</option>
            <option value={1001013}>NISSAN CIVILIAN</option>
            <option value={1001014}>NISSAN CUBE</option>
            <option value={1001015}>NISSAN DATSUN</option>
            <option value={1001016}>NISSAN Z-CAR</option>
            <option value={1001017}>NISSAN FRONTIER</option>
            <option value={1001018}>NISSAN GT-R</option>
            <option value={1001019}>NISSAN JUKE</option>
            <option value={1001020}>NISSAN KICKS</option>
            <option value={1001021}>NISSAN LEAF</option>
            <option value={1001022}>NISSAN MAXIMA</option>
            <option value={1001023}>NISSAN MURANO</option>
            <option value={1001024}>NISSAN NV1500</option>
            <option value={1001025}>NISSAN NV2500</option>
            <option value={1001026}>NISSAN NX</option>
            <option value={1001027}>NISSAN NAVARA</option>
            <option value={1001028}>NISSAN PATHFINDER</option>
            <option value={1001029}>NISSAN PATHFINDER ARMADA</option>
            <option value={1001030}>NISSAN PATROL</option>
            <option value={1001031}>NISSAN PRIMERA</option>
            <option value={1001032}>NISSAN QASHQAI</option>
            <option value={1001033}>NISSAN QUEST</option>
            <option value={1001034}>NISSAN ROGUE</option>
            <option value={1001035}>NISSAN SENTRA</option>
            <option value={1001036}>NISSAN STANZA</option>
            <option value={1001037}>NISSAN SUNNY</option>
            <option value={1001038}>NISSAN TEANA</option>
            <option value={1001039}>NISSAN TERRANO</option>
            <option value={1001040}>NISSAN TILDA</option>
            <option value={1001041}>NISSAN TITAN</option>
            <option value={1001042}>NISSAN URVAN</option>
            <option value={1001043}>NISSAN VERSA</option>
            <option value={1001044}>NISSAN X-TRAIL</option>
            <option value={1001045}>NISSAN XTERRA</option>
        </>
    )
}

export default Nissan