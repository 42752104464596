import React from 'react'

const CatBeautyShowerBath  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12148}>{props.strings.cat12148}</option>
            <option value={12151}>{props.strings.cat12151}</option>
            <option value={12161}>{props.strings.cat12161}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyShowerBath