import React from 'react'
import { useSelector } from 'react-redux'
import dark from './PreloaderDark.module.css'
import light from './PreloaderLight.module.css'

let Preloader = (props) => {
    const theme = useSelector(state => state.sitebar.theme)
    const strings = useSelector(state => state.sitebar.strings)
    return (
        <div className={theme === 'light' ? light.load : dark.load}>
            <div className={theme === 'light' ? light.loader : dark.loader}></div>
            <div className={theme === 'light' ? light.loadText : dark.loadText}><h1>{strings.loading ? strings.loading + "..." : "CONNECTING..."}</h1></div>
        </div>
    )
}

export default Preloader