import React from 'react'
import Header from './Header'

const HeaderContainer = (props) => {
    return (
        <Header
            theme={props.theme}
            strings={props.strings}

            isAuth={props.isAuth}
            profileStore={props.profileStore}

            navToggle={props.navToggle}
            navbarActivate={props.navbarActivate}
        />
    )
}

export default HeaderContainer