import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import SetPanel from '../common/SetPanel/SetPanel'
import Paginator from '../common/Paginator/Paginator'
import ProductItem from '../Items/Item/ProductItem'
import StoreFull from '../Items/FullItem/StoreFull'
import Menu from '../Items/Menu/Menu'
import Slider from '../Items/Slider/Slider'
import SuccessMin from '../common/Success/SuccessMin'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'

const ProfileStoreContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />

                <BlockPanel
                    theme={props.theme}
                    iconLeft={<i className="fas fa-chevron-left"></i>}
                    leftLink={"/profile"}
                    icon1={<i className="fas fa-store-alt"></i>}
                    title={props.strings.myStore}
                    iconRight={<i className="fas fa-ellipsis-v"></i>}
                    rightLink={"/mystore/settings"}
                />

                {props.storeIsFetching
                    ? <PreloaderMini />
                    : <>
                        {props.store
                            ? <>
                                <Slider
                                    imageSource={props.domain + "/media/store/"}
                                    imageAlt={"store"}
                                    images={props.images}
                                    activeIndex={props.activeIndex}
                                    setActiveIndex={props.setActiveIndex}
                                    crope={props.crope}
                                    isPhotosCrope={props.isPhotosCrope}
                                />
                            
                                <StoreFull
                                    theme={props.theme}
                                    strings={props.strings}
                                    id={props.store.id}
                                    title={props.store.title}
                                    location={props.store.location}
                                    marker={props.store.marker}
                                    text={props.store.text}
                                    phone={props.store.phone}
                                    pub={props.store.pub}
                                    rating={props.rating}
                                    ratingCount={props.ratingCount}
                                    status={props.store.status}
                                />

                                <BlockPanel
                                    theme={props.theme}
                                    icon1={<i className="fas fa-bag-shopping"></i>}
                                    title={props.strings.myProducts}
                                />

                                {props.store.status !== 0
                                    ? <div className={props.theme === 'light' ? light.setContainer : dark.setContainer} >
                                        <SetPanel
                                            theme={props.theme}
                                            to={"/mystore/add/product"}
                                            text={props.strings.newProduct}
                                            iconLeft={<i className="fas fa-plus-circle"></i>}
                                            iconRight={<i className="fas fa-chevron-right"></i>}
                                        />
                                    </div>
                                    : null
                                }

                                {props.productsIsFetching
                                    ? <PreloaderMini />
                                    : !props.products
                                        ? <>
                                            {props.productsStatus
                                                ? <SuccessMin
                                                    theme={props.theme}
                                                    icon={<i className="fas fa-bag-shopping"></i>}
                                                    success={props.strings['status' + props.productsStatus] + "!"}
                                                />
                                                : null
                                            }
                                        </>
                                        : <>
                                            <Paginator
                                                theme={props.theme}
                                                count={props.count}
                                                totalCount={props.totalCount}
                                                currentPage={props.currentPage}
                                                pagesCount={props.pagesCount}
                                                onPageChanged={props.onPageChanged}
                                            />

                                            {props.products.map(
                                                p => <ProductItem
                                                        key={p.id}
                                                        domain={props.domain}
                                                        theme={props.theme}
                                                        strings={props.strings}
                                                        to={'/mystore/products/'}
                                                        id={p.id}
                                                        image0={p.image0}
                                                        title={p.title}
                                                        location={p.location}
                                                        marker={p.marker}
                                                        type={p.type}
                                                        chapter={p.chapter}
                                                        categorie={p.categorie}
                                                        text={p.text}
                                                        price={p.price}
                                                        views={p.views}
                                                        status={p.status}
                                                        favoriteCount={p.favoriteCount}
                                                    />
                                                )
                                            }

                                            <Paginator
                                                theme={props.theme}
                                                count={props.count}
                                                totalCount={props.totalCount}
                                                currentPage={props.currentPage}
                                                pagesCount={props.pagesCount}
                                                onPageChanged={props.onPageChanged}
                                            />
                                        </>
                                }
                            </>
                            : null
                        }
                    </>
                }

            </div>
        </div>
    )
}

export default ProfileStoreContent