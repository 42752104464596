import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    requestComment,
    setComment,
    setProfile,
    setProduct,
    setSuccess as setCommentSuccess,
    setError as setCommentError
} from '../../redux/adminComment-reducer'
import {
    changeStatus,
    remove,
    setUpdated,
    setSuccess as setCommentSetSuccess,
    setError as setCommentSetError
} from '../../redux/adminCommentSet-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setIsFetching } from '../../redux/adminComments-reducer'
import AdminComment from './AdminComment'

const AdminCommentContainer= (props) => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const params = useParams()

    const comment = useSelector(state => state.adminComment.comment)
    const ostProfile = useSelector(state => state.adminComment.profile)
    const product = useSelector(state => state.adminComment.product)
    const status = useSelector(state => state.adminComment.status)
    const commentSuccess = useSelector(state => state.adminComment.success)
    const commentError = useSelector(state => state.adminComment.error)
    const back = useSelector(state => state.adminComment.back)
    const commentIsFetching = useSelector(state => state.adminComment.isFetching)
    
    const updated = useSelector(state => state.adminCommentSet.updated)
    const commentSetSuccess = useSelector(state => state.adminCommentSet.success)
    const commentSetError = useSelector(state => state.adminCommentSet.error)
    const isFetching = useSelector(state => state.adminCommentSet.isFetching)

    const admin = 1

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        } else if (!params.commentId) {
            navigate('/admin/comments')
        }
    }, [navigate, props.isAuth, props.isAdmin, params.commentId])

    useEffect(() => {
        if (params.commentId || updated) {
            dispatch(setComment([]))
            dispatch(setProfile([]))
            dispatch(setProduct([]))
            dispatch(requestComment(admin, params.commentId))
            if (updated) {
                dispatch(setIsFetching(true))
                dispatch(setUpdated(false))
            }
            window.scrollTo({top: 0, left: 0})
        }
    }, [dispatch, params.commentId, updated])

    const onSubmitView = async (statusId) => {
        dispatch(changeStatus(admin, params.commentId, statusId))
    }

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(admin, params.commentId, trash))
        dispatch(setIsFetching(true))
        setTimeout(() => {
            navigate('/admin/comments')
        }, 1000)
    }

    useEffect(
        () => {
            if(commentError){
                dispatch(setNotifyError(commentError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setCommentError(null))
            } else if(commentSetError){
                dispatch(setNotifyError(commentSetError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setCommentSetError(null))
            } else if(commentSuccess){
                dispatch(setNotifySuccess(commentSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setCommentSuccess(null))
            } else if(commentSetSuccess){
                dispatch(setNotifySuccess(commentSetSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setCommentSetSuccess(null))
            }
        }, [dispatch, commentError, commentSetError, commentSuccess, commentSetSuccess]
    )

    return (
        <AdminComment
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            comment={comment}
            ostProfile={ostProfile}
            product={product}
            status={status}
            back={back}
            commentIsFetching={commentIsFetching}

            isFetching={isFetching}

            onSubmitView={onSubmitView}
            onSubmitTrash={onSubmitTrash}
        />
    )
}

export default AdminCommentContainer