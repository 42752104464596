import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Preloader from '../common/Preloader/Preloader'
import ProfileStoreSetContent from './ProfileStoreSetContent'
import Sitebar from '../Sitebars/Sitebar'

const ProfileStoreSet = (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                {props.isFetching
                    ? <Preloader />
                    : null
                }
                <ProfileStoreSetContent
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}

                    store={props.store}
                    back={props.back}

                    navigate={props.navigate}

                    validateTitle={props.validateTitle}
                    validateText={props.validateText}

                    onSubmitImage={props.onSubmitImage}
                    onSubmitRemoveImage={props.onSubmitRemoveImage}
                    onSubmitTitle={props.onSubmitTitle}
                    onSubmitText={props.onSubmitText}
                    onSubmitTrash={props.onSubmitTrash}
                />
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    theme={props.theme}
                    strings={props.strings}

                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default ProfileStoreSet