import React from 'react'

const Type = (props) => {
    return (
        <>
            <option value={0}>{props.strings.type}...</option>
            <option value={1}>{props.strings.cat1}</option>
            <option value={1000}>{props.strings.cat1000}</option>
            <option value={2000}>{props.strings.cat2000}</option>
            <option value={3000}>{props.strings.cat3000}</option>
            <option value={4000}>{props.strings.cat4000}</option>
            <option value={5000}>{props.strings.cat5000}</option>
            <option value={6000}>{props.strings.cat6000}</option>
            <option value={7000}>{props.strings.cat7000}</option>
            <option value={8000}>{props.strings.cat8000}</option>
            <option value={9000}>{props.strings.cat9000}</option>
            <option value={10000}>{props.strings.cat10000}</option>
            <option value={11000}>{props.strings.cat11000}</option>
            <option value={11001}>{props.strings.cat11001}</option>
            <option value={11002}>{props.strings.cat11002}</option>
            <option value={12000}>{props.strings.cat12000}</option>
            <option value={13000}>{props.strings.cat13000}</option>
            <option value={14000}>{props.strings.cat14000}</option>
            <option value={15000}>{props.strings.cat15000}</option>
            <option value={16000}>{props.strings.cat16000}</option>
            <option value={17000}>{props.strings.cat17000}</option>
            <option value={18000}>{props.strings.cat18000}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default Type