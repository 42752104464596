import React from 'react'

const CatFoodsOils  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6401}>{props.strings.cat6401}</option>
            <option value={6405}>{props.strings.cat6405}</option>
            <option value={6410}>{props.strings.cat6410}</option>
            <option value={6415}>{props.strings.cat6415}</option>
            <option value={6420}>{props.strings.cat6420}</option>
            <option value={6425}>{props.strings.cat6425}</option>
            <option value={6430}>{props.strings.cat6430}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsOils 