import React from 'react'

const Bussiness = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={5001}>{props.strings.cat5001}</option>
            <option value={5100}>{props.strings.cat5100}</option>
        </>
    )
}

export default Bussiness