import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'
import SuccessMax from '../common/Success/SuccessMax'
import SuccessMin from '../common/Success/SuccessMin'
import CommentsItem from '../Items/CommentsItem/CommentsItem'
import ProductItem from '../Items/Item/ProductItem'

const ProfileCommentContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                {props.isFetching
                    ? <PreloaderMini />
                    : !props.comment
                        ? <>
                            <Menu
                                theme={props.theme}
                                back={props.back}
                            />
                            {props.status
                                ? <SuccessMax
                                    theme={props.theme}
                                    icon={<i className="fas fa-comment"></i>}
                                    success={props.strings['status' + props.status] + "!"}
                                />
                                : null
                            }
                        </>
                        : <>
                            <Menu 
                                theme={props.theme}
                                back={props.back}
                            />
                            
                            <BlockPanel
                                theme={props.theme}
                                iconLeft={<i className="fas fa-chevron-left"></i>}
                                leftLink={"/profile/comments"}
                                icon1={<i className="fas fa-comment"></i>}
                                title={props.strings.comment}
                                iconRight={<i className="fas fa-trash"></i>}
                                rightClick={props.onSubmitTrash}
                            />

                            <CommentsItem
                                isAuth={props.isAuth}
                                domain={props.domain}
                                theme={props.theme}
                                strings={props.strings}
                                to={'/profile/comments/'}
                                key={props.comment.id}
                                id={props.comment.id}
                                avatar={props.comment.avatar0}
                                login={props.comment.login}
                                text={props.comment.text}
                                pub={props.comment.pub}
                                status={props.comment.status}
                                
                                profile={props.profile}
                            />

                            <BlockPanel
                                theme={props.theme}
                                icon1={<i className="fas fa-bag-shopping"></i>}
                                title={props.strings.commentProduct}
                            />       
                            {!props.product
                                ? props.status
                                    ? <SuccessMin
                                        theme={props.theme}
                                        icon={<i className="fas fa-user"></i>}
                                        success={props.strings['status' + props.status] + "!"}
                                    />
                                    : null
                                : <ProductItem
                                    domain={props.domain}
                                    theme={props.theme}
                                    strings={props.strings}
                                    to={'/products/'}
                                    id={props.product.id}
                                    image0={props.product.image0}
                                    title={props.product.title}
                                    location={props.product.location}
                                    marker={props.product.marker}
                                    type={props.product.type}
                                    chapter={props.product.chapter}
                                    categorie={props.product.categorie}
                                    text={props.product.text}
                                    price={props.product.price}
                                    views={props.product.views}
                                />
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default ProfileCommentContent