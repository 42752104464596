import React from 'react'

const Kia = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1001100}>KIA AMANTI</option>
            <option value={1001101}>KIA BONGO</option>
            <option value={1001102}>KIA BORREGO</option>
            <option value={1001103}>KIA CADENZA</option>
            <option value={1001104}>KIA CARENS</option>
            <option value={1001105}>KIA CARNIVAL</option>
            <option value={1001106}>KIA CERATO</option>
            <option value={1001107}>KIA CREDOS</option>
            <option value={1001108}>KIA CLARUS</option>
            <option value={1001109}>KIA FORTE</option>
            <option value={1001110}>KIA FRONTIER</option>
            <option value={1001111}>KIA MAGENTIS</option>
            <option value={1001112}>KIA MENTOR</option>
            <option value={1001113}>KIA MOHAVE</option>
            <option value={1001114}>KIA OPIRUS</option>
            <option value={1001115}>KIA OPTIMA</option>
            <option value={1001116}>KIA PICANTO</option>
            <option value={1001117}>KIA PRIDE</option>
            <option value={1001118}>KIA RIO</option>
            <option value={1001119}>KIA RIO 5</option>
            <option value={1001120}>KIA RONDO</option>
            <option value={1001121}>KIA SEDONA</option>
            <option value={1001122}>KIA SEPHIA</option>
            <option value={1001123}>KIA SORENTO</option>
            <option value={1001124}>KIA SOUL</option>
            <option value={1001125}>KIA SPECTRA</option>
            <option value={1001126}>KIA SPECTRA 5</option>
            <option value={1001127}>KIA SPORTAGE</option>
            <option value={1001128}>KIA STINGER</option>
        </>
    )
}

export default Kia