import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    setActiveIndex,
    setSuccess as setStoreSuccess,
    setError as setStoreError,
    setIsPhotosCrope
} from '../../redux/profileStore-reducer'
import {
    requestRating,
    setProfileRating,
    setRating,
    setRatingCount,
    setSuccess as setRatingSuccess,
    setSuccess as setRatingError,
    setIsFetching as setRatingIsFetching
} from '../../redux/rating-reducer'
import {
    requestProducts,
    setCurrentPage,
    setSuccess as setProductsSuccess,
    setError as setProductsError,
    setIsFetching,
} from '../../redux/profileStoreProducts-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setTrashed } from '../../redux/profileStoreProductSet-reducer'
import ProfileStore from './ProfileStore'

const ProfileStoreContainer = (props) => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const images = useSelector(state => state.profileStore.images)
    const activeIndex = useSelector(state => state.profileStore.activeIndex)
    const isPhotosCrope = useSelector(state => state.profileStore.isPhotosCrope)
    const storeStatus = useSelector(state => state.profileStore.status)
    const storeSuccess = useSelector(state => state.profileStore.success)
    const storeError = useSelector(state => state.profileStore.error)
    const back = useSelector(state => state.profileStore.back)
    const storeIsFetching = useSelector(state => state.profileStore.isFetching)

    const rating = useSelector(state => state.rating.rating)
    const ratingCount = useSelector(state => state.rating.ratingCount)
    const ratingSuccess = useSelector(state => state.rating.success)
    const ratingError = useSelector(state => state.rating.error)
    const ratingIsFetching = useSelector(state => state.rating.isFetching)

    const products = useSelector(state => state.profileStoreProducts.products)
    const count = useSelector(state => state.profileStoreProducts.count)
    const totalCount = useSelector(state => state.profileStoreProducts.totalCount)
    const currentPage = useSelector(state => state.profileStoreProducts.currentPage)
    const pagesCount = useSelector(state => state.profileStoreProducts.pagesCount)
    const productsStatus = useSelector(state => state.profileStoreProducts.status)
    const productsSuccess = useSelector(state => state.profileStoreProducts.success)
    const productsError = useSelector(state => state.profileStoreProducts.error)
    const productsIsFetching = useSelector(state => state.profileStoreProducts.isFetching)
    
    const trashed = useSelector(state => state.profileStoreProductSet.trashed)

    useEffect(() => {
        if (!props.isAuth) {
            navigate('/login')
        } else if (props.profile.status === 2) {
            navigate('/profile')
        } else if (props.profile.status === 0) {
            navigate('/logout')
        } else if (storeStatus === 6) {
            navigate('/create/store')
        }
    }, [navigate, props.isAuth, props.profile.status, storeStatus])

    useEffect(() => {
        if (currentPage || trashed) {
            const auth = 1
            dispatch(requestProducts(auth, currentPage, count))
            dispatch(setTrashed(false))
        }
    }, [dispatch, count, currentPage, trashed])
 
    useEffect(() => {
        if (props.profileStore) {
            dispatch(setProfileRating(null))
            dispatch(setRating(null))
            dispatch(setRatingCount(null))
            dispatch(requestRating(props.profileStore.id))
            dispatch(setRatingIsFetching(false))
        }
    }, [dispatch, navigate, props.profileStore, ratingIsFetching])

    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setIsFetching(true))
    }

    const crope = () => {
        if(isPhotosCrope) {
            dispatch(setIsPhotosCrope(false))
        } else {
            dispatch(setIsPhotosCrope(true))
        }
    }

    useEffect(
        () => {
            if(storeError){
                dispatch(setNotifyError(storeError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setStoreError(null))
            } else if(ratingError){
                dispatch(setNotifyError(ratingError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setRatingError(null))
            } else if(productsError){
                dispatch(setNotifyError(productsError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setProductsError(null))
            } else if(storeSuccess){
                dispatch(setNotifySuccess(storeSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setStoreSuccess(null))
            } else if(ratingSuccess){
                dispatch(setNotifySuccess(ratingSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setRatingSuccess(null))
            } else if(productsSuccess){
                dispatch(setNotifySuccess(productsSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setProductsSuccess(null))
            }
        }, [dispatch, storeError, ratingError, productsError, storeSuccess, ratingSuccess, productsSuccess]
    )

    return (
        <ProfileStore
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            store={props.profileStore}
            
            images={images}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            isPhotosCrope={isPhotosCrope}
            crope={crope}
            storeStatus={storeStatus}
            back={back}
            storeIsFetching={storeIsFetching}
            
            rating={rating}
            ratingCount={ratingCount}

            products={products}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            productsStatus={productsStatus}
            onPageChanged={onPageChanged}
            productsIsFetching={productsIsFetching}
        />
    )
}

export default ProfileStoreContainer