import React from 'react'

const CatCarProducts = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={501}>{props.strings.cat501}</option>
            <option value={510}>{props.strings.cat510}</option>
            <option value={520}>{props.strings.cat520}</option>
            <option value={530}>{props.strings.cat530}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatCarProducts