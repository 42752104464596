import React from 'react'

const CatElectronicsPrefixes  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10021}>{props.strings.cat10021}</option>
            <option value={10022}>{props.strings.cat10022}</option>
            <option value={10023}>{props.strings.cat10023}</option>
            <option value={10024}>{props.strings.cat10024}</option>
            <option value={10025}>{props.strings.cat10025}</option>
            <option value={10026}>{props.strings.cat10026}</option>
            <option value={10027}>{props.strings.cat10027}</option>
            <option value={10028}>{props.strings.cat10028}</option>
            <option value={100002}>{props.strings.cat100002}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsPrefixes 