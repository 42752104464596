import React from 'react'

const CatServicesStudios  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={2101}>{props.strings.cat2101}</option>
            <option value={2102}>{props.strings.cat2102}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatServicesStudios 