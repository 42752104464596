import React from 'react'

const OrderProducts = (props) => {
    return (
        <>
            <option value={'products.id'}>{props.strings.news}</option>
            <option value={'products.views'}>{props.strings.topViews}</option>
            <option value={'favoriteCount'}>{props.strings.topFavorite}</option>
        </>
    )
}

export default OrderProducts