import React from 'react'
import s from './AdvertisingSliderFull.module.css'
import { NavLink } from 'react-router-dom'

const AdvertisingSliderFull = (props) => {

    return (
        <div className={s.sliderContainer}
            onTouchStart={props.swipeStart}
            onTouchMove={props.swipeMove}
        >
            <section>
                {props.advertisings.map((slide, index) => (
                    <div
                        key={index}
                        className={index === props.activeIndex ? s.slides + " " + s.active : s.inactive}
                    >
                        <img
                            className={s.slideImage}
                            src={props.domain + "/media/reclama/" + slide.image}
                            alt='reclam'
                        />
                    </div>
                ))}
            </section>
            <div className={s.slideLink}>
                <section>
                    {props.advertisings.map((slide, index) => (
                        <NavLink
                            key={index}
                            to={"/advertisings/" + slide.id}
                            className={index === props.activeIndex ? s.slides + " " + s.active : s.inactive}               
                        >
                            <div className={s.type}>
                                {props.strings.advertising}
                            </div>
                        </NavLink>
                    ))}
                </section>
            </div>
            <div className={s.arrows}>

                <span className={s.prev} onClick={() => props.prevSlide()}>
                    <i className="fas fa-chevron-left"></i>
                </span>

                <span className={s.next} onClick={() => props.nextSlide()}>
                    <i className="fas fa-chevron-right"></i>
                </span>

            </div>
            <div className={s.allDots}>
                {props.advertisings.map((slide, index) => (
                    <span
                        key={index}
                        className={props.activeIndex === index ? s.dot + " " + s.activeDot : s.dot}
                        onClick={() => props.setSlide(index)}
                    ><i className='fas fa-circle'></i></span>
                ))}
            </div>
        </div>
    )
}

export default AdvertisingSliderFull