import React from 'react'

const CatBuildingFinishing  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={7301}>{props.strings.cat7301}</option>
            <option value={7303}>{props.strings.cat7303}</option>
            <option value={7305}>{props.strings.cat7305}</option>
            <option value={7307}>{props.strings.cat7307}</option>
            <option value={7310}>{props.strings.cat7310}</option>
            <option value={7313}>{props.strings.cat7313}</option>
            <option value={7315}>{props.strings.cat7315}</option>
            <option value={7317}>{props.strings.cat7317}</option>
            <option value={7320}>{props.strings.cat7320}</option>
            <option value={7323}>{props.strings.cat7323}</option>
            <option value={7325}>{props.strings.cat7325}</option>
            <option value={7327}>{props.strings.cat7327}</option>
            <option value={7330}>{props.strings.cat7330}</option>
            <option value={7333}>{props.strings.cat7333}</option>
            <option value={7335}>{props.strings.cat7335}</option>
            <option value={7337}>{props.strings.cat7337}</option>
            <option value={7340}>{props.strings.cat7340}</option>
            <option value={7343}>{props.strings.cat7343}</option>
            <option value={7345}>{props.strings.cat7345}</option>
            <option value={7347}>{props.strings.cat7347}</option>
            <option value={7350}>{props.strings.cat7350}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBuildingFinishing 