import React from 'react'

const CatElectronicsNetwork  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10281}>{props.strings.cat10281}</option>
            <option value={10282}>{props.strings.cat10282}</option>
            <option value={10283}>{props.strings.cat10283}</option>
            <option value={10284}>{props.strings.cat10284}</option>
            <option value={10285}>{props.strings.cat10285}</option>
            <option value={10286}>{props.strings.cat10286}</option>
            <option value={100002}>{props.strings.cat100002}</option>
            <option value={100003}>{props.strings.cat100003}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsNetwork 