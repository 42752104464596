import { ProductAPI } from '../api/api'

const SET_PROFILE_FAVORITE = 'favorite/SET_PROFILE_FAVORITE'
const SET_FAVORITE_COUNT = 'favorite/SET_FAVORITE_COUNT'
const SET_SUCCESS = 'favorite/SET_SUCCESS'
const SET_ERROR = 'favorite/SET_ERROR'
const IS_FETCHING = 'favorite/IS_FETCHING'

let initialState = {
    profileFavorite: null,
    favoriteCount: null,
    success: null,
    error: null,
    isFetching: true
}

const favoriteReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE_FAVORITE:
            return { ...state, profileFavorite: action.profileFavorite }
        case SET_FAVORITE_COUNT:
            return { ...state, favoriteCount: action.favoriteCount }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setProfileFavorite = (profileFavorite) => ({ type: SET_PROFILE_FAVORITE, profileFavorite })
export const setFavoriteCount = (favoriteCount) => ({ type: SET_FAVORITE_COUNT, favoriteCount })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestFavorite = (productId) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProductAPI.favorite(productId)
        dispatch(setProfileFavorite(data.data.profileFavorite))
        dispatch(setFavoriteCount(data.data.favoriteCount))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default favoriteReducer