import React from 'react'

const Beauty = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={12001}>{props.strings.cat12001}</option>
            <option value={12002}>{props.strings.cat12002}</option>
            <option value={12003}>{props.strings.cat12003}</option>
            <option value={12004}>{props.strings.cat12004}</option>
            <option value={12005}>{props.strings.cat12005}</option>
            <option value={12006}>{props.strings.cat12006}</option>
            <option value={12008}>{props.strings.cat12008}</option>
            <option value={12011}>{props.strings.cat12011}</option>
            <option value={12012}>{props.strings.cat12012}</option>
            <option value={12013}>{props.strings.cat12013}</option>
            <option value={12014}>{props.strings.cat12014}</option>
            <option value={12015}>{props.strings.cat12015}</option>
            <option value={12017}>{props.strings.cat12017}</option>
            <option value={12019}>{props.strings.cat12019}</option>
        </>
    )
}

export default Beauty