import React from 'react'
import s from './AdvertisingSlider.module.css'
import { NavLink } from 'react-router-dom'

const AdvertisingSlider = (props) => {

    return (
        <div className={s.sliderContainer}
            onTouchStart={props.swipeStart}
            onTouchMove={props.swipeMove}
        >
            <section>
                {props.advertisings.map((slide, index) => (
                    <div
                        key={index}
                        className={index === props.activeIndex ? s.slides + " " + s.active : s.inactive}
                    >
                        <img
                            className={s.slideImage}
                            src={props.domain + "/media/reclama/" + slide.image}
                            alt='reclam'
                        />
                    </div>
                ))}
            </section>
            <div className={s.slideLink}>
                <section>
                    {props.advertisings.map((slide, index) => (
                        <NavLink
                            key={index}
                            to={"/advertisings/" + slide.id}
                            className={index === props.activeIndex ? s.slides + " " + s.active : s.inactive}
                        >
                            <div className={s.type}>
                                {props.strings.advertising}
                            </div>
                        </NavLink>
                    ))}
                </section>
            </div>
        </div>
    )
}

export default AdvertisingSlider