import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    logout,
    setSuccess,
    setError,
    setIsFetching
} from '../../redux/auth-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import Logout from './Logout'

const LogoutContainer= (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const success = useSelector(state => state.auth.success)
    const error = useSelector(state => state.auth.error)

    const back = useSelector(state => state.auth.back)

    useEffect(() => {
        if (props.isAuth) {
            dispatch(setIsFetching(true))
            dispatch(logout())
            dispatch(setIsFetching(false))
            setTimeout(() => {
                navigate('/')
            }, 1000)
        }
    }, [dispatch, navigate, props.isAuth])

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <Logout
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            
            advertisingsSitebar={props.advertisingsSitebar}
            
            back={back}
        />
    )
}

export default LogoutContainer