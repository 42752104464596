import React from 'react'
import dark from './FormDark.module.css'
import light from './FormLight.module.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const SetAboutForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.formPanel : dark.formPanel}>
            <Formik
                initialValues={{
                    text: '',
                }}
                validate={props.validate}
                onSubmit={props.onSubmit}
                >
                {({ isSubmitting }) => (
                    <Form className={props.theme === 'light' ? light.form : dark.form} >
                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="text"
                            component="div"
                        />
                        <Field
                            className={props.theme === 'light' ? light.editInput : dark.editInput}
                            type="text"
                            name="text"
                            as="textarea"
                            autoComplete="about"
                            placeholder={props.placeholder}
                        />
                        <button
                            className={props.theme === 'light' ? light.textarea : dark.textarea}
                            type="submit"
                            disabled={isSubmitting}
                        >
                            <i className="fas fa-check"></i>
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default SetAboutForm