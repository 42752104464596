import React from 'react'

const CatElectronicsComputerTechnology  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10201}>{props.strings.cat10201}</option>
            <option value={10202}>{props.strings.cat10202}</option>
            <option value={10203}>{props.strings.cat10203}</option>
            <option value={10204}>{props.strings.cat10204}</option>
            <option value={10205}>{props.strings.cat10205}</option>
            <option value={10206}>{props.strings.cat10206}</option>
            <option value={10207}>{props.strings.cat10207}</option>
            <option value={10208}>{props.strings.cat10208}</option>
            <option value={10209}>{props.strings.cat10209}</option>
            <option value={10210}>{props.strings.cat10210}</option>
            <option value={10211}>{props.strings.cat10211}</option>
            <option value={10212}>{props.strings.cat10212}</option>
            <option value={10213}>{props.strings.cat10213}</option>
            <option value={100002}>{props.strings.cat100002}</option>
            <option value={100003}>{props.strings.cat100003}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsComputerTechnology 