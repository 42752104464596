import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    requestStores,
    setStores,
    setViewStatus,
    setCurrentPage,
    setSuccess,
    setError,
    setIsFetching
} from '../../redux/adminStores-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setTrashed } from '../../redux/adminStoreSet-reducer'
import AdminStores from './AdminStores'

const AdminStoresContainer= (props) => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const stores = useSelector(state => state.adminStores.stores)

    const count = useSelector(state => state.adminStores.count)
    const totalCount = useSelector(state => state.adminStores.totalCount)
    const currentPage = useSelector(state => state.adminStores.currentPage)
    const pagesCount = useSelector(state => state.adminStores.pagesCount)

    const status = useSelector(state => state.adminStores.status)
    const success = useSelector(state => state.adminStores.success)
    const error = useSelector(state => state.adminStores.error)
    const viewStatus = useSelector(state => state.adminStores.viewStatus)
    const back = useSelector(state => state.adminStores.back)
    const isFetching = useSelector(state => state.adminStores.isFetching)

    const trashed = useSelector(state => state.adminStoreSet.trashed)
    
    const admin = 1

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        }
    }, [navigate, props.isAuth, props.isAdmin])
    
    useEffect(() => {
        if (isFetching) {
            dispatch(setStores([]))
            dispatch(requestStores(admin, count, currentPage, viewStatus))
        }
    }, [dispatch, count, currentPage, viewStatus, isFetching])

    useEffect(() => {
        if (trashed) {
            dispatch(setTrashed(false))
        }
    }, [dispatch, trashed])

    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setIsFetching(true))
    }

    const setStatus = (status) => {
        dispatch(setStores([]))
        dispatch(setCurrentPage(1))
        dispatch(setViewStatus(status))
        dispatch(setIsFetching(true))
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <AdminStores
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            stores={stores}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            status={status}
            back={back}
            isFetching={isFetching}

            onPageChanged={onPageChanged}
            setStatus={setStatus}
        />
    )
}

export default AdminStoresContainer