import React from 'react'

const CatBeautyFace  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12102}>{props.strings.cat12102}</option>
            <option value={12103}>{props.strings.cat12103}</option>
            <option value={12109}>{props.strings.cat12109}</option>
            <option value={12111}>{props.strings.cat12111}</option>
            <option value={12116}>{props.strings.cat12116}</option>
            <option value={12126}>{props.strings.cat12126}</option>
            <option value={12128}>{props.strings.cat12128}</option>
            <option value={12129}>{props.strings.cat12129}</option>
            <option value={12132}>{props.strings.cat12132}</option>
            <option value={12135}>{props.strings.cat12135}</option>
            <option value={12137}>{props.strings.cat12137}</option>
            <option value={12138}>{props.strings.cat12138}</option>
            <option value={12140}>{props.strings.cat12140}</option>
            <option value={12142}>{props.strings.cat12142}</option>
            <option value={12144}>{props.strings.cat12144}</option>
            <option value={12149}>{props.strings.cat12149}</option>
            <option value={12150}>{props.strings.cat12150}</option>
            <option value={12153}>{props.strings.cat12153}</option>
            <option value={12157}>{props.strings.cat12157}</option>
            <option value={12158}>{props.strings.cat12158}</option>
            <option value={12160}>{props.strings.cat12160}</option>
            <option value={12163}>{props.strings.cat12163}</option>
            <option value={12164}>{props.strings.cat12164}</option>
            <option value={12165}>{props.strings.cat12165}</option>
            <option value={12167}>{props.strings.cat12167}</option>
            <option value={12168}>{props.strings.cat12168}</option>
            <option value={12170}>{props.strings.cat12170}</option>
            <option value={12172}>{props.strings.cat12172}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyFace