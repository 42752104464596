import React from 'react'
import dark from './HeaderDark.module.css'
import light from './HeaderLight.module.css'
import Navbar from './Navbar/Navbar'
import NavbarToggle from './NavbarToggle/NavbarToggle'

const Header = (props) => {
    return (
        <>
            <NavbarToggle
                theme={props.theme}
                strings={props.strings}

                isAuth={props.isAuth}
                profileStore={props.profileStore}

                navToggle={props.navToggle}
                navbarActivate={props.navbarActivate}
            />
            <div className={props.theme === 'light' ? light.header : dark.header}>
                <div className={props.theme === 'light' ? light.container : dark.container}>
                    <div className={props.theme === 'light' ? light.logo : dark.logo}>
                        <h1>tmOnline<i className="fas fa-shopping-cart"></i></h1>
                    </div>
                    <div className={props.theme === 'light' ? light.headerinner : dark.headerinner}>
                        <div className={props.theme === 'light' ? light.headerBtnMenu : dark.headerBtnMenu} onClick={props.navbarActivate} >
                            <h1><i className="fas fa-bars"></i></h1>
                        </div>
                        <Navbar
                            theme={props.theme}
                            strings={props.strings}

                            isAuth={props.isAuth}
                            profileStore={props.profileStore}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header