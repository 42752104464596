import { AdminAPI } from '../api/api'

const SET_ADMINS = 'adminAdmins/SET_ADMINS'
const SET_COUNT = 'adminAdmins/SET_COUNT'
const SET_TOTAL_COUNT = 'adminAdmins/SET_TOTAL_COUNT'
const SET_CURRENT_PAGE = 'adminAdmins/SET_CURRENT_PAGE'
const SET_PAGES_COUNT = 'adminAdmins/SET_PAGES_COUNT'
const SET_STATUS = 'adminAdmins/SET_STATUS'
const SET_SUCCESS = 'adminAdmins/SET_SUCCESS'
const SET_ERROR = 'adminAdmins/SET_ERROR'
const IS_FETCHING = 'adminAdmins/IS_FETCHING'

let initialState = {
    admins: [],
    count: 30,
    totalCount: 0,
    currentPage: 1,
    pagesCount: 0,
    status: null,
    success: null,
    error: null,
    back: false,
    isFetching: true
}

const adminAdminsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADMINS:
            return { ...state, admins: action.admins }
        case SET_COUNT:
            return { ...state, count: action.count }
        case SET_TOTAL_COUNT:
            return { ...state, totalCount: action.totalCount }
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.currentPage }
        case SET_PAGES_COUNT:
            return { ...state, pagesCount: action.pagesCount }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setAdmins = (admins) => ({ type: SET_ADMINS, admins })
export const setCount = (count) => ({ type: SET_COUNT, count })
export const setTotalCount = (totalCount) => ({ type: SET_TOTAL_COUNT, totalCount })
export const setCurrentPage = (currentPage) => ({ type: SET_CURRENT_PAGE, currentPage })
export const setPagesCount = (pagesCount) => ({ type: SET_PAGES_COUNT, pagesCount })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestAdmins = () => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAPI.admins()
        dispatch(setAdmins(data.data.admins))
        dispatch(setTotalCount(data.data.totalCount))
        dispatch(setPagesCount(data.data.pagesCount))
        dispatch(setStatus(data.data.status))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default adminAdminsReducer