import React from 'react'

const CatElectronicsBatteries  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10721}>{props.strings.cat10721}</option>
            <option value={10722}>{props.strings.cat10722}</option>
            <option value={100002}>{props.strings.cat100002}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsBatteries 