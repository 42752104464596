import React from 'react'

const CatSportEquipments  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={15201}>{props.strings.cat15201}</option>
            <option value={15202}>{props.strings.cat15202}</option>
            <option value={15203}>{props.strings.cat15203}</option>
            <option value={15204}>{props.strings.cat15204}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatSportEquipments