import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Preloader from '../common/Preloader/Preloader'
import AdminAdvertisingContent from './AdminAdvertisingContent'
import Sitebar from '../Sitebars/Sitebar'

const AdminAdvertising = (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                {props.isFetching
                    ? <Preloader />
                    : null
                }
                <AdminAdvertisingContent
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}
                    
                    advertising={props.advertising}
                    images={props.images}
                    activeIndex={props.activeIndex}
                    setActiveIndex={props.setActiveIndex}
                    isPhotosCrope={props.isPhotosCrope}
                    crope={props.crope}
                    status={props.status}
                    back={props.back}
                    isFetching={props.advertisingIsFetching}
                    
                    onSubmitView={props.onSubmitView}
                    onSubmitTrash={props.onSubmitTrash}
                />
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    theme={props.theme}
                    strings={props.strings}
                    sitebarActivate={props.sitebarActivate}
                    
                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default AdminAdvertising