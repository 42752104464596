import React from 'react'

const CatBuildingMaterials  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={7002}>{props.strings.cat7002}</option>
            <option value={7005}>{props.strings.cat7005}</option>
            <option value={7010}>{props.strings.cat7010}</option>
            <option value={7015}>{props.strings.cat7015}</option>
            <option value={7020}>{props.strings.cat7020}</option>
            <option value={7025}>{props.strings.cat7025}</option>
            <option value={7030}>{props.strings.cat7030}</option>
            <option value={7035}>{props.strings.cat7035}</option>
            <option value={7040}>{props.strings.cat7040}</option>
            <option value={7045}>{props.strings.cat7045}</option>
            <option value={7050}>{props.strings.cat7050}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBuildingMaterials 