import React from 'react'
import dark from './FormDark.module.css'
import light from './FormLight.module.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { NavLink } from 'react-router-dom'
import Engine from '../FormSelects/Transport/Engine'
import Drive from '../FormSelects/Transport/Drive'
import Transmission from '../FormSelects/Transport/Transmission'
import Body from '../FormSelects/Transport/Body'
import Color from '../FormSelects/Transport/Color'
import Year from '../FormSelects/Other/Year'
import Type from '../Categories/Types/Type'
import Cars from '../Categories/Chapters/Cars'
import Tools from '../Categories/Chapters/Tools'
import Services from '../Categories/Chapters/Services'
import Jobs from '../Categories/Chapters/Jobs'
import RealEstate from '../Categories/Chapters/RealEstate'
import Bussiness from '../Categories/Chapters/Bussiness'
import Foods from '../Categories/Chapters/Foods'
import Building from '../Categories/Chapters/Building'
import HomeOffice from '../Categories/Chapters/HomeOffice'
import Chancellery from '../Categories/Chapters/Chancellery'
import Electronics from '../Categories/Chapters/Electronics'
import Man from '../Categories/Chapters/Man'
import Woman from '../Categories/Chapters/Woman'
import Children from '../Categories/Chapters/Children'
import Beauty from '../Categories/Chapters/Beauty'
import Pharamcy from '../Categories/Chapters/Pharamcy'
import Recreation from '../Categories/Chapters/Recreation'
import Sport from '../Categories/Chapters/Sport'
import Cafes from '../Categories/Chapters/Cafes'
import Agriculture from '../Categories/Chapters/Agriculture'
import Animals from '../Categories/Chapters/Animals'
import CatCars from '../Categories/Categories/CatCars'
import CatCarParts from '../Categories/Categories/CatCarParts'
import CatCarProducts from '../Categories/Categories/CatCarProducts'
import CatToolsHand from '../Categories/Categories/CatToolsHand'
import CatToolsElectric from '../Categories/Categories/CatToolsElectric'
import CatToolsHydrolic from '../Categories/Categories/CatToolsHydrolic'
import CatServicesRealEstate from '../Categories/Categories/CatServicesRealEstate'
import CatServicesCar from '../Categories/Categories/CatServicesCar'
import CatServicesEquipment from '../Categories/Categories/CatServicesEquipment'
import CatServicesComputer from '../Categories/Categories/CatServicesComputer'
import CatServicesPhones from '../Categories/Categories/CatServicesPhones'
import CatServicesStudios from '../Categories/Categories/CatServicesStudios'
import CatServicesClothing from '../Categories/Categories/CatServicesClothing'
import CatServicesBeauty from '../Categories/Categories/CatServicesBeauty'
import CatServicesDocuments from '../Categories/Categories/CatServicesDocuments'
import CatServicesTransportation from '../Categories/Categories/CatServicesTransportation'
import CatServicesTraining from '../Categories/Categories/CatServicesTraining'
import CatJobs from '../Categories/Categories/CatJobs'
import CatRealEstateSale from '../Categories/Categories/CatRealEstateSale'
import CatRealEstateRent from '../Categories/Categories/CatRealEstateRent'
import CatBussinessSale from '../Categories/Categories/CatBussinessSale'
import CatBussinessProduction from '../Categories/Categories/CatBussinessProduction'
import CatFoodsBeverages from '../Categories/Categories/CatFoodsBeverages'
import CatFoodsSweets from '../Categories/Categories/CatFoodsSweets'
import CatFoodsMilkProducts from '../Categories/Categories/CatFoodsMilkProducts'
import CatFoodsFlourProducts from '../Categories/Categories/CatFoodsFlourProducts'
import CatFoodsMeatProducts from '../Categories/Categories/CatFoodsMeatProducts'
import CatFoodsCanneds from '../Categories/Categories/CatFoodsCanneds'
import CatFoodsProteinsCarbohytrates from '../Categories/Categories/CatFoodsProteinsCarbohytrates'
import CatFoodsOils from '../Categories/Categories/CatFoodsOils'
import CatFoodsVinegarsSeasoning from '../Categories/Categories/CatFoodsVinegarsSeasoning'
import CatFoodsFruits from '../Categories/Categories/CatFoodsFruits'
import CatFoodsBerries from '../Categories/Categories/CatFoodsBerries'
import CatFoodsVegetables from '../Categories/Categories/CatFoodsVegetables'
import CatFoodsNuts from '../Categories/Categories/CatFoodsNuts'
import CatFoodsBaby from '../Categories/Categories/CatFoodsBaby'
import CatBuildingMaterials from '../Categories/Categories/CatBuildingMaterials'
import CatBuildingSlates from '../Categories/Categories/CatBuildingSlates'
import CatBuildingIron from '../Categories/Categories/CatBuildingIron'
import CatBuildingWood from '../Categories/Categories/CatBuildingWood'
import CatBuildingReadyOrder from '../Categories/Categories/CatBuildingReadyOrder'
import CatBuildingFinishing from '../Categories/Categories/CatBuildingFinishing'
import CatBuildingPipes from '../Categories/Categories/CatBuildingPipes'
import CatBuildingSanNode from '../Categories/Categories/CatBuildingSanNode'
import CatBuildingHeating from '../Categories/Categories/CatBuildingHeating'
import CatBuildingConsumables from '../Categories/Categories/CatBuildingConsumables'
import CatHomeOfficeInterior from '../Categories/Categories/CatHomeOfficeInterior'
import CatHomeOfficeFurniture from '../Categories/Categories/CatHomeOfficeFurniture'
import CatHomeOfficeInventory from '../Categories/Categories/CatHomeOfficeInventory'
import CatHomeOfficeTablewares from '../Categories/Categories/CatHomeOfficeTablewares'
import CatHomeOfficeKitchenGoods from '../Categories/Categories/CatHomeOfficeKitchenGoods'
import CatHomeOfficeKitchenAppliances from '../Categories/Categories/CatHomeOfficeKitchenAppliances'
import CatHomeOfficeHouseholdGoods from '../Categories/Categories/CatHomeOfficeHouseholdGoods'
import CatHomeOfficeChemistry from '../Categories/Categories/CatHomeOfficeChemistry'
import CatHomeOfficeCounters from '../Categories/Categories/CatHomeOfficeCounters'
import CatChancelleryPaperProducts from '../Categories/Categories/CatChancelleryPaperProducts'
import CatChancelleryWritingDrawing from '../Categories/Categories/CatChancelleryWritingDrawing'
import CatChancelleryDocumentation from '../Categories/Categories/CatChancelleryDocumentation'
import CatElectronicsTvsMonitors from '../Categories/Categories/CatElectronicsTvsMonitors'
import CatElectronicsPrefixes from '../Categories/Categories/CatElectronicsPrefixes'
import CatElectronicsMusic from '../Categories/Categories/CatElectronicsMusic'
import CatElectronicsCar from '../Categories/Categories/CatElectronicsCar'
import CatElectronicsAntennas from '../Categories/Categories/CatElectronicsAntennas'
import CatElectronicsRemoteControllers from '../Categories/Categories/CatElectronicsRemoteControllers'
import CatElectronicsSmartHouse from '../Categories/Categories/CatElectronicsSmartHouse'
import CatElectronicsElectricity from '../Categories/Categories/CatElectronicsElectricity'
import CatElectronicsLighting from '../Categories/Categories/CatElectronicsLighting'
import CatElectronicsCommercialEquipment from '../Categories/Categories/CatElectronicsCommercialEquipment'
import CatElectronicsComputerTechnology from '../Categories/Categories/CatElectronicsComputerTechnology'
import CatElectronicsForGaming from '../Categories/Categories/CatElectronicsForGaming'
import CatElectronicsPrintersScanners from '../Categories/Categories/CatElectronicsPrintersScanners'
import CatElectronicsNetwork from '../Categories/Categories/CatElectronicsNetwork'
import CatElectronicsPcParts from '../Categories/Categories/CatElectronicsPcParts'
import CatElectronicsStorageDrives from '../Categories/Categories/CatElectronicsStorageDrives'
import CatElectronicsSoftwares from '../Categories/Categories/CatElectronicsSoftwares'
import CatElectronicsUpsExtensions from '../Categories/Categories/CatElectronicsUpsExtensions'
import CatElectronicsOfficeEquipment from '../Categories/Categories/CatElectronicsOfficeEquipment'
import CatElectronicsConnection from '../Categories/Categories/CatElectronicsConnection'
import CatElectronicsPhoneParts from '../Categories/Categories/CatElectronicsPhoneParts'
import CatElectronicsPhotoVideo from '../Categories/Categories/CatElectronicsPhotoVideo'
import CatElectronicsBatteries from '../Categories/Categories/CatElectronicsBatteries'
import CatElectronicsAppliances from '../Categories/Categories/CatElectronicsAppliances'
import CatElectronicsBeauty from '../Categories/Categories/CatElectronicsBeauty'
import CatManClothes from '../Categories/Categories/CatManClothes'
import CatManBagsWallets from '../Categories/Categories/CatManBagsWallets'
import CatManJewelery from '../Categories/Categories/CatManJewelery'
import CatWomanClothes from '../Categories/Categories/CatWomanClothes'
import CatWomanBagsWallets from '../Categories/Categories/CatWomanBagsWallets'
import CatWomanJewelery from '../Categories/Categories/CatWomanJewelery'
import CatChildrenClothes from '../Categories/Categories/CatChildrenClothes'
import CatChildrenKids from '../Categories/Categories/CatChildrenKids'
import CatBeautyHair from '../Categories/Categories/CatBeautyHair'
import CatBeautyFace from '../Categories/Categories/CatBeautyFace'
import CatBeautyEyebrows from '../Categories/Categories/CatBeautyEyebrows'
import CatBeautyEyelashes from '../Categories/Categories/CatBeautyEyelashes'
import CatBeautyLips from '../Categories/Categories/CatBeautyLips'
import CatBeautyOralCavity from '../Categories/Categories/CatBeautyOralCavity'
import CatBeautyBody from '../Categories/Categories/CatBeautyBody'
import CatBeautyNails from '../Categories/Categories/CatBeautyNails'
import CatBeautyHygiene from '../Categories/Categories/CatBeautyHygiene'
import CatBeautyShowerBath from '../Categories/Categories/CatBeautyShowerBath'
import CatBeautyChildren from '../Categories/Categories/CatBeautyChildren'
import CatBeautyMan from '../Categories/Categories/CatBeautyMan'
import CatBeautyPerfumery from '../Categories/Categories/CatBeautyPerfumery'
import CatBeautyAccessories from '../Categories/Categories/CatBeautyAccessories'
import CatPharamcyMedications from '../Categories/Categories/CatPharamcyMedications'
import CatPharamcyMedicalDevices from '../Categories/Categories/CatPharamcyMedicalDevices'
import CatPharamcyOrthopedicProducts from '../Categories/Categories/CatPharamcyOrthopedicProducts'
import CatPharamcyMomAndBaby from '../Categories/Categories/CatPharamcyMomAndBaby'
import CatRecreationTourismGoods from '../Categories/Categories/CatRecreationTourismGoods'
import CatRecreationEntertainmentGoods from '../Categories/Categories/CatRecreationEntertainmentGoods'
import CatSportGoods from '../Categories/Categories/CatSportGoods'
import CatSportUniforms from '../Categories/Categories/CatSportUniforms'
import CatSportEquipments from '../Categories/Categories/CatSportEquipments'
import CatCafesMenu from '../Categories/Categories/CatCafesMenu'
import CatAgricultureHarvest from '../Categories/Categories/CatAgricultureHarvest'
import CatAgricultureTechnique from '../Categories/Categories/CatAgricultureTechnique'
import CatAnimalsPets from '../Categories/Categories/CatAnimalsPets'
import CatAnimalsPetsGoods from '../Categories/Categories/CatAnimalsPetsGoods'
import CatAnimalsVeterinary from '../Categories/Categories/CatAnimalsVeterinary'
import CatAnimalsEquipment from '../Categories/Categories/CatAnimalsEquipment'
import CarManufacturers from '../FormSelects/ProductManufacturers/CarManufacturers'
import Bmw from '../FormSelects/ProductModels/Cars/Bmw'
import Mercedes from '../FormSelects/ProductModels/Cars/Mercedes'
import Toyota from '../FormSelects/ProductModels/Cars/Toyota'
import Lexus from '../FormSelects/ProductModels/Cars/Lexus'
import Audi from '../FormSelects/ProductModels/Cars/Audi'
import Hyundai from '../FormSelects/ProductModels/Cars/Hyundai'
import Volkswagen from '../FormSelects/ProductModels/Cars/Volkswagen'
import Chevrolet from '../FormSelects/ProductModels/Cars/Chevrolet'
import Mazda from '../FormSelects/ProductModels/Cars/Mazda'
import Infiniti from '../FormSelects/ProductModels/Cars/Infiniti'
import Nissan from '../FormSelects/ProductModels/Cars/Nissan'
import Kia from '../FormSelects/ProductModels/Cars/Kia'
import Jeep from '../FormSelects/ProductModels/Cars/Jeep'

const AddProductForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.formPanel : dark.formPanel}>
            <Formik
                initialValues={props.initialValues}
                validate={props.validate}
                onSubmit={props.onSubmit}
            >
                {({ isSubmitting, values }) => (
                    <Form className={props.theme === 'light' ? light.form : dark.form} >
                        
                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="type"
                            component="div"
                        />
                        <Field
                            name="type"
                            as="select"
                        >
                            <Type strings={props.strings} />
                        </Field>
                        { values.type === '1'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Cars strings={props.strings} />
                                </Field>
                                { values.chapter === '10'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatCars strings={props.strings} />
                                        </Field>
                                        { values.categorie !== '' && values.categorie !== '0'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="manufacturer"
                                                component="div"
                                            />
                                            <Field
                                                name="manufacturer"
                                                as="select"
                                            >
                                                <CarManufacturers strings={props.strings} />
                                            </Field>
                                            { values.manufacturer === '1000001'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Bmw strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1000100'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Mercedes strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1000200'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Toyota strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1000300'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Lexus strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1000400'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Audi strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1000500'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Hyundai strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1000600'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Volkswagen strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1000700'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Chevrolet strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1000800'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Mazda strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1000900'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Infiniti strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1001000'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Nissan strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1001100'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Kia strings={props.strings} />
                                                    </Field>
                                                </>
                                                : values.manufacturer === '1001200'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="model"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="model"
                                                        as="select"
                                                    >
                                                        <Jeep strings={props.strings} />
                                                    </Field>
                                                </>
                                                : null
                                            }
                                            { values.manufacturer !== '' && values.manufacturer !== '0'
                                                ? <>
                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="engine"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="engine"
                                                        as="select"
                                                    >
                                                        <Engine strings={props.strings} />
                                                    </Field>

                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="transmission"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="transmission"
                                                        as="select"
                                                    >
                                                        <Transmission strings={props.strings} />
                                                    </Field>

                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="drive"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="drive"
                                                        as="select"
                                                    >
                                                        <Drive strings={props.strings} />
                                                    </Field>

                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="body"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="body"
                                                        as="select"
                                                    >
                                                        <Body strings={props.strings} />
                                                    </Field>

                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="color"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="color"
                                                        as="select"
                                                    >
                                                        <Color strings={props.strings} />
                                                    </Field>

                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="year"
                                                        component="div"
                                                    />
                                                    <Field
                                                        name="year"
                                                        as="select"
                                                    >
                                                        <Year strings={props.strings} />
                                                    </Field>

                                                    <ErrorMessage
                                                        className={props.theme === 'light' ? light.err : dark.err}
                                                        name="mileage"
                                                        component="div"
                                                    />
                                                    <Field
                                                        type="text"
                                                        name="mileage"
                                                        autoComplete="mileage"
                                                        placeholder={props.strings.mileage + "..."}
                                                        pattern="[0-9]{1,9}"
                                                        title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9).'}
                                                    />
                                                </>
                                                : null
                                            }
                                        </>
                                        : null
                                        }
                                    </>
                                    : values.chapter === '100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatCarParts strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '500'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatCarProducts strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '1000'
                                ? <>
                                    <ErrorMessage
                                        className={props.theme === 'light' ? light.err : dark.err}
                                        name="chapter"
                                        component="div"
                                    />
                                    <Field
                                        name="chapter"
                                        as="select"
                                    >
                                        <Tools strings={props.strings} />
                                    </Field>
                                    { values.chapter === '1001'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatToolsHand strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '1300'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatToolsElectric strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '1600'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatToolsHydrolic strings={props.strings} />
                                            </Field>
                                        </>
                                        : null
                                    }
                                </>
                            : values.type === '2000'
                                ? <>
                                    <ErrorMessage
                                        className={props.theme === 'light' ? light.err : dark.err}
                                        name="chapter"
                                        component="div"
                                    />
                                    <Field
                                        name="chapter"
                                        as="select"
                                    >
                                        <Services strings={props.strings} />
                                    </Field>
                                    { values.chapter === '2001'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatServicesRealEstate strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '2020'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatServicesCar strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '2040'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatServicesEquipment strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '2060'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatServicesComputer strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '2080'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatServicesPhones strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '2100'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatServicesStudios strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '2120'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatServicesClothing strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '2140'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatServicesBeauty strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '2160'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatServicesDocuments strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '2180'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatServicesTransportation strings={props.strings} />
                                            </Field>
                                        </>
                                        : values.chapter === '2200'
                                        ? <>
                                            <ErrorMessage
                                                className={props.theme === 'light' ? light.err : dark.err}
                                                name="categorie"
                                                component="div"
                                            />
                                            <Field
                                                name="categorie"
                                                as="select"
                                            >
                                                <CatServicesTraining strings={props.strings} />
                                            </Field>
                                        </>
                                        : null
                                    }
                                </>
                            : values.type === '3000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Jobs strings={props.strings} />
                                </Field>
                                { values.chapter === '3001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatJobs strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '3002'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatJobs strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '4000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <RealEstate strings={props.strings} />
                                </Field>
                                { values.chapter === '4001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatRealEstateSale strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '4100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatRealEstateRent strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '5000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Bussiness strings={props.strings} />
                                </Field>
                                { values.chapter === '5001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBussinessSale strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '5100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBussinessProduction strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '6000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Foods strings={props.strings} />
                                </Field>
                                { values.chapter === '6001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsBeverages strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6050'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsSweets strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsMilkProducts strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6150'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsFlourProducts strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6200'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsMeatProducts strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6300'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsCanneds strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6350'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsProteinsCarbohytrates strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6400'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsOils strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6450'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsVinegarsSeasoning strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6500'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsFruits strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6600'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsBerries strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6700'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsVegetables strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6800'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsNuts strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '6850'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatFoodsBaby strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '7000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Building strings={props.strings} />
                                </Field>
                                { values.chapter === '7001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBuildingMaterials strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '7100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBuildingSlates strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '7150'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBuildingIron strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '7200'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBuildingWood strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '7250'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBuildingReadyOrder strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '7300'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBuildingFinishing strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '7400'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBuildingPipes strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '7500'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBuildingSanNode strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '7600'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBuildingHeating strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '7950'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBuildingConsumables strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '8000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <HomeOffice strings={props.strings} />
                                </Field>
                                { values.chapter === '8001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatHomeOfficeInterior strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '8050'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatHomeOfficeFurniture strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '8100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatHomeOfficeInventory strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '8150'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatHomeOfficeTablewares strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '8200'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatHomeOfficeKitchenGoods strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '8250'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatHomeOfficeKitchenAppliances strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '8300'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatHomeOfficeHouseholdGoods strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '8350'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatHomeOfficeChemistry strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '8400'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatHomeOfficeCounters strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '9000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Chancellery strings={props.strings} />
                                </Field>
                                { values.chapter === '9001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatChancelleryPaperProducts strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '9050'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatChancelleryWritingDrawing strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '9100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatChancelleryDocumentation strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '10000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Electronics strings={props.strings} />
                                </Field>
                                { values.chapter === '10001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsTvsMonitors strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10020'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsPrefixes strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10040'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsMusic strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10050'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsCar strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10060'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsAntennas strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10080'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsRemoteControllers strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsSmartHouse strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10120'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsElectricity strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10160'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsLighting strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10180'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsCommercialEquipment strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10200'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsComputerTechnology strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10240'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsForGaming strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10260'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsPrintersScanners strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10280'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsNetwork strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10300'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsPcParts strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10320'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsStorageDrives strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10340'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsSoftwares strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10360'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsUpsExtensions strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10380'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsOfficeEquipment strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10600'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsConnection strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10620'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsPhoneParts strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10700'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsPhotoVideo strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10720'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsBatteries strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10900'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsAppliances strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '10980'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatElectronicsBeauty strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '11000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Man strings={props.strings} />
                                </Field>
                                { values.chapter === '11003'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatManClothes strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '11300'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatManBagsWallets strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '11400'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatManJewelery strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '11001'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Woman strings={props.strings} />
                                </Field>
                                { values.chapter === '11003'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatWomanClothes strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '11300'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatWomanBagsWallets strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '11400'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatWomanJewelery strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '11002'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Children strings={props.strings} />
                                </Field>
                                { values.chapter === '11003'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatChildrenClothes strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '11500'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatChildrenKids strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '12000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Beauty strings={props.strings} />
                                </Field>
                                { values.chapter === '12001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyHair strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12002'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyFace strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12003'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyEyebrows strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12004'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyEyelashes strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12005'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyLips strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12006'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyOralCavity strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12008'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyBody strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12011'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyNails strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12012'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyHygiene strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12013'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyShowerBath strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12014'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyChildren strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12015'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyMan strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12017'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyPerfumery strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '12019'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatBeautyAccessories strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '13000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Pharamcy strings={props.strings} />
                                </Field>
                                { values.chapter === '13001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatPharamcyMedications strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '13500'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatPharamcyMedicalDevices strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '13700'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatPharamcyOrthopedicProducts strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '13800'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatPharamcyMomAndBaby strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '14000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Recreation strings={props.strings} />
                                </Field>
                                { values.chapter === '14001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatRecreationTourismGoods strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '14100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatRecreationEntertainmentGoods strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '15000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Sport strings={props.strings} />
                                </Field>
                                { values.chapter === '15001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatSportGoods strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '15100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatSportUniforms strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '15200'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatSportEquipments strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '16000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Cafes strings={props.strings} />
                                </Field>
                                { values.chapter === '16001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatCafesMenu strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '17000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Agriculture strings={props.strings} />
                                </Field>
                                { values.chapter === '17001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatAgricultureHarvest strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '17100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatAgricultureTechnique strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : values.type === '18000'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="chapter"
                                    component="div"
                                />
                                <Field
                                    name="chapter"
                                    as="select"
                                >
                                    <Animals strings={props.strings} />
                                </Field>
                                { values.chapter === '18001'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatAnimalsPets strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '18100'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatAnimalsPetsGoods strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '18200'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatAnimalsVeterinary strings={props.strings} />
                                        </Field>
                                    </>
                                    : values.chapter === '18300'
                                    ? <>
                                        <ErrorMessage
                                            className={props.theme === 'light' ? light.err : dark.err}
                                            name="categorie"
                                            component="div"
                                        />
                                        <Field
                                            name="categorie"
                                            as="select"
                                        >
                                            <CatAnimalsEquipment strings={props.strings} />
                                        </Field>
                                    </>
                                    : null
                                }
                            </>
                            : null
                        }

                        { values.type !== '0' && values.type !== '' && values.chapter !== '10' && values.categorie !== '' && values.categorie !== '0'
                            ? <>
                                <ErrorMessage
                                    className={props.theme === 'light' ? light.err : dark.err}
                                    name="title"
                                    component="div"
                                />
                                <Field
                                    type="text"
                                    name="title"
                                    autoComplete="title"
                                    placeholder={props.strings.title + "..."}
                                    pattern="[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я0-9\s._-]{3,50}"
                                    title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'}
                                />
                            </>
                            : null
                        }

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="text"
                            component="div"
                        />
                        <Field
                            type="text"
                            name="text"
                            as="textarea"
                            autoComplete="about"
                            placeholder={props.strings.aboutProduct + "..."}
                        />

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="price"
                            component="div"
                        />
                        <Field
                            type="text"
                            name="price"
                            autoComplete="price"
                            placeholder={props.strings.price + "..."}
                            pattern="[0-9]{1,9}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9).'}
                        />

                        <Field
                            className={props.theme === 'light' ? light.check : dark.check}
                            type="checkbox"
                            name="checkUsing"
                        />
                        <NavLink to="/sections-and-informations/terms-of-use">
                            {props.strings.agree}! <i className="fas fa-chevron-right"></i>
                        </NavLink>
                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="checkUsing"
                            component="div"
                        />

                        <button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {props.strings.add}
                        </button>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default AddProductForm