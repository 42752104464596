import React from 'react'

const CatElectronicsPhoneParts  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10621}>{props.strings.cat10621}</option>
            <option value={10622}>{props.strings.cat10622}</option>
            <option value={10623}>{props.strings.cat10623}</option>
            <option value={10624}>{props.strings.cat10624}</option>
            <option value={10625}>{props.strings.cat10625}</option>
            <option value={10626}>{props.strings.cat10626}</option>
            <option value={10627}>{props.strings.cat10627}</option>
            <option value={10628}>{props.strings.cat10628}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsPhoneParts 