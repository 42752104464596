import { ProductsAPI } from '../api/api';

const SET_ORDER = 'products/SET_ORDER'
const SET_LOCATION = 'products/SET_LOCATION'
const SET_MARKER = 'products/SET_MARKER'
const SET_TYPES = 'products/SET_TYPES'
const SET_CHAPTER = 'products/SET_CHAPTER'
const SET_CATEGORIE = 'products/SET_CATEGORIE'
const SET_MANUFACTURER = 'products/SET_MANUFACTURER'
const SET_MODEL = 'products/SET_MODEL'
const SET_ENGINE = 'products/SET_ENGINE'
const SET_TRANSMISSION = 'products/SET_TRANSMISSION'
const SET_DRIVE = 'products/SET_DRIVE'
const SET_BODY = 'products/SET_BODY'
const SET_COLOR = 'products/SET_COLOR'
const SET_YEAR = 'products/SET_YEAR'
const SET_SEARCH = 'products/SET_SEARCH'
const SET_COUNT = 'products/SET_COUNT'
const SET_TOTAL_COUNT = 'products/SET_TOTAL_COUNT'
const SET_CURRENT_PAGE = 'products/SET_CURRENT_PAGE'
const SET_PAGES_COUNT = 'products/SET_PAGES_COUNT'
const SET_PRODUCTS = 'products/SET_PRODUCTS'
const SET_STATUS = 'products/SET_STATUS'
const SET_SUCCESS = 'products/SET_SUCCESS'
const IS_FETCHING = 'products/IS_FETCHING'

let initialState = {
    order: 'id',
    location: null,
    marker: null,
    types: null,
    chapter: null,
    categorie: null,
    manufacturer: null,
    model: null,
    engine: null,
    transmission: null,
    drive: null,
    body: null,
    color: null,
    year: null,
    search: '',
    products: [],
    count: 30,
    totalCount: 0,
    currentPage: 1,
    pagesCount: 0,
    status: null,
    success: null,
    back: false,
    isFetching: true
}

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDER:
            return { ...state, order: action.order }
        case SET_LOCATION:
            return { ...state, location: action.location }
        case SET_MARKER:
            return { ...state, marker: action.marker }
        case SET_TYPES:
            return { ...state, types: action.types }
        case SET_CHAPTER:
            return { ...state, chapter: action.chapter }
        case SET_CATEGORIE:
            return { ...state, categorie: action.categorie }
        case SET_MANUFACTURER:
            return { ...state, manufacturer: action.manufacturer }
        case SET_MODEL:
            return { ...state, model: action.model }
        case SET_ENGINE:
            return { ...state, engine: action.engine }
        case SET_TRANSMISSION:
            return { ...state, transmission: action.transmission }
        case SET_DRIVE:
            return { ...state, drive: action.drive }
        case SET_BODY:
            return { ...state, body: action.body }
        case SET_COLOR:
            return { ...state, color: action.color }
        case SET_YEAR:
            return { ...state, year: action.year }
        case SET_SEARCH:
            return { ...state, search: action.search }
        case SET_PRODUCTS:
            return { ...state, products: action.products }
        case SET_COUNT:
            return { ...state, count: action.count }
        case SET_TOTAL_COUNT:
            return { ...state, totalCount: action.totalCount }
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.currentPage }
        case SET_PAGES_COUNT:
            return { ...state, pagesCount: action.pagesCount }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setOrder = (order) => ({ type: SET_ORDER, order })
export const setLocation = (location) => ({ type: SET_LOCATION, location })
export const setMarker = (marker) => ({ type: SET_MARKER, marker })
export const setType = (types) => ({ type: SET_TYPES, types })
export const setChapter = (chapter) => ({ type: SET_CHAPTER, chapter })
export const setCategorie = (categorie) => ({ type: SET_CATEGORIE, categorie })
export const setManufacturer = (manufacturer) => ({ type: SET_MANUFACTURER, manufacturer })
export const setModel = (model) => ({ type: SET_MODEL, model })
export const setEngine = (engine) => ({ type: SET_ENGINE, engine })
export const setTransmission = (transmission) => ({ type: SET_TRANSMISSION, transmission })
export const setDrive = (drive) => ({ type: SET_DRIVE, drive })
export const setBody = (body) => ({ type: SET_BODY, body })
export const setColor = (color) => ({ type: SET_COLOR, color })
export const setYear = (year) => ({ type: SET_YEAR, year })
export const setSearchText = (search) => ({ type: SET_SEARCH, search })
export const setCount = (count) => ({ type: SET_COUNT, count })
export const setTotalCount = (totalCount) => ({ type: SET_TOTAL_COUNT, totalCount })
export const setCurrentPage = (currentPage) => ({ type: SET_CURRENT_PAGE, currentPage })
export const setPagesCount = (pagesCount) => ({ type: SET_PAGES_COUNT, pagesCount })
export const setProducts = (products) => ({ type: SET_PRODUCTS, products })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestProducts = (
    order,
    location,
    marker,
    types,
    chapter,
    categorie,
    manufacturer,
    model,
    engine,
    transmission,
    drive,
    body,
    color,
    year,
    search,
    count,
    currentPage
) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await ProductsAPI.products(
            order,
            location,
            marker,
            types,
            chapter,
            categorie,
            manufacturer,
            model,
            engine,
            transmission,
            drive,
            body,
            color,
            year,
            search,
            count,
            currentPage
        )
        dispatch(setProducts(data.data.products))
        dispatch(setTotalCount(data.data.totalCount))
        dispatch(setPagesCount(data.data.pagesCount))
        dispatch(setStatus(data.data.status))
        dispatch(setSuccess(data.data.success))
        dispatch(setIsFetching(false))
    }
}

export default productsReducer