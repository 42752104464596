import React from 'react'
import dark from './Dark.module.css'
import light from './Light.module.css'

const AccountVerify = (props) => {
    return (
        <div className={props.theme === 'light' ? light.verify : dark.verify}>
            <div className={props.theme === 'light' ? light.blocks : dark.blocks}>
                {props.strings.phoneVerify}
            </div>

            
            <div className={props.theme === 'light' ? light.blocks : dark.blocks}>
                {props.strings.fromNumber}:
            </div>
                    
            <div className={props.theme === 'light' ? light.phone : dark.phone}>
                {props.phone}
            </div>

            <div className={props.theme === 'light' ? light.blocks : dark.blocks}>
                {props.strings.toNumber}:
            </div>

            <div className={props.theme === 'light' ? light.phone : dark.phone}>
                +993 64298343
            </div>

            <div className={props.theme === 'light' ? light.blocks : dark.blocks}>
                {props.strings.twoHours}!
            </div>

            <div className={props.theme === 'light' ? light.blocks : dark.blocks}>
                <a href={"sms:+993 64298343?body=."}>
                    <i className="fas fa-envelope"></i> {props.strings.sendMessage} <i className="fas fa-chevron-right"></i>
                </a>
            </div>
        </div>
    )
}

export default AccountVerify