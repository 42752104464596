import React from 'react'
import dark from './NotifyDark.module.css'
import light from './NotifyLight.module.css'

let NotifyError = (props) => {
    return (
        <div className={props.theme === 'light' ? light.notify : dark.notify}>
            {props.error
                ? <>
                    <div className={props.theme === 'light' ? light.icon : dark.icon}>
                        <i className="fa-solid fa-circle-xmark"></i>
                    </div>
                    <div className={props.theme === 'light' ? light.notifyText : dark.notifyText}>
                        {props.strings.error + ": " + props.strings['error' + props.error]}!
                    </div>
                </>
                : null
            }
        </div>
    )
}

export default NotifyError