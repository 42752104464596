import React from 'react'

const CatServicesDocuments  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={2161}>{props.strings.cat2161}</option>
            <option value={2162}>{props.strings.cat2162}</option>
            <option value={2163}>{props.strings.cat2163}</option>
            <option value={2164}>{props.strings.cat2164}</option>
            <option value={2165}>{props.strings.cat2165}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatServicesDocuments 