import React from 'react'

const CatCars = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={20}>{props.strings.cat20}</option>
            <option value={30}>{props.strings.cat30}</option>
            <option value={40}>{props.strings.cat40}</option>
            <option value={50}>{props.strings.cat50}</option>
        </>
    )
}

export default CatCars