import React from 'react'

const Balkan = (props) => {
    return (
        <>
            <option value={null}>{props.strings.marker}...</option>
            <option value={13}>{props.strings.loc13}</option>
            <option value={14}>{props.strings.loc14}</option>
            <option value={15}>{props.strings.loc15}</option>
            <option value={16}>{props.strings.loc16}</option>
            <option value={17}>{props.strings.loc17}</option>
            <option value={18}>{props.strings.loc18}</option>
            <option value={19}>{props.strings.loc19}</option>
            <option value={20}>{props.strings.loc20}</option>
            <option value={21}>{props.strings.loc21}</option>
            <option value={22}>{props.strings.loc22}</option>
            <option value={23}>{props.strings.loc23}</option>
        </>
    )
}

export default Balkan