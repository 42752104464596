import React from 'react'

const Locations = (props) => {
    return (
        <>
            <option value={null}>{props.strings.location}...</option>
            <option value={1}>{props.strings.loc1}</option>
            <option value={2}>{props.strings.loc2}</option>
            <option value={12}>{props.strings.loc12}</option>
            <option value={24}>{props.strings.loc24}</option>
            <option value={35}>{props.strings.loc35}</option>
            <option value={52}>{props.strings.loc52}</option>
        </>
    )
}

export default Locations