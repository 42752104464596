import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import SuccessMax from '../common/Success/SuccessMax'
import Menu from '../Items/Menu/Menu'

const NotFoundContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                <SuccessMax
                    theme={props.theme}
                    icon={<i className="fas fa-face-frown"></i>}
                    success={props.strings.pageNotFound + "!"}
                />
            </div>
        </div>
    )
}

export default NotFoundContent