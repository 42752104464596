import React from 'react'

const CatFoodsBaby  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6851}>{props.strings.cat6851}</option>
            <option value={6852}>{props.strings.cat6852}</option>
            <option value={6853}>{props.strings.cat6853}</option>
            <option value={6854}>{props.strings.cat6854}</option>
            <option value={6855}>{props.strings.cat6855}</option>
            <option value={6856}>{props.strings.cat6856}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsBaby 