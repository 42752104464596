import React from 'react'

const Services = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={2001}>{props.strings.cat2001}</option>
            <option value={2020}>{props.strings.cat2020}</option>
            <option value={2040}>{props.strings.cat2040}</option>
            <option value={2060}>{props.strings.cat2060}</option>
            <option value={2080}>{props.strings.cat2080}</option>
            <option value={2100}>{props.strings.cat2100}</option>
            <option value={2120}>{props.strings.cat2120}</option>
            <option value={2140}>{props.strings.cat2140}</option>
            <option value={2160}>{props.strings.cat2160}</option>
            <option value={2180}>{props.strings.cat2180}</option>
            <option value={2200}>{props.strings.cat2200}</option>
        </>
    )
}

export default Services