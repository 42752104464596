import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    adminLogin,
    setSuccess,
    setError
} from '../../redux/auth-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import AdminLogin from './AdminLogin'

const AdminLoginContainer= (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const success = useSelector(state => state.auth.success)
    const error = useSelector(state => state.auth.error)
    const back = useSelector(state => state.auth.back)

    const isFetching = useSelector(state => state.auth.isFetching)

    useEffect(() => {
        if (!props.isAuth) {
            navigate('/login')
        } else if (props.profile.privilege !== '1') {
            navigate('/profile')
        }
    }, [navigate, props.isAuth, props.profile])

    useEffect(() => {
        if (props.isAdmin) {
            setTimeout(() => {
                navigate('/admin/panel')
            }, 1000)
        }
    }, [navigate, props.isAdmin])

    const initialValues = { login: '', salt: '' }

    const validate = (values) => {
        const errors = {}
        
        if (!values.login) {
            errors.login = props.strings.required
        } else if (values.login.length < 3) {
            errors.login = props.strings.min + ': 3/' + values.login.length
        } else if (values.login.length > 20) {
            errors.login = props.strings.max + ': 20/' + values.login.length
        } else if (!/^[A-Z,a-z,0-9]{3,20}$/i.test(values.login)) {
            errors.login = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.strings + ' (A-Z, a-z).'
        }
    
        if (!values.salt) {
            errors.salt = props.strings.required
        } else if (values.salt.length < 8) {
            errors.salt = props.strings.min + ': 8/' + values.salt.length
        } else if (values.salt.length > 15) {
            errors.salt = props.strings.max + ': 15/' + values.salt.length
        } else if (!/^[A-Z,a-z,0-9]{8,15}$/i.test(values.salt)) {
            errors.salt = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.strings + ' (A-Z, a-z).'
        }
        return errors
    }

    const onSubmit = (values, { setSubmitting }) => {
        const login = values.login
        const salt = values.salt
        dispatch(adminLogin(login, salt))
        setSubmitting(false);
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <AdminLogin
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            
            back={back}
            isFetching={isFetching}

            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
        />
    )
}

export default AdminLoginContainer