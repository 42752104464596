import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    setComment,
    setProfile,
    setProduct,
    requestComment
} from '../../redux/adminComment-reducer'
import {
    setUpdated,
    setSuccess,
    setError,
    changeText,
    remove
} from '../../redux/adminCommentSet-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setIsFetching } from '../../redux/adminComments-reducer'
import AdminCommentSet from './AdminCommentSet'

const AdminCommentSetContainer = (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()

    const comment = useSelector(state => state.adminComment.comment)
    const product = useSelector(state => state.adminComment.product)
    
    const updated = useSelector(state => state.adminCommentSet.updated)
    const success = useSelector(state => state.adminCommentSet.success)
    const error = useSelector(state => state.adminCommentSet.error)
    const back = useSelector(state => state.adminCommentSet.back)
    const isFetching = useSelector(state => state.adminCommentSet.isFetching)

    const id = comment.id
    const admin = 1

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        } else if (!params.commentId) {
            navigate('/admin/comments')
        } else if (!comment) {
            navigate('/admin/comments')
        }
    }, [navigate, props.isAuth, props.isAdmin, params.commentId, comment])

    useEffect(() => {
        if (params.commentId || updated) {
            dispatch(setComment([]))
            dispatch(setProfile([]))
            dispatch(setProduct([]))
            dispatch(requestComment(admin, params.commentId))
            if (updated) {
                dispatch(setIsFetching(true))
                dispatch(setUpdated(false))
            }
        }
    }, [dispatch, navigate, params.commentId, updated])
       
    const validateText= (values) => {
        const errors = {}
        if (!values.text) {
            errors.text = props.strings.required
        } else if (values.text.length < 10) {
            errors.text = props.strings.min + ': 10/' + values.text.length
        } else if (values.text.length > 1500) {
            errors.text = props.strings.max + ': 1500/' + values.text.length
        }
        return errors
    }

    const onSubmitText = (values, { setSubmitting }) => {
        const text = values.text
        dispatch(changeText(admin, id, text))
        setSubmitting(false)
    }

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(admin, id, trash))
        dispatch(setIsFetching(true))
        setTimeout(() => {
            navigate('/admin/comments')
        }, 1000)
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <AdminCommentSet
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            comment={comment}
            product={product}

            back={back}
            isFetching={isFetching}

            validateText={validateText}

            onSubmitText={onSubmitText}
            onSubmitTrash={onSubmitTrash}
            navigate={navigate}
        />
    )
}

export default AdminCommentSetContainer