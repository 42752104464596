import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import ProductItem from '../Items/Item/ProductItem'
import Paginator from '../common/Paginator/Paginator'
import Menu from '../Items/Menu/Menu'
import SuccessAverage from '../common/Success/SuccessAverage'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'

const HistoryProductsContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                <BlockPanel
                    theme={props.theme}
                    iconLeft={<i className="fas fa-chevron-left"></i>}
                    leftLink={"/profile"}
                    icon1={<i className="fas fa-bag-shopping"></i>}
                    icon2={<i className="fas fa-clock-rotate-left"></i>}
                    title={props.strings.history}
                />

                {props.isFetching
                    ? <PreloaderMini />
                    : !props.products
                        ? <>
                            {props.productsStatus
                                ? <SuccessAverage
                                    theme={props.theme}
                                    icon={<i className="fas fa-bag-shopping"></i>}
                                    success={props.strings['status' + props.productsStatus] + "!"}
                                />
                                : null
                            }
                        </>
                        : <>
                            <Paginator
                                theme={props.theme}
                                count={props.count}
                                totalCount={props.totalCount}
                                currentPage={props.currentPage}
                                pagesCount={props.pagesCount}
                                onPageChanged={props.onPageChanged}
                            />

                            {props.products.map(
                                p => <ProductItem
                                        key={p.id}
                                        domain={props.domain}
                                        theme={props.theme}
                                        strings={props.strings}
                                        to={'/products/'}
                                        id={p.id}
                                        image0={p.image0}
                                        title={p.title}
                                        location={p.location}
                                        marker={p.marker}
                                        type={p.type}
                                        chapter={p.chapter}
                                        categorie={p.categorie}
                                        text={p.text}
                                        price={p.price}
                                        views={p.views}
                                        favoriteCount={p.favoriteCount}
                                    />
                                )
                            }

                            <Paginator
                                theme={props.theme}
                                count={props.count}
                                totalCount={props.totalCount}
                                currentPage={props.currentPage}
                                pagesCount={props.pagesCount}
                                onPageChanged={props.onPageChanged}
                            />
                        </>
                }
            </div>
        </div>
    )
}

export default HistoryProductsContent