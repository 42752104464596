import React from 'react'

const CatPharamcyMedications  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={13010}>{props.strings.cat13010}</option>
            <option value={13020}>{props.strings.cat13020}</option>
            <option value={13030}>{props.strings.cat13030}</option>
            <option value={13040}>{props.strings.cat13040}</option>
            <option value={13050}>{props.strings.cat13050}</option>
            <option value={13060}>{props.strings.cat13060}</option>
            <option value={13070}>{props.strings.cat13070}</option>
            <option value={13080}>{props.strings.cat13080}</option>
            <option value={13090}>{props.strings.cat13090}</option>
            <option value={13100}>{props.strings.cat13100}</option>
            <option value={13110}>{props.strings.cat13110}</option>
            <option value={13120}>{props.strings.cat13120}</option>
            <option value={13130}>{props.strings.cat13130}</option>
            <option value={13140}>{props.strings.cat13140}</option>
            <option value={13150}>{props.strings.cat13150}</option>
            <option value={13160}>{props.strings.cat13160}</option>
            <option value={13170}>{props.strings.cat13170}</option>
            <option value={13180}>{props.strings.cat13180}</option>
            <option value={13190}>{props.strings.cat13190}</option>
            <option value={13200}>{props.strings.cat13200}</option>
            <option value={13210}>{props.strings.cat13210}</option>
            <option value={13220}>{props.strings.cat13220}</option>
            <option value={13230}>{props.strings.cat13230}</option>
            <option value={13240}>{props.strings.cat13240}</option>
            <option value={13250}>{props.strings.cat13250}</option>
            <option value={13260}>{props.strings.cat13260}</option>
            <option value={13270}>{props.strings.cat13270}</option>
            <option value={13280}>{props.strings.cat13280}</option>
            <option value={13290}>{props.strings.cat13290}</option>
            <option value={13300}>{props.strings.cat13300}</option>
            <option value={13310}>{props.strings.cat13310}</option>
            <option value={13320}>{props.strings.cat13320}</option>
            <option value={13330}>{props.strings.cat13330}</option>
            <option value={13340}>{props.strings.cat13340}</option>
            <option value={13350}>{props.strings.cat13350}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatPharamcyMedications