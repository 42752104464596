import React from 'react'

const CatBuildingIron  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={7151}>{props.strings.cat7151}</option>
            <option value={7155}>{props.strings.cat7155}</option>
            <option value={7160}>{props.strings.cat7160}</option>
            <option value={7165}>{props.strings.cat7165}</option>
            <option value={7170}>{props.strings.cat7170}</option>
            <option value={7175}>{props.strings.cat7175}</option>
            <option value={7180}>{props.strings.cat7180}</option>
            <option value={7185}>{props.strings.cat7185}</option>
            <option value={7190}>{props.strings.cat7190}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBuildingIron 