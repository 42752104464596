import React, { useRef, useState } from 'react'
import fullScreen from './FullScreen.module.css'
import fullSize from './FullSize.module.css'
import normalSize from './NormalSize.module.css'

const Player = (props) => {

    const videoRef = useRef(null)
    const progressRef = useRef(null)

    const [isPlaying, setIsPlaying] = useState(false)
    const [progress, setProgress] = useState(0)
    const [time, setTime] = useState('00:00')
    const [currentTime, setCurrentTime] = useState('00:00')
    const [controlsActive, setControlsActive] = useState(true)
    const [largeScreen, setLargeScreen] = useState(false)

    const togglePlay = () => {
        if (isPlaying) {
            videoRef.current.pause()
            setControlsActive(true)
        } else {
            videoRef.current.play()
            setControlsActive(true)
        }
        setIsPlaying(!isPlaying)
    }


    const handleProgress = () => {

        const duration = videoRef.current.duration
        const currentTime = videoRef.current.currentTime
        const progress = (currentTime / duration) * 100

        const seconds = Math.floor(currentTime)
        const minutes = Math.floor(seconds / 60)
        const hours = Math.floor(minutes / 60)
        const days = Math.floor(hours / 24)

        const currentSecond = seconds - (minutes * 60)
        const currentMinute = minutes - (hours * 60)
        const currentHours = hours - (days * 24)

        const ostSecond = Math.floor(duration % 60)
        const ostMinute = Math.floor(duration / 60)
        const ostHour = Math.floor(ostMinute / 60)

        const ostTime = (
            ostHour !== 0
            ? ostHour < 10
                ? '0' + ostHour + ':'
                : ostHour
            : ''
        ) + (
            ostMinute < 10 ? '0' + ostMinute : ostMinute
        ) + ':' + (
            ostSecond < 10 ? '0' + ostSecond : ostSecond
        )

        setTime(ostTime)
        
        setProgress(progress)

        setCurrentTime(
            (currentHours !== 0
                ? currentHours < 10
                    ? '0' + currentHours + ':'
                    : currentHours + ':'
                : ''
            ) + (
                currentMinute < 10
                    ? '0' + currentMinute
                    : currentMinute
            ) + ':' + (
                currentSecond < 10
                ? '0' + currentSecond
                : currentSecond
            )
        )

        if (videoRef.current.ended) {
            if (document.fullscreenElement) {
                document.exitFullscreen()
                window.screen.orientation.lock('portrait')
                setLargeScreen(false)
            }
            togglePlay()
        }

    }

    const progressRemote = (e) => {
        let progressMargin = progressRef.current.getBoundingClientRect().left
        let currentRemote = e.clientX - progressMargin
        let remoteTime = (currentRemote * 100) / e.target.clientWidth
        videoRef.current.currentTime = (remoteTime * Math.round(videoRef.current.duration)) / 100
        if (!isPlaying) {
            togglePlay()
        }
    }

    const setPlayer = () => {
        if (controlsActive) {
            setControlsActive(false)
        } else {
            setControlsActive(true)
        }
    }
    
    const requestFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen()
            window.screen.orientation.lock('portrait')
            setLargeScreen(false)
        } else {
            document.documentElement.requestFullscreen()
            window.screen.orientation.lock('landscape')
            setLargeScreen(true)
        }
    }

    return (
        <div
            className={
                largeScreen
                    ? fullScreen.playerContainer
                    : props.isPhotosCrope
                        ? fullSize.playerContainer
                        : normalSize.playerContainer
            }
            onClick={setPlayer}
            onDoubleClick={requestFullScreen}
        >
            <video
                className={
                    largeScreen
                        ? fullScreen.slideVideo
                        : props.isPhotosCrope
                            ? fullSize.slideVideo
                            : normalSize.slideVideo
                }
                onTimeUpdate={handleProgress}
                ref={videoRef}
                preload="none"
                poster={props.poster}
            >
                <source src={props.source} type="video/mp4" />
            </video>
            <div
                className={
                    largeScreen
                        ? controlsActive
                            ? fullScreen.videoPlayer + ' ' +  fullScreen.playerActive
                            : fullScreen.videoPlayer
                        : props.isPhotosCrope
                            ? controlsActive
                                ? fullSize.videoPlayer + ' ' +  fullSize.playerActive
                                : fullSize.videoPlayer
                            : controlsActive
                                ? normalSize.videoPlayer + ' ' +  normalSize.playerActive
                                : normalSize.videoPlayer
                }
            >
                <div
                    className={
                        largeScreen
                            ? fullScreen.centreControls
                            : props.isPhotosCrope
                                ? fullSize.centreControls
                                : normalSize.centreControls
                    }
                >
                    {isPlaying
                        ? <span
                            key={1}
                            className={
                                largeScreen
                                    ? fullScreen.centreItem
                                    : props.isPhotosCrope
                                        ? fullSize.centreItem
                                        : normalSize.centreItem
                            }
                            onClick={togglePlay}
                        >
                            <i className="fas fa-pause"></i>
                        </span>
                        : <span
                            key={2}
                            className={
                                largeScreen
                                    ? fullScreen.centreItem
                                    : props.isPhotosCrope
                                        ? fullSize.centreItem
                                        : normalSize.centreItem
                            }
                            onClick={togglePlay}
                        >
                            <i className="fas fa-play"></i>
                        </span>
                    }
                </div>
                <div
                    className={
                        largeScreen
                            ? fullScreen.controls
                            : props.isPhotosCrope
                                ? fullSize.controls
                                : normalSize.controls
                    }
                >
                    {isPlaying
                        ? <span
                            key={1}
                            className={
                                largeScreen
                                    ? fullScreen.item + ' ' + fullScreen.itemLeft
                                    : props.isPhotosCrope
                                        ? fullSize.item + ' ' + fullSize.itemLeft
                                        : normalSize.item + ' ' + normalSize.itemLeft
                            }
                            onClick={togglePlay}
                        >
                                <i className="fas fa-pause"></i>
                        </span>
                        : <span
                            key={2}
                            className={
                                largeScreen
                                    ? fullScreen.item + ' ' + fullScreen.itemLeft
                                    : props.isPhotosCrope
                                        ? fullSize.item + ' ' + fullSize.itemLeft
                                        : normalSize.item + ' ' + normalSize.itemLeft
                            }
                            onClick={togglePlay}
                        >
                            <i className="fas fa-play"></i>
                        </span>
                    }
                    <progress
                        className={
                            largeScreen
                                ? fullScreen.progress
                                : props.isPhotosCrope
                                    ? fullSize.progress
                                    : normalSize.progress
                        }
                        ref={progressRef}
                        value={progress}
                        max={100}
                        onClick={progressRemote}
                    />
                    <div
                        className={
                            largeScreen
                                ? fullScreen.time
                                : props.isPhotosCrope
                                    ? fullSize.time
                                    : normalSize.time
                        }
                    >
                        <span
                            className={
                                largeScreen
                                    ? time === "00:00"
                                        ? fullScreen.currentTime + " " + fullScreen.free
                                        : fullScreen.currentTime
                                    : time === "00:00"
                                        ? props.isPhotosCrope
                                            ? fullSize.currentTime + " " + fullSize.free
                                            : normalSize.currentTime + " " + normalSize.free
                                        : props.isPhotosCrope
                                            ? fullSize.currentTime
                                            : normalSize.currentTime
                            }
                        >
                            {currentTime}
                        </span>
                            /
                        <span
                            className={
                                largeScreen
                                    ? fullScreen.duration
                                    : props.isPhotosCrope
                                        ? fullSize.duration
                                        : normalSize.duration
                            }
                        >
                            {time}
                        </span>
                    </div>
                    <span
                        className={
                            largeScreen
                                ? fullScreen.item + ' ' + fullScreen.itemRight
                                : props.isPhotosCrope
                                    ? fullSize.item + ' ' + fullSize.itemRight
                                    : normalSize.item + ' ' + normalSize.itemRight
                        }
                        onClick={requestFullScreen}
                    >
                        <i className="fas fa-expand"></i>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Player