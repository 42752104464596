import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import StoreContent from './StoreContent'
import Sitebar from '../Sitebars/Sitebar'

const Store = (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                <StoreContent
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}

                    isAuth={props.isAuth}

                    store={props.store}
                    images={props.images}
                    activeIndex={props.activeIndex}
                    setActiveIndex={props.setActiveIndex}
                    isPhotosCrope={props.isPhotosCrope}
                    crope={props.crope}
                    storeStatus={props.storeStatus}
                    back={props.back}
                    storeIsFetching={props.storeIsFetching}

                    profileRating={props.profileRating}
                    rating={props.rating}
                    ratingCount={props.ratingCount}
        
                    products={props.products}
                    count={props.count}
                    totalCount={props.totalCount}
                    currentPage={props.currentPage}
                    pagesCount={props.pagesCount}
                    onPageChanged={props.onPageChanged}
                    productsStatus={props.productsStatus}
                    productIsFetching={props.productIsFetching}
                    
                    leftClick={props.leftClick}
                    possibleRates={props.possibleRates}
                    selectedRate={props.selectedRate}
                    hoveredRate={props.hoveredRate}
                    onSelectedRate={props.onSelectedRate}
                    onHoveredRate={props.onHoveredRate}
                    changeRate={props.changeRate}
                />
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}
                    sitebarActivate={props.sitebarActivate}

                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}

                    advertisingsSitebar={props.advertisingsSitebar}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default Store