import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setErrorShow } from '../../../redux/notify-reducer'
import NotifyError from './NotifyError'

let NotifyErrorContainer = (props) => {
    const dispatch = useDispatch()
    const error = useSelector(state => state.notify.error)
    const errorShow = useSelector(state => state.notify.errorShow)

    
    useEffect(
        () => {
            if(errorShow){
                setTimeout(() => {
                    dispatch(setErrorShow(false))
                }, 3000)
            }
        }, [dispatch, errorShow]
    )

    return (
        <>
            {errorShow
                ?
                    <NotifyError
                        theme={props.theme}
                        strings={props.strings}
                        error={error}
                    />
                : null
            }
        </>
    )
}

export default NotifyErrorContainer