import React from 'react'
import { useDispatch } from 'react-redux'
import dark from './SLDark.module.css'
import light from './SLLight.module.css'
import { languageData } from '../../../redux/sitebar-reducer'
import { Formik, Form } from 'formik'

const LangTM = (props) => {
        
    const dispatch = useDispatch()

    const onSubmit = (values, { setSubmitting }) => {
        const language = "tm"
        dispatch(languageData(language))
        setSubmitting(false)
    }

    return (
        <Formik
            initialValues={{
                language: ''
            }}
            onSubmit={onSubmit}
            >
            {({ isSubmitting }) => (
                <Form>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                    >
                        TM
                    </button>
                </Form>
            )}
        </Formik>
    )
}

const LangRU = (props) => {
        
    const dispatch = useDispatch()

    const onSubmit = (values, { setSubmitting }) => {
        const language = "ru"
        dispatch(languageData(language))
        setSubmitting(false)
    }

    return (
        <Formik
            initialValues={{
                language: ''
            }}
            onSubmit={onSubmit}
            >
            {({ isSubmitting }) => (
                <Form>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                    >
                        RU
                    </button>
                </Form>
            )}
        </Formik>
    )
}

const LangEN = (props) => {
        
    const dispatch = useDispatch()

    const onSubmit = (values, { setSubmitting }) => {
        const language = "en"
        dispatch(languageData(language))
        setSubmitting(false)
    }

    return (
        <Formik
            initialValues={{
                language: ''
            }}
            onSubmit={onSubmit}
            >
            {({ isSubmitting }) => (
                <Form>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                    >
                        EN
                    </button>
                </Form>
            )}
        </Formik>
    )
}

const LangTR = (props) => {
        
    const dispatch = useDispatch()

    const onSubmit = (values, { setSubmitting }) => {
        const language = "tr"
        dispatch(languageData(language))
        setSubmitting(false)
    }

    return (
        <Formik
            initialValues={{
                language: ''
            }}
            onSubmit={onSubmit}
            >
            {({ isSubmitting }) => (
                <Form>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                    >
                        TR
                    </button>
                </Form>
            )}
        </Formik>
    )
}

const SitebarTheme = (props) => {
    return (
        <div className={props.theme === 'light' ? light.language : dark.language}>
            <div className={props.theme === 'light' ? light.lang : dark.lang}>
                <label><i className="fas fa-globe"></i> {props.strings.lang}</label>
                <LangTM />
                <LangRU />
                <LangEN />
                <LangTR />
            </div>
        </div>
    )
}

export default SitebarTheme