import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import BlockPanel from '../common/BlockPanel/BlockPanel'
import ViewStatusBar from '../common/ViewStatusBar/ViewStatusBar'
import SuccessMax from '../common/Success/SuccessMax'
import PreloaderMini from '../common/PreloaderMini/PreloaderMini'
import SuccessMin from '../common/Success/SuccessMin'
import Slider from '../Items/Slider/Slider'
import StoreFull from '../Items/FullItem/StoreFull'
import ProfileItem from '../Items/Item/ProfileItem'
import Paginator from '../common/Paginator/Paginator'
import ProductItem from '../Items/Item/ProductItem'
import ViewPanel from '../common/ViewPanel/ViewPanel'

const AdminStoreContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                {props.storeIsFetching
                    ? <PreloaderMini />
                    : !props.store
                        ? <>
                            <Menu
                                theme={props.theme}
                                back={props.back}
                            />
                            {props.storeStatus
                                ? <SuccessMax
                                    theme={props.theme}
                                    icon={<i className="fas fa-store-alt"></i>}
                                    success={props.strings['status' + props.storeStatus] + "!"}
                                />
                                : null
                            }
                        </>
                        : <>
                            <Menu
                                theme={props.theme}
                                back={props.back}
                                phone={props.store.phone}
                                sms={props.store.phone}
                            />

                            <BlockPanel
                                theme={props.theme}
                                iconLeft={<i className="fas fa-chevron-left"></i>}
                                leftLink={"/admin/stores"}
                                icon1={<i className="fas fa-store-alt"></i>}
                                title={props.store.title}
                                iconRight={<i className="fas fa-ellipsis-v"></i>}
                                rightLink={"/admin/stores/" + props.store.id + "/settings"}
                            />

                            <ViewStatusBar
                                theme={props.theme}
                                strings={props.strings}
                                
                                onSubmitView={props.onSubmitView}
                                onSubmitTrash={props.onSubmitTrash}
                            />

                            <Slider
                                imageSource={props.domain + "/media/store/"}
                                imageAlt={"store"}
                                images={props.images}
                                activeIndex={props.activeIndex}
                                setActiveIndex={props.setActiveIndex}
                                crope={props.crope}
                                isPhotosCrope={props.isPhotosCrope}
                            />
                            
                            <StoreFull
                                theme={props.theme}
                                strings={props.strings}
                                id={props.store.id}
                                title={props.store.title}
                                location={props.store.location}
                                marker={props.store.marker}
                                text={props.store.text}
                                phone={props.store.phone}
                                pub={props.store.pub}
                                rating={props.rating}
                                ratingCount={props.ratingCount}
                                status={props.store.status}
                            />

                            <BlockPanel
                                theme={props.theme}
                                icon1={<i className="fas fa-user-circle"></i>}
                                title={props.strings.storeUser}
                            />

                            {!props.ostProfile
                                ? props.storeStatus
                                    ? <SuccessMin
                                        theme={props.theme}
                                        icon={<i className="fas fa-user"></i>}
                                        success={props.strings['status' + props.storeStatus] + "!"}
                                    />
                                    : null
                                : <ProfileItem
                                    domain={props.domain}
                                    theme={props.theme}
                                    strings={props.strings}
                                    to={'/admin/profiles/'}
                                    id={props.ostProfile.id}
                                    avatar0={props.ostProfile.avatar0}
                                    login={props.ostProfile.login}
                                    name={props.ostProfile.name}
                                    location={props.ostProfile.location}
                                    marker={props.ostProfile.marker}
                                    text={props.ostProfile.text}
                                    phone={props.ostProfile.phone}
                                    reg={props.ostProfile.reg}
                                    status={props.ostProfile.status}
                                />
                            }

                            <BlockPanel
                                theme={props.theme}
                                icon1={<i className="fas fa-bag-shopping"></i>}
                                title={props.strings.storeProducts}
                            />

                            <ViewPanel
                                theme={props.theme}
                                strings={props.strings}
                                
                                setStatus={props.setStatus}
                            />

                            {props.productsIsFetching
                                ? <PreloaderMini />
                                : !props.products
                                    ? props.productsStatus
                                        ? <SuccessMin
                                            theme={props.theme}
                                            icon={<i className="fas fa-bag-shopping"></i>}
                                            success={props.strings['status' + props.productsStatus] + "!"}
                                        />
                                        : null
                                    : <>
                                        <Paginator
                                            theme={props.theme}
                                            count={props.count}
                                            totalCount={props.totalCount}
                                            currentPage={props.currentPage}
                                            pagesCount={props.pagesCount}
                                            onPageChanged={props.onPageChanged}
                                        />

                                        {props.products.map(
                                            p => <ProductItem
                                                    key={p.id}
                                                    domain={props.domain}
                                                    theme={props.theme}
                                                    strings={props.strings}
                                                    to={'/admin/products/'}
                                                    id={p.id}
                                                    image0={p.image0}
                                                    title={p.title}
                                                    location={p.location}
                                                    marker={p.marker}
                                                    type={p.type}
                                                    chapter={p.chapter}
                                                    categorie={p.categorie}
                                                    text={p.text}
                                                    price={p.price}
                                                    views={p.views}
                                                    status={p.status}
                                                    favoriteCount={p.favoriteCount}
                                                />
                                            )
                                        }

                                        <Paginator
                                            theme={props.theme}
                                            count={props.count}
                                            totalCount={props.totalCount}
                                            currentPage={props.currentPage}
                                            pagesCount={props.pagesCount}
                                            onPageChanged={props.onPageChanged}
                                        />
                                        
                                    </>
                            }
                        </>
                }
            </div>
        </div>
    )
}

export default AdminStoreContent