import React from 'react'

const CatServicesEquipment  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={2041}>{props.strings.cat2041}</option>
            <option value={2042}>{props.strings.cat2042}</option>
            <option value={2043}>{props.strings.cat2043}</option>
            <option value={2044}>{props.strings.cat2044}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatServicesEquipment 