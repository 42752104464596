import React from 'react'

const CatCafesMenu  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={16003}>{props.strings.cat16003}</option>
            <option value={16005}>{props.strings.cat16005}</option>
            <option value={16007}>{props.strings.cat16007}</option>
            <option value={16010}>{props.strings.cat16010}</option>
            <option value={16013}>{props.strings.cat16013}</option>
            <option value={16015}>{props.strings.cat16015}</option>
            <option value={16017}>{props.strings.cat16017}</option>
            <option value={16020}>{props.strings.cat16020}</option>
            <option value={16023}>{props.strings.cat16023}</option>
            <option value={16025}>{props.strings.cat16025}</option>
            <option value={16027}>{props.strings.cat16027}</option>
            <option value={16030}>{props.strings.cat16030}</option>
            <option value={16033}>{props.strings.cat16033}</option>
            <option value={16035}>{props.strings.cat16035}</option>
            <option value={16037}>{props.strings.cat16037}</option>
            <option value={16040}>{props.strings.cat16040}</option>
            <option value={16043}>{props.strings.cat16043}</option>
            <option value={16045}>{props.strings.cat16045}</option>
            <option value={16047}>{props.strings.cat16047}</option>
            <option value={16050}>{props.strings.cat16050}</option>
            <option value={16053}>{props.strings.cat16053}</option>
            <option value={16055}>{props.strings.cat16055}</option>
            <option value={16057}>{props.strings.cat16057}</option>
            <option value={16060}>{props.strings.cat16060}</option>
            <option value={16063}>{props.strings.cat16063}</option>
            <option value={16065}>{props.strings.cat16065}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatCafesMenu