import React from 'react'

const CatServicesPhones  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={2081}>{props.strings.cat2081}</option>
            <option value={2082}>{props.strings.cat2082}</option>
            <option value={2083}>{props.strings.cat2083}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatServicesPhones 