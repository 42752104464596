import { combineReducers, legacy_createStore as createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import authReducer from './auth-reducer'
import profileReducer from './profile-reducer'
import profileSetReducer from './profileSet-reducer'
import profileStoreReducer from './profileStore-reducer'
import profileStoreSetReducer from './profileStoreSet-reducer'
import addProductReducer from './addProduct-reducer'
import profileStoreProductsReducer from './profileStoreProducts-reducer'
import profileStoreProductReducer from './profileStoreProduct-reducer'
import profileStoreProductSetReducer from './profileStoreProductSet-reducer'
import profileCommentsReducer from './profileComments-reducer'
import profileCommentReducer from './profileComment-reducer'
import profileCommentSetReducer from './profileCommentSet-reducer'
import notifyReducer from './notify-reducer'
import advertisingsReducer from './advertisings-reducer'
import advertisingReducer from './advertising-reducer'
import headerReducer from './header-reducer'
import homeReducer from './home-reducer'
import topViewProductsReducer from './topViewProducts-reducer'
import topRatingStoresReducer from './topRatingStores-reducer'
import newProductsReducer from './newProductsReducer-reducer'
import productsReducer from './products-reducer'
import favoriteProductsReducer from './favoriteProducts-reducer'
import historyProductsReducer from './historyProducts-reducer'
import productReducer from './product-reducer'
import favoriteReducer from './favorite-reducer'
import addCommentReducer from './addComment-reducer'
import commentsReducer from './comments-reducer'
import storesReducer from './stores-reducer'
import historyStoresReducer from './historyStores-reducer'
import storeReducer from './store-reducer'
import storeProductsReducer from './storeProducts-reducer'
import addRatingReducer from './addRating-reducer'
import ratingReducer from './rating-reducer'
import sitebarReducer from './sitebar-reducer'
import notFoundReducer from './notFound-reducer'
import createStoreReducer from './createStore-reducer'
import adminPanelReducer from './adminPanel-reducer'
import adminAdminsReducer from './adminAdmins-reducer'
import addAdvertisingReducer from './addAdvertising-reducer'
import adminAdvertisingsReducer from './adminAdvertisings-reducer'
import adminAdvertisingReducer from './adminAdvertising-reducer'
import advertisingSetReducer from './advertisingSet-reducer'
import adminProfilesReducer from './adminProfiles-reducer'
import adminProfileReducer from './adminProfile-reducer'
import adminProfileCommentsReducer from './adminProfileComments-reducer'
import adminProfileSetReducer from './adminProfileSet-reducer'
import adminStoresReducer from './adminStores-reducer'
import adminStoreReducer from './adminStore-reducer'
import adminStoreProductsReducer from './adminStoreProducts-reducer'
import adminStoreSetReducer from './adminStoreSet-reducer'
import adminProductsReducer from './adminProducts-reducer'
import adminProductReducer from './adminProduct-reducer'
import adminProductSetReducer from './adminProductSet-reducer'
import adminCommentsReducer from './adminComments-reducer'
import adminCommentReducer from './adminComment-reducer'
import adminCommentSetReducer from './adminCommentSet-reducer'
import favoriteStoresReducer from './favoriteStores-reducer'
import addFavoriteReducer from './addFavorite-reducer'

let reducers = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    profileSet: profileSetReducer,
    createStore: createStoreReducer,
    profileStore: profileStoreReducer,
    profileStoreSet: profileStoreSetReducer,
    addProduct: addProductReducer,
    profileStoreProducts: profileStoreProductsReducer,
    profileStoreProduct: profileStoreProductReducer,
    profileStoreProductSet: profileStoreProductSetReducer,
    profileComments: profileCommentsReducer,
    profileComment: profileCommentReducer,
    profileCommentSet: profileCommentSetReducer,

    notify: notifyReducer,

    advertisings: advertisingsReducer,
    advertising: advertisingReducer,

    header: headerReducer,

    home: homeReducer,
    topViewProducts: topViewProductsReducer,
    topRatingStores: topRatingStoresReducer,
    newProducts: newProductsReducer,

    products: productsReducer,
    favoriteProducts: favoriteProductsReducer,
    historyProducts: historyProductsReducer,
    product: productReducer,
    favorite: favoriteReducer,
    addComment: addCommentReducer,
    comments: commentsReducer,
    addFavorite: addFavoriteReducer,

    stores: storesReducer,
    favoriteStores: favoriteStoresReducer,
    historyStores: historyStoresReducer,
    store: storeReducer,
    storeProducts: storeProductsReducer,
    addRating: addRatingReducer,
    rating: ratingReducer,

    sitebar: sitebarReducer,
    
    notFound: notFoundReducer,

    adminPanel: adminPanelReducer,
    adminAdmins: adminAdminsReducer,

    addAdvertising: addAdvertisingReducer,
    adminAdvertisings: adminAdvertisingsReducer,
    adminAdvertising: adminAdvertisingReducer,
    advertisingSet: advertisingSetReducer,

    adminProfiles: adminProfilesReducer,
    adminProfile: adminProfileReducer,
    adminProfileComments: adminProfileCommentsReducer,
    adminProfileSet: adminProfileSetReducer,

    adminStores: adminStoresReducer,
    adminStore: adminStoreReducer,
    adminStoreProducts: adminStoreProductsReducer,
    adminStoreSet: adminStoreSetReducer,

    adminProducts: adminProductsReducer,
    adminProduct: adminProductReducer,
    adminProductSet: adminProductSetReducer,

    adminComments: adminCommentsReducer,
    adminComment: adminCommentReducer,
    adminCommentSet: adminCommentSetReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const reduxStore = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default reduxStore