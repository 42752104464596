import React from 'react'
import dark from './VPDark.module.css'
import light from './VPLight.module.css'

const ViewPanel = (props) => {
    return (
        <div className={props.theme === 'light' ? light.viewStatusBar : dark.viewStatusBar}>
            <button
                className={
                    props.theme === 'light'
                        ? light.statusButton + ' ' + light.view
                        : dark.statusButton + ' ' + dark.view
                    }
                onClick={() => {props.setStatus(1)}}
            >
                <i className="fas fa-eye"></i>
            </button>
            <button
                className={
                    props.theme === 'light'
                        ? light.statusButton + ' ' + light.new
                        : dark.statusButton + ' ' + dark.new
                    }
                onClick={() => {props.setStatus(2)}}
            >
                <i className="fas fa-circle-check"></i>
            </button>
            <button
                className={
                    props.theme === 'light'
                        ? light.statusButton + ' ' + light.set
                        : dark.statusButton + ' ' + dark.set
                    }
                onClick={() => {props.setStatus(3)}}
            >
                <i className="fas fa-gear"></i>
            </button>
            <button
                className={
                    props.theme === 'light'
                        ? light.statusButton + ' ' + light.block
                        : dark.statusButton + ' ' + dark.block
                    }
                onClick={() => {props.setStatus(4)}}
            >
                <i className="fas fa-eye-slash"></i>
            </button>
        </div>
    )
}

export default ViewPanel