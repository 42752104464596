import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    setActiveIndex,
    setIsPhotosCrope,
    setSuccess,
    setError
} from '../../redux/profile-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setTrashed } from '../../redux/profileStoreSet-reducer'
import Profile from './Profile'

const ProfileContainer = (props) => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const images = useSelector(state => state.profile.images)
    const activeIndex = useSelector(state => state.profile.activeIndex)
    const isPhotosCrope = useSelector(state => state.profile.isPhotosCrope)
    const success = useSelector(state => state.profile.success)
    const error = useSelector(state => state.profile.error)
    const back = useSelector(state => state.profile.back)
    const isFetching = useSelector(state => state.profile.isFetching)

    const trashed = useSelector(state => state.profileStoreSet.trashed)

    useEffect(() => {
        if (!props.isAuth) {
            navigate('/login')
        } else if (props.profile.status === 0) {
            navigate('/logout')
        }
    }, [props.isAuth, props.profile.status, navigate])

    useEffect(() => {
        if (trashed) {
            dispatch(setTrashed(false))
        }
    }, [dispatch, trashed])

    const crope = () => {
        if(isPhotosCrope) {
            dispatch(setIsPhotosCrope(false))
        } else {
            dispatch(setIsPhotosCrope(true))
        }
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, success, error]
    )

    return (
        <Profile
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            images={images}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            isPhotosCrope={isPhotosCrope}
            crope={crope}
            back={back}
            isFetching={isFetching}
        />
    )
}

export default ProfileContainer