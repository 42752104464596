import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    requestAdvertisings,
    setActiveIndex
} from '../../redux/advertisings-reducer'
import AdvertisingSlider from '../Items/AdvertisingSlider/AdvertisingSlider'

const AdvertisingsSitebar = (props) => {
    const dispatch = useDispatch()
    const advertisings = useSelector(state => state.advertisings.advertisings)
    const activeIndex = useSelector(state => state.advertisings.activeIndex)
    const status = useSelector(state => state.advertisings.status)
    const success = useSelector(state => state.advertisings.success)
    const isFetching = useSelector(state => state.advertisings.isFetching)
    
    useEffect(() => {
        if (isFetching) {
            dispatch(requestAdvertisings())
        }
    }, [dispatch, isFetching])

    const len = advertisings ? advertisings.length - 1 : null

    useEffect(
        () => {
            const interval = setInterval(() => {
                dispatch(setActiveIndex(activeIndex === len ? 0 : activeIndex + 1))
            }, 5000)
            return () => clearInterval(interval)
        }, [dispatch, activeIndex, len]
    )

    const nextSlide = () => {
        dispatch(setActiveIndex(activeIndex === len ? 0 : activeIndex + 1))
    }

    const prevSlide = () => {
        dispatch(setActiveIndex(activeIndex < 1 ? len : activeIndex - 1))
    }

    const setSlide = (index) => {
        dispatch(setActiveIndex(index))
    }

    let x1 = null
    let y1 = null

    const swipeStart = (e) => {
        const firstTouch = e.touches[0]
        x1 = firstTouch.clientX
        y1 = firstTouch.clientY
    }

    const swipeMove = (e) => {
        if (!x1 || !y1) {
            return false
        }

        let x2 = e.touches[0].clientX
        let y2 = e.touches[0].clientY

        let xDiff = x2 - x1
        let yDiff = y2 - y1

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) dispatch(setActiveIndex(activeIndex < 1 ? len : activeIndex - 1))
            else dispatch(setActiveIndex(activeIndex === len ? 0 : activeIndex + 1))
        }

        x1 = null
        y1 = null
    }
    
    return (
        <>
            {advertisings
                ? <AdvertisingSlider
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}
                    advertisings={advertisings}
                    activeIndex={activeIndex}
                    status={status}
                    success={success}
                    nextSlide={nextSlide}
                    prevSlide={prevSlide}
                    setSlide={setSlide}
                    swipeStart={swipeStart}
                    swipeMove={swipeMove}
                />
                : null
            }
        </>
    )
}

export default AdvertisingsSitebar