import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    requestProfile,
    setProfile,
    setImages,
    setActiveIndex,
    setStore,
    setIsPhotosCrope,
    setSuccess as setProfileSuccess,
    setError as setProfileError
} from '../../redux/adminProfile-reducer'
import { changeStatus, setUpdated, setSuccess, setError, remove} from '../../redux/adminProfileSet-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setIsFetching } from '../../redux/adminProfiles-reducer'
import AdminProfile from './AdminProfile'

const AdminProfileContainer = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()

    const ostProfile = useSelector(state => state.adminProfile.profile)
    const images = useSelector(state => state.adminProfile.images)
    const activeIndex = useSelector(state => state.adminProfile.activeIndex)
    const store = useSelector(state => state.adminProfile.store)
    const isPhotosCrope = useSelector(state => state.adminProfile.isPhotosCrope)
    const status = useSelector(state => state.adminProfile.status)
    const success = useSelector(state => state.adminProfile.success)
    const error = useSelector(state => state.adminProfile.error)
    const back = useSelector(state => state.adminProfile.back)
    const ostProfileIsFetching = useSelector(state => state.adminProfile.isFetching)

    const updated = useSelector(state => state.adminProfileSet.updated)
    const profileSetSuccess = useSelector(state => state.adminProfileSet.success)
    const profileSetError = useSelector(state => state.adminProfileSet.error)
    const isFetching = useSelector(state => state.adminProfileSet.isFetching)

    const admin = 1

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        } else if (!params.profileId) {
            navigate('/admin/profiles')
        } else if (!ostProfile) {
            navigate('/admin/profiles')
        }
    }, [navigate, props.isAuth, props.isAdmin, params.profileId, ostProfile])
    
    useEffect(() => {
        if (params.profileId || updated) {
            dispatch(setProfile([]))
            dispatch(setImages([]))
            dispatch(setActiveIndex(0))
            dispatch(setStore([]))
            dispatch(requestProfile(params.profileId))
            if (updated) {
                dispatch(setIsFetching(true))
                dispatch(setUpdated(false))
            }
            window.scrollTo({top: 0, left: 0})
        }
    }, [dispatch, navigate, params.profileId, updated])

    const onSubmitView = async (statusId) => {
        dispatch(changeStatus(admin, params.profileId, statusId))
    }

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(admin, params.profileId, trash))
        dispatch(setIsFetching(true))
        setTimeout(() => {
            navigate('/admin/profiles')
        }, 1000)
    }

    const crope = () => {
        if(isPhotosCrope) {
            dispatch(setIsPhotosCrope(false))
        } else {
            dispatch(setIsPhotosCrope(true))
        }
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setProfileError(null))
            } else if(profileSetError){
                dispatch(setNotifyError(profileSetError))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setProfileSuccess(null))
            } else if(profileSetSuccess){
                dispatch(setNotifySuccess(profileSetSuccess))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success, profileSetError, profileSetSuccess]
    )

    return (
        <AdminProfile
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            ostProfile={ostProfile}
            images={images}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            store={store}
            status={status}
            isPhotosCrope={isPhotosCrope}
            crope={crope}
            back={back}
            ostProfileIsFetching={ostProfileIsFetching}

            isFetching={isFetching}
            
            onSubmitView={onSubmitView}
            onSubmitTrash={onSubmitTrash}
        />
    )
}

export default AdminProfileContainer