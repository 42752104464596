import React from 'react'

const CatAnimalsPets  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={18003}>{props.strings.cat18003}</option>
            <option value={18005}>{props.strings.cat18005}</option>
            <option value={18007}>{props.strings.cat18007}</option>
            <option value={18010}>{props.strings.cat18010}</option>
            <option value={18013}>{props.strings.cat18013}</option>
            <option value={18015}>{props.strings.cat18015}</option>
            <option value={18017}>{props.strings.cat18017}</option>
            <option value={18020}>{props.strings.cat18020}</option>
            <option value={18023}>{props.strings.cat18023}</option>
            <option value={18025}>{props.strings.cat18025}</option>
            <option value={18030}>{props.strings.cat18030}</option>
            <option value={18033}>{props.strings.cat18033}</option>
            <option value={18035}>{props.strings.cat18035}</option>
            <option value={18037}>{props.strings.cat18037}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatAnimalsPets