import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    changeImage,
    imageDelete,
    changeTitle,
    changeText,
    setSuccess,
    setError,
    remove,
    setUpdated
} from '../../redux/adminStoreSet-reducer'
import { resizeFile, resizeFileMini } from '../../api/main'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setIsFetching } from '../../redux/adminStores-reducer'
import AdminStoreSet from './AdminStoreSet'
import {
    requestStore,
    setStore,
    setImages,
    setProfile
} from '../../redux/adminStore-reducer'

const AdminStoreSetContainer = (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()
    
    const store = useSelector(state => state.adminStore.store)

    const updated = useSelector(state => state.adminStoreSet.updated)
    const success = useSelector(state => state.adminStoreSet.success)
    const error = useSelector(state => state.adminStoreSet.error)
    const back = useSelector(state => state.adminStoreSet.back)
    const isFetching = useSelector(state => state.adminStoreSet.isFetching)

    const id = store.id
    const admin = 1

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        } else if (!params.storeId) {
            navigate('/admin/stores')
        } else if (!store) {
            navigate('/admin/stores')
        }
    }, [navigate, props.isAuth, props.isAdmin, params.storeId, store])
    
    useEffect(() => {
        if (params.storeId || updated) {
            dispatch(setStore([]))
            dispatch(setImages([]))
            dispatch(setProfile([]))
            dispatch(requestStore(params.storeId, admin))
            if (updated) {
                dispatch(setIsFetching(true))
                dispatch(setUpdated(false))
            }
        }
    }, [dispatch, navigate, params.storeId, updated])

    const onSubmitImage = async (e) => {
        const file = e.target.files[0]
        const image0 = await resizeFileMini(file)
        const image = await resizeFile(file)
        const imageMiniId = 1
        const imageId = e.target.attributes.imageid.value
        if (props.profileStore.image0 === null) {
            dispatch(changeImage(admin, id, imageMiniId, image0))
            dispatch(changeImage(admin, id, imageId, image))
        } else {
            dispatch(changeImage(admin, id, imageId, image))
        }
    }

    const onSubmitRemoveImage = async (e) => {
        const removeId = e.target.attributes.imageid.value
        dispatch(imageDelete(admin, id, removeId))
}
    
    const validateTitle= (values) => {
        const errors = {}
        if (!values.title) {
            errors.title = props.strings.required
        } else if (values.title.length < 3) {
            errors.title = props.strings.min + ': 3/' + values.title.length
        } else if (values.title.length > 50) {
            errors.title = props.strings.max + ': 50/' + values.title.length
        } else if (!/^[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я0-9\s._-]{3,50}$/i.test(values.title)) {
            errors.title = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'
        }
        return errors
    }

    const onSubmitTitle = (values, { setSubmitting }) => {
        const title = values.title
        dispatch(changeTitle(admin, id, title))
        setSubmitting(false)
    }
            
    const validateText= (values) => {
        const errors = {}
        if (!values.text) {
            errors.text = props.strings.required
        } else if (values.text.length < 10) {
            errors.text = props.strings.min + ': 10/' + values.text.length
        } else if (values.text.length > 1500) {
            errors.text = props.strings.max + ': 1500/' + values.text.length
        }
        return errors
    }

    const onSubmitText = (values, { setSubmitting }) => {
        const text = values.text
        dispatch(changeText(admin, id, text))
        setSubmitting(false)
    }

    const onSubmitTrash = () => {
        const trash = 1
        dispatch(remove(admin, id, trash))
        dispatch(setIsFetching(true))
        setTimeout(() => {
            navigate('/admin/stores')
        }, 1000)
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, success, error]
    )

    return (
        <AdminStoreSet
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            
            store={store}
            
            back={back}
            isFetching={isFetching}

            validateTitle={validateTitle}
            validateText={validateText}

            onSubmitImage={onSubmitImage}
            onSubmitRemoveImage={onSubmitRemoveImage}
            onSubmitTitle={onSubmitTitle}
            onSubmitText={onSubmitText}
            onSubmitTrash={onSubmitTrash}
            navigate={navigate}
        />
    )
}

export default AdminStoreSetContainer