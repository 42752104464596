import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    signup,
    setSuccess,
    setError
} from '../../redux/auth-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import Signup from './Signup'

const SignupContainer= (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const success = useSelector(state => state.auth.success)
    const error = useSelector(state => state.auth.error)
    const back = useSelector(state => state.auth.back)

    useEffect(() => {
        if (props.isAuth) {
            setTimeout(() => {
                navigate('/profile')
            }, 1000)
        }
    }, [props.isAuth, navigate])

    const initialValues = {
        name: '',
        login: '',
        location: '',
        marker: '',
        code: '',
        phone: '',
        password: '',
        password2: '',
        checkUsing: ''
    }

    const validate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = props.strings.required
        } else if (values.name.length < 3) {
            errors.name = props.strings.min + ': 3/' + values.name.length
        } else if (values.name.length > 50) {
            errors.name = props.strings.max + ': 50/' + values.name.length
        } else if (!/^[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я\s]{3,50}$/i.test(values.name)) {
            errors.name = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'
        }

        if (!values.login) {
            errors.login = props.strings.required
        } else if (values.login.length < 3) {
            errors.login = props.strings.min + ': 3/' + values.login.length
        } else if (values.login.length > 50) {
            errors.login = props.strings.max + ': 50/' + values.login.length
        } else if (!/^[A-Za-z0-9_]{3,50}$/i.test(values.login)) {
            errors.login = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (_), ' + props.strings.strings + ' (A-Z, a-z).'
        }

        if (!values.location) {
            if (!values.location) {
                errors.location = props.strings.required
            } else if (values.location === '0') {
                errors.location = props.strings.required
            }
        }

        if (!values.marker) {
            if (!values.marker) {
                errors.marker = props.strings.required
            } else if (values.marker === '0') {
                errors.marker = props.strings.required
            }
        }

        if (!values.phone) {
            errors.phone = props.strings.required
        } else if (values.phone.length < 8) {
            errors.phone = props.strings.min + ': 8/' + values.phone.length
        } else if (values.phone.length > 10) {
            errors.phone = props.strings.max + ': 10/' + values.phone.length
        } else if (!/^[0-9]{8,10}$/i.test(values.phone)) {
            errors.phone = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9).'
        }
    
        if (!values.password) {
            errors.password = props.strings.required
        } else if (values.password.length < 8) {
            errors.password = props.strings.min + ': 8/' + values.password.length
        } else if (values.password.length > 20) {
            errors.password = props.strings.max + ': 20/' + values.password.length
        } else if (!/^[A-Za-z0-9]{8,20}$/i.test(values.password)) {
            errors.password = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.strings + ' (A-Z, a-z).'
        }

        if (!values.password2) {
            errors.password2 = props.strings.required
        } else if (values.password2 !== values.password) {
            errors.password2 = props.strings.error + '. ' + props.strings.passwordRepeatErr
        }

        if (!values.checkUsing) {
            errors.checkUsing = props.strings.error106
        }
        return errors;
    }

    const onSubmit = (values, { setSubmitting }) => {
        const name = values.name
        const login = values.login
        const location = values.location
        const marker = values.marker
        const code = values.code ? values.code : '+993'
        const phone = code + ' ' + values.phone
        const password = values.password
        dispatch(signup(name, login, location, marker, phone, password))
        setSubmitting(false)
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <Signup
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            advertisingsSitebar={props.advertisingsSitebar}

            back={back}

            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
        />
    )
}

export default SignupContainer