import React from 'react'
import dark from './NotifyDark.module.css'
import light from './NotifyLight.module.css'

let NotifyWarning = (props) => {
    return (
        <div className={props.theme === 'light' ? light.notify : dark.notify}>
            {props.warning
                ? <>
                    <div className={props.theme === 'light' ? light.icon : dark.icon}>
                        <i className="fas fa-circle-exclamation"></i>
                    </div>
                    <div className={props.theme === 'light' ? light.notifyText : dark.notifyText}>
                        {props.strings.warning + ": " + props.strings['warning' + props.warning]}!
                    </div>
                </>
                : null
            }
        </div>
    )
}

export default NotifyWarning