import React from 'react'

const CatJobs  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={3010}>{props.strings.cat3010}</option>
            <option value={3020}>{props.strings.cat3020}</option>
            <option value={3030}>{props.strings.cat3030}</option>
            <option value={3040}>{props.strings.cat3040}</option>
            <option value={3050}>{props.strings.cat3050}</option>
            <option value={3060}>{props.strings.cat3060}</option>
            <option value={3070}>{props.strings.cat3070}</option>
            <option value={3080}>{props.strings.cat3080}</option>
            <option value={3090}>{props.strings.cat3090}</option>
            <option value={3100}>{props.strings.cat3100}</option>
            <option value={3110}>{props.strings.cat3110}</option>
            <option value={3120}>{props.strings.cat3120}</option>
            <option value={3130}>{props.strings.cat3130}</option>
            <option value={3140}>{props.strings.cat3140}</option>
            <option value={3150}>{props.strings.cat3150}</option>
            <option value={3160}>{props.strings.cat3160}</option>
            <option value={3170}>{props.strings.cat3170}</option>
            <option value={3180}>{props.strings.cat3180}</option>
            <option value={3190}>{props.strings.cat3190}</option>
            <option value={3200}>{props.strings.cat3200}</option>
            <option value={3210}>{props.strings.cat3210}</option>
            <option value={3220}>{props.strings.cat3220}</option>
            <option value={3230}>{props.strings.cat3230}</option>
            <option value={3240}>{props.strings.cat3240}</option>
            <option value={3250}>{props.strings.cat3250}</option>
            <option value={3260}>{props.strings.cat3260}</option>
            <option value={3270}>{props.strings.cat3270}</option>
            <option value={3280}>{props.strings.cat3280}</option>
            <option value={3290}>{props.strings.cat3290}</option>
            <option value={3300}>{props.strings.cat3300}</option>
            <option value={3310}>{props.strings.cat3310}</option>
            <option value={3320}>{props.strings.cat3320}</option>
            <option value={3330}>{props.strings.cat3330}</option>
            <option value={3340}>{props.strings.cat3340}</option>
            <option value={3350}>{props.strings.cat3350}</option>
            <option value={3360}>{props.strings.cat3360}</option>
            <option value={3370}>{props.strings.cat3370}</option>
            <option value={3380}>{props.strings.cat3380}</option>
            <option value={3390}>{props.strings.cat3390}</option>
            <option value={3400}>{props.strings.cat3400}</option>
            <option value={3410}>{props.strings.cat3410}</option>
            <option value={3420}>{props.strings.cat3420}</option>
            <option value={3430}>{props.strings.cat3430}</option>
            <option value={3440}>{props.strings.cat3440}</option>
            <option value={3450}>{props.strings.cat3450}</option>
            <option value={3460}>{props.strings.cat3460}</option>
            <option value={3470}>{props.strings.cat3470}</option>
            <option value={3480}>{props.strings.cat3480}</option>
            <option value={3490}>{props.strings.cat3490}</option>
            <option value={3500}>{props.strings.cat3500}</option>
            <option value={3510}>{props.strings.cat3510}</option>
            <option value={3520}>{props.strings.cat3520}</option>
            <option value={3530}>{props.strings.cat3530}</option>
            <option value={3540}>{props.strings.cat3540}</option>
            <option value={3550}>{props.strings.cat3550}</option>
            <option value={3560}>{props.strings.cat3560}</option>
            <option value={3570}>{props.strings.cat3570}</option>
            <option value={3580}>{props.strings.cat3580}</option>
            <option value={3590}>{props.strings.cat3590}</option>
            <option value={3600}>{props.strings.cat3600}</option>
            <option value={3610}>{props.strings.cat3610}</option>
            <option value={3620}>{props.strings.cat3620}</option>
            <option value={3630}>{props.strings.cat3630}</option>
            <option value={3640}>{props.strings.cat3640}</option>
            <option value={3650}>{props.strings.cat3650}</option>
            <option value={3660}>{props.strings.cat3660}</option>
            <option value={3670}>{props.strings.cat3670}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatJobs 