const SET_NAV_TOGGLE = 'header/SET_NAV_TOGGLE'
const IS_FETCHING = 'header/IS_FETCHING'

let initialState = {
    navToggle: true,
    isFetching: true
}

const headerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NAV_TOGGLE:
            return { ...state, navToggle: action.navToggle }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setNavToggle = (navToggle) => ({ type: SET_NAV_TOGGLE, navToggle })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export default headerReducer