import { AdminAdvertisingAPI } from '../api/api'

const SET_NEW_ADVERTISING_ID = 'addAdvertising/SET_NEW_ADVERTISING_ID'
const SET_SUCCESS = 'addAdvertising/SET_SUCCESS'
const SET_ERROR = 'addAdvertising/SET_ERROR'
const IS_FETCHING = 'addAdvertising/IS_FETCHING'

let initialState = {
    newAdvertisingId: null,
    success: null,
    error: null,
    back: true,
    isFetching: false
}

const addAdvertisingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEW_ADVERTISING_ID:
            return { ...state, newAdvertisingId: action.newAdvertisingId }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setNewAdvertisingId = (newAdvertisingId) => ({ type: SET_NEW_ADVERTISING_ID, newAdvertisingId })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const addAdvertising = (title, subtitle, text) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdminAdvertisingAPI.addAdvertising(title, subtitle, text)
        dispatch(setNewAdvertisingId(data.data.id))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default addAdvertisingReducer