import React from 'react'
import dark from '../../../CSS/Dark.module.css'
import light from '../../../CSS/Light.module.css'
import BlockPanel from '../../common/BlockPanel/BlockPanel'
import SetAdsHrefForm from '../../common/Forms/SetAdsHrefForm'

const SetHref = (props) => {
    return (
        <>
            <BlockPanel
                theme={props.theme}
                iconLeft={<i className="fas fa-chevron-left"></i>}
                leftLink={"/admin/advertisings/" + props.advertising.id + "/settings"}
                icon1={<i className="fas fa-link"></i>}
                title={props.advertising.href}
            />
            <div className={props.theme === 'light' ? light.formContainer : dark.formContainer}>
                <SetAdsHrefForm
                    theme={props.theme}
                    strings={props.strings}
                    onSubmit={props.onSubmit}
                />
            </div>
        </>
    )
}

export default SetHref