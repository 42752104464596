import React from 'react'

const Recreation = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={14001}>{props.strings.cat14001}</option>
            <option value={14100}>{props.strings.cat14100}</option>
        </>
    )
}

export default Recreation