import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import { Route, Routes } from 'react-router-dom'
import Menu from '../Items/Menu/Menu'
import Set from './Settings/Set'
import SetVideo from './Settings/SetVideo'
import SetImage from './Settings/SetImage'
import SetTitle from './Settings/SetTitle'
import SetAbout from './Settings/SetAbout'
import SetPrice from './Settings/SetPrice'
import SetTrash from './Settings/SetTrash'

const AdminProductSetContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />
                <Routes>
                    <Route
                        path='/'
                        element={
                            <Set
                                domain={props.domain}
                                theme={props.theme}
                                strings={props.strings}
                                product={props.product}
                            />
                        }
                    />

                    <Route
                        path='/video'
                        element={
                            <SetVideo
                                domain={props.domain}
                                theme={props.theme}
                                strings={props.strings}
                                product={props.product}
                                changeVideo={props.onSubmitVideo}
                                removeVideo={props.onSubmitRemoveVideo}
                            />
                        }
                    />

                    <Route
                        path='/image'
                        element={
                            <SetImage
                                domain={props.domain}
                                theme={props.theme}
                                strings={props.strings}
                                product={props.product}
                                changeImage={props.onSubmitImage}
                                removeImage={props.onSubmitRemoveImage}
                            />
                        }
                    />

                    <Route
                        path='/title'
                        element={
                            <SetTitle
                                theme={props.theme}
                                strings={props.strings}
                                product={props.product}
                                validate={props.validateTitle}
                                onSubmit={props.onSubmitTitle}
                            />
                        }
                    />

                    <Route
                        path='/about'
                        element={
                            <SetAbout
                                theme={props.theme}
                                strings={props.strings}
                                product={props.product}
                                validate={props.validateText}
                                onSubmit={props.onSubmitText}
                            />
                        }
                    />

                    <Route
                        path='/price'
                        element={
                            <SetPrice
                                theme={props.theme}
                                strings={props.strings}
                                product={props.product}
                                validate={props.validatePrice}
                                onSubmit={props.onSubmitPrice}
                            />
                        }
                    />

                    <Route
                        path='/trash'
                        element={
                            <SetTrash
                                theme={props.theme}
                                strings={props.strings}
                                product={props.product}
                                navigate={props.navigate}
                                onSubmitTrash={props.onSubmitTrash}
                            />
                        }
                    />

                </Routes>
            </div>
        </div>
    )
}

export default AdminProductSetContent