import React from 'react'
import light from './AdminPanelItemLight.module.css'
import dark from './AdminPanelItemDark.module.css'

const AdminPanelItem = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockView : dark.blockView}>

            <div className={props.theme === 'light' ? light.title : dark.title}>
                <h3>{props.icon} {props.title}</h3>
            </div>

            {props.new
                ? <div className={props.theme === 'light' ? light.item : dark.item}>
                    
                    <div className={props.theme === 'light' ? light.column : dark.column}>
                        {props.strings.panelNew}:
                    </div>

                    <div className={props.theme === 'light' ? light.string : dark.string}>
                        {props.new}
                    </div>

                </div>
                : null
            }

            {props.set
                ? <div className={props.theme === 'light' ? light.item : dark.item}>
                    
                    <div className={props.theme === 'light' ? light.column : dark.column}>
                        {props.strings.panelSet}:
                    </div>
                    
                    <div className={props.theme === 'light' ? light.string : dark.string}>
                        {props.set}
                    </div>

                </div>
                : null
            }
            
            {props.block
                ? <div className={props.theme === 'light' ? light.item : dark.item}>
                    
                    <div className={props.theme === 'light' ? light.column : dark.column}>
                        {props.strings.panelBlock}:
                    </div>
                    
                    <div className={props.theme === 'light' ? light.string : dark.string}>
                        {props.block}
                    </div>

                </div>
                : null
            }
            
            {props.none
                ? <div className={props.theme === 'light' ? light.item : dark.item}>
                    
                    <div className={props.theme === 'light' ? light.column : dark.column}>
                        {props.strings.panelError}:
                    </div>
                    
                    <div className={props.theme === 'light' ? light.string : dark.string}>
                        {props.none}
                    </div>

                </div>
                : null
            }
            
            {props.view
                ? <div className={props.theme === 'light' ? light.item : dark.item}>
                    
                    <div className={props.theme === 'light' ? light.column : dark.column}>
                        {props.strings.panelView}:
                    </div>
                    
                    <div className={props.theme === 'light' ? light.string : dark.string}>
                        {props.view}
                    </div>

                </div>
                : null
            }
            
            {props.count
                ? <div className={props.theme === 'light' ? light.item : dark.item}>

                    <div className={props.theme === 'light' ? light.line : dark.line}></div>

                    <div className={props.theme === 'light' ? light.column : dark.column}>
                        {props.strings.panelCount}:
                    </div>
                    
                    <div className={props.theme === 'light' ? light.string : dark.string}>
                        {props.count}
                    </div>

                </div>
                : null
            }

        </div>
    )
}

export default AdminPanelItem