import React from 'react'

const CatBeautyLips  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12103}>{props.strings.cat12103}</option>
            <option value={12104}>{props.strings.cat12104}</option>
            <option value={12118}>{props.strings.cat12118}</option>
            <option value={12119}>{props.strings.cat12119}</option>
            <option value={12124}>{props.strings.cat12124}</option>
            <option value={12138}>{props.strings.cat12138}</option>
            <option value={12141}>{props.strings.cat12141}</option>
            <option value={12155}>{props.strings.cat12155}</option>
            <option value={12172}>{props.strings.cat12172}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyLips