import React from 'react'
import dark from './FormDark.module.css'
import light from './FormLight.module.css'
import { NavLink } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'

const LoginForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.formPanel : dark.formPanel}>
            <Formik
                initialValues={props.initialValues}
                validate={props.validate}
                onSubmit={props.onSubmit}
            >
                {({ errors, isSubmitting }) => (
                    <Form className={props.theme === 'light' ? light.form : dark.form} >

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="phone"
                            component="div"
                        />
                        <Field
                            className={props.theme === 'light' ? light.codeRegion : dark.codeRegion}
                            name="code"
                            as="select"
                        >
                            <option defaultChecked value="+993">+993 Türkmenistan</option>
                            <option value="+90">+90 Türkiýe</option>
                            <option value="+7">+7 Россия</option>
                        </Field>

                        <Field
                            className={props.theme === 'light' ? light.phone : dark.phone}
                            type="tel"
                            name="phone"
                            autoComplete="current-phone"
                            placeholder={props.strings.phonePlace}
                            pattern="[0-9]{8,10}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9).'}
                        />


                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="password"
                            component="div"
                        />
                        <Field
                            type="password"
                            name="password"
                            autoComplete="current-password"
                            placeholder={props.strings.password}
                            pattern="[A-Z,a-z,0-9]{8,15}"
                            title={props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.strings + ' (A-Z, a-z).'}
                        />

                        <button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {props.strings.entrance}
                        </button>

                        <NavLink
                            className={props.theme === 'light' ? light.link : dark.link}
                            to="/login/forgot-password"
                        >
                            <i className="fas fa-chevron-left"></i> {props.strings.passwordForgot}?
                        </NavLink>

                        <NavLink
                            className={props.theme === 'light' ? light.link : dark.link}
                            to="/signup"
                        >
                            <i className="fas fa-chevron-left"></i> {props.strings.signup}
                        </NavLink>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default LoginForm