import { HomeAPI } from '../api/api'

const SET_NEW_PRODUCTS = 'newProducts/SET_NEW_PRODUCTS'
const SET_STATUS = 'newProducts/SET_STATUS'
const SET_SUCCESS = 'newProducts/SET_SUCCESS'
const IS_FETCHING = 'newProducts/IS_FETCHING'

let initialState = {
    newProducts: [],
    status: null,
    success: null,
    isFetching: true
}

const newProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NEW_PRODUCTS:
            return { ...state, newProducts: action.newProducts }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setNewProducts = (newProducts) => ({ type: SET_NEW_PRODUCTS, newProducts })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestNewProducts = () => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await HomeAPI.newProducts()
        dispatch(setNewProducts(data.data.newProducts))
        dispatch(setSuccess(data.data.success))
        dispatch(setStatus(data.data.status))
        dispatch(setIsFetching(false))
    }
}

export default newProductsReducer