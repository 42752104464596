import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ForgotPassword from './ForgotPassword'

const ForgotPasswordContainer= (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    
    const back = useSelector(state => state.auth.back)
    const isFetching = useSelector(state => state.auth.isFetching)

    useEffect(() => {
        if (props.isAuth) {
            navigate('/profile')
        }
    }, [props.isAuth, navigate])

    return (
        <ForgotPassword
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            
            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            advertisingsSitebar={props.advertisingsSitebar}
            
            back={back}
            isFetching={isFetching}
        />
    )
}

export default ForgotPasswordContainer