import React from 'react'

const CatElectronicsOfficeEquipment  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10381}>{props.strings.cat10381}</option>
            <option value={10382}>{props.strings.cat10382}</option>
            <option value={10383}>{props.strings.cat10383}</option>
            <option value={10384}>{props.strings.cat10384}</option>
            <option value={10385}>{props.strings.cat10385}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsOfficeEquipment 