import React from 'react'

const Chevrolet = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1000700}>CHEVROLET 150</option>
            <option value={1000701}>CHEVROLET 2500</option>
            <option value={1000702}>CHEVROLET 3500</option>
            <option value={1000703}>CHEVROLET APV</option>
            <option value={1000704}>CHEVROLET ASTRO</option>
            <option value={1000705}>CHEVROLET AVALANCHE</option>
            <option value={1000706}>CHEVROLET AVALANCHE 1500</option>
            <option value={1000707}>CHEVROLET AVALANCHE 2500</option>
            <option value={1000708}>CHEVROLET AVEO</option>
            <option value={1000709}>CHEVROLET BEL AIR</option>
            <option value={1000710}>CHEVROLET BERETTA</option>
            <option value={1000711}>CHEVROLET BLAZER</option>
            <option value={1000713}>CHEVROLET CAMARO</option>
            <option value={1000714}>CHEVROLET CAPRICE</option>
            <option value={1000715}>CHEVROLET CAPRICE CLASSIC</option>
            <option value={1000716}>CHEVROLET CAPTIVA</option>
            <option value={1000717}>CHEVROLET CAVALIER</option>
            <option value={1000718}>CHEVROLET CITATION</option>
            <option value={1000719}>CHEVROLET CLASSIC</option>
            <option value={1000720}>CHEVROLET COBALT</option>
            <option value={1000721}>CHEVROLET COBALT SS</option>
            <option value={1000722}>CHEVROLET COLORADO</option>
            <option value={1000723}>CHEVROLET CORSICA</option>
            <option value={1000724}>CHEVROLET CORVAIR</option>
            <option value={1000725}>CHEVROLET CORVAIR 500</option>
            <option value={1000726}>CHEVROLET CORVETTE</option>
            <option value={1000727}>CHEVROLET CRUZE</option>
            <option value={1000728}>CHEVROLET EPICA</option>
            <option value={1000729}>CHEVROLET EQINOX</option>
            <option value={1000730}>CHEVROLET EXPRESS</option>
            <option value={1000731}>CHEVROLET EXPRESS 1500</option>
            <option value={1000732}>CHEVROLET EXPRESS 2500</option>
            <option value={1000733}>CHEVROLET EXPRESS 3500</option>
            <option value={1000734}>CHEVROLET G-SERIES 1500</option>
            <option value={1000735}>CHEVROLET G-SERIES 2500</option>
            <option value={1000736}>CHEVROLET G-SERIES 3500</option>
            <option value={1000737}>CHEVROLET G-SERIES G10</option>
            <option value={1000738}>CHEVROLET G-SERIES G20</option>
            <option value={1000739}>CHEVROLET G-SERIES G30</option>
            <option value={1000740}>CHEVROLET HHR</option>
            <option value={1000741}>CHEVROLET HHR PANEL</option>
            <option value={1000742}>CHEVROLET IMPALA</option>
            <option value={1000743}>CHEVROLET IMPALA SS</option>
            <option value={1000744}>CHEVROLET K5 BLAZER</option>
            <option value={1000745}>CHEVROLET LUV</option>
            <option value={1000746}>CHEVROLET LUMINA</option>
            <option value={1000747}>CHEVROLET LUMINA APV</option>
            <option value={1000748}>CHEVROLET MALIBU</option>
            <option value={1000749}>CHEVROLET MALIBU MAXX</option>
            <option value={1000750}>CHEVROLET METRO</option>
            <option value={1000751}>CHEVROLET MONTE CARLO</option>
            <option value={1000752}>CHEVROLET MONZA</option>
            <option value={1000753}>CHEVROLET NIVA</option>
            <option value={1000754}>CHEVROLET PRIZM</option>
            <option value={1000755}>CHEVROLET S10</option>
            <option value={1000756}>CHEVROLET S10 BLAZER</option>
            <option value={1000757}>CHEVROLET SSR</option>
            <option value={1000758}>CHEVROLET SILVERADO</option>
            <option value={1000759}>CHEVROLET SILVERADO 1500</option>
            <option value={1000760}>CHEVROLET SILVERADO 2500</option>
            <option value={1000761}>CHEVROLET SILVERADO 3500</option>
            <option value={1000762}>CHEVROLET SILVERADO 3500HD</option>
            <option value={1000763}>CHEVROLET SILVERADO HYBRID</option>
            <option value={1000764}>CHEVROLET SONIC</option>
            <option value={1000765}>CHEVROLET SPARK</option>
            <option value={1000766}>CHEVROLET SPORTVAN</option>
            <option value={1000767}>CHEVROLET SPORTVAN G10</option>
            <option value={1000768}>CHEVROLET SPORTVAN G20</option>
            <option value={1000769}>CHEVROLET SPORTVAN G30</option>
            <option value={1000770}>CHEVROLET SUBURBAN</option>
            <option value={1000771}>CHEVROLET SUBURBAN 1500</option>
            <option value={1000772}>CHEVROLET SUBURBAN 2500</option>
            <option value={1000773}>CHEVROLET TAHOE</option>
            <option value={1000774}>CHEVROLET TRACKER</option>
            <option value={1000775}>CHEVROLET TRAIL BLAZER</option>
            <option value={1000776}>CHEVROLET TRAVERSE</option>
            <option value={1000777}>CHEVROLET UPLANDER</option>
            <option value={1000778}>CHEVROLET VEGA</option>
            <option value={1000779}>CHEVROLET VENTURE</option>
            <option value={1000780}>CHEVROLET VOLT</option>
        </>
    )
}

export default Chevrolet