import React from 'react'
import light from './CommentsLight.module.css'
import dark from './CommentsDark.module.css'
import { NavLink } from 'react-router-dom'

const CommentsItem = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockView : dark.blockView}>

            <div className={props.theme === 'light' ? light.user : dark.user}>

                <div className={props.theme === 'light' ? light.avatar : dark.avatar}>
                    {props.avatar !== null
                        ? <img src={props.domain + "/media/avatar/" + props.avatar} alt='avatar'/>
                        : <img src={props.domain + "/media/avatar/nullmini.png"} alt="avatar" />
                    }
                </div>
                
                <div className={props.theme === 'light' ? light.userInfo : dark.userInfo}>
                    {props.login ? <><i className="fas fa-user-circle"></i> {props.login}</> : null}
                </div>

                {props.profile.id === props.profileId
                    ? <NavLink
                        to={props.to + props.id}
                        className={
                            props.theme === 'light'
                            ? light.link
                            : dark.link
                        }
                    >
                        <i className="fas fa-ellipsis-v"></i>
                    </NavLink>
                    : <div className={props.theme === 'light' ? light.icon : dark.icon}>
                        <i className="fas fa-comment"></i>
                    </div>
                }

            </div>

            <div className={props.theme === 'light' ? light.info : dark.info}>
                {props.profile.id === props.profileId || props.isAdmin || props.isAuth
                    ? <div className={props.theme === 'light' ? light.status : dark.status}>
                        {props.strings['status' + props.status]}...
                    </div>
                    : null
                }
                
                <div className={props.theme === 'light' ? light.fullInfo : dark.fullInfo}>

                    <div className={props.theme === 'light' ? light.text : dark.text}>
                        {props.text}
                    </div>

                    <div className={props.theme === 'light' ? light.views : dark.views}>
                        {props.strings.pub}: <span>{props.pub} <i className="fas fa-calendar-check"></i></span>
                    </div>

                </div>
                
            </div>
        </div>
    )
}

export default CommentsItem