import React from 'react'

const CatChancelleryPaperProducts  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={9002}>{props.strings.cat9002}</option>
            <option value={9005}>{props.strings.cat9005}</option>
            <option value={9006}>{props.strings.cat9006}</option>
            <option value={9007}>{props.strings.cat9007}</option>
            <option value={9008}>{props.strings.cat9008}</option>
            <option value={9009}>{props.strings.cat9009}</option>
            <option value={9010}>{props.strings.cat9010}</option>
            <option value={9011}>{props.strings.cat9011}</option>
            <option value={9012}>{props.strings.cat9012}</option>
            <option value={9013}>{props.strings.cat9013}</option>
            <option value={9014}>{props.strings.cat9014}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatChancelleryPaperProducts 