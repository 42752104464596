import React from 'react'

const CatElectronicsAppliances  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10901}>{props.strings.cat10901}</option>
            <option value={10902}>{props.strings.cat10902}</option>
            <option value={10903}>{props.strings.cat10903}</option>
            <option value={10904}>{props.strings.cat10904}</option>
            <option value={10905}>{props.strings.cat10905}</option>
            <option value={10906}>{props.strings.cat10906}</option>
            <option value={10907}>{props.strings.cat10907}</option>
            <option value={10908}>{props.strings.cat10908}</option>
            <option value={10909}>{props.strings.cat10909}</option>
            <option value={10910}>{props.strings.cat10910}</option>
            <option value={10911}>{props.strings.cat10911}</option>
            <option value={10912}>{props.strings.cat10912}</option>
            <option value={10913}>{props.strings.cat10913}</option>
            <option value={10914}>{props.strings.cat10914}</option>
            <option value={10915}>{props.strings.cat10915}</option>
            <option value={10916}>{props.strings.cat10916}</option>
            <option value={10917}>{props.strings.cat10917}</option>
            <option value={10918}>{props.strings.cat10918}</option>
            <option value={10919}>{props.strings.cat10919}</option>
            <option value={10920}>{props.strings.cat10920}</option>
            <option value={10921}>{props.strings.cat10921}</option>
            <option value={10922}>{props.strings.cat10922}</option>
            <option value={10923}>{props.strings.cat10923}</option>
            <option value={10924}>{props.strings.cat10924}</option>
            <option value={10925}>{props.strings.cat10925}</option>
            <option value={10926}>{props.strings.cat10926}</option>
            <option value={10927}>{props.strings.cat10927}</option>
            <option value={10928}>{props.strings.cat10928}</option>
            <option value={10929}>{props.strings.cat10929}</option>
            <option value={10930}>{props.strings.cat10930}</option>
            <option value={10931}>{props.strings.cat10931}</option>
            <option value={10932}>{props.strings.cat10932}</option>
            <option value={10933}>{props.strings.cat10933}</option>
            <option value={10934}>{props.strings.cat10934}</option>
            <option value={10935}>{props.strings.cat10935}</option>
            <option value={10936}>{props.strings.cat10936}</option>
            <option value={10937}>{props.strings.cat10937}</option>
            <option value={10938}>{props.strings.cat10938}</option>
            <option value={10939}>{props.strings.cat10939}</option>
            <option value={10940}>{props.strings.cat10940}</option>
            <option value={10941}>{props.strings.cat10941}</option>
            <option value={10942}>{props.strings.cat10942}</option>
            <option value={10943}>{props.strings.cat10943}</option>
            <option value={10944}>{props.strings.cat10944}</option>
            <option value={10945}>{props.strings.cat10945}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsAppliances 