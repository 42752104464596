import React from 'react'

const CatAnimalsVeterinary  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={18201}>{props.strings.cat18201}</option>
            <option value={18203}>{props.strings.cat18203}</option>
            <option value={18205}>{props.strings.cat18205}</option>
            <option value={18207}>{props.strings.cat18207}</option>
            <option value={18210}>{props.strings.cat18210}</option>
            <option value={18213}>{props.strings.cat18213}</option>
            <option value={18215}>{props.strings.cat18215}</option>
            <option value={18217}>{props.strings.cat18217}</option>
            <option value={18220}>{props.strings.cat18220}</option>
            <option value={18223}>{props.strings.cat18223}</option>
            <option value={18225}>{props.strings.cat18225}</option>
            <option value={18227}>{props.strings.cat18227}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatAnimalsVeterinary