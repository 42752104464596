import React from 'react'
import dark from './FormDark.module.css'
import light from './FormLight.module.css'
import { Formik, Form, Field} from 'formik'

const SetAdsHrefForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.formPanel : dark.formPanel}>
            <Formik
                initialValues={{ href: '' }}
                onSubmit={props.onSubmit}
                >
                {({ isSubmitting }) => (
                    <Form className={props.theme === 'light' ? light.form : dark.form} >

                        <Field
                            className={props.theme === 'light' ? light.editInput : dark.editInput}
                            type="text"
                            name="href"
                            autoComplete="href"
                            placeholder={"href..."}
                        />
                        <button
                            className={props.theme === 'light' ? light.editButton : dark.editButton}
                            type="submit"
                            disabled={isSubmitting}
                        >
                            <i className="fas fa-check"></i>
                        </button>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default SetAdsHrefForm