import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Sitebar from '../Sitebars/Sitebar'
import ProfileCommentsContent from './ProfileCommentsContent'

const ProfileComments= (props) => {
    return (
        <div className={props.theme === 'light' ? light.view : dark.view}>
            <div className={props.theme === 'light' ? light.cont1 : dark.cont1}>
                <ProfileCommentsContent
                    domain={props.domain}
                    theme={props.theme}
                    strings={props.strings}

                    comments={props.comments}
                    count={props.count}
                    totalCount={props.totalCount}
                    currentPage={props.currentPage}
                    pagesCount={props.pagesCount}
                    status={props.status}
                    back={props.back}
                    isFetching={props.isFetching}
                    
                    onPageChanged={props.onPageChanged}
                />
            </div>
            <div className={props.sitebarSlider
                ? props.theme === 'light' ? light.cont2 : dark.cont2
                : props.theme === 'light' ? light.cont2 + " " + light.sitebarActive : dark.cont2 + " " + dark.sitebarActive
                }
            >
                <Sitebar
                    theme={props.theme}
                    strings={props.strings}
                    sitebarActivate={props.sitebarActivate}

                    isAuth={props.isAuth}
                    isAdmin={props.isAdmin}
                    profile={props.profile}
                />
            </div>
            <div className={props.theme === 'light' ? light.menuPanel : dark.menuPanel} ></div>
        </div>
    )
}

export default ProfileComments