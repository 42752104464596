import React from 'react'

const CatServicesClothing  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={2121}>{props.strings.cat2121}</option>
            <option value={2122}>{props.strings.cat2122}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatServicesClothing 