import { RatingAPI } from '../api/api'

const SET_PROFILE_RATING = 'rating/SET_PROFILE_RATING'
const SET_RATING = 'rating/SET_RATING'
const SET_RATING_COUNT = 'rating/SET_RATING_COUNT'
const SET_SUCCESS = 'rating/SET_SUCCESS'
const SET_ERROR = 'rating/SET_ERROR'
const IS_FETCHING = 'rating/IS_FETCHING'

let initialState = {
    profileRating: null,
    rating: null,
    ratingCount: null,
    success: null,
    error: null,
    isFetching: true
}

const ratingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE_RATING:
            return { ...state, profileRating: action.profileRating }
        case SET_RATING:
            return { ...state, rating: action.rating }
        case SET_RATING_COUNT:
            return { ...state, ratingCount: action.ratingCount }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setProfileRating = (profileRating) => ({ type: SET_PROFILE_RATING, profileRating })
export const setRating = (rating) => ({ type: SET_RATING, rating })
export const setRatingCount = (ratingCount) => ({ type: SET_RATING_COUNT, ratingCount })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestRating = (storeId) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await RatingAPI.rating(storeId)
        dispatch(setProfileRating(data.data.profileRating))
        dispatch(setRating(data.data.rating))
        dispatch(setRatingCount(data.data.ratingCount))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default ratingReducer