import React from 'react'
import dark from '../../CSS/Dark.module.css'
import light from '../../CSS/Light.module.css'
import Menu from '../Items/Menu/Menu'
import { Route, Routes } from 'react-router-dom'
import Set from './Settings/Set'
import SetAvatar from './Settings/SetAvatar'
import SetLogin from './Settings/SetLogin'
import SetLocation from './Settings/SetLocation'
import SetName from './Settings/SetName'
import SetBirth from './Settings/SetBirth'
import SetAbout from './Settings/SetAbout'
import SetPassword from './Settings/SetPassword'
import SetTrash from './Settings/SetTrash'

const ProfileSetContent = (props) => {
    return (
        <div className={props.theme === 'light' ? light.contents : dark.contents}>
            <div className={props.theme === 'light' ? light.block : dark.block}>
                <Menu
                    theme={props.theme}
                    back={props.back}
                />

                <Routes>
                    <Route
                        path='/'
                        element={
                            <Set
                                domain={props.domain}
                                theme={props.theme}
                                strings={props.strings}
                                profile={props.profile}
                            />
                        }
                    />

                    <Route
                        path='/avatar'
                        element={
                            <SetAvatar
                                domain={props.domain}
                                theme={props.theme}
                                strings={props.strings}
                                profile={props.profile}
                                changeImage={props.onSubmitAvatar}
                                removeImage={props.onSubmitRemoveAvatar}
                            />
                        }
                    />

                    <Route
                        path='/login'
                        element={
                            <SetLogin
                                theme={props.theme}
                                strings={props.strings}
                                profile={props.profile}
                                validate={props.validateLogin}
                                onSubmit={props.onSubmitLogin}
                            />
                        }
                    />
                    
                    <Route
                        path='/location'
                        element={
                            <SetLocation
                                theme={props.theme}
                                strings={props.strings}
                                profile={props.profile}
                                validate={props.validateLocation}
                                onSubmit={props.onSubmitLocation}
                            />
                        }
                    />

                    <Route
                        path='/name'
                        element={
                            <SetName
                                theme={props.theme}
                                strings={props.strings}
                                profile={props.profile}
                                validate={props.validateName}
                                onSubmit={props.onSubmitName}
                            />
                        }
                    />

                    <Route
                        path='/birthday'
                        element={
                            <SetBirth
                                theme={props.theme}
                                strings={props.strings}
                                profile={props.profile}
                                validate={props.validateBirth}
                                onSubmit={props.onSubmitBirth}
                            />
                        }
                    />

                    <Route
                        path='/about'
                        element={
                            <SetAbout
                                theme={props.theme}
                                strings={props.strings}
                                profile={props.profile}
                                validate={props.validateText}
                                onSubmit={props.onSubmitText}
                            />
                        }
                    />

                    <Route
                        path='/password'
                        element={
                            <SetPassword
                                theme={props.theme}
                                strings={props.strings}
                                validate={props.validatePassword}
                                onSubmit={props.onSubmitPassword}
                            />
                        }
                    />
                    
                    <Route
                        path='/trash'
                        element={
                            <SetTrash
                                theme={props.theme}
                                strings={props.strings}
                                profile={props.profile}
                                navigate={props.navigate}
                                onSubmitTrash={props.onSubmitTrash}
                            />
                        }
                    />

                </Routes>
            </div>
        </div>
    )
}

export default ProfileSetContent