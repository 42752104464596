import React from 'react'
import dark from './SitebarDark.module.css'
import light from './SitebarLight.module.css'
import SitebarTheme from '../common/SitebarTheme/SitebarTheme'
import SitebarLanguage from '../common/SitebarLanguage/SitebarLanguage'
import SitebarAdminPanel from '../common/SitebarAdminPanel/SitebarAdminPanel'
import Footer from '../Footer/Footer'

const Sitebar = (props) => {
    return (
        <div className={props.theme === 'light' ? light.sitebar : dark.sitebar}>

            <SitebarTheme
                theme={props.theme}
                strings={props.strings}
            />

            <SitebarLanguage
                theme={props.theme}
                strings={props.strings}
            />

            {props.isAuth && props.profile
                ? props.profile.privilege !== null
                    ? <>
                        <SitebarAdminPanel
                            theme={props.theme}
                            strings={props.strings}
                            sitebarActivate={props.sitebarActivate}

                            isAdmin={props.isAdmin}
                        />
                    </>
                    : null
                : null
            }

            {props.advertisingsSitebar
                ?  props.advertisingsSitebar
                :null
            }

            {props.finderStores
                ?  props.finderStores
                :null
            }

            {props.finderProducts
                ?  props.finderProducts
                :null
            }
            <Footer
                theme={props.theme}
                strings={props.strings}
            />
        </div>
    )
}

export default Sitebar