import React from 'react'

const CatServicesCar  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={2021}>{props.strings.cat2021}</option>
            <option value={2022}>{props.strings.cat2022}</option>
            <option value={2023}>{props.strings.cat2023}</option>
            <option value={2024}>{props.strings.cat2024}</option>
            <option value={2025}>{props.strings.cat2025}</option>
            <option value={2026}>{props.strings.cat2026}</option>
            <option value={2027}>{props.strings.cat2027}</option>
            <option value={2028}>{props.strings.cat2028}</option>
            <option value={2029}>{props.strings.cat2029}</option>
            <option value={2030}>{props.strings.cat2030}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatServicesCar 