import React from 'react'

const CatElectronicsElectricity  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10121}>{props.strings.cat10121}</option>
            <option value={10122}>{props.strings.cat10122}</option>
            <option value={10123}>{props.strings.cat10123}</option>
            <option value={10124}>{props.strings.cat10124}</option>
            <option value={10125}>{props.strings.cat10125}</option>
            <option value={10126}>{props.strings.cat10126}</option>
            <option value={10127}>{props.strings.cat10127}</option>
            <option value={10128}>{props.strings.cat10128}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsElectricity 