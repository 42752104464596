import { StoresAPI } from '../api/api';

const SET_STORES = 'stores/SET_STORES'
const SET_COUNT = 'stores/SET_COUNT'
const SET_TOTAL_COUNT = 'stores/SET_TOTAL_COUNT'
const SET_CURRENT_PAGE = 'stores/SET_CURRENT_PAGE'
const SET_PAGES_COUNT = 'stores/SET_PAGES_COUNT'
const SET_LOCATION = 'stores/SET_LOCATION'
const SET_MARKER = 'stores/SET_MARKER'
const SET_ORDER = 'stores/SET_ORDER'
const SET_SEARCH = 'stores/SET_SEARCH'
const SET_STATUS = 'stores/SET_STATUS'
const SET_SUCCESS = 'stores/SET_SUCCESS'
const IS_FETCHING = 'stores/IS_FETCHING'


let initialState = {
    order: 'stores.id',
    location: null,
    marker: null,
    search: '',
    stores: [],
    count: 30,
    totalCount: 0,
    currentPage: 1,
    pagesCount: 1,
    status: null,
    success: null,
    back: false,
    isFetching: true
}

const storesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDER:
            return { ...state, order: action.order }
        case SET_LOCATION:
            return { ...state, location: action.location }
        case SET_MARKER:
            return { ...state, marker: action.marker }
        case SET_SEARCH:
            return { ...state, search: action.search }
        case SET_STORES:
            return { ...state, stores: action.stores }
        case SET_COUNT:
            return { ...state, count: action.count }
        case SET_TOTAL_COUNT:
            return { ...state, totalCount: action.totalCount }
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.currentPage }
        case SET_PAGES_COUNT:
            return { ...state, pagesCount: action.pagesCount }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setOrder = (order) => ({ type: SET_ORDER, order })
export const setLocation = (location) => ({ type: SET_LOCATION, location })
export const setMarker = (marker) => ({ type: SET_MARKER, marker })
export const setSearchText = (search) => ({ type: SET_SEARCH, search })
export const setStores = (stores) => ({ type: SET_STORES, stores })
export const setCount = (count) => ({ type: SET_COUNT, count })
export const setTotalCount = (totalCount) => ({ type: SET_TOTAL_COUNT, totalCount })
export const setCurrentPage = (currentPage) => ({ type: SET_CURRENT_PAGE, currentPage })
export const setPagesCount = (pagesCount) => ({ type: SET_PAGES_COUNT, pagesCount })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestStores = (currentPage, count, location, marker, search, order) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await StoresAPI.stores(currentPage, count, location, marker, search, order)
        dispatch(setStores(data.data.stores))
        dispatch(setTotalCount(data.data.totalCount))
        dispatch(setPagesCount(data.data.pagesCount))
        dispatch(setStatus(data.data.status))
        dispatch(setSuccess(data.data.success))
        dispatch(setIsFetching(false))
    }
}

export default storesReducer