import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    createStore,
    setSuccess,
    setError
} from '../../redux/createStore-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import CreateStore from './CreateStore'

const CreateStoreContainer= (props) => {

    window.scrollTo({top: 0, left: 0})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const success = useSelector(state => state.createStore.success)
    const error = useSelector(state => state.createStore.error)
    const back = useSelector(state => state.createStore.back)

    useEffect(() => {
        if (!props.isAuth) {
            navigate('/login')
        } else if (props.profile.status === 2) {
            navigate('/profile')
        } else if (props.profile.status === 0) {
            navigate('/logout')
        } else if (props.profileStore) {
            navigate('/mystore')
        }
    }, [navigate, props.isAuth, props.profile.status, props.profileStore])

    useEffect(() => {
        if (props.profileStoreCreated) {
            setTimeout(() => {
                navigate('/mystore/settings/image')
            }, 500)
        }
    }, [navigate, props.profileStoreCreated])

    const validate = (values) => {
        const errors = {}
        if (!values.title) {
            errors.title = props.strings.required
        } else if (values.title.length < 3) {
            errors.title = props.strings.min + ': 3/' + values.title.length
        } else if (values.title.length > 50) {
            errors.title = props.strings.max + ': 50/' + values.title.length
        } else if (!/^[A-ZÜüÇçÝýŞşŇňÖöА-ЯЁёa-zа-я0-9\s._-]{3,50}$/i.test(values.title)) {
            errors.title = props.strings.error + '. ' + props.strings.pattern + ': ' + props.strings.numbers + ' (0-9), ' + props.strings.symbols + ' (._-), ' + props.strings.strings + ' (A-Z, А-Я, a-z, а-я).'
        }
        
        if (!values.text) {
            errors.text = props.strings.required
        } else if (values.text.length < 10) {
            errors.text = props.strings.min + ': 10/' + values.text.length
        } else if (values.text.length > 1500) {
            errors.text = props.strings.max + ': 1500/' + values.text.length
        }

        if (!values.checkUsing) {
            errors.checkUsing = props.strings.error106
        }
        return errors;
    }

    const onSubmit = (values, { setSubmitting }) => {
        const title = values.title
        const text = values.text
        dispatch(createStore(title, text))
        setSubmitting(false)
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <CreateStore
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}
            
            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}
            
            advertisingsSitebar={props.advertisingsSitebar}

            back={back}

            validate={validate}
            onSubmit={onSubmit}
        />
    )
}

export default CreateStoreContainer