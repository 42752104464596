import React from 'react'

const Audi = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1000400}>AUDI 100</option>
            <option value={1000401}>AUDI 200</option>
            <option value={1000402}>AUDI 4000</option>
            <option value={1000403}>AUDI 4000CS QUATTRO</option>
            <option value={1000404}>AUDI 4000S</option>
            <option value={1000405}>AUDI 4000S QUATTRO</option>
            <option value={1000406}>AUDI 5000CS</option>
            <option value={1000407}>AUDI 5000CS QUATTRO</option>
            <option value={1000408}>AUDI 5000S</option>
            <option value={1000409}>AUDI 80</option>
            <option value={1000410}>AUDI 80/90</option>
            <option value={1000411}>AUDI 90</option>
            <option value={1000412}>AUDI A3</option>
            <option value={1000413}>AUDI A4</option>
            <option value={1000414}>AUDI A5</option>
            <option value={1000415}>AUDI A6</option>
            <option value={1000416}>AUDI A7</option>
            <option value={1000417}>AUDI A8</option>
            <option value={1000418}>AUDI ALLROAD</option>
            <option value={1000419}>AUDI CABRIOLET</option>
            <option value={1000420}>AUDI COUPE GT</option>
            <option value={1000421}>AUDI COUPE QUATTRO</option>
            <option value={1000422}>AUDI Q5</option>
            <option value={1000423}>AUDI Q7</option>
            <option value={1000424}>AUDI Q8</option>
            <option value={1000425}>AUDI QUATTRO</option>
            <option value={1000426}>AUDI R8</option>
            <option value={1000427}>AUDI RS2</option>
            <option value={1000428}>AUDI RS4</option>
            <option value={1000429}>AUDI RS6</option>
            <option value={1000430}>AUDI S3</option>
            <option value={1000431}>AUDI S4</option>
            <option value={1000432}>AUDI S5</option>
            <option value={1000433}>AUDI S6</option>
            <option value={1000434}>AUDI S8</option>
            <option value={1000435}>AUDI TT</option>
            <option value={1000436}>AUDI V8</option>
            <option value={1000437}>AUDI RIOLET</option>
        </>
    )
}

export default Audi