import { AdvertisingsAPI } from '../api/api';

const SET_ADVERTISINGS = 'advertisings/SET_ADVERTISINGS'
const SET_ACTIVE_INDEX = 'advertisings/SET_ACTIVE_INDEX'
const SET_STATUS = 'advertisings/SET_STATUS'
const SET_SUCCESS = 'advertisings/SET_SUCCESS'
const IS_FETCHING = 'advertisings/IS_FETCHING'

let initialState = {
    advertisings: [],
    activeIndex: 0,
    status: null,
    success: null,
    isFetching: true
}

const advertisingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADVERTISINGS:
            return { ...state, advertisings: action.advertisings }
        case SET_ACTIVE_INDEX:
            return { ...state, activeIndex: action.activeIndex }
        case SET_STATUS:
            return { ...state, status: action.status }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setAvertisings = (advertisings) => ({ type: SET_ADVERTISINGS, advertisings })
export const setActiveIndex = (activeIndex) => ({ type: SET_ACTIVE_INDEX, activeIndex })
export const setStatus = (status) => ({ type: SET_STATUS, status })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const requestAdvertisings = () => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await AdvertisingsAPI.advertisings()
        dispatch(setAvertisings(data.data.advertisings))
        dispatch(setStatus(data.data.status))
        dispatch(setSuccess(data.data.success))
        dispatch(setIsFetching(false))
    }
}

export default advertisingsReducer