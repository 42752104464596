import React from 'react'

const CatElectronicsCommercialEquipment  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={10181}>{props.strings.cat10181}</option>
            <option value={10182}>{props.strings.cat10182}</option>
            <option value={10183}>{props.strings.cat10183}</option>
            <option value={10184}>{props.strings.cat10184}</option>
            <option value={10185}>{props.strings.cat10185}</option>
            <option value={10186}>{props.strings.cat10186}</option>
            <option value={10187}>{props.strings.cat10187}</option>
            <option value={10188}>{props.strings.cat10188}</option>
            <option value={100001}>{props.strings.cat100001}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatElectronicsCommercialEquipment 