import { CommentsAPI } from '../api/api'

const SET_UPDATED = 'profileCommentSet/SET_UPDATED'
const SET_TRASHED = 'profileCommentSet/SET_TRASHED'
const SET_SUCCESS = 'profileCommentSet/SET_SUCCESS'
const SET_ERROR = 'profileCommentSet/SET_ERROR'
const IS_FETCHING = 'profileCommentSet/IS_FETCHING'

let initialState = {
    updated: false,
    trashed: false,
    success: null,
    error: null,
    back: true,
    isFetching: false
}

const profileCommentSetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UPDATED:
            return { ...state, updated: action.updated }
        case SET_TRASHED:
            return { ...state, trashed: action.trashed }
        case SET_SUCCESS:
            return { ...state, success: action.success }
        case SET_ERROR:
            return { ...state, error: action.error }
        case IS_FETCHING:
            return { ...state, isFetching: action.isFetching }
        default:
            return state
    }
}

export const setUpdated = (updated) => ({ type: SET_UPDATED, updated })
export const setTrashed = (trashed) => ({ type: SET_TRASHED, trashed })
export const setSuccess = (success) => ({ type: SET_SUCCESS, success })
export const setError = (error) => ({ type: SET_ERROR, error })
export const setIsFetching = (isFetching) => ({ type: IS_FETCHING, isFetching })

export const changeStatus = (auth, id, status) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await CommentsAPI.changeStatus(auth, id, status)
        dispatch(setUpdated(data.data.updated))
        dispatch(setTrashed(data.data.trashed))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const changeText = (auth, id, text) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await CommentsAPI.changeText(auth, id, text)
        dispatch(setUpdated(data.data.updated))
        dispatch(setTrashed(data.data.trashed))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export const remove = (auth, id, trash) => {
    return async (dispatch) => {
        dispatch(setIsFetching(true))
        let data = await CommentsAPI.remove(auth, id, trash)
        dispatch(setUpdated(data.data.updated))
        dispatch(setTrashed(data.data.trashed))
        dispatch(setSuccess(data.data.success))
        dispatch(setError(data.data.error))
        dispatch(setIsFetching(false))
    }
}

export default profileCommentSetReducer