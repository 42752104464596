import React from 'react'

const Tools = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={1001}>{props.strings.cat1001}</option>
            <option value={1300}>{props.strings.cat1300}</option>
            <option value={1600}>{props.strings.cat1600}</option>
        </>
    )
}

export default Tools