import React from 'react'
import dark from './Dark.module.css'
import light from './Light.module.css'

const Info = (props) => {
    return (
        <div className={props.theme === 'light' ? light.info : dark.info}>
            <div>{props.icon} {props.info}</div>
            <div>{props.requestI}</div>
        </div>
    )
}

export default Info