import React from 'react'
import dark from './NavbarDark.module.css'
import light from './NavbarLight.module.css'
import { NavLink } from 'react-router-dom'

const Navbar = (props) => {
    return (
        <div className={props.theme === 'light' ? light.navbar : dark.navbar}>
            <NavLink
                to="/"
                className={props.theme === 'light'
                    ? ({isActive}) => isActive 
                        ? light.item + " " + light.active 
                        : light.item
                    : ({isActive}) => isActive 
                        ? dark.item + " " + dark.active 
                        : dark.item
                }
            >
                <i className="fas fa-home"></i> {props.strings.home}
            </NavLink>

            <NavLink
                to="/products"
                className={props.theme === 'light'
                    ? ({isActive}) => isActive 
                        ? light.item + " " + light.active 
                        : light.item
                    : ({isActive}) => isActive 
                        ? dark.item + " " + dark.active 
                        : dark.item
                }
            >
                <i className="fas fa-bag-shopping"></i> {props.strings.products}
            </NavLink>

            <NavLink
                to="/stores"
                className={props.theme === 'light'
                    ? ({isActive}) => isActive 
                        ? light.item + " " + light.active 
                        : light.item
                    : ({isActive}) => isActive 
                        ? dark.item + " " + dark.active 
                        : dark.item
                }
            >
                <i className="fas fa-store-alt"></i> {props.strings.stores}
            </NavLink>

            { props.isAuth
                ? <NavLink
                    to="/profile"
                    className={props.theme === 'light'
                        ? ({isActive}) => isActive 
                            ? light.item + " " + light.active 
                            : light.item
                        : ({isActive}) => isActive 
                            ? dark.item + " " + dark.active 
                            : dark.item
                    }
                >
                    <i className="fas fa-user"></i> {props.strings.me}
                </NavLink>
                : null
            }

            { props.isAuth
                ? !props.profileStore
                    ? <NavLink
                        to="/create/store"
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-store-alt"></i> + {props.strings.createStore}
                    </NavLink>
                    : <NavLink
                        to="/mystore"
                        className={props.theme === 'light'
                            ? ({isActive}) => isActive 
                                ? light.item + " " + light.active 
                                : light.item
                            : ({isActive}) => isActive 
                                ? dark.item + " " + dark.active 
                                : dark.item
                        }
                    >
                        <i className="fas fa-store-alt"></i> {props.profileStore.titleM}
                    </NavLink>
                : null
            }

            { props.isAuth
                ? <NavLink
                    to="/logout"
                    className={props.theme === 'light'
                        ? ({isActive}) => isActive 
                            ? light.item + " " + light.active 
                            : light.item
                        : ({isActive}) => isActive 
                            ? dark.item + " " + dark.active 
                            : dark.item
                    }
                >
                    <i className="fas fa-sign-out-alt"></i> {props.strings.exit}
                </NavLink>
                : null
            }
            { !props.isAuth
                ? <NavLink
                    to="/login"
                    className={props.theme === 'light'
                        ? ({isActive}) => isActive 
                            ? light.item + " " + light.active 
                            : light.item
                        : ({isActive}) => isActive 
                            ? dark.item + " " + dark.active 
                            : dark.item
                    }
                >
                    <i className="fas fa-sign-in-alt"></i> {props.strings.entrance}
                </NavLink>
                : null
            }
        </div>
    )
}

export default Navbar