import React from 'react'

const CatBuildingPipes  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={7401}>{props.strings.cat7401}</option>
            <option value={7405}>{props.strings.cat7405}</option>
            <option value={7410}>{props.strings.cat7410}</option>
            <option value={7415}>{props.strings.cat7415}</option>
            <option value={7420}>{props.strings.cat7420}</option>
            <option value={7425}>{props.strings.cat7425}</option>
            <option value={7430}>{props.strings.cat7430}</option>
            <option value={7435}>{props.strings.cat7435}</option>
            <option value={7440}>{props.strings.cat7440}</option>
            <option value={7445}>{props.strings.cat7445}</option>
            <option value={7450}>{props.strings.cat7450}</option>
            <option value={7455}>{props.strings.cat7455}</option>
            <option value={7460}>{props.strings.cat7460}</option>
            <option value={7465}>{props.strings.cat7465}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBuildingPipes 