import React from 'react'

const CatBeautyPerfumery  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12114}>{props.strings.cat12114}</option>
            <option value={12115}>{props.strings.cat12115}</option>
        </>
    )
}

export default CatBeautyPerfumery