import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'
import { NavLink } from 'react-router-dom'

const AdminItem = (props) => {
    return (
        <NavLink to={props.to + props.id} >
            <div className={props.theme === 'light' ? light.blockView : dark.blockView} >
                {props.avatar0
                    ? <img src={props.domain + "/media/avatar/" + props.avatar0} alt="avatar" />
                    : <img src={props.domain + "/media/avatar/nullmini.png"} alt="avatar" />
                }
                <div className={props.theme === 'light' ? light.info : dark.info}>

                    <div className={props.theme === 'light' ? light.title : dark.title}>
                        <i className="fas fa-crown"></i> {props.login} | <i className="fas fa-user"></i> {props.name}
                    </div>
                    <div className={props.theme === 'light' ? light.small : dark.small}>
                        <small><i className="fas fa-map-marker-alt"></i> {props.strings['loc' + props.location]}. {props.strings['loc' + props.marker]}.</small>
                    </div>
                    
                    {props.status
                        ? <div className={props.theme === 'light' ? light.status : dark.status}>
                            {props.strings['status' + props.status]}...
                        </div>
                        : null
                    }

                    <div className={props.theme === 'light' ? light.phone : dark.phone}>
                        <i className="fas fa-phone-square"></i> {props.phone}

                        <div className={props.theme === 'light' ? light.views : dark.views}>
                            <i className="fas fa-crown"></i> {props.privilege}
                        </div>
                    </div>
                </div>
            </div>
        </NavLink>
    )
}

export default AdminItem