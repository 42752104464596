import React from 'react'

const Sport = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={15001}>{props.strings.cat15001}</option>
            <option value={15100}>{props.strings.cat15100}</option>
            <option value={15200}>{props.strings.cat15200}</option>
        </>
    )
}

export default Sport