import React from 'react'
import { useDispatch } from 'react-redux'
import dark from './STDark.module.css'
import light from './STLight.module.css'
import { themeData } from '../../../redux/sitebar-reducer'
import { Formik, Form } from 'formik'

const ThemeLight = (props) => {
    
    const dispatch = useDispatch()

    const onSubmit = (values, { setSubmitting }) => {
        const theme = "light"
        dispatch(themeData(theme))
        setSubmitting(false)
    }

    return (
        <Formik
            initialValues={{
                theme: ''
            }}
            onSubmit={onSubmit}
            >
            {({ isSubmitting }) => (
                <Form>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                    >
                        <i className="fas fa-sun"></i> {props.strings.light}
                    </button>
                </Form>
            )}
        </Formik>
    )
}


const ThemeDark = (props) => {

    const dispatch = useDispatch()

    const onSubmit = (values, { setSubmitting }) => {
        const theme = "dark"
        dispatch(themeData(theme))
        setSubmitting(false)
    }

    return (
        <Formik
            initialValues={{
                theme: ''
            }}
            onSubmit={onSubmit}
            >
            {({ isSubmitting }) => (
                <Form>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                    >
                        <i className="fas fa-moon"></i> {props.strings.dark}
                    </button>
                </Form>
            )}
        </Formik>
    )
}


const SitebarTheme = (props) => {
    return (
        <div className={props.theme === 'light' ? light.themes : dark.themes}>
            <div className={props.theme === 'light' ? light.theme : dark.theme}>
                <label><i className="fas fa-adjust"></i> {props.strings.theme}</label>
                <ThemeLight strings={props.strings} />
                <ThemeDark strings={props.strings} />
            </div>
        </div>
    )
}

export default SitebarTheme