import React from 'react'

const Mercedes = (props) => {
    return (
        <>
            <option value={0}>{props.strings.model}...</option>
            <option value={1000100}>Mercedes-Benz 190E</option>
            <option value={1000101}>Mercedes-Benz 230E</option>
            <option value={1000102}>Mercedes-Benz 300CE</option>
            <option value={1000103}>Mercedes-Benz 300D</option>
            <option value={1000104}>Mercedes-Benz 300E</option>
            <option value={1000105}>Mercedes-Benz 300SD</option>
            <option value={1000106}>Mercedes-Benz 300SE</option>
            <option value={1000107}>Mercedes-Benz 300SL</option>
            <option value={1000108}>Mercedes-Benz 300TE</option>
            <option value={1000109}>Mercedes-Benz 400E</option>
            <option value={1000110}>Mercedes-Benz 400SE</option>
            <option value={1000111}>Mercedes-Benz 400SEL</option>
            <option value={1000112}>Mercedes-Benz 500E</option>
            <option value={1000113}>Mercedes-Benz 500SEC</option>
            <option value={1000114}>Mercedes-Benz 500SEL</option>
            <option value={1000115}>Mercedes-Benz 500SL</option>
            <option value={1000116}>Mercedes-Benz 600SEC</option>
            <option value={1000117}>Mercedes-Benz 600SEL</option>
            <option value={1000118}>Mercedes-Benz 600SL</option>
            <option value={1000119}>Mercedes-Benz A150</option>
            <option value={1000120}>Mercedes-Benz ACTROS 3331</option>
            <option value={1000121}>Mercedes-Benz B-CLASS</option>
            <option value={1000122}>Mercedes-Benz C-CLASS</option>
            <option value={1000123}>Mercedes-Benz C250</option>
            <option value={1000124}>Mercedes-Benz C280</option>
            <option value={1000125}>Mercedes-Benz C300</option>
            <option value={1000126}>Mercedes-Benz C320</option>
            <option value={1000127}>Mercedes-Benz C350</option>
            <option value={1000128}>Mercedes-Benz CL-CLASS</option>
            <option value={1000129}>Mercedes-Benz CL65 AMG</option>
            <option value={1000130}>Mercedes-Benz CLA</option>
            <option value={1000131}>Mercedes-Benz CLK-CLASS</option>
            <option value={1000132}>Mercedes-Benz CLS-CLASS</option>
            <option value={1000133}>Mercedes-Benz E-CLASS</option>
            <option value={1000134}>Mercedes-Benz E280</option>
            <option value={1000135}>Mercedes-Benz E300</option>
            <option value={1000136}>Mercedes-Benz E320</option>
            <option value={1000137}>Mercedes-Benz E350</option>
            <option value={1000138}>Mercedes-Benz E420</option>
            <option value={1000139}>Mercedes-Benz E430</option>
            <option value={1000140}>Mercedes-Benz E500</option>
            <option value={1000141}>Mercedes-Benz G-CLASS</option>
            <option value={1000142}>Mercedes-Benz G55 AMG</option>
            <option value={1000143}>Mercedes-Benz GL-CLASS</option>
            <option value={1000144}>Mercedes-Benz GLE43</option>
            <option value={1000145}>Mercedes-Benz GLK350</option>
            <option value={1000146}>Mercedes-Benz GLK-CLASS</option>
            <option value={1000147}>Mercedes-Benz M-CLASS</option>
            <option value={1000148}>Mercedes-Benz ML350</option>
            <option value={1000149}>Mercedes-Benz ML400</option>
            <option value={1000150}>Mercedes-Benz R-CLASS</option>
            <option value={1000151}>Mercedes-Benz S-CLASS</option>
            <option value={1000152}>Mercedes-Benz SL-CLASS</option>
            <option value={1000153}>Mercedes-Benz SL65 AMG</option>
            <option value={1000154}>Mercedes-Benz SLK-CLASS</option>
            <option value={1000155}>Mercedes-Benz SLK55 AMG</option>
            <option value={1000156}>Mercedes-Benz SLR McLaren</option>
            <option value={1000157}>Mercedes-Benz SLS AMG</option>
            <option value={1000158}>Mercedes-Benz SLS-Class</option>
            <option value={1000159}>Mercedes-Benz SPRINTER</option>
            <option value={1000160}>Mercedes-Benz SPRINTER 2500</option>
            <option value={1000161}>Mercedes-Benz SPRINTER 3500</option>
            <option value={1000162}>Mercedes-Benz VIANO</option>
            <option value={1000163}>Mercedes-Benz W123</option>
            <option value={1000164}>Mercedes-Benz W126</option>
            <option value={1000165}>Mercedes-Benz W201</option>
            <option value={1000166}>Mercedes-Benz W212</option>
        </>
    )
}

export default Mercedes