import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import dark from './AppDark.module.css'
import light from './AppLight.module.css'

import { setNavToggle } from './redux/header-reducer'

import {
    setSitebarSlider,
    requestStrings,
    requestTheme
} from './redux/sitebar-reducer'

import { domain } from './api/main'

import { logout, requestAuth, setAccess } from './redux/auth-reducer'
import { requestProfile, unsetProfile } from './redux/profile-reducer'
import { setUpdated as setProfileUpdated, setTrashed as setProfileTrashed  } from './redux/profileSet-reducer'
import { setCreated as setProfileStoreCreated } from './redux/createStore-reducer'
import { requestStore, unsetStore } from './redux/profileStore-reducer'
import { setUpdated as setProfileStoreUpdated, setTrashed as setProfileStoreTrashed } from './redux/profileStoreSet-reducer'

import Preloader from './components/common/Preloader/Preloader'

import AdvertisingsSitebar from './components/Advertisings/AdvertisingsSitebar'
import FinderStores from './components/common/Finders/FinderStores'
import FinderProducts from './components/common/Finders/FinderProducts'

import NotifySuccessContainer from './components/common/NotifySuccess/NotifySuccessContainer'
import NotifyWarningContainer from './components/common/NotifyWarning/NotifyWarningContainer'
import NotifyErrorContainer from './components/common/NotifyError/NotifyErrorContainer'

import HeaderContainer from './components/Header/HeaderContainer'
import HomeContainer from './components/Home/HomeContainer'
import ProductContainer from './components/Product/ProductContainer'
import ProductsContainer from './components/Products/ProductsContainer'
import StoreContainer from './components/Store/StoreContainer'
import StoresContainer from './components/Stores/StoresContainer'
import AdvertisingContainer from './components/Advertising/AdvertisingContainer'
import ProfileContainer from './components/Profile/ProfileContainer'
import ProfileSetContainer from './components/ProfileSet/ProfileSetContainer'
import CreateStoreContainer from './components/CreateStore/CreateStoreContainer'
import ProfileStoreContainer from './components/ProfileStore/ProfileStoreContainer'
import ProfileStoreSetContainer from './components/ProfileStoreSet/ProfileStoreSetContainer'
import AddProductContainer from './components/AddProduct/AddProductContainer'
import ProfileStoreProductSetContainer from './components/ProfileStoreProductSet/ProfileStoreProductSetContainer'
import ProfileStoreProductContainer from './components/ProfileStoreProduct/ProfileStoreProductContainer'
import ProfileCommentsContainer from './components/ProfileComments/ProfileCommentsContainer'
import ProfileCommentContainer from './components/ProfileComment/ProfileCommentContainer'
import FavoriteStoresContainer from './components/FavoriteStores/FavoriteStoresContainer'
import FavoriteProductsContainer from './components/FavoriteProducts/FavoriteProductsContainer'
import HistoryStoresContainer from './components/HistoryStores/HistoryStoresContainer'
import HistoryProductsContainer from './components/HistoryProducts/HistoryProductsContainer'
import SignupContainer from './components/Signup/SignupContainer'
import LoginContainer from './components/Login/LoginContainer'
import ForgotPasswordContainer from './components/ForgotPassword/ForgotPasswordContainer'
import LogoutContainer from './components/Logout/LogoutContainer'

import AdminLoginContainer from './components/AdminLogin/AdminLoginContainer'
import AdminLogoutContainer from './components/AdminLogout/AdminLogoutContainer'
import AdminPanelContainer from './components/AdminPanel/AdminPanelContainer'
import AdminAdminsContainer from './components/AdminAdmins/AdminAdminsContainer'

import AddAdvertisingContainer from './components/AddAdvertising/AddAdvertisingContainer'
import AdminAdvertisingsContainer from './components/AdminAdvertisings/AdminAdvertisingsContainer'
import AdminAdvertisingContainer from './components/AdminAdvertising/AdminAdvertisingContainer'
import AdminAdvertisingSetContainer from './components/AdminAdvertisingSet/AdminAdvertisingSetContainer'

import AdminProfilesContainer from './components/AdminProfiles/AdminProfilesContainer'
import AdminProfileContainer from './components/AdminProfile/AdminProfileContainer'
import AdminProfileSetContainer from './components/AdminProfileSet/AdminProfileSetContainer'
import AdminProfileCommentsContainer from './components/AdminProfileComments/AdminProfileCommentsContainer'

import AdminStoresContainer from './components/AdminStores/AdminStoresContainer'
import AdminStoreContainer from './components/AdminStore/AdminStoreContainer'
import AdminStoreSetContainer from './components/AdminStoreSet/AdminStoreSetContainer'

import AdminProductsContainer from './components/AdminProducts/AdminProductsContainer'
import AdminProductContainer from './components/AdminProduct/AdminProductContainer'
import AdminProductSetContainer from './components/AdminProductSet/AdminProductSetContainer'

import AdminCommentsContainer from './components/AdminComments/AdminCommentsContainer'
import AdminCommentContainer from './components/AdminComment/AdminCommentContainer'
import AdminCommentSetContainer from './components/AdminCommentSet/AdminCommentSetContainer'

import HelpContainer from './components/Help/HelpContainer'
import TermsOfUseContainer from './components/TermsOfUse/TermsOfUseContainer'
import AboutUsContainer from './components/AboutUs/AboutUsContainer'

import NotFoundContainer from './components/NotFound/NotFoundContainer'

const App = () => {
    const dispatch = useDispatch()
    const theme = useSelector(state => state.sitebar.theme)
    const strings = useSelector(state => state.sitebar.strings)

    const navToggle = useSelector(state => state.header.navToggle)

    const sitebarSlider = useSelector(state => state.sitebar.sitebarSlider)
    const sitebarIsFetching = useSelector(state => state.sitebar.isFetching)

    const isAuth = useSelector(state => state.auth.isAuth)
    const isAdmin = useSelector(state => state.auth.isAdmin)
    const authAccess = useSelector(state => state.auth.access)
    const authIsFetching = useSelector(state => state.auth.isFetching)
    
    const profile = useSelector(state => state.profile.profile)

    const profileUpdated = useSelector(state => state.profileSet.updated)
    const profileTrashed = useSelector(state => state.profileSet.trashed)

    const profileStore = useSelector(state => state.profileStore.store)
    const profileStoreStatus = useSelector(state => state.profileStore.status)

    const profileStoreCreated = useSelector(state => state.createStore.created)
    const profileStoreUpdated = useSelector(state => state.profileStoreSet.updated)
    const profileStoreTrashed = useSelector(state => state.profileStoreSet.trashed)

    const auth = 1

    const navbarActivate = () => {
        if (navToggle) {
            dispatch(setNavToggle(false))
        } else {
            dispatch(setNavToggle(true))
        }
    }

    useEffect(() => {
        if (sitebarIsFetching) {
            dispatch(requestStrings())
            dispatch(requestTheme())
        }
    }, [dispatch, sitebarIsFetching])

    const sitebarActivate = () => {
        if (sitebarSlider) {
            dispatch(setSitebarSlider(false))
        } else {
            dispatch(setSitebarSlider(true))
        }
    }


    useEffect(() => {
        if (authIsFetching) {
                dispatch(requestAuth())
        } else if (authAccess) {
                dispatch(requestAuth())
                dispatch(setAccess(false))
        }
    }, [dispatch, authIsFetching, authAccess])

    useEffect(() => {
        if (isAuth) {
            dispatch(requestProfile(auth))
            dispatch(requestStore(auth))
        } else {
            dispatch(unsetProfile())
            dispatch(unsetStore())
        }
    }, [dispatch, isAuth])

    useEffect(() => {
        if (isAuth && profileUpdated) {
            dispatch(requestProfile(auth))
            dispatch(setProfileUpdated(false))
        }
    }, [dispatch, isAuth, profileUpdated])

    useEffect(() => {
        if (isAuth && profileTrashed) {
            dispatch(unsetProfile())
            dispatch(unsetStore())
            dispatch(setProfileTrashed(false))
            dispatch(logout())
        }
    }, [dispatch, isAuth, profileTrashed])

    useEffect(() => {
        if (isAuth && profileStoreCreated) {
            dispatch(requestStore(auth))
            dispatch(setProfileStoreCreated(false))
        }
    }, [dispatch, isAuth, profileStoreCreated])

    useEffect(() => {
        if (isAuth && profileStoreUpdated) {
            dispatch(requestStore(auth))
            dispatch(setProfileStoreUpdated(false))
        }
    }, [dispatch, isAuth, profileStoreUpdated])

    useEffect(() => {
        if (isAuth && profileStoreTrashed) {
            dispatch(unsetStore())
            dispatch(requestStore(auth))
            dispatch(setProfileStoreTrashed(false))
        }
    }, [dispatch, isAuth, profileStoreTrashed])

    const advertisingsSitebar = (
        <AdvertisingsSitebar
            domain={domain}
            theme={theme}
            strings={strings}
        />
    )

    const finderStores = (
        <FinderStores
            theme={theme}
            strings={strings}
        />
    )

    const finderProducts = (
        <FinderProducts
            theme={theme}
            strings={strings}
        />
    )

    return (
        <>
            { sitebarIsFetching ? <Preloader /> : null }
            { authIsFetching ? <Preloader /> : null }

            <NotifySuccessContainer
                theme={theme}
                strings={strings}
            />
            
            <NotifyWarningContainer
                theme={theme}
                strings={strings}
            />

            <NotifyErrorContainer
                theme={theme}
                strings={strings}
            />
        
            <div className={theme === 'light' ? light.app : dark.app}>
                <HeaderContainer
                    theme={theme}
                    strings={strings}
                    navToggle={navToggle}
                    navbarActivate={navbarActivate}
                    isAuth={isAuth}
                    profile={profile}
                    profileStore={profileStore}
                />
                <button className={theme === 'light' ? light.sitebarSlider : dark.sitebarSlider} onClick={sitebarActivate}>
                    <i className="fas fa-indent"></i>
                </button>
                <Routes>

                    <Route
                        path='/'
                        element={
                            <HomeContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}

                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />

                    <Route
                        path='/products'
                        element={
                            <ProductsContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}

                                finderProducts={finderProducts}
                            />
                        }
                    />
                    <Route
                        path='/products/:productId'
                        element={
                            <ProductContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}

                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />

                    <Route
                        path='/stores'
                        element={
                            <StoresContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}

                                finderStores={finderStores}
                            />
                        }
                    />
                    <Route 
                        path='/stores/:storeId'
                        element={
                            <StoreContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}

                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />

                    <Route 
                        path='/advertisings/:advertisingId'
                        element={
                            <AdvertisingContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />

                    <Route
                        path='/profile'
                        element={
                            <ProfileContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/profile/settings/*'
                        element={
                            <ProfileSetContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/profile/comments'
                        element={
                            <ProfileCommentsContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/profile/comments/:commentId'
                        element={
                            <ProfileCommentContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/profile/favorite-stores'
                        element={
                            <FavoriteStoresContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}

                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />
                    <Route
                        path='/profile/favorite-products'
                        element={
                            <FavoriteProductsContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}

                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />
                    <Route
                        path='/profile/history-stores'
                        element={
                            <HistoryStoresContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}

                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />
                    <Route
                        path='/profile/history-products'
                        element={
                            <HistoryProductsContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}

                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />

                    <Route
                        path='/create/store'
                        element={
                            <CreateStoreContainer
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                profileStore={profileStore}
                                profileStoreCreated={profileStoreCreated}
                                
                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />
                    <Route
                        path='/mystore'
                        element={
                            <ProfileStoreContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                profileStore={profileStore}
                            />
                        }
                    />
                    <Route
                        path='/mystore/settings/*'
                        element={
                            <ProfileStoreSetContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                profileStore={profileStore}
                                profileStoreStatus={profileStoreStatus}
                            />
                        }
                    />
                    
                    <Route
                        path='/mystore/add/product'
                        element={
                            <AddProductContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                profileStore={profileStore}
                                profileStoreStatus={profileStoreStatus}
                            />
                        }
                    />
                    <Route
                        path='/mystore/products/:productId'
                        element={
                            <ProfileStoreProductContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                profileStoreStatus={profileStoreStatus}
                            />
                        }
                    />
                    <Route
                        path='/mystore/products/:productId/settings/*'
                        element={
                            <ProfileStoreProductSetContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                profileStoreStatus={profileStoreStatus}
                            />
                        }
                    />
                    
                    <Route
                        path='/signup'
                        element={
                            <SignupContainer
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}

                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />
                    <Route
                        path='/login'
                        element={
                            <LoginContainer
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                
                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />
                    <Route
                        path='/login/forgot-password'
                        element={
                            <ForgotPasswordContainer
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                
                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />
                    <Route
                        path='/logout'
                        element={
                            <LogoutContainer
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                
                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />
                    
                    <Route
                        path='/admin/login'
                        element={
                            <AdminLoginContainer
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/admin/logout'
                        element={
                            <AdminLogoutContainer
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />

                    <Route
                        path='/admin/panel'
                        element={
                            <AdminPanelContainer
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />

                    <Route
                        path='/admin/administrators'
                        element={
                            <AdminAdminsContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />

                    <Route
                        path='/admin/add/advertising'
                        element={
                            <AddAdvertisingContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/admin/advertisings'
                        element={
                            <AdminAdvertisingsContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/admin/advertisings/:advertisingId'
                        element={
                            <AdminAdvertisingContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/admin/advertisings/:advertisingId/settings/*'
                        element={
                            <AdminAdvertisingSetContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    
                    <Route
                        path='/admin/profiles'
                        element={
                            <AdminProfilesContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route 
                        path='/admin/profiles/:profileId'
                        element={
                            <AdminProfileContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/admin/profiles/:profileId/settings/*'
                        element={
                            <AdminProfileSetContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/admin/profiles/comments/:profileId'
                        element={
                            <AdminProfileCommentsContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    
                    <Route
                        path='/admin/stores'
                        element={
                            <AdminStoresContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route 
                        path='/admin/stores/:storeId'
                        element={
                            <AdminStoreContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/admin/stores/:storeId/settings/*'
                        element={
                            <AdminStoreSetContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    
                    <Route
                        path='/admin/products'
                        element={
                            <AdminProductsContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    
                    <Route
                        path='/admin/products/:productId'
                        element={
                            <AdminProductContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/admin/products/:productId/settings/*'
                        element={
                            <AdminProductSetContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    
                    <Route
                        path='/admin/comments'
                        element={
                            <AdminCommentsContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/admin/comments/:commentId'
                        element={
                            <AdminCommentContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    <Route
                        path='/admin/comments/:commentId/settings/*'
                        element={
                            <AdminCommentSetContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                    
                    <Route
                        path='/sections-and-informations/help'
                        element={
                            <HelpContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                
                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />
                    
                    <Route
                        path='/sections-and-informations/terms-of-use'
                        element={
                            <TermsOfUseContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                
                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />
                    
                    <Route
                        path='/sections-and-informations/about-us'
                        element={
                            <AboutUsContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                                
                                advertisingsSitebar={advertisingsSitebar}
                            />
                        }
                    />
                    
                    <Route
                        path='*'
                        element={
                            <NotFoundContainer
                                domain={domain}
                                theme={theme}
                                strings={strings}
                                sitebarSlider={sitebarSlider}
                                sitebarActivate={sitebarActivate}
                                
                                isAuth={isAuth}
                                isAdmin={isAdmin}
                                profile={profile}
                            />
                        }
                    />
                </Routes>
            </div>
        </>
    )
}

export default App