import React from 'react'
import dark from './ItemDark.module.css'
import light from './ItemLight.module.css'

const VideoItem = (props) => {
    return (
        <div className={props.theme === 'light' ? light.blockView : dark.blockView} >
            <video
                preload="none"
                poster={props.poster} 
                controls controlsList="nodownload"
            >
                { props.src
                    ? <source src={props.src} type="video/mp4" />
                    : null
                }
            </video>
            <div className={props.theme === 'light' ? light.selectVideoBlock : dark.selectVideoBlock}>
                <span>
                    {props.title}
                </span>
                <label
                    className={props.theme === 'light' ? light.updateInput : dark.updateInput}
                    htmlFor={"updateInput"+props.imageId}
                >
                    <i className="fas fa-folder-open"></i>
                    <input
                        className={props.theme === 'light' ? light.hidden : dark.hidden}
                        id={"updateInput"+props.imageId}
                        type="file"
                        name="video"
                        autoComplete="video"
                        accept="video/*"
                        onChange={props.changeVideo}
                        imageId={props.imageId}
                    />
                </label>
                <button
                    className={props.theme === 'light' ? light.delete : dark.delete}
                    onClick={props.removeVideo}
                    disabled={props.disabled}
                    imageId={props.imageId}
                >
                    <i className="fas fa-trash-alt"></i>
                </button>
            </div>
        </div>
    )
}

export default VideoItem