import React from 'react'
import dark from './FormDark.module.css'
import light from './FormLight.module.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Locations from '../Locations/Locations'
import Ahal from '../Locations/Ahal'
import Balkan from '../Locations/Balkan'
import Dashoguz from '../Locations/Dashoguz'
import Lebap from '../Locations/Lebap'
import Mary from '../Locations/Mary'

const SetLocationForm = (props) => {
    return (
        <div className={props.theme === 'light' ? light.formPanel : dark.formPanel}>
            <Formik
                initialValues={{
                    location: '',
                    marker: ''
                }}
                validate={props.validate}
                onSubmit={props.onSubmit}
                >
                {({ isSubmitting, values  }) => (
                    <Form className={props.theme === 'light' ? light.form : dark.form} >
                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="location"
                            component="div"
                        />
                        <Field
                            name="location"
                            as="select"
                        >
                            <Locations strings={props.strings} />
                        </Field>

                        <ErrorMessage
                            className={props.theme === 'light' ? light.err : dark.err}
                            name="marker"
                            component="div"
                        />
                        {values.location === '1'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Locations strings={props.strings} />
                            </Field>
                            : values.location === '2'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Ahal strings={props.strings} />
                            </Field>
                            : values.location === '12'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Balkan strings={props.strings} />
                            </Field>
                            : values.location === '24'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Dashoguz strings={props.strings} />
                            </Field>
                            : values.location === '35'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Lebap strings={props.strings} />
                            </Field>
                            : values.location === '52'
                            ? <Field
                                name="marker"
                                as="select"
                            >
                                <Mary strings={props.strings} />
                            </Field>
                            : null
                        }

                        <button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            <i className="fas fa-check"></i>
                        </button>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default SetLocationForm