import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    requestPanelInformations,
    setSuccess,
    setError
} from '../../redux/adminPanel-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import AdminPanel from './AdminPanel'

const AdminPanelContainer= (props) => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const admins = useSelector(state => state.adminPanel.admins)
    const advertisings = useSelector(state => state.adminPanel.advertisings)
    const profiles = useSelector(state => state.adminPanel.profiles)
    const stores = useSelector(state => state.adminPanel.stores)
    const ratings = useSelector(state => state.adminPanel.ratings)
    const favorites = useSelector(state => state.adminPanel.favorites)
    const products = useSelector(state => state.adminPanel.products)
    const comments = useSelector(state => state.adminPanel.comments)

    const success = useSelector(state => state.adminPanel.success)
    const error = useSelector(state => state.adminPanel.error)
    const isFetching = useSelector(state => state.adminPanel.isFetching)
    const back = useSelector(state => state.adminPanel.back)

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        }
    }, [navigate, props.isAuth, props.isAdmin])

    
    useEffect(() => {
        if (isFetching) {
            dispatch(requestPanelInformations())
        }
    }, [dispatch, isFetching])


    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <AdminPanel
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            admins={admins}
            advertisings={advertisings}
            profiles={profiles}
            stores={stores}
            ratings={ratings}
            favorites={favorites}
            products={products}
            comments={comments}
            
            back={back}
        />
    )
}

export default AdminPanelContainer