import React from 'react'

const CatBeautyAccessories  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={12200}>{props.strings.cat12200}</option>
            <option value={12201}>{props.strings.cat12201}</option>
            <option value={12202}>{props.strings.cat12202}</option>
            <option value={12203}>{props.strings.cat12203}</option>
            <option value={12204}>{props.strings.cat12204}</option>
            <option value={12205}>{props.strings.cat12205}</option>
            <option value={12206}>{props.strings.cat12206}</option>
            <option value={12207}>{props.strings.cat12207}</option>
            <option value={12208}>{props.strings.cat12208}</option>
            <option value={12209}>{props.strings.cat12209}</option>
            <option value={12210}>{props.strings.cat12210}</option>
            <option value={12211}>{props.strings.cat12211}</option>
            <option value={12212}>{props.strings.cat12212}</option>
            <option value={12213}>{props.strings.cat12213}</option>
            <option value={12214}>{props.strings.cat12214}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatBeautyAccessories