import React from 'react'

const CatFoodsVegetables  = (props) => {
    return (
        <>
            <option value={0}>{props.strings.categorie}...</option>
            <option value={6701}>{props.strings.cat6701}</option>
            <option value={6702}>{props.strings.cat6702}</option>
            <option value={6703}>{props.strings.cat6703}</option>
            <option value={6705}>{props.strings.cat6705}</option>
            <option value={6709}>{props.strings.cat6709}</option>
            <option value={6710}>{props.strings.cat6710}</option>
            <option value={6711}>{props.strings.cat6711}</option>
            <option value={6712}>{props.strings.cat6712}</option>
            <option value={6713}>{props.strings.cat6713}</option>
            <option value={6714}>{props.strings.cat6714}</option>
            <option value={6715}>{props.strings.cat6715}</option>
            <option value={6716}>{props.strings.cat6716}</option>
            <option value={6717}>{props.strings.cat6717}</option>
            <option value={6718}>{props.strings.cat6718}</option>
            <option value={6719}>{props.strings.cat6719}</option>
            <option value={6720}>{props.strings.cat6720}</option>
            <option value={6721}>{props.strings.cat6721}</option>
            <option value={6722}>{props.strings.cat6722}</option>
            <option value={6723}>{props.strings.cat6723}</option>
            <option value={6724}>{props.strings.cat6724}</option>
            <option value={6725}>{props.strings.cat6725}</option>
            <option value={6726}>{props.strings.cat6726}</option>
            <option value={6727}>{props.strings.cat6727}</option>
            <option value={6728}>{props.strings.cat6728}</option>
            <option value={100000}>{props.strings.cat100000}</option>
        </>
    )
}

export default CatFoodsVegetables 