import React from 'react'

const Woman = (props) => {
    return (
        <>
            <option value={0}>{props.strings.chapter}...</option>
            <option value={11003}>{props.strings.cat11003}</option>
            <option value={11300}>{props.strings.cat11300}</option>
            <option value={11400}>{props.strings.cat11400}</option>
        </>
    )
}

export default Woman