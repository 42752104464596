import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    requestProfiles,
    setProfiles,
    setViewStatus,
    setCurrentPage,
    setSuccess,
    setError,
    setIsFetching
} from '../../redux/adminProfiles-reducer'
import {
    setSuccess as setNotifySuccess,
    setSuccessShow as setNotifySuccessShow,
    setError as setNotifyError,
    setErrorShow as setNotifyErrorShow
} from '../../redux/notify-reducer'
import { setTrashed } from '../../redux/adminProfileSet-reducer'
import AdminProfiles from './AdminProfiles'

const AdminProfilesContainer= (props) => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const profiles = useSelector(state => state.adminProfiles.profiles)

    const count = useSelector(state => state.adminProfiles.count)
    const totalCount = useSelector(state => state.adminProfiles.totalCount)
    const currentPage = useSelector(state => state.adminProfiles.currentPage)
    const pagesCount = useSelector(state => state.adminProfiles.pagesCount)

    const status = useSelector(state => state.adminProfiles.status)
    const success = useSelector(state => state.adminProfiles.success)
    const error = useSelector(state => state.adminProfiles.error)
    const viewStatus = useSelector(state => state.adminProfiles.viewStatus)
    const back = useSelector(state => state.adminProfiles.back)
    const isFetching = useSelector(state => state.adminProfiles.isFetching)

    const trashed = useSelector(state => state.adminProfileSet.trashed)

    useEffect(() => {
        if (!props.isAuth && !props.isAdmin) {
            navigate('/')
        }
    }, [navigate, props.isAuth, props.isAdmin])

    useEffect(() => {
        if (isFetching) {
            dispatch(setProfiles([]))
            dispatch(requestProfiles(count, currentPage, viewStatus))
        }
    }, [dispatch, count, currentPage, viewStatus, isFetching])

    useEffect(() => {
        if (trashed) {
            dispatch(setTrashed(false))
        }
    }, [dispatch, trashed])

    const onPageChanged = (number) => {
        dispatch(setCurrentPage(number))
        dispatch(setIsFetching(true))
    }

    const setStatus = (status) => {
        dispatch(setProfiles([]))
        dispatch(setCurrentPage(1))
        dispatch(setViewStatus(status))
        dispatch(setIsFetching(true))
    }

    useEffect(
        () => {
            if(error){
                dispatch(setNotifyError(error))
                dispatch(setNotifyErrorShow(true))
                dispatch(setError(null))
            } else if(success){
                dispatch(setNotifySuccess(success))
                dispatch(setNotifySuccessShow(true))
                dispatch(setSuccess(null))
            }
        }, [dispatch, error, success]
    )

    return (
        <AdminProfiles
            domain={props.domain}
            theme={props.theme}
            strings={props.strings}
            sitebarSlider={props.sitebarSlider}
            sitebarActivate={props.sitebarActivate}

            isAuth={props.isAuth}
            isAdmin={props.isAdmin}
            profile={props.profile}

            profiles={profiles}
            count={count}
            totalCount={totalCount}
            currentPage={currentPage}
            pagesCount={pagesCount}
            status={status}
            back={back}
            isFetching={isFetching}

            onPageChanged={onPageChanged}
            setStatus={setStatus}
        />
    )
}

export default AdminProfilesContainer